import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import ResultDialog from "../../components/groupOrder/ResultDialog";

function ResultDialogContainer() {
  const {
    result: { getJibunAddress },
  } = useSelector(({ groupOrder }: RootStateOrAny) => groupOrder);

  const { success, error } = getJibunAddress;

  const isSucceed = success.state;
  const isErrorOccured = error.state;
  const errorData = error.data;

  if (isSucceed) {
    return (
      <ResultDialog
        result={isSucceed}
        title={"🎉 지번주소변환성공 🎉"}
        text={`도착지 지번주소와 상세주소가 올바르게 변환되었는지 확인하세요\n변환이 잘못되었을 경우 해당 행을 클릭해서 주소검색 후 수정해주세요!`}
      />
    );
  }

  if (isErrorOccured) {
    return (
      <ResultDialog
        result={isErrorOccured}
        title={"🚨 지번주소변환실패 🚨"}
        text={`메인페이지로 돌아갑니다. 개발팀에게 문의해주세요\n${errorData}`}
        error={isErrorOccured}
      />
    );
  }

  return <></>;
}

export default ResultDialogContainer;
