import React from "react";

import { RootStateOrAny, useSelector } from "react-redux";

import AddressCheck from "../../components/groupOrder/AddressCheck";

function AddressCheckContainer() {
  const isAddressCheckOpen = useSelector(
    ({ groupOrder }: RootStateOrAny) => groupOrder.isAddressCheckOpen
  );

  if (!isAddressCheckOpen) {
    return <></>;
  }

  return <AddressCheck />;
}

export default AddressCheckContainer;
