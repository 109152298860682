import React from "react";

import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";

import {
  TextField,
  Chip,
  Button,
  FormControlLabel,
  Checkbox,
  Snackbar,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";

// import Notice from "../../components/common/Notice";
import { ContainerWrapper } from "../../components/common/Base";
import { RowDivider } from "../../components/common/Base";
import { WhiteSpacePaper } from "../../components/common/MaterialBase";

import Map from "../../components/sqaureBundle/Map";
import Result from "../../components/sqaureBundle/Result";

import {
  deleteReceiverAddress,
  setError,
  setMessage,
  setReceiverAddressList,
  setReset,
  setSenderAddress,
  submitSquareBundle,
  deleteSenderAddress,
  setRidersNumber,
  setIsCapital,
  setSuccess,
} from "../../modules/squareBundle";
import Notice from "../../components/common/Notice";

// import { bundleOrders } from "../../lib/api/orders";

// TODO: 변수명 수정

const RowContainerWrapper = styled(ContainerWrapper)`
  flex-direction: row;

  align-items: flex-start;

  margin-top: 0.5rem;
  margin-left: 1rem;

  height: calc(100% - 10rem);
`;

const HalfContainer = styled.div`
  width: 50%;
  height: 100%;
  padding: 0 1rem;
`;

const MarginChip = styled(Chip)`
  margin: 0.3rem;
`;

const TextWrapper = styled.div`
  height: 3rem;
  margin-top: 1rem;
  vertical-align: middle;
  text-align: center;
`;

const StyledTextBox = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const SearchButtonWrapper = styled.div`
  margin-bottom: 15px;
`;

const AddressChipWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const SquareBundleContainer = () => {
  const dispatch = useDispatch();

  const {
    isCapital,
    ridersNumber,
    senderAddress,
    receiverAddressList,
    resultPoints,
    receiverNumbers,
    log,
    success,
    error,
  } = useSelector(({ squareBundleOrder }) => squareBundleOrder);

  const loading = useSelector(({ loading }) => loading.squareBundleOrder);

  // 스낵바 닫힘
  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setSuccess(false));
    dispatch(setError(false));
  };

  const handleDeleteReceiverAddress = (chipId) => () => {
    dispatch(deleteReceiverAddress(chipId));
  };

  const handleDeleteSenderAddress = () => {
    dispatch(deleteSenderAddress());
  };

  const handleReset = () => {
    dispatch(setReset());
  };

  const handleCapital = (isCapital) => {
    dispatch(setIsCapital(isCapital));
  };

  const handleRidersNumber = (ridersNumber) => {
    dispatch(setRidersNumber(ridersNumber));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!senderAddress.name) {
      alert("출발지 주소를 입력해주세요!");
      return;
    }

    if (receiverAddressList.length === 0) {
      alert("도착지 주소를 입력해주세요!");
      return;
    }

    if (ridersNumber < 2) {
      alert("배송기사는 2명 이상부터 가능합니다!");
      return;
    }

    if (ridersNumber > 7) {
      alert("배송기사는 7명까지 가능합니다!");
      return;
    }

    dispatch(submitSquareBundle());
  };

  const openDaumPostcode = (flag) => {
    if (receiverAddressList.length === 7) {
      dispatch(setMessage("도착지는 7개까지 가능합니다"));
      dispatch(setError(true));
      return;
    }
    // 다음 주소 검색 창이 load 되면 실행
    // eslint-disable-next-line
    daum.postcode.load(() => {
      // eslint-disable-next-line
      new daum.Postcode({
        oncomplete: (data) => {
          // "b_code": "4514013400", => 00 을 빼주고 있음
          const geocoder = new window.kakao.maps.services.Geocoder();

          geocoder.addressSearch(data.address, function (results, status) {
            const address =
              results.length === 0
                ? `${data.sido} ${data.sigungu} ${data.bname}`
                : data.address;

            if (results.length === 0) {
              dispatch(
                setMessage(
                  `좌표 검색 불가지역으로 \n[${data.address}] ->\n[${address}] 으로 검색합니다`
                )
              );
            }

            if (flag === "sender") {
              dispatch(
                setSenderAddress({
                  name: address,
                  startPoint: [results[0].y, results[0].x],
                })
              );
            }

            if (flag === "receiver") {
              dispatch(
                setReceiverAddressList({
                  name: address,
                  bundlePoint: [results[0].y, results[0].x],
                })
              );
            }
          });
        },
      }).open();
    });
  };

  return (
    <>
      <TextWrapper>
        <StyledTextBox>Algolab`s Square Unit Bundle Delivery</StyledTextBox>
      </TextWrapper>
      <RowContainerWrapper>
        <Map
          senderAddress={senderAddress}
          receiverAddressList={receiverAddressList}
          resultPoints={resultPoints}
        />
        <HalfContainer>
          <SearchButtonWrapper>
            <Button
              variant="contained"
              color="default"
              size="large"
              fullWidth
              startIcon={<SearchIcon />}
              onClick={() => openDaumPostcode("sender")}
            >
              출발지 검색
            </Button>
          </SearchButtonWrapper>

          <SearchButtonWrapper>
            <Button
              variant="contained"
              color="default"
              size="large"
              fullWidth
              startIcon={<SearchIcon />}
              onClick={() => openDaumPostcode("receiver")}
            >
              도착지 검색
            </Button>
          </SearchButtonWrapper>

          <TextField
            label="배달기사"
            id="ridersNumber"
            name="ridersNumber"
            value={ridersNumber}
            variant="outlined"
            margin="normal"
            onChange={(e) => handleRidersNumber(e.target.value)}
            fullWidth
            type="number"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isCapital"
                color="secondary"
                checked={isCapital}
                onChange={(e) => handleCapital(e.target.checked)}
              />
            }
            label="수도권 전체 검색"
          />
          <AddressChipWrapper>
            {senderAddress.name && (
              <MarginChip
                key={senderAddress.name}
                label={`출발지 : ${senderAddress.name}`}
                onDelete={handleDeleteSenderAddress}
              />
            )}
          </AddressChipWrapper>
          <AddressChipWrapper>
            {receiverAddressList.length > 0 &&
              receiverAddressList.map(({ id, name }, index) => (
                <MarginChip
                  key={id}
                  label={`도착지 ${index + 1}: ${name}`}
                  onDelete={handleDeleteReceiverAddress(id)}
                />
              ))}
          </AddressChipWrapper>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            Analysis
          </Button>
          <RowDivider />
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleReset}
          >
            Reset
          </Button>
          {loading ? <WhiteSpacePaper>계산중입니다</WhiteSpacePaper> : <></>}
          {log && <WhiteSpacePaper>{log}</WhiteSpacePaper>}

          <Result
            open={receiverNumbers.length > 0}
            receiverNumbers={receiverNumbers}
          />
        </HalfContainer>
      </RowContainerWrapper>
      {success && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={success}
          autoHideDuration={5000}
          onClose={closeSnackbar}
          key={"success"}
        >
          <Notice
            variant="success"
            message={"🎉 경로계산을 완료하였습니다! 🎉"}
            onClose={closeSnackbar}
          />
        </Snackbar>
      )}
      {error && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={error}
          autoHideDuration={5000}
          onClose={closeSnackbar}
          key={"error"}
        >
          <Notice
            variant="error"
            message={"🚨 경로계산을 실패했습니다! 🚨"}
            onClose={closeSnackbar}
          />
        </Snackbar>
      )}
    </>
  );
};

export default SquareBundleContainer;
