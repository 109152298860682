import React from "react";
import { useHistory } from "react-router-dom";
import CorporationDetailContainer from "../containers/corporation/CorporationDetailContainer";
import CorporationAddContainer from "../containers/corporation/CorporationAddContainer";

export default function CorporationDetailPage() {
  const history = useHistory();
  const route = history.location.pathname.replace("/corporation/", "");
  return (
    <>
      {route === "add" ? (
        <CorporationAddContainer />
      ) : (
        <CorporationDetailContainer />
      )}
    </>
  );
}
