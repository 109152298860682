import styled from "styled-components";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
} from "@material-ui/core";

import {
  AdminAuthHeader,
  authCheckboxProps,
  AdminUserAuthsType,
} from "../../../containers/users/types";
import { ascendingFunc } from "../../../lib/core";

interface UserAuthDetailTableProps {
  authOptions: AdminUserAuthsType[];
  onAuthsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAuthsRowDelete: (e: AdminUserAuthsType) => void;
}

function UserAuthDetailTable({
  authOptions,
  onAuthsChange,
  onAuthsRowDelete,
}: UserAuthDetailTableProps) {
  return (
    <Table>
      <TableHead>
        <TitleRow>
          {AdminAuthHeader.map((header, idx) => (
            <TableCell key={idx}>{header}</TableCell>
          ))}
        </TitleRow>
      </TableHead>
      <TableBody>
        {authOptions.sort(ascendingFunc).map((row) => (
          <BodyRow key={row.menu_id}>
            <th>{row.label}</th>
            {authCheckboxProps.map(({ key }) => (
              <td key={key}>
                <FormLabel
                  control={
                    <Checkbox
                      name={`${row.menu_id} ${key}`}
                      checked={row[key]}
                      onChange={onAuthsChange}
                    />
                  }
                  label={""}
                  labelPlacement="start"
                />
              </td>
            ))}
            <td>
              <TextField
                label="외부 채널 등록"
                placeholder="ex) algoquick_g1"
                id={`${row.menu_id} custom1`}
                name={`${row.menu_id} custom1`}
                value={row.custom1 || ""}
                onChange={onAuthsChange}
                variant="outlined"
                size="small"
              />
            </td>
            <td>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => onAuthsRowDelete(row)}
              >
                삭제
              </Button>
            </td>
          </BodyRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default UserAuthDetailTable;

const TitleRow = styled(TableRow)`
  th {
    text-align: center;
  }
`;

const BodyRow = styled(TableRow)`
  height: 2.5rem;
  line-height: 2.5rem;
  border-bottom: 1px solid #e4e4e4;
  th {
    width: 15%;
    padding-left: 1rem;
    text-align: left;
    font-weight: 500;
  }
  td {
    text-align: center;
    width: 10%;
  }
`;

const FormLabel = styled(FormControlLabel)`
  margin-left: 0 !important;
`;
