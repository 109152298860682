import styled, { css } from "styled-components";
import { format } from "date-fns";

import { OrderByPkType } from "../../containers/order/types";
import {
  vehicleFilter,
  vehicleOptionFilter,
  chargeFilter,
} from "../../lib/orders/charge";
import { PAYMENTS } from "../../lib/constants/constants";

interface ContentsType {
  row: OrderByPkType;
}

function UseStatementContents({ row }: ContentsType) {
  const { usersUserByRelatedUserId, users_user, algoquick_orders } = row;
  const {
    corporate_credit_amt,
    discount_charge,
    payment_charge,
    insurance_charge,
  } = algoquick_orders[0];

  const registDate = (date: string) => {
    return format(new Date(date), "yyyy.MM.dd");
  };

  const clientInfo = [
    {
      name: "고 객 명",
      data: users_user
        ? users_user?.corporations_corporation?.name || ""
        : usersUserByRelatedUserId?.corporations_corporation?.name || "",
    },
    { name: "부 서 명", data: row.client_department },
    { name: "접 수 자", data: row.client_name },
    { name: "연 락 처", data: row.client_phone_number },
  ];
  const deliveryInfo = [
    {
      name: "배 송 일",
      data: row.aq_pickup_request_time
        ? format(new Date(row.aq_pickup_request_time), "yyyy년 MM월 dd일")
        : format(new Date(row.created), "yyyy년 MM월 dd일"),
    },
    {
      name: "출 발 지",
      data: `${row.sender_jibun_address} ${row.sender_address_detail || ""}`,
    },
    {
      name: "도 착 지",
      data: `${row.receiver_jibun_address} ${
        row.receiver_address_detail || ""
      }`,
    },
    {
      name: "배송수단",
      data:
        row.vehicle_type === "TRUCK"
          ? vehicleOptionFilter(row.vehicle_option, row.rider_name || "")
          : vehicleFilter(row.vehicle_type, row.rider_name || ""),
    },
    { name: "배 송 품", data: row.note?.split("/")[0] || "" },
    { name: "메 모", data: row.memo },
  ];

  const totalCharge =
    payment_charge - (corporate_credit_amt || 0) + insurance_charge;
  return (
    <>
      <Header className="header-container">
        <div className="header-cell title">사용 내역서</div>
        <div className="header-cell intro">
          <div className="header-info name">
            <span>접수일</span>
            <span>서비스명</span>
            <span>주문번호</span>
            <span>문의처</span>
          </div>
          <div className="header-info data">
            <span>{registDate(row.created)}</span>
            <span>퀵서비스</span>
            <span>{row.id}</span>
            <span>1666-0131</span>
          </div>
        </div>
      </Header>
      <Main className="contents-container">
        {/* 고객정보 */}
        <ul className="contents">
          <li className="contents-cell title">고객정보</li>
          {clientInfo.map((client) => (
            <li className="contents-cell intro" key={client.name}>
              <span className="contents-info name">{client.name}</span>
              <span className="contents-info data">{client.data}</span>
            </li>
          ))}
        </ul>
        {/* 배송정보 */}
        <ul className="contents">
          <li className="contents-cell title">배송정보</li>
          {deliveryInfo.map((client) => (
            <li className="contents-cell intro" key={client.name}>
              <span className="contents-info name">{client.name}</span>
              <span className="contents-info data">{client.data}</span>
            </li>
          ))}
        </ul>
        {/* 요금정보 */}
        <ul className="charges">
          <li className="charges-cell">
            <span className="charges-info name">배송요금:</span>
            <span className="charges-info data">
              ₩{chargeFilter(row.location_charge + row.extra_charge)}
            </span>
          </li>
          <li className="charges-cell">
            <span className="charges-info name">실 비:</span>
            <span className="charges-info data">
              ₩{chargeFilter(row.delivery_charge)}
            </span>
          </li>
          {(!!corporate_credit_amt || !!discount_charge) && (
            <li className="charges-cell">
              <span className="charges-info name">조정금액:</span>
              <span className="charges-info data">
                - ₩{chargeFilter((corporate_credit_amt || 0) + discount_charge)}
              </span>
            </li>
          )}
          {insurance_charge > 0 && (
            <li className="charges-cell">
              <span className="charges-info name">안심배송:</span>
              <span className="charges-info data">
                ₩{chargeFilter(insurance_charge)}
              </span>
            </li>
          )}
          <li className="charges-cell">
            <span className="charges-info name">결제방식:</span>
            <span className="charges-info data">{PAYMENTS[row.payment]}</span>
          </li>
          <li className="charges-cell">
            <span className="charges-info name">합계금액:</span>
            <span className="charges-info data">
              ₩{chargeFilter(totalCharge)}
            </span>
          </li>
          {row.payment === "ADVANCED_CASH" ||
          row.payment === "CASH_ON_DELIVERY" ? (
            <li className="charges-cell">
              <span className="charges-info name">결제금액:</span>
              <span className="charges-info data">
                ₩{chargeFilter(totalCharge)}
              </span>
            </li>
          ) : (
            <li className="charges-cell">
              <span className="charges-info name">
                결제금액(합계금액+세액):
              </span>
              <span className="charges-info data">
                ₩{chargeFilter(Math.round(totalCharge * 1.1))}
              </span>
            </li>
          )}
          <li className="algoquick-cell">
            <span className="algoquick-info">
              온라인 퀵서비스 플랫폼 알고퀵
            </span>
            <span className="algoquick-info">https://algoquick.com</span>
          </li>
        </ul>
      </Main>
    </>
  );
}
export default UseStatementContents;

// mixin
const pdfView = css`
  display: flex;
  margin: 0 auto;
  width: 90%; // pdf view
`;
const titleView = (fontSize: string) => css`
  font-size: ${fontSize};
  font-weight: 700;
`;
const dataView = (paddingLeft: string, borderBottom: string) => css`
  padding-left: ${paddingLeft};
  line-height: 1.7rem;
  border-bottom: ${borderBottom};
`;

const Header = styled.header`
  &.header-container {
    ${pdfView}
    height: 7rem;

    .header-cell {
      &.title {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        width: 25%;
        margin-right: 0.7rem;
        padding-bottom: 10px;
        font-size: 1.7rem;
        font-weight: 600;

        color: #fff;
        background-color: #363636;
      }
      &.intro {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 75%;

        .header-info {
          display: flex;
          width: calc(100% - 5px);
          height: 1.6rem;
          line-height: 1.8rem;
          span {
            width: 25%;
          }

          &.name {
            ${titleView("15px")}
            padding-left: 5px;
            line-height: 1.6rem;
          }

          &.data {
            padding-left: 5px;
            color: #797979;
            background-color: #e5e5e5;
          }
        }
      }
    }
  }
`;

const Main = styled.main`
  &.contents-container {
    ${pdfView}
    flex-direction: column;
    min-height: calc(100% - 7rem - 14rem - 1px);

    ul.contents {
      margin-top: 1.2rem;

      li.contents-cell {
        &.title {
          ${titleView("15px")}
          ${dataView("2.2rem", "3px solid #363636")}
        }
        &.intro {
          ${dataView("2.5rem", "1px solid #d1d1d1")}
          display: flex;

          .contents-info {
            &.name {
              ${titleView("14px")}
              width: 6rem;
            }
            &.data {
              padding-left: 2rem;
              width: calc(100% - 6rem);
              color: #797979;
            }
          }
        }
      }
    }

    ul.charges {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 1rem;

      li.charges-cell {
        margin-left: 2.5rem;
        text-align: right;
        line-height: 2rem;
        width: 100%;

        .charges-info {
          &.name {
            ${titleView("14px")}
            display: inline-block;
          }
          &.data {
            display: inline-block;
            padding-right: 5px;
            text-align: right;
            width: 11rem;
            color: #797979;
          }
        }

        &:nth-child(2),
        &:nth-child(4) {
          background-color: #e5e5e5;
        }
        &:nth-child(6) {
          .data {
            ${titleView("15px")}
            margin-top: 0.5rem;
            color: #363636;
          }
        }
      }

      li.algoquick-cell {
        display: flex;
        flex-direction: column;
        padding-top: 1.2rem;
        text-align: right;

        width: 100%;
        border-top: 3px solid #363636;
        .algoquick-info {
          line-height: 2rem;
          font-size: 1.5rem;
          font-weight: 700;
        }
      }
    }
  }
`;
