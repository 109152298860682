import React from "react";
import { Button } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";

interface ExcelFileDownProps {
  isIcon: boolean;
  buttonName?: string;
  color?: "primary" | "secondary";
  onClick: (e: React.MouseEvent) => void;
}

// 사용하는 상위 컴포넌트에서 "download-button"으로 스타일링
function CustomExcelDownload({
  isIcon,
  buttonName,
  color,
  onClick,
}: ExcelFileDownProps) {
  return (
    <Button
      className="download-button"
      variant="outlined"
      color={color || "default"}
      size="large"
      startIcon={isIcon ? <CloudDownload /> : null}
      onClick={onClick}
    >
      {buttonName || "엑셀양식 다운로드"}
    </Button>
  );
}

export default CustomExcelDownload;
