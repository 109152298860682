import { useMemo } from "react";
import styled from "styled-components";
import { RootStateOrAny, useSelector } from "react-redux";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { formatDate } from "../../lib/datetime";
import { reviewField } from "../../lib/validate";
import { AdminUserAuthsType } from "../../containers/users/types";
import {
  ReviewDataType,
  ReviewInfoType,
} from "../../components/riderReview/ReviewListType";
import {
  maskingPhoneNumber,
  maskingAddress,
} from "../../containers/order/utils";
import {
  FlexBox,
  FlexBoxContentEnd,
  FlexColumnCenter,
  FlexRowCenter,
  RowDivider,
  ColumnDivider,
} from "../common/Base";
import Detail from "../common/Detail";

interface detailProps {
  data: ReviewDataType;
  info: ReviewInfoType;
  handleCheckReview: () => void;
}

function ReviewDetail({
  data: {
    created,
    id,
    is_broken,
    is_delayed,
    is_unkind,
    review,
    rider_score,
    orders_rider,
    users_user: user,
  },
  info,
  handleCheckReview,
}: detailProps) {
  const adminUserAuth = useSelector(
    ({ userAuth }: RootStateOrAny) =>
      userAuth.userAuth?.data?.users_adminuserauth
  );
  /**
   * @description 메뉴명 권한
   * @returns { string{} | undefined }
   */
  const RIDERREVIEW = useMemo(
    () =>
      adminUserAuth?.find(
        ({ menu_id }: AdminUserAuthsType) => menu_id === "RIDERREVIEW"
      ),
    [adminUserAuth]
  );

  return (
    <>
      <FlexColumnCenterReview>
        <Detail>
          <FlexBox>
            <TextField
              label="등록날짜"
              id="created"
              name="created"
              value={formatDate(created) || " "}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <ColumnDivider size="2" />
            <TextField
              label="주문번호"
              id="id"
              name="id"
              value={id}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <ColumnDivider size="2" />
            <TextField
              label="확인 관리자"
              id="fullname"
              name="fullname"
              value={user?.fullname || ""}
              variant="outlined"
              margin="normal"
              fullWidth
              disabled
            />
          </FlexBox>
          <FlexBox>
            <TextField
              label="기사이름"
              id="rider_name"
              name="rider_name"
              value={reviewField(orders_rider?.rider_name)}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <ColumnDivider size="2" />
            <TextField
              label="기사연락처"
              id="rider_phone_number"
              name="rider_phone_number"
              value={reviewField(orders_rider?.rider_phone_number)}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <ColumnDivider size="2" />
            <TextField
              label="평점"
              id="rider_score"
              name="rider_score"
              value={rider_score}
              disabled
              variant="filled"
              margin="normal"
              fullWidth
            />
          </FlexBox>
          <FlexRowCenter>
            <FlexSpaceAround>
              <FormControlLabel
                disabled
                control={<Checkbox checked={is_unkind} />}
                label="불친절"
              />
              <ColumnDivider />
              <ColumnDivider />
              <FormControlLabel
                disabled
                control={<Checkbox checked={is_broken} />}
                label="배송품 파손"
              />
              <ColumnDivider />
              <ColumnDivider />
              <FormControlLabel
                disabled
                control={<Checkbox checked={is_delayed} />}
                label="배송 지연"
              />
            </FlexSpaceAround>
          </FlexRowCenter>
          <FlexBox>
            <TextField
              label="기사 후기"
              id="review"
              name="review"
              multiline
              minRows={3}
              value={reviewField(review)}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </FlexBox>
        </Detail>
        <RowDivider />
        <RowDivider />
        <Detail>
          <FlexBox>
            <TextField
              label="의뢰인 이름"
              id="client_name"
              name="client_name"
              value={reviewField(info?.client_name)}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <ColumnDivider />
            <ColumnDivider />
            <TextField
              label="의뢰인 연락처"
              id="client_phone_number"
              name="client_phone_number"
              value={maskingPhoneNumber(info?.client_phone_number, RIDERREVIEW)}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </FlexBox>
          <FlexBox>
            <TextField
              label="출발지 이름"
              id="sender_name"
              name="sender_name"
              value={reviewField(info?.sender_name)}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <ColumnDivider />
            <ColumnDivider />
            <TextField
              label="출발지 주소"
              id="sender_address"
              name="sender_address"
              value={
                maskingAddress(info?.sender_jibun_address, RIDERREVIEW) +
                " " +
                maskingAddress(info?.sender_address_detail, RIDERREVIEW)
              }
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </FlexBox>
          <FlexBox>
            <TextField
              label="도착지 이름"
              id="receiver_name"
              name="receiver_name"
              value={reviewField(info?.receiver_name)}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <ColumnDivider />
            <ColumnDivider />
            <TextField
              label="도착지 주소"
              id="receiver_address"
              name="receiver_address"
              value={
                maskingAddress(info?.receiver_jibun_address, RIDERREVIEW) +
                " " +
                maskingAddress(info?.receiver_address_detail, RIDERREVIEW)
              }
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </FlexBox>
          <RowDivider />
          <FlexBoxContentEnd>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCheckReview}
            >
              평점 확인
            </Button>
          </FlexBoxContentEnd>
        </Detail>
      </FlexColumnCenterReview>
    </>
  );
}

export default ReviewDetail;

export const FlexColumnCenterReview = styled(FlexColumnCenter)`
  margin-top: 2rem;
`;

export const FlexSpaceAround = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;
`;
