import { Dialog, DialogContent } from "@material-ui/core";
import ReOrder from "../../components/order/ReOrder";
import React from "react";
import { StyledDialogTitle } from "../../components/common/modal/Modal";

type ReOrderDetailProps = {
  reOrder: {
    id: string;
  };
  reOrderPopup: boolean;
  setReOrderPopup: (reOrderPopup: boolean) => void;
  ReOrderOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ReOrderOnSubmit: (e: React.FormEvent) => void;
};

function ReOrderDetailContainer({
  reOrder,
  reOrderPopup,
  setReOrderPopup,
  ReOrderOnChange,
  ReOrderOnSubmit,
}: ReOrderDetailProps) {
  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setReOrderPopup(false);
  };

  return (
    <Dialog
      open={reOrderPopup}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle id="alert-dialog-title">{`${reOrder.id} 재접수`}</StyledDialogTitle>
      <DialogContent>
        <ReOrder
          row={reOrder}
          onChange={ReOrderOnChange}
          reOrderClose={handleClose}
          onSubmit={ReOrderOnSubmit}
        />
      </DialogContent>
    </Dialog>
  );
}

export default ReOrderDetailContainer;
