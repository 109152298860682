import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface ResultDialogProps {
  result: boolean;
  title: string;
  text?: string;
  onClose?: () => void;
}

function ResultDialog({ result, title, text, onClose }: ResultDialogProps) {
  const [open, setOpen] = React.useState(result);
  const handleClose = () => {
    setOpen(false);
    if (onClose !== undefined) onClose();
    else window.location.href = "/";
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResultDialog;
