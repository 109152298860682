export const orderStatus = [
  {
    value: "BEFORE_WAITING",
    label: "승인대기",
  },
  {
    value: "REJECTION",
    label: "반려",
  },
  {
    value: "RESERVATION",
    label: "예약",
  },
  {
    value: "WAITING",
    label: "대기",
  },
  {
    value: "RECEIPT",
    label: "접수",
  },
  {
    value: "ALLOCATION",
    label: "배차",
  },
  {
    value: "BEFORE_PICK_UP",
    label: "픽업대기",
  },
  {
    value: "PICK_UP",
    label: "픽업",
  },
  {
    value: "BEFORE_COMPLETION",
    label: "완료대기",
  },
  {
    value: "COMPLETION",
    label: "완료",
  },
  {
    value: "CANCELLATION",
    label: "취소",
  },
];

export const kakaoOrderStatus = [
  {
    value: "WAITING",
    label: "대기",
  },
  {
    value: "RECEIPT",
    label: "접수",
  },
  {
    value: "ALLOCATION",
    label: "배차",
  },
  {
    value: "BEFORE_PICK_UP",
    label: "픽업대기",
  },
  {
    value: "PICK_UP",
    label: "픽업",
  },
  {
    value: "BEFORE_COMPLETION",
    label: "완료대기",
  },
  {
    value: "COMPLETION",
    label: "완료",
  },
];
