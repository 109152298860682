import graphqlQuery from "./base";

export const getNetworkTerminals = (data: {
  type: string;
  receiverL1: string;
  receiverL2: string;
  senderL1: string;
  senderL2: string;
}) =>
  graphqlQuery(`{
    algoquick_network(
      where: {
        _and: {
          network_type: {_eq: "${data.type}"},
          receiver_l1_code_id: {_eq: "${data.receiverL1}"},
          ${
            data.receiverL2 &&
            `receiver_l2_code_id: {_eq: "${data.receiverL2}"},`
          }
          sender_l1_code_id: {_eq: "${data.senderL1}"},
          ${data.senderL2 && `sender_l2_code_id: {_eq: "${data.senderL2}"},`}
        }
    }) {
      id
      network_type
      receiver_locationl1 {
        name
      }
      receiver_locationl2 {
        name
      }
      receiver_network_name
      sender_locationl2 {
        name
      }
      sender_locationl1 {
        name
      }
      sender_network_name
    }
  }`);

export const createNetworkTerminal = (data: {
  network_type: string;
  receiver_l1_code_id: string;
  receiver_l2_code_id: string;
  receiver_network_bcode_id: string;
  receiver_network_name: string;
  sender_l1_code_id: string;
  sender_l2_code_id: string;
  sender_network_bcode_id: string;
  sender_network_name: string;
}) => {
  const timestamp = new Date().toISOString();
  return graphqlQuery(`
    mutation {
      insert_algoquick_network(
        objects: {
          created: "${timestamp}",
          modified: "${timestamp}",
          network_type: "${data.network_type}",
          receiver_l1_code_id: "${data.receiver_l1_code_id}",
          receiver_l2_code_id: "${data.receiver_l2_code_id}",
          receiver_network_bcode_id: "${data.receiver_network_bcode_id}",
          receiver_network_name: "${data.receiver_network_name}",
          sender_l1_code_id: "${data.sender_l1_code_id}",
          sender_network_bcode_id: "${data.sender_network_bcode_id}",
          sender_l2_code_id: "${data.sender_l2_code_id}",
          sender_network_name: "${data.sender_network_name}"
        }) {
          returning {
            id
          }
        }
    }`);
};

export const deleteNetworkTerminal = (id: number) =>
  graphqlQuery(`
    mutation {
      delete_algoquick_network_by_pk(id: ${id}) {
        id
      }
    }`);
