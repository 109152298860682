import axios from "axios";

export const hasuraUrl = process.env.REACT_APP_HASURA_SERVER;
const algoAdmin = process.env.REACT_APP_ALGOLAB_TOKEN;

// graphql header에 => localStorage 토큰을 비밀번호로 연동하여 사용
const graphqlQuery = (query) => {
  /* Algolab 계정 */
  if (sessionStorage.authorization === algoAdmin) {
    return axios({
      url: hasuraUrl,
      method: "post",
      headers: {
        "x-hasura-admin-secret": sessionStorage.getItem("authorization"),
      },
      data: {
        query,
      },
    });
  } else {
    /* Algolab 외 계정 */
    return axios({
      url: hasuraUrl,
      method: "post",
      headers: {
        Authorization: `Algolab ${sessionStorage.getItem("authorization")}`,
      },
      data: {
        query,
      },
    });
  }
};

export default graphqlQuery;
