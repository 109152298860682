import { MenuItem } from "@material-ui/core";
import React from "react";
import { HeaderText, SelectWrapper } from "../../pages/GroupOrder/style";

type paymentFormProps = {
  payment: string;
  onChange: any;
};

function PaymentForm({ payment, onChange }: paymentFormProps) {
  return (
    <>
      <HeaderText>지불방법</HeaderText>

      <SelectWrapper onChange={onChange} value={payment} name="payment">
        <MenuItem value={"CORPORATE_ACCOUNT"}>법인신용</MenuItem>
        <MenuItem value={"ADVANCED_CASH"}>현금선불</MenuItem>
      </SelectWrapper>
    </>
  );
}

export default PaymentForm;
