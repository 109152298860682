import { Button, Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import styled from "styled-components";

/**
 * @property { boolean } open - open state
 * @property { function } onClose - setOpen(false)
 * @property { function } onEvent - 진행할 이벤트 함수
 * @property { string } message - 확인 메시지 내용
 * @property { string } yesButton - 확인 버튼명
 * @property { string } noButton - 취소 버튼명
 */
interface ConfirmModalProps {
  open: boolean;
  onClose: () => void;
  onEvent: () => void;
  message?: string;
  yesButton?: string;
  noButton?: string;
}

function ConfirmModal({
  open,
  onClose,
  onEvent,
  message = "정말로 진행하시겠습니까?",
  yesButton = "예",
  noButton = "아니오",
}: ConfirmModalProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogSection>
        <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
        <DialogActions>
          <Button onClick={onEvent} color="primary" autoFocus>
            {yesButton}
          </Button>
          <Button onClick={onClose} color="primary">
            {noButton}
          </Button>
        </DialogActions>
      </DialogSection>
    </Dialog>
  );
}

export default ConfirmModal;

const DialogSection = styled.section`
  padding: 10px;
  width: 400px;
`;
