import { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  useSelector,
  useDispatch,
  RootStateOrAny,
  shallowEqual,
} from "react-redux";
import axios from "axios";
import AuthForm from "../../components/auth/AuthForm";
import AuthThreshold from "../../components/auth/AuthThreshold";
import AuthThreeMonth from "../../components/auth/AuthThreeMonth";
import IpBlockingPage from "../../pages/IpBlockingPage";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { login, initializeForm } from "../../modules/auth";
import { checkAuth, userIP } from "../../modules/userAuth";
import { check } from "../../modules/user";
import { postLog } from "../../lib/hasura/users";
import { LOG_TYPE } from "../../lib/constants/constants";

// import { checkOTP, googleOTP } from "../../lib/api/auth";
import { googleOTP } from "../../lib/api/auth";

function LoginForm({ history }: RouteComponentProps) {
  const dispatch = useDispatch();

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  // const [ipBlock, setIpBlock] = useState(false); // IP 외부 접근 제한
  // const [isOtp, setIsOtp] = useState(""); // otp 등록여부
  const [ipBlock] = useState(false);
  const [isOtp, setIsOtp] = useState("ip");

  const [ipAddress, setIpAddress] = useState(""); // IP 주소
  const [google, setGoogle] = useState(""); // google otp

  const [tenTime, setTenTime] = useState(false); // 로그인 실패 임계값
  const [threeMonth, setThreeMonth] = useState(false); // 3개월 비번 변경

  const [error, setError] = useState(null);

  const [token, authError, user, checkError] = useSelector(
    ({ user, auth }: RootStateOrAny) => [
      auth.token,
      auth.authError,
      user.user,
      user.checkError,
    ],
    shallowEqual
  );

  // // 로그인시 IP 주소 불러오기
  // const ipData = async () => {
  //   try {
  //     const ipData = await fetch("https://geolocation-db.com/json/");
  //     const locationIp = await ipData.json();
  //     const ip = locationIp?.IPv4 || "";

  //     setIpAddress(ip);
  //     dispatch(userIP(ip));
  //   } catch (err) {
  //     dispatch(finishGlobalLoading());
  //     setIpAddress("");
  //     alert("IP를 불러오지 못하였습니다. 잠시후 다시 시도해주세요.");
  //   }
  // };
  // // 로그인시 OTP 체크
  // const postCheckOTP = async (ip: string) => {
  //   try {
  //     const {
  //       data: { auth },
  //     } = await checkOTP(ip);
  //     setIsOtp(auth);
  //   } catch (err) {
  //     dispatch(finishGlobalLoading());
  //     sessionStorage.clear();

  //     if (axios.isAxiosError(err)) {
  //       // 외부 IP 차단
  //       if (err.response?.status === 401) setIpBlock(true);
  //     }
  //   }
  // };

  // google OTP
  const getGoogleOTP = async (otp: string) => {
    try {
      const { data } = await googleOTP(otp);
      setGoogle(data?.message);
    } catch (err) {
      dispatch(finishGlobalLoading());
      initiallizeAllData();

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 400) {
          alert("OTP값이 일치하지 않습니다."); // 'otp 키값 미발급', 'otp 키값 미일치'
        } else if (err.response?.status === 404) {
          alert("OTP값을 입력하지 않았습니다.");
        } else alert("(OTP 에러) 개발팀에 문의해주세요.");
      }
    }
  };

  const initiallizeAllData = () => {
    sessionStorage.clear();
    dispatch(initializeForm());
    setIsOtp("");
    setIpAddress("");
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    switch (name) {
      case "username":
        return setUserName(value);
      case "password":
        return setPassword(value);
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(login({ username, password })); // auth, token 등록
  };

  /**
   * @description user 등록시 로그인
   * @event dispatch(check())
   */
  useEffect(() => {
    if (user) {
      try {
        dispatch(checkAuth(user.id)); // 유저 권한 등록 (userAuth)
        postLog(user.id, ipAddress, LOG_TYPE.LOGIN, "로그인", null);
        history.push("/");
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(finishGlobalLoading());
      }
    }
  }, [user]);

  useEffect(() => {
    // 임계값 5회 초과
    if (authError) {
      if (authError.includes("임계값")) {
        alert("5회 잘못 입력하였습니다. 비밀번호 변경화면으로 이동합니다.");
        setTenTime(true);
      } else setError(authError);
    }
  }, [authError]);

  useEffect(() => {
    if (checkError) {
      const msg = "일시적 오류로 현재 로그인이 불가합니다.";
      // 비밀번호 변경 3개월
      if (checkError.response?.status === 400) setThreeMonth(true);
      else setError(checkError.response?.detail || msg);

      dispatch(finishGlobalLoading());
    }
  }, [checkError]);

  /**
   * @description 개인정보 보호 관련 로직
   */
  useEffect(() => {
    // // ip API
    // if (token && !ipAddress) {
    //   dispatch(startGlobalLoading());
    //   ipData(); // ipAddress 등록
    // }

    // // otp API
    // if (token && ipAddress && !isOtp) {
    //   postCheckOTP(ipAddress); // isOtp 등록
    // }

    // otp 입력 확인
    if (token && isOtp && !google) {
      dispatch(startGlobalLoading());
      if (isOtp === "ip" || isOtp === "customer") {
        dispatch(check()); // ip, customer => 여기서 user API 호출
      } else {
        let sign = prompt("구글 OTP를 입력하세요") || "";
        getGoogleOTP(sign);
      }
    }

    // '구글 OTP를 입력하세요'
    if (google === "ok") dispatch(check()); // otp => 여기서 user API 호출
  }, [token, ipAddress, isOtp, google]);

  return (
    <>
      {ipBlock ? (
        <IpBlockingPage />
      ) : (
        <>
          <AuthForm
            username={username}
            password={password}
            onChange={onChange}
            onSubmit={onSubmit}
            error={error}
          />
          {tenTime && (
            <AuthThreshold tenTime={tenTime} setTenTime={setTenTime} />
          )}
          {threeMonth && <AuthThreeMonth setThreeMonth={setThreeMonth} />}
        </>
      )}
    </>
  );
}

export default withRouter(LoginForm);
