import React from "react";
import styled from "styled-components";
import OrderMissedList from "./OrderMissedList";
import OrdersDelayed from "./OrdersDelayed/OrdersDelayed";

const StyledWrapper = styled.div`
  width: 100%;
  margin-top: 0.2rem;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const StyledOrderMissedLists = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const OrdersMissed = ({ orderMissedProps }) => {
  return (
    <>
      <StyledWrapper>
        <StyledOrderMissedLists>
          <OrderMissedList type="isd" orderMissedProps={orderMissedProps} />
          {/* 인성에만 있고 알고퀵에는 없는 주문 */}
          <OrderMissedList
            type="algoquick"
            orderMissedProps={orderMissedProps}
          />
          {/* 알고퀵에만 있고 인성에는 없는 주문 */}
        </StyledOrderMissedLists>
        <OrdersDelayed orderMissedProps={orderMissedProps} />
        {/* 지연중인 주문 */}
      </StyledWrapper>
    </>
  );
};

export default OrdersMissed;
