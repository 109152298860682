import { useEffect } from "react";
import { Route } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import HeaderContainer from "./containers/common/HeaderContainer";
import MenuPage from "./pages/MenuPage";
import LoginPage from "./pages/LoginPage";
import OrdersListPage from "./pages/OrdersListPage";
import GroupOrderPage from "./pages/GroupOrder";
import OrdersOmissionPage from "./pages/OrdersOmissionPage";
import OrderDetailPage from "./pages/OrderDetailPage";
import OrdersDashboardPage from "./pages/OrdersDashboardPage";
import BundlePage from "./pages/BundlePage";
import NetworkPage from "./pages/NetworkPage";
import NetworkAddPage from "./pages/NetworkAddPage";
import CorporationsPage from "./pages/CorporationsPage";
import CorporationDetailPage from "./pages/CorporationDetailPage";
import CorporationDetailBulkPage from "./pages/CorporationDetailBulkPage";
import CorporationExtraChargePage from "./pages/CorporationExtraChargePage";
import DepartmentsPage from "./pages/DepartmentsPage";
import DepartmentDetailPage from "./pages/DepartmentDetailPage";
import UsersPage from "./pages/UsersPage";
import UserDetailPage from "./pages/UserDetailPage";
import SettingsPage from "./pages/SettingsPage";
import SchedulerPage from "./pages/SchedulerPage";
import SendBillPage from "./pages/SendBillPage";
import SquareBundle from "./pages/SquareBundle";
import SingleCorpChargePage from "./pages/SingleCorpChargePage";
import RiderReviewPage from "./pages/RiderReviewPage";
import RiderDetailPage from "./pages/RiderDetailPage";
import LargeChargePage from "./pages/LargeChargePage";
import LargeAddressPage from "./pages/LargeAddressPage";
import AddressDetailPage from "./pages/AddressDetailPage";
import InquiryListPage from "./pages/InquiryListPage";
import InquiryDetailPage from "./pages/InquiryDetailPage";
import UsersAuthPage from "./pages/UsersAuthPage";
import UsersAuthDetailPage from "./pages/UsersAuthDetailPage";
import LogListPage from "./pages/LogListPage";
import CreditHistoryPage from "./pages/CreditPage";
import CircularLoading from "./components/common/CircularLoading";
import { checkAuth } from "./modules/userAuth";
import PrivateRoute from "./components/common/PrivateRoute";

function App() {
  const dispatch = useDispatch();
  const [userId, globalLoading] = useSelector(
    ({ user, loading }: RootStateOrAny) => [user.user?.id, loading.global]
  );

  useEffect(() => {
    /* 최상단이라 새로고침해도 checkAuth() 살아남음 */
    if (userId) dispatch(checkAuth(userId));
  }, [userId]);

  return (
    <>
      <HeaderContainer />
      <Route component={MenuPage} path={"/"} exact />
      <Route component={LoginPage} path={"/login"} exact />
      <PrivateRoute
        routeName={"ORDERS"}
        component={OrdersListPage}
        path={"/orders"}
        exact
      />
      <PrivateRoute
        routeName={"ORDERS"}
        component={OrdersOmissionPage}
        path={"/orders/omission"}
        exact
      />
      <PrivateRoute
        routeName={"ORDERS"}
        component={OrderDetailPage}
        path={"/order/:id"}
        exact
      />
      <PrivateRoute
        routeName={"ORDERS"}
        component={OrdersDashboardPage}
        path={"/orders/dashboard"}
        exact
      />
      <PrivateRoute
        routeName={"GROUPORDERS"}
        component={GroupOrderPage}
        path={"/group-orders"}
        exact
      />
      <PrivateRoute
        routeName={"BUNDLE"}
        component={BundlePage}
        path={"/bundle"}
        exact
      />
      <PrivateRoute
        routeName={"SQUAREBUNDLE"}
        component={SquareBundle}
        path={"/square-bundle"}
        exact
      />
      <PrivateRoute
        routeName={"NETWORK"}
        component={NetworkPage}
        path={"/network"}
        exact
      />
      <PrivateRoute
        routeName={"NETWORK"}
        component={NetworkAddPage}
        path={"/network/add"}
        exact
      />
      <PrivateRoute
        routeName={"CORPORATIONS"}
        component={CorporationsPage}
        path={"/corporations"}
        exact
      />
      <PrivateRoute
        routeName={"CORPORATIONS"}
        component={CorporationDetailPage}
        path={"/corporation/:id"}
        exact
      />
      <PrivateRoute
        routeName={"CORPORATIONS"}
        component={CorporationDetailBulkPage}
        path={"/corporation/:id/bulk"}
        exact
      />
      <PrivateRoute
        routeName={"CORPORATIONS"}
        component={CorporationExtraChargePage}
        path={"/corporation/:id/extra"}
        exact
      />
      <PrivateRoute
        routeName={"CORPORATIONS"}
        component={CreditHistoryPage}
        path={"/credit-history"}
        exact
      />
      <PrivateRoute
        routeName={"CORPORATIONS_DEPARTMENTS"}
        component={DepartmentsPage}
        path={"/departments"}
        exact
      />
      <PrivateRoute
        routeName={"CORPORATIONS_DEPARTMENTS"}
        component={DepartmentDetailPage}
        path={"/department/:id"}
        exact
      />
      <PrivateRoute
        routeName={"CORPORATIONS_CHARGE"}
        component={SingleCorpChargePage}
        path={"/coporate-charge"}
        exact
      />
      <PrivateRoute
        routeName={"USERS"}
        component={UsersPage}
        path={"/users"}
        exact
      />
      <PrivateRoute
        routeName={"USERS"}
        component={UserDetailPage}
        path={"/user/:id"}
        exact
      />
      <PrivateRoute
        routeName={"USERS_AUTH"}
        component={UsersAuthPage}
        path={"/users/auth"}
        exact
      />
      <PrivateRoute
        routeName={"USERS_AUTH"}
        component={UsersAuthDetailPage}
        path={"/users/auth/:id"}
        exact
      />
      <PrivateRoute
        routeName={"SCHEDULER"}
        component={SchedulerPage}
        path={"/scheduler"}
        exact
      />
      <PrivateRoute
        routeName={"SENDBILL"}
        component={SendBillPage}
        path={"/send-bill"}
        exact
      />
      <PrivateRoute
        routeName={"ADDRESSDETAIL"}
        component={AddressDetailPage}
        path={"/addressdetail"}
        exact
      />
      <PrivateRoute
        routeName={"LARGECHARGE"}
        component={LargeAddressPage}
        path={"/large-address"}
        exact
      />
      <PrivateRoute
        routeName={"LARGECHARGE"}
        component={LargeChargePage}
        path={"/large-charge"}
        exact
      />
      <PrivateRoute
        routeName={"INQUIRY"}
        component={InquiryListPage}
        path={"/inquiry"}
        exact
      />
      <PrivateRoute
        routeName={"INQUIRY"}
        component={InquiryDetailPage}
        path={"/inquiry/:id"}
        exact
      />
      <PrivateRoute
        routeName={"RIDERREVIEW"}
        component={RiderReviewPage}
        path={"/rider-review"}
        exact
      />
      <PrivateRoute
        routeName={"RIDERREVIEW"}
        component={RiderDetailPage}
        path={"/rider-review/:id"}
        exact
      />
      <PrivateRoute
        routeName={"LOGS"}
        component={LogListPage}
        path={"/logs"}
        exact
      />
      <PrivateRoute
        routeName={"SETTINGS"}
        component={SettingsPage}
        path={"/settings"}
        exact
      />
      {globalLoading && <CircularLoading />}
    </>
  );
}

export default App;
