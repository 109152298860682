import React from "react";
import { Button } from "@material-ui/core";

type SendButtonContainer = {
  isSendingAvailable: boolean;
  postExcelBill: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export default function SendButtonContainer({
  isSendingAvailable,
  postExcelBill,
}: SendButtonContainer) {
  if (!isSendingAvailable) {
    return <></>;
  }

  return (
    <>
      <Button
        className="bill-button"
        variant="contained"
        color="secondary"
        size="large"
        onClick={postExcelBill}
      >
        청구서 발송진행
      </Button>
    </>
  );
}
