import React, { useReducer, useEffect, useState, ChangeEvent } from "react";
import { Snackbar } from "@material-ui/core";
import SettingsList from "../../components/settings/SettingsList";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";
import { SettingsState, SettingsLogType } from "./types";
import { getSettings, setSettings } from "../../lib/hasura/settings";
import { destructResponse } from "../../lib/hasura/common";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";

const INITIAL_STATE = {
  weather: 0,
  notice: "",
  isIcyRoad: false,
};

const addWeather = (num: number) => ({
  type: "weather",
  payload: num,
});
const addNotice = (text: string) => ({
  type: "notice",
  payload: text,
});
const addIcyRoad = (bol: boolean) => ({
  type: "isIcyRoad",
  payload: bol,
});
const resetSettings = () => ({
  type: "reset",
  payload: null,
});

type SettingsAction =
  | ReturnType<typeof addWeather>
  | ReturnType<typeof addNotice>
  | ReturnType<typeof addIcyRoad>
  | ReturnType<typeof resetSettings>;

function reducer(state: SettingsState, action: SettingsAction): SettingsState {
  switch (action.type) {
    case "weather":
    case "notice":
    case "isIcyRoad":
      return {
        ...state,
        [action.type]: action.payload,
      };
    case "reset":
      return INITIAL_STATE;
    default:
      return state;
  }
}

function SettingsContainer() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { weather, notice, isIcyRoad } = state;
  const [settingsLog, setSettingsLog] = useState<SettingsLogType[]>([]);
  const [log, setLog] = useState(false);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchSettingsData();
  }, []);

  const fetchSettingsData = async () => {
    dispatch(startGlobalLoading());
    try {
      const response = await destructResponse<SettingsLogType[]>(
        "algoquick_notice",
        () => getSettings()
      );

      dispatch({
        type: "weather",
        payload: response[0].charge,
      });
      dispatch({
        type: "notice",
        payload: response[0].notice,
      });
      dispatch({
        type: "isIcyRoad",
        payload: response[0].is_icy_road,
      });

      setSettingsLog(response);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const updateSettingsData = async ({
    weather,
    notice,
    isIcyRoad,
  }: SettingsState) => {
    dispatch(startGlobalLoading());
    try {
      const {
        data: { errors },
      } = await setSettings(notice, weather, isIcyRoad);
      if (errors !== undefined) throw new Error(errors[0].message);

      openSnackbar("변경되었습니다.");
      fetchSettingsData();
    } catch (err) {
      openSnackbar(`변경에 실패하였습니다.\n${(err as Error).message}`, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const reset = () => {
    dispatch({ type: "reset", payload: null });
    updateSettingsData(INITIAL_STATE);
  };

  const onChange = (
    e: React.ChangeEvent<{
      name: string;
      value: string | number;
      checked?: boolean;
    }>
  ) => {
    const { name, value, checked } = e.target;
    switch (name) {
      case "isIcyRoad":
        dispatch({
          type: name,
          payload: checked ?? false,
        });
        break;
      default:
        dispatch({
          type: name,
          payload: value,
        });
    }
  };

  const onSubmit = () => updateSettingsData(state);

  const handleLog = () => setLog(!log);

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <SettingsList
        weather={weather}
        invalidWeather={!/^0$|^([1-9]\d*000)$/.test("" + weather)}
        notice={notice}
        isIcyRoad={isIcyRoad}
        onChange={onChange}
        onSubmit={onSubmit}
        reset={reset}
        handleLog={handleLog}
        log={log}
        settingsLog={settingsLog}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default SettingsContainer;
