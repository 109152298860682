import axios from "axios";
import { http } from "./auth";

const getIsdOrdersMissed = () => http.get(`/order/missed-isd-order`);
const getAlgoOrdersMissed = () => http.get(`/order/missed-order`);
const getOrdersDelayed = () => http.get(`/order/delayed-order`);
const getOrdersCompared = () => http.get(`/order/compared-order`);

const requestOrdersToBeChecked = () =>
  axios
    .all([
      getIsdOrdersMissed(),
      getAlgoOrdersMissed(),
      getOrdersDelayed(),
      getOrdersCompared(),
    ])
    .then(
      axios.spread((isdOrders, algoOrders, ordersDelayed, ordersCompared) => ({
        isdOrders,
        algoOrders,
        ordersDelayed,
        ordersCompared,
      }))
    )
    .catch((err) => console.error(err));

export { requestOrdersToBeChecked };
