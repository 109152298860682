import React, { useState } from "react";
import styled from "styled-components";
import ReceiverDialog from "./ReceiverDialog";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import {
  removeComma,
  convertVehicleTypeToKorean,
} from "../../pages/GroupOrder/utils";
import { vehicleOptionFilter } from "../../lib/orders/charge";

interface ResultTableProps {
  data: any;
}

interface dialogStateInterface {
  open: boolean;
  index: number;
}

function ReceiverTable({ data }: ResultTableProps) {
  const classesRow = useStylesRow();
  const classesCell = useStylesCell();

  const initialState: dialogStateInterface = { open: false, index: 0 };
  const [dialogState, setDialogState] = useState(initialState);

  const handleDialog = (state: boolean) => () => {
    setDialogState({ ...dialogState, open: state });
  };

  const openDialog = (index: number) => () => {
    setDialogState({ open: true, index });
  };
  return (
    <>
      <TableWrapper>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className={classesRow.head}>
                <TableCell>{`출발지이름\n(연락처)`}</TableCell>
                <TableCell>{`출발지\n지번주소`}</TableCell>
                <TableCell>{`출발지\n상세주소`}</TableCell>
                <TableCell>{`도착지이름\n(연락처)`}</TableCell>
                <TableCell>{`도착지\n지번주소`}</TableCell>
                <TableCell>{`도착지\n상세주소`}</TableCell>
                <TableCell>배송품</TableCell>
                <TableCell>{`차량종류\n차량옵션\n요금`}</TableCell>
                <TableCell>메모</TableCell>
                <TableCell>픽업예약시간</TableCell>
                <TableCell>도착시간범위</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any, index: number) => (
                <TableRow
                  className={classesRow.root}
                  hover={true}
                  key={`${row.receiverName}${row.receiverPhoneNumber}`}
                  onClick={openDialog(index)}
                >
                  {row.senderJibunAddress === "주소 확인이 필요합니다." ||
                  row.receiverJibunAddress === "주소 확인이 필요합니다." ? (
                    <>
                      <TableCell
                        className={classesCell.error}
                      >{`${row.senderName}\n(${row.senderPhoneNumber})`}</TableCell>
                      <TableCell className={classesCell.error}>
                        {row.senderJibunAddress}
                      </TableCell>
                      <TableCell className={classesCell.error}>
                        {row.senderAddressDetail}
                      </TableCell>
                      <TableCell
                        className={classesCell.error}
                      >{`${row.receiverName}\n(${row.receiverPhoneNumber})`}</TableCell>
                      <TableCell className={classesCell.error}>
                        {row.receiverJibunAddress}
                      </TableCell>
                      <TableCell className={classesCell.error}>
                        {row.receiverAddressDetail}
                      </TableCell>
                      <TableCell className={classesCell.error}>
                        {row.deliveryItem}
                      </TableCell>
                      <TableCell className={classesCell.error}>
                        {`${convertVehicleTypeToKorean(
                          row.vehicleType
                        )}\n${vehicleOptionFilter(row.vehicleOption)}\n${
                          row.charge && removeComma(row.charge)
                        }`}
                      </TableCell>
                      <TableCell className={classesCell.error}>
                        {row.memo}
                      </TableCell>
                      <TableCell className={classesCell.error}>{`${
                        row.pickupRequestTime ? row.pickupRequestTime : ""
                      }`}</TableCell>
                      <TableCell className={classesCell.error}>{`${
                        row.completeRequestTime.lower
                          ? row.completeRequestTime.lower
                          : ""
                      }~\n${
                        row.completeRequestTime.upper
                          ? row.completeRequestTime.upper
                          : ""
                      }`}</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>{`${row.senderName}\n(${row.senderPhoneNumber})`}</TableCell>
                      <TableCell>{row.senderJibunAddress}</TableCell>
                      <TableCell>{row.senderAddressDetail}</TableCell>
                      <TableCell>{`${row.receiverName}\n(${row.receiverPhoneNumber})`}</TableCell>
                      <TableCell>{row.receiverJibunAddress}</TableCell>
                      <TableCell>{row.receiverAddressDetail}</TableCell>
                      <TableCell>{row.deliveryItem}</TableCell>
                      <TableCell>
                        {`${convertVehicleTypeToKorean(
                          row.vehicleType
                        )}\n${vehicleOptionFilter(row.vehicleOption)}\n${
                          row.charge && removeComma(row.charge)
                        }`}
                      </TableCell>
                      <TableCell>{row.memo}</TableCell>
                      <TableCell>{`${
                        row.pickupRequestTime ? row.pickupRequestTime : ""
                      }`}</TableCell>
                      <TableCell>{`${
                        row.completeRequestTime.lower
                          ? row.completeRequestTime.lower
                          : ""
                      }~\n${
                        row.completeRequestTime.upper
                          ? row.completeRequestTime.upper
                          : ""
                      }`}</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
      {dialogState.open && (
        <ReceiverDialog
          dialogState={dialogState}
          handleDialog={handleDialog}
          data={data}
        />
      )}
    </>
  );
}

export default ReceiverTable;

const useStylesRow = makeStyles(() => ({
  head: {
    backgroundColor: "whitesmoke",
    whiteSpace: "pre-line",
  },
  root: {
    backgroundColor: `#fff`,
    whiteSpace: "pre-line",
    "&:hover": {
      backgroundColor: "whitesmoke",
      cursor: "pointer",
    },
  },
}));
const useStylesCell = makeStyles(() => ({
  error: {
    backgroundColor: "#fdecea",
    whiteSpace: "pre-line",
  },
}));

const TableWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
`;
