import { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { AdminUserAuthsType } from "../../containers/users/types";

type RouteProps = {
  routeName: string;
  exact?: boolean;
  path: string;
  component: any;
};

function PrivateRoute({ routeName, component, ...rest }: RouteProps) {
  const isLogin = sessionStorage.getItem("authorization");
  const adminUserAuth = useSelector(
    ({ userAuth }: RootStateOrAny) =>
      userAuth.userAuth?.data?.users_adminuserauth
  );

  const adminAuth = useMemo(
    () =>
      adminUserAuth?.find(({ menu_id }: AdminUserAuthsType) => {
        return menu_id === routeName;
      }),
    [adminUserAuth]
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (adminAuth?.search)
          return <Route {...props} component={component} />;
        else {
          alert("해당 페이지의 권한이 없습니다.");
          return isLogin ? (
            // 회원은 홈으로
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          ) : (
            // 비회원은 로그인으로
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
}

export default PrivateRoute;
