import { Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { SheetJSFT } from "../../pages/GroupOrder/utils";

interface ExcelUploadProps {
  isIcon: boolean;
  buttonName?: string;
  fileName?: string;
  color?: "primary" | "secondary";
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// 사용하는 상위 컴포넌트에서 "upload-button"으로 스타일링
function CustomExcelUpload({
  isIcon,
  buttonName,
  fileName,
  color,
  onChange,
}: ExcelUploadProps) {
  return (
    <>
      <Button
        className="upload-button"
        variant="outlined"
        color={color || "default"}
        size="large"
        component="label"
        htmlFor="excel-upload"
        startIcon={isIcon ? <CloudUpload /> : null}
      >
        <input
          type="file"
          className="form-control"
          id="excel-upload"
          accept={SheetJSFT}
          style={{ display: "none" }}
          onChange={onChange}
        />
        {buttonName || "엑셀파일 업로드"}
      </Button>
      <p>{fileName && `파일이름 : ${fileName}`}</p>
    </>
  );
}

export default CustomExcelUpload;
