import { handleActions } from "redux-actions";
import { call, put, takeLatest } from "redux-saga/effects";
import produce from "immer";
import { format } from "date-fns";

import * as orderAPI from "../lib/api/orders";
import { finishLoading, startLoading } from "./loading";

export const READ_EXCEL = "sendBill/READ_EXCEL";
export const POST_EXCEL = "sendBill/POST_EXCEL";
export const SELECT_DATE = "sendBill/SELECT_DATE";
export const INPUT_SENDERMAIL = "sendBill/INPUT_SENDERMAIL";
export const SET_BILL_DATE = "sendBill/SET_BILL_DATE";
export const SET_ADD_EXTRA_CHARGE = "sendBill/SET_ADD_EXTRA_CHARGE";

const initialState = {
  excelData: [],
  date: "",
  dateRange: [
    {
      startDate: undefined,
      endDate: undefined,
      key: "selection",
    },
  ],
  sender: {
    emailAddress: "",
    emailTitle: "",
    emailText: "",
  },
  isRangeChangeAvailable: false,
  isUploadingAvailable: false,
  isSendingAvailable: false,
  result: {
    success: false,
    errorMessage: "",
  },
  billDate: format(new Date(), "yyyy-MM"),
  extraCharge: false,
};

export function readExcel(excel) {
  return { type: READ_EXCEL, payload: excel };
}

export function postExcel(billInfo) {
  return { type: POST_EXCEL, payload: billInfo };
}

export function resetPostExcelResult() {
  return { type: `${POST_EXCEL}_RESET` };
}

export function selectDate(date) {
  return { type: SELECT_DATE, payload: date };
}

export function inputSenderMail({ name, value }) {
  return { type: INPUT_SENDERMAIL, payload: { name, value } };
}

export function setBillDate(billDate) {
  return { type: SET_BILL_DATE, payload: billDate };
}

export function setAddExtraCharge(extraCharge) {
  return { type: SET_ADD_EXTRA_CHARGE, payload: extraCharge };
}

const sendBill = handleActions(
  {
    [INPUT_SENDERMAIL]: (state, { payload: { name, value } }) => {
      return produce(state, (draft) => {
        draft.sender[name] = value;
        draft.isRangeChangeAvailable =
          draft.sender.emailAddress !== "" &&
          draft.sender.emailTitle !== "" &&
          draft.sender.emailText !== "";
      });
    },
    [SET_BILL_DATE]: (state, { payload: billDate }) => {
      return produce(state, (draft) => {
        draft.billDate = format(new Date(billDate), "yyyy-MM");
      });
    },
    [SELECT_DATE]: (state, action) => {
      return produce(state, (draft) => {
        const dateRange = action.payload[0];
        const startDate = format(new Date(dateRange.startDate), "yyyy-MM-dd");
        const endDate = format(new Date(dateRange.endDate), "yyyy-MM-dd");
        draft.isUploadingAvailable = true;
        draft.dateRange = action.payload;

        draft.date = `${startDate} ~ ${endDate}`;
      });
    },

    [READ_EXCEL]: (state, action) => {
      return produce(state, (draft) => {
        const excelData = action.payload;
        draft.isSendingAvailable = true;
        draft.excelData = excelData;
      });
    },

    [`${POST_EXCEL}_SUCCESS`]: (state) => {
      return produce(state, (draft) => {
        draft.result.success = true;
        draft.result.errorMessage = "";
      });
    },

    [`${POST_EXCEL}_FAILURE`]: (state, { payload }) => {
      return produce(state, (draft) => {
        draft.result.success = false;
        draft.result.errorMessage = payload;
      });
    },

    [`${POST_EXCEL}_RESET`]: (state) => {
      return produce(state, (draft) => {
        draft.result.success = false;
        draft.result.errorMessage = "";
      });
    },

    [SET_ADD_EXTRA_CHARGE]: (state, { payload: extraCharge }) => {
      return produce(state, (draft) => {
        draft.extraCharge = extraCharge;
      });
    },
  },
  initialState
);

function* sendBillExcelSaga(action) {
  yield put(startLoading(POST_EXCEL));

  try {
    const response = yield call(orderAPI.postSendBillExcel, action.payload);
    yield put({ type: `${POST_EXCEL}_SUCCESS`, payload: response.data });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: `${POST_EXCEL}_FAILURE`,
      payload: JSON.stringify(error.response?.data) || error.message,
    });
  }

  yield put(finishLoading(POST_EXCEL));
}

export function* sendBillSaga() {
  yield takeLatest(POST_EXCEL, sendBillExcelSaga);
}

export default sendBill;
