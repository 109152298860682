import React from "react";
import styled from "styled-components";
import { Button, TextField } from "@material-ui/core";

interface NoExtraChargeListProps {
  type: string;
  onAllRegist: () => void;
  handleMaxBaseCharge?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function NoExtraChargeList({
  type,
  onAllRegist,
  handleMaxBaseCharge,
}: NoExtraChargeListProps) {
  return (
    <NoChargeList>
      등록된 {type}요금이 없습니다.
      {!!handleMaxBaseCharge && (
        <BaseChargeForm>
          <BaseChargeHeader>
            <div className="title">
              설정하려는 기본요금의 최대 범위를 확인해주세요.
            </div>
            <div className="description">
              변경없을시 최대 50,000원으로 생성됩니다.
            </div>
          </BaseChargeHeader>
          <TextField
            label="기본요금 최대범위"
            type="number"
            id="max_base_Charge"
            name="max_base_Charge"
            defaultValue={50000}
            variant="outlined"
            margin="normal"
            inputProps={{ min: 0, step: 1000 }}
            onChange={handleMaxBaseCharge}
          />
        </BaseChargeForm>
      )}
      <StyledButton variant="outlined" color="primary" onClick={onAllRegist}>
        {type}요금 전체 생성하기
      </StyledButton>
    </NoChargeList>
  );
}

export default NoExtraChargeList;

const NoChargeList = styled.section`
  display: flex;
  flex-direction: column;
  padding: 10rem 2rem;
  font-size: 20px;
`;

const StyledButton = styled(Button)`
  margin: 2rem auto !important;
  width: 15rem;
  height: 3rem;
  font-size: 1rem;
`;

const BaseChargeForm = styled.section`
  margin: 20px auto 0;
  width: fit-content;
  padding: 20px 50px;
  border: 1px solid #e7e6e6;
`;

const BaseChargeHeader = styled.header`
  padding-bottom: 10px;
  font-weight: 700;

  .title {
    margin: 10px;
    font-size: 18px;
  }
  .description {
    font-size: 17px;
    color: #9e9e9e;
  }
`;
