// 급송요금 + 시간요금
export type AddChargeType = typeof ADD_EXTRA_CHARGE;
export const ADD_EXTRA_CHARGE = {
  min_base_charge: 0,
  max_base_Charge: 0,
  calc_selector: "fixed",
  fixed_charge: 0,
  percent_of_charge: 0,
  vehicle_type: "MOTORCYCLE", // 시간요금에서 사용
};
export const selectorUnit = ({
  calc_selector,
  fixed_charge,
  percent_of_charge,
}: AddChargeType) => {
  switch (calc_selector) {
    case "fixed":
      return `${fixed_charge}원`;
    case "percent":
      return `기본요금x${percent_of_charge}%`;
    default:
      return "";
  }
};

// 상하차요금(다마스&라보 + 트럭)
export type AddLoadingChargeType = typeof ADD_LOADING_CHARGE;
export const ADD_LOADING_CHARGE = {
  loading: "",
  unloading: "",
  loading_level: 1, // 다마스&라보 상하차요금에서 사용
  loading_charge: 0,
  vehicle_option: "1T", // 트럭 상하차요금에서 사용
};

// 급송요금
export type ExpressChargeType = {
  base_charge: number;
  express_charge: number;
  id: number;
};

// 시간요금
export type TimeChargeType = {
  base_charge: number;
  time: number;
  time_charge: number;
  vehicle_type: string;
};
export type RefinedTimeChargeType = {
  base_charge: number;
  time: number;
  motorcycle_charge: number;
  damas_charge: number;
  labo_charge: number;
  truck_charge: number;
};

// 왕복요금
export type RoundTripType = {
  vehicle_type: string;
  round_trip_charge_rate: number;
};

// 과적요금
export type BulkChargeType = {
  bulk_score: number;
  weight: number;
  bulk_charge: number;
};

// 다마스&라보 상하차요금
export type LoadingChargeType = {
  vehicle_type: string;
  loading_level: number;
  loading: string;
  unloading: string;
  loading_charge: number;
};
export type RefinedLoadingChargeType = {
  vehicle_type: string;
  loading: string;
  unloading: string;
  charges: {
    loading_level: number;
    loading_charge: number;
  }[];
};

// 트럭 상하차요금
export type TruckLoadingChargeType = {
  vehicle_type: string;
  vehicle_option: string;
  loading: string;
  unloading: string;
  loading_charge: number;
};
export type RefinedTruckLoadingChargeType = {
  loading: string;
  unloading: string;
  charges: {
    vehicle_option: string;
    loading_charge: number;
  }[];
};

// 다마스&라보 상하차요금 SelectBox
type DamasLaboLoadingType = "CUSTOMER" | "TOGETHER" | "RIDER";
export const DamasLaboLoadingSelector: {
  key: DamasLaboLoadingType;
  label: string;
}[] = [
  { key: "CUSTOMER", label: "고객님 직접" },
  { key: "TOGETHER", label: "기사님과 함께" },
  { key: "RIDER", label: "기사님 단독" },
];

// 트럭 상하차요금 SelectBox
type TruckLoadingType = "CUSTOMER" | "FORK_LIFT" | "TOGETHER" | "RIDER";
export const TruckLoadingSelector: {
  key: TruckLoadingType;
  label: string;
}[] = [
  { key: "CUSTOMER", label: "고객님 직접" },
  { key: "FORK_LIFT", label: "지게차" },
  { key: "TOGETHER", label: "기사님과 함께" },
  { key: "RIDER", label: "기사님 단독" },
];

// 다마스&라보 상하차 회전수
type LoadingLevelType = 1 | 2 | 3 | 4;
export const DamasLaboLoadingLevel: { key: LoadingLevelType; label: string }[] =
  [
    { key: 1, label: "1회전" },
    { key: 2, label: "2회전" },
    { key: 3, label: "3회전" },
    { key: 4, label: "4회전이상" },
  ];

// 다마스&라보&트럭 상하차 방법
type LoadingUnitType = {
  [index: string]: string;
  CUSTOMER: string;
  FORK_LIFT: string;
  TOGETHER: string;
  RIDER: string;
};
export const LOADING_UNIT: LoadingUnitType = {
  CUSTOMER: "고객님 직접",
  FORK_LIFT: "지게차",
  TOGETHER: "기사님과 함께",
  RIDER: "기사님 단독",
};

// 제외 휴일
export type ExceptHolidayType = {
  except_date: string;
  id?: number;
};

// 날씨요금
export type WeatherChargeType = {
  vehicle_type: string;
  weather_charge: number;
};

// 휴일/명절요금
export type HolidayChargeType = {
  holiday_charge: number;
  is_special: boolean;
  vehicle_type: string;
};

export const radioChargeTypes = [
  {
    value: "fixed",
    label: "고정금액",
  },
  {
    value: "percent",
    label: "기본요금x00%",
  },
];

export const radioVehicleTypes = [
  {
    value: "MOTORCYCLE",
    label: "오토바이",
  },
  {
    value: "DAMAS",
    label: "다마스",
  },
  {
    value: "LABO",
    label: "라보",
  },
  {
    value: "TRUCK",
    label: "트럭",
  },
];

export const TRUCK_OPTION = [
  "1T",
  "1_4T",
  "2_5T",
  "3_5T",
  "5T",
  "8T",
  "11T",
  "14T",
  "15T",
  "18T",
  "25T",
];

export const DEFAULT_BULK_CHARGES = [
  { bulk_score: 0, weight: 0, bulk_charge: 0 },
  { bulk_score: 1, weight: 0, bulk_charge: 0 },
  { bulk_score: 2, weight: 0, bulk_charge: 0 },
  { bulk_score: 3, weight: 0, bulk_charge: 0 },
  { bulk_score: 4, weight: 0, bulk_charge: 0 },
  { bulk_score: 5, weight: 0, bulk_charge: 3000 },
  { bulk_score: 6, weight: 0, bulk_charge: 3000 },
  { bulk_score: 7, weight: 0, bulk_charge: 5000 },
  { bulk_score: 8, weight: 0, bulk_charge: 7000 },
  { bulk_score: 9, weight: 0, bulk_charge: 7000 },
  { bulk_score: 10, weight: 0, bulk_charge: 8000 },
  { bulk_score: 11, weight: 0, bulk_charge: 8000 },
  { bulk_score: 12, weight: 0, bulk_charge: 8000 },
  { bulk_score: 13, weight: 0, bulk_charge: 8000 },
  { bulk_score: 0, weight: 20, bulk_charge: 3000 },
  { bulk_score: 1, weight: 20, bulk_charge: 3000 },
  { bulk_score: 2, weight: 20, bulk_charge: 3000 },
  { bulk_score: 3, weight: 20, bulk_charge: 3000 },
  { bulk_score: 4, weight: 20, bulk_charge: 3000 },
  { bulk_score: 5, weight: 20, bulk_charge: 5000 },
  { bulk_score: 6, weight: 20, bulk_charge: 5000 },
  { bulk_score: 7, weight: 20, bulk_charge: 7000 },
  { bulk_score: 8, weight: 20, bulk_charge: 7000 },
  { bulk_score: 9, weight: 20, bulk_charge: 7000 },
  { bulk_score: 10, weight: 20, bulk_charge: 8000 },
  { bulk_score: 11, weight: 20, bulk_charge: 8000 },
  { bulk_score: 12, weight: 20, bulk_charge: 8000 },
  { bulk_score: 13, weight: 20, bulk_charge: 8000 },
  { bulk_score: 0, weight: 30, bulk_charge: 4000 },
  { bulk_score: 1, weight: 30, bulk_charge: 4000 },
  { bulk_score: 2, weight: 30, bulk_charge: 4000 },
  { bulk_score: 3, weight: 30, bulk_charge: 4000 },
  { bulk_score: 4, weight: 30, bulk_charge: 4000 },
  { bulk_score: 5, weight: 30, bulk_charge: 6000 },
  { bulk_score: 6, weight: 30, bulk_charge: 6000 },
  { bulk_score: 7, weight: 30, bulk_charge: 8000 },
  { bulk_score: 8, weight: 30, bulk_charge: 8000 },
  { bulk_score: 9, weight: 30, bulk_charge: 8000 },
  { bulk_score: 10, weight: 30, bulk_charge: 9000 },
  { bulk_score: 11, weight: 30, bulk_charge: 9000 },
  { bulk_score: 12, weight: 30, bulk_charge: 9000 },
  { bulk_score: 13, weight: 30, bulk_charge: 9000 },
  { bulk_score: 0, weight: 40, bulk_charge: 6000 },
  { bulk_score: 1, weight: 40, bulk_charge: 6000 },
  { bulk_score: 2, weight: 40, bulk_charge: 6000 },
  { bulk_score: 3, weight: 40, bulk_charge: 6000 },
  { bulk_score: 4, weight: 40, bulk_charge: 6000 },
  { bulk_score: 5, weight: 40, bulk_charge: 7000 },
  { bulk_score: 6, weight: 40, bulk_charge: 7000 },
  { bulk_score: 7, weight: 40, bulk_charge: 9000 },
  { bulk_score: 8, weight: 40, bulk_charge: 9000 },
  { bulk_score: 9, weight: 40, bulk_charge: 9000 },
  { bulk_score: 10, weight: 40, bulk_charge: 10000 },
  { bulk_score: 11, weight: 40, bulk_charge: 10000 },
  { bulk_score: 12, weight: 40, bulk_charge: 10000 },
  { bulk_score: 13, weight: 40, bulk_charge: 10000 },
];
