import { MenuItem, Switch } from "@material-ui/core";
import React from "react";
import { SelectWrapper, StyledInputLabel } from "../../pages/GroupOrder/style";

type receiverInfo = {
  receiverJibunAddress: string;
  receiverAddressDetail: string;
  deliveryItem: string;
  charge: string;
  vehicleType: string;
  truckOption: string;
  truckWeight: string;
  isLift: boolean;
  memo: string;
  pickupRequestTime: any;
  completeRequestTime: any;
};

type VehicleFormProps = {
  handleReceiverInfo: (key: string) => (e: any) => void;
  handleSwitch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  receiverInfo: receiverInfo;
};
function VehicleForm({
  handleReceiverInfo,
  handleSwitch,
  receiverInfo,
}: VehicleFormProps) {
  const { vehicleType, truckWeight, truckOption, isLift } = receiverInfo;
  return (
    <>
      <StyledInputLabel margin="dense">차량종류</StyledInputLabel>
      <SelectWrapper
        onChange={handleReceiverInfo("vehicleType")}
        value={vehicleType}
      >
        <MenuItem value={"MOTORCYCLE"}>오토바이</MenuItem>
        <MenuItem value={"DAMAS"}>다마스</MenuItem>
        <MenuItem value={"LABO"}>라보</MenuItem>
        <MenuItem value={"TRUCK"}>트럭</MenuItem>
      </SelectWrapper>
      {vehicleType === "TRUCK" ? (
        <>
          <StyledInputLabel margin="dense">트럭 톤수</StyledInputLabel>
          <SelectWrapper
            onChange={handleReceiverInfo("truckWeight")}
            value={truckWeight}
          >
            <MenuItem value={"1T"}>1톤</MenuItem>
            <MenuItem value={"1_4T"}>1.4톤</MenuItem>
            <MenuItem value={"2_5T"}>2.5톤</MenuItem>
            <MenuItem value={"3_5T"}>3.5톤</MenuItem>
            <MenuItem value={"5T"}>5톤</MenuItem>
            <MenuItem value={"8T"}>8톤</MenuItem>
            <MenuItem value={"11T"}>11톤</MenuItem>
            <MenuItem value={"14T"}>14톤</MenuItem>
            <MenuItem value={"15T"}>15톤</MenuItem>
            <MenuItem value={"18T"}>18톤</MenuItem>
            <MenuItem value={"25T"}>25톤</MenuItem>
          </SelectWrapper>
          <StyledInputLabel margin="dense">트럭 종류</StyledInputLabel>
          <SelectWrapper
            onChange={handleReceiverInfo("truckOption")}
            value={truckOption}
          >
            <MenuItem value={"/CARGO"}>카고</MenuItem>
            <MenuItem value={"/CANVAS"}>호루</MenuItem>
            <MenuItem value={"/BOX"}>탑차</MenuItem>
            <MenuItem value={"/WING"}>윙바디</MenuItem>
            <MenuItem value={"/CHILLER/BOX"}>냉장탑차</MenuItem>
            <MenuItem value={"/CHILLER/WING"}>냉장윙바디</MenuItem>
            <MenuItem value={"/FREEZER/BOX"}>냉동탑차</MenuItem>
            <MenuItem value={"/FREEZER/WING"}>냉동윙바디</MenuItem>
          </SelectWrapper>
          <StyledInputLabel margin="dense">리프트</StyledInputLabel>
          <Switch checked={isLift} onChange={handleSwitch} color="primary" />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default VehicleForm;
