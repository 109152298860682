import React, { useState, useEffect, useMemo } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Snackbar } from "@material-ui/core";

import CorporationDetail from "../../components/corporation/CorporationDetail";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import {
  CorpProps,
  CorpPropsKey,
  ExcelColumnOption,
  DEFAULT_CORP_DETAIL,
  radioInputs,
} from "./types";
import { UsersListType } from "../users/types";
import { HTMLTargetEvent } from "../../lib/constants/orders/types";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import {
  getCorp,
  updateCorp,
  beforeDeleteCorp,
  deleteCorp,
} from "../../lib/hasura/corporations";
import { arrayDestructResponse } from "../../lib/hasura/common";
import { postLog } from "../../lib/hasura/users";
import { deleteCorpLogo } from "../../lib/api/aws";
import { validateCorp } from "../../lib/validate";
import {
  CORPORATAE_DATA_LABEL,
  EXCEL_COLUMNS,
  LOG_TYPE,
  STRING_ON_EMPTY,
  STRING_ON_NOT_FOUND,
} from "../../lib/constants/constants";

function CorporationDetailContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const corpId = history.location.pathname.replace("/corporation/", "");
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const [corp, setCorp] = useState<CorpProps>(DEFAULT_CORP_DETAIL);
  const [orgCorp, setOrgCorp] = useState<CorpProps>(corp);
  const [users, setUsers] = useState<UsersListType[]>([]);
  const [depts, setDepts] = useState([]);
  const [columnOptions, setColumnOptions] = useState<ExcelColumnOption[]>([]);
  const [orgColumnOptions, setOrgColumnOptions] = useState<ExcelColumnOption[]>(
    []
  );
  const [chipData, setChipData] = useState<any>([]);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  const visibleColumnOptions = useMemo(
    () => columnOptions.filter((opt) => !opt.hide),
    [columnOptions]
  );

  const fetchData = async () => {
    dispatch(startGlobalLoading());
    try {
      const [result_corp, users_user, corporations_department] =
        await arrayDestructResponse(
          [
            "corporations_corporation_by_pk",
            "users_user",
            "corporations_department",
          ],
          () => getCorp(corpId)
        );

      const {
        id,
        name,
        channel,
        phone,
        address,
        registration_number,
        contracted,
        budget_enable,
        limit_budget,
        restrict_by_budget,
        fixed_date,
        discount_rate,
        users_user: users_data,
        order_approval_amt,
        order_approval_enable,
        order_approval_user_select_enable,
        order_approval_time_enable,
        order_approval_time,
        order_client_fix_enable,
        billing_department_enable,
        costcenter_change_memo_enable,
        corporate_account_enable,
        corporate_order_memo_enable,
        corporate_card_memo_enable,
        express_order_memo_enable,
        corporate_credit_enable,
        corporate_department_enable,
        corporate_department_select_enable,
        corporate_department_select_type,
        corporate_email_address,
        corporate_email_address_list,
        corporate_credit,
        excel_column_option: option,
        logo,
        address_book_enable,
        address_book_type,
      } = result_corp;

      const corp = {
        id,
        name,
        channel: channel || "algoquick",
        phone: phone || "", // 다우오피스로 생성된 법인은 서버로부터 null 생성
        address: address || "",
        registration_number: registration_number || "",
        contracted,
        budget_enable,
        limit_budget,
        restrict_by_budget,
        fixed_date,
        discount_rate,
        administrator_id: users_data?.id || " ",
        order_approval_amt,
        order_approval_enable,
        order_approval_user_select_enable,
        order_approval_time_enable,
        order_approval_time_holiday: order_approval_time?.holiday || false,
        order_approval_time_weekend: order_approval_time?.weekend || false,
        order_approval_time_start: order_approval_time?.time.start || "00:00",
        order_approval_time_end: order_approval_time?.time.end || "24:00",
        order_client_fix_enable,
        billing_department_enable,
        costcenter_change_memo_enable,
        corporate_account_enable,
        corporate_order_memo_enable,
        corporate_card_memo_enable,
        express_order_memo_enable,
        corporate_credit_enable,
        corporate_department_enable,
        corporate_department_select_enable,
        corporate_department_select_type:
          corporate_department_select_type || "0", // null일때 "0"으로 변환
        corporate_email_address: corporate_email_address || "",
        corporate_email_address_list,
        corporate_credit,
        logo,
        address_book_enable,
        address_book_type,
      };
      setCorp(corp);
      setOrgCorp(corp);
      setUsers(users_user);
      setDepts(corporations_department);
      setColumnOptions(
        EXCEL_COLUMNS.map((col) => {
          let value = col.default;
          if (option && option[col.key] !== undefined) value = option[col.key];

          return { ...col, value };
        })
      );
      setOrgColumnOptions(
        EXCEL_COLUMNS.map((col) => {
          let value = col.default;
          if (option && option[col.key] !== undefined) value = option[col.key];

          return { ...col, value };
        })
      );
    } catch (err) {
      console.log(err);
      openSnackbar(
        "법인 정보를 받아올 수 없습니다. 개발팀에 문의해주세요.",
        true
      );
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // mounted
  useEffect(() => {
    fetchData();
  }, [corpId]);

  const handleChangeColumnOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    // e는 Synthetic Event로서 React가 재사용하기 위해 리셋될 수 있다.
    // e.target이 null이 되는 것을 막는다.
    e.persist();

    setColumnOptions((options: ExcelColumnOption[]) =>
      options.map((opt) =>
        e.target.name === opt.key ? { ...opt, value: e.target.checked } : opt
      )
    );
  };

  const onChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
      checked?: boolean;
    }>
  ) => {
    e.preventDefault();
    const { name, value, checked } = e.target;
    switch (name) {
      case "order_client_fix_enable":
      case "billing_department_enable":
      case "costcenter_change_memo_enable":
      case "corporate_account_enable":
      case "corporate_order_memo_enable":
      case "corporate_card_memo_enable":
      case "express_order_memo_enable":
      case "corporate_credit_enable":
      case "corporate_department_enable":
      case "corporate_department_select_enable":
        setCorp({
          ...corp,
          [name]: checked,
        });
        break;
      case "corporate_email_address_list":
        setCorp({
          ...corp,
          [name]: chipData,
        });
        break;
      default:
        // 법인명, 채널, 관리자
        setCorp({
          ...corp,
          [name as string]: value,
        });
        break;
    }
  };

  const onChangeRadioSync = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    switch (name) {
      case "address_book_enable":
        setCorp({
          ...corp,
          [name]: checked,
          address_book_type: checked ? "CORPORATION" : null,
        });
        break;
      default:
        break;
    }
  };

  const onChangeLowerSync = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const dependencies: CorpPropsKey[] = [];
    switch (name) {
      case "budget_enable":
        dependencies.push("restrict_by_budget");
        break;
      case "order_approval_enable":
        dependencies.push(
          "order_approval_user_select_enable",
          "order_approval_time_enable",
          "order_approval_time_holiday",
          "order_approval_time_weekend"
        );
        break;
      case "order_approval_time_enable":
        dependencies.push(
          "order_approval_time_holiday",
          "order_approval_time_weekend"
        );
        break;
      default:
        break;
    }

    const sync: Record<string, boolean> = {};
    if (!checked)
      dependencies.forEach((name) => {
        sync[name] = false;
      });
    setCorp((corp) => ({ ...corp, ...sync, [name]: checked }));
  };

  const onChangeUpperSync = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const dependencies: CorpPropsKey[] = [];
    switch (name) {
      case "restrict_by_budget":
        dependencies.push("budget_enable");
        break;
      case "order_approval_user_select_enable":
      case "order_approval_time_enable":
        dependencies.push("order_approval_enable");
        break;
      case "order_approval_time_holiday":
      case "order_approval_time_weekend":
        dependencies.push(
          "order_approval_enable",
          "order_approval_time_enable"
        );
        break;
      default:
        break;
    }

    const sync: Record<string, boolean> = {};
    if (checked)
      dependencies.forEach((name) => {
        sync[name] = true;
      });
    setCorp((corp) => ({ ...corp, ...sync, [name]: checked }));
  };

  const handleDateChange = (name: string, date: Date | null) => {
    switch (name) {
      case "contracted":
        setCorp({
          ...corp,
          contracted: date?.toISOString().slice(0, 10) as string,
        });
        break;
      case "order_approval_time_start": {
        const h = String(date?.getHours() || 0).padStart(2, "0");
        const m = String(date?.getMinutes() || 0).padStart(2, "0");
        setCorp({
          ...corp,
          order_approval_time_start: `${h}:${m}`,
        });
        break;
      }
      case "order_approval_time_end": {
        const h = String(date?.getHours() || 24).padStart(2, "0");
        const m = String(date?.getMinutes() || 0).padStart(2, "0");
        setCorp({
          ...corp,
          order_approval_time_end: `${h}:${m}`,
        });
        break;
      }
      default:
        break;
    }
  };

  const handleDeleteCorp = async () => {
    dispatch(startGlobalLoading());
    try {
      if (corp.logo !== null) await deleteCorpLogo(corpId);
      await beforeDeleteCorp(corpId);
      const {
        data: { errors },
      } = await deleteCorp(corpId);
      if (errors !== undefined) throw new Error();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `법인 삭제: ${corp.name}`,
        "법인 관리"
      );
      history.push("/corporations");
    } catch (error) {
      const msg =
        "삭제에 실패하였습니다.\n법인에 등록된 부서나 회원이 남아있는지 확인해주세요.";
      openSnackbar(msg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  /* --- email list chips --- */
  const handleKeyDown = (e: HTMLTargetEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const duplicatedValue = chipData.indexOf(e.target.value.trim());
      if (duplicatedValue !== -1 || e.target.value.trim() === "") {
        e.target.value = "";
        return;
      }

      setChipData(chipData.concat(e.target.value.trim()));
      setCorp({
        ...corp,
        corporate_email_address_list: chipData.concat(e.target.value.trim()),
      });
      e.target.value = "";
    }
  };
  const handleDelete = (chipToDelete: string) => () => {
    setChipData(chipData.filter((chip: string) => chip !== chipToDelete));
    setCorp({
      ...corp,
      corporate_email_address_list: chipData.filter(
        (chip: string) => chip !== chipToDelete
      ),
    });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    try {
      const errorMessage = validateCorp(corp);
      if (errorMessage !== "") throw new Error(errorMessage);

      const {
        data: { errors },
      } = await updateCorp(
        {
          prev_corporate_credit: orgCorp.corporate_credit,
          ...corp,
        },
        columnOptions
      );
      if (errors !== undefined) throw new Error();

      // 법인정보 수정 로그
      const modifiedDetail = Object.entries(CORPORATAE_DATA_LABEL) //
        .reduce<string[]>((acc, [key, label]) => {
          const v1 = orgCorp[key as keyof CorpProps];
          const v2 = corp[key as keyof CorpProps];
          if (JSON.stringify(v1) !== JSON.stringify(v2)) {
            const t1 = valueToString(v1, key);
            const t2 = valueToString(v2, key);
            acc.push(`${label}: ${t1} -> ${t2}`);
          }
          return acc;
        }, []);
      // 주문내역관리 수정 로그
      const modifiedOption = EXCEL_COLUMNS.reduce<string[]>((acc, cur) => {
        const v1 = orgColumnOptions.find(({ key }) => key === cur.key)?.value;
        const v2 = columnOptions.find(({ key }) => key === cur.key)?.value;
        if (v1 !== v2) {
          const t1 = valueToString(v1, cur.key);
          const t2 = valueToString(v2, cur.key);
          acc.push(`${cur.label}: ${t1} -> ${t2}`);
        }
        return acc;
      }, []);
      const mergedInfo =
        [...modifiedDetail, ...modifiedOption].join(" / ") || "변경 사항 없음";

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `법인 수정: ${corp.name} (${mergedInfo})`,
        "법인 관리"
      );

      setOrgCorp(corp);
      setOrgColumnOptions(columnOptions);
      openSnackbar(`${corp.name} 수정을 완료했습니다.`);
    } catch (error) {
      const message =
        (error as Error).message ||
        `${corp.name} 수정을 실패했습니다.\n데이터를 확인해 주세요.`;
      openSnackbar(message, true);
    } finally {
      dispatch(finishGlobalLoading());
    }

    function valueToString(value: any, key: string) {
      switch (typeof value) {
        case "number":
          return value;
        case "boolean":
          return value ? "O" : "X";
        case "object":
          if (Array.isArray(value))
            return value.length > 0 ? value.join(", ") : STRING_ON_EMPTY;
          else return value;
        case "string":
          if (value.trim() === "") return STRING_ON_EMPTY;
          if (key === "administrator_id")
            return (
              users.find((user) => user.id === value)?.username ||
              STRING_ON_NOT_FOUND // 이 값을 반환할 일은 없다.
            );
          if (key === "corporate_department_select_type")
            return (
              radioInputs.find((type) => type.value === value)?.label ||
              STRING_ON_NOT_FOUND // 이 값을 반환할 일은 없다.
            );
          return value || STRING_ON_EMPTY;
        default:
          return value || STRING_ON_EMPTY;
      }
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
    if (!error) history.push(`/corporation/${corpId}`);
  };

  return (
    <>
      <CorporationDetail
        corp={corp}
        users={users}
        depts={depts}
        columnOptions={visibleColumnOptions}
        handleChangeColumnOption={handleChangeColumnOption}
        onChange={onChange}
        onChangeRadioSync={onChangeRadioSync}
        onChangeLowerSync={onChangeLowerSync}
        onChangeUpperSync={onChangeUpperSync}
        onSubmit={onSubmit}
        handleDateChange={handleDateChange}
        handleDeleteCorp={handleDeleteCorp}
        action="detail"
        chipData={chipData}
        setChipData={setChipData}
        handleKeyDown={handleKeyDown}
        handleDelete={handleDelete}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default CorporationDetailContainer;
