import { useMemo, useCallback } from "react";
import styled from "styled-components";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  makeStyles,
} from "@material-ui/core";
import {
  LoadingChargeType,
  RefinedLoadingChargeType,
  LOADING_UNIT,
} from "../../containers/corporation/extraTypes";
import { sortbyLoading } from "../../containers/corporation/utils";

interface ExtraLoadingChargeListProps {
  vehicleType: "DAMAS" | "LABO";
  setVehicleType: React.Dispatch<React.SetStateAction<"DAMAS" | "LABO">>;
  loadingData: LoadingChargeType[];
}

function ExtraLoadingChargeList({
  vehicleType,
  setVehicleType,
  loadingData,
}: ExtraLoadingChargeListProps) {
  const classes = useStyles();

  // 데이터 객체안의 charges 배열을 loading_level 순으로 정렬
  const sortbyCharges = (
    a: { loading_level: number },
    b: { loading_level: number }
  ) => a.loading_level - b.loading_level;

  /**
   * loading-unloading 기준으로 1~4회전 데이터를 묶어 배열로 생성
   * @returns { RefinedLoadingChargeType[] }
   */
  const refinedData = (acc: any, cur: LoadingChargeType) => {
    const key = `${cur.loading}-${cur.unloading}`; // 상차-하차 조합(9가지)
    if (!acc[key]) {
      acc[key] = {
        vehicle_type: cur.vehicle_type,
        loading: cur.loading,
        unloading: cur.unloading,
        charges: [
          {
            loading_level: cur.loading_level,
            loading_charge: cur.loading_charge,
          },
        ],
      };
    } else {
      // 상차-하차 조합마다 1~4회전 데이터 추가
      acc[key].charges.push({
        loading_level: cur.loading_level,
        loading_charge: cur.loading_charge,
      });
    }

    return acc;
  };
  const loadingList = useMemo(() => {
    const refinedList: RefinedLoadingChargeType[] = Object.values(
      loadingData.reduce(refinedData, {})
    );

    const sortedList = refinedList.map((item) => ({
      ...item,
      charges: [...item.charges].sort(sortbyCharges), // loading_level 정렬
    }));

    return sortedList.sort(sortbyLoading); // loading -> unloading 정렬
  }, [loadingData]);

  const whichVehicle = useCallback(
    (type) => (vehicleType === type ? "selected-type" : ""),
    [vehicleType]
  );

  const handleVehicle = (type: "DAMAS" | "LABO") => {
    setVehicleType(type);
  };

  return (
    <>
      <VehicleTypeSelector>
        <Button
          className={`vehicle-btn ${whichVehicle("DAMAS")}`}
          onClick={() => handleVehicle("DAMAS")}
        >
          다마스
        </Button>
        <Button
          className={`vehicle-btn ${whichVehicle("LABO")}`}
          onClick={() => handleVehicle("LABO")}
        >
          라보
        </Button>
      </VehicleTypeSelector>
      <TableBox>
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell align="center">상차</TableCell>
            <TableCell align="center">하차</TableCell>
            <TableCell align="center">1회전</TableCell>
            <TableCell align="center">2회전</TableCell>
            <TableCell align="center">3회전</TableCell>
            <TableCell align="center">4회전이상</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadingList.map((el, index) => (
            <TableRow key={index}>
              <TableCell align="center">{LOADING_UNIT[el.loading]}</TableCell>
              <TableCell align="center">{LOADING_UNIT[el.unloading]}</TableCell>
              {el.charges.map(({ loading_level, loading_charge }) => (
                <TableCell key={loading_level} align="center">
                  {loading_charge}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableBox>
    </>
  );
}

export default ExtraLoadingChargeList;

const useStyles = makeStyles(() => ({
  root: { backgroundColor: "#E7E6E6" },
}));

const VehicleTypeSelector = styled.section`
  display: flex;
  justify-content: center;
  margin: 1rem auto 0;
  width: 80%;

  .vehicle-btn {
    padding: 8px 0;
    width: 50%;
  }

  .selected-type {
    font-weight: 700;
    background-color: #e7e6e6;
    border-bottom: 1px solid #fff;
  }
`;

const TableBox = styled(Table)`
  margin: 0 auto;
  width: 80% !important;
  border: 1px solid #e7e6e6;
`;
