import styled from "styled-components";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { description_A, description_B, descriptionType } from "./types";
import { DescriptWrapper } from "../../pages/GroupOrder/style";

function LargeChargeDescription() {
  return (
    <DescriptWrapper>
      <div>
        엑셀 양식을 다운받아 <strong>출발지, 도착지, 차종</strong> 을
        기입해주세요.
      </div>
      <header>* 차종별 입력텍스트 예시입니다. *</header>
      <TableWrapper>
        <GroupTable>
          <TableHead>
            <TableRow>
              <TableCell align={"center"}>차종(기본옵션)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {description_A.map((el: descriptionType) => (
              <TableRow key={el.id}>
                <TableCell align={"center"}>{el.vehicleExample}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </GroupTable>
        <GroupTable>
          <TableHead>
            <TableRow>
              <TableCell align={"center"}>차종(트럭옵션)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {description_B.map((el: descriptionType) => (
              <TableRow key={el.id}>
                <TableCell align={"center"}>{el.vehicleExample}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </GroupTable>
      </TableWrapper>
    </DescriptWrapper>
  );
}

export default LargeChargeDescription;

const TableWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  table {
    width: 48%;
  }
`;

const GroupTable = styled(Table)`
  margin-top: 0.5rem;
  border: 1px solid rgba(224, 224, 224, 1);
  thead {
    background-color: whitesmoke;
  }
`;
