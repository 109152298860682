import { TruckOptionsType } from "../../containers/groupOrder/types";

export function convertVehicleTypeToKorean(vehicle: string) {
  switch (vehicle) {
    case "MOTORCYCLE":
      return "오토바이";
    case "DAMAS":
      return "다마스";
    case "LABO":
      return "라보";
    case "TRUCK":
      return "트럭";
    default:
      return "오토바이";
  }
}

export function convertVehicleTypeToEnglish(vehicle: string) {
  switch (vehicle) {
    case "오토바이":
      return "MOTORCYCLE";
    case "다마스":
      return "DAMAS";
    case "라보":
      return "LABO";
    case "트럭":
      return "TRUCK";
    default:
      return "MOTORCYCLE";
  }
}

export function convertPaymentToKorean(payment: string) {
  switch (payment) {
    case "ADVANCED_CASH":
      return "현금선불";
    case "CASH_ON_DELIVERY":
      return "현금착불";
    case "CREDIT_CARD":
      return "신용카드";
    case "CORPORATE_ACCOUNT":
      return "법인신용";
    case "CUSTOMER_ACCOUNT":
      return "고객신용";
  }
}
export const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");
export function removeDash(phoneNumber: string) {
  if (phoneNumber.includes("-") === false) {
    return phoneNumber;
  }

  if (phoneNumber.includes("-") === true) {
    return phoneNumber.replaceAll("-", "");
  }
}

export function removeComma(charge: number) {
  const fare = String(charge);
  if (fare.includes(",") === false) {
    return fare;
  }

  if (fare.includes(",") === true) {
    return fare.replaceAll(",", "");
  }
}

export function checkValidTimeFormat(time: string): string {
  const isNumber = !isNaN(Number(time));

  if (isNumber) {
    return "";
  }
  const dateInstance = new Date(time);

  const result =
    isNaN(dateInstance.getTime()) ||
    time.includes(".") ||
    time.includes(",") ||
    time.includes("_");

  if (result) {
    return "";
  }

  return time;
}
interface completeRequestTimeInterface {
  lower?: string | any;
  upper?: string | any;
}
export function checkValidCompleteRequestTime(lower: string, upper: string) {
  const completeRequestTime: completeRequestTimeInterface = {
    lower: checkValidTimeFormat(lower),
    upper: checkValidTimeFormat(upper),
  };

  if (!completeRequestTime.lower) {
    delete completeRequestTime.lower;
  }

  if (!completeRequestTime.upper) {
    delete completeRequestTime.upper;
  }

  if (Object.keys(completeRequestTime).length === 0) {
    return "";
  }
  return completeRequestTime;
}

export function convertTruckWeightToEnglish(truckWeight: string) {
  /* 오류시 기본값 1T */
  if (isNaN(Number(truckWeight))) {
    return "1T";
  }

  /* 엑셀 1.4 => 데이터 1_4T */
  type truckWeightsType = {
    [key: string]: string;
    ["1.4"]: string;
    ["2.5"]: string;
    ["3.5"]: string;
  };

  const truckWeights: truckWeightsType = {
    "1.4": "1_4T",
    "2.5": "2_5T",
    "3.5": "3_5T",
  };

  return truckWeights[truckWeight]
    ? truckWeights[truckWeight]
    : `${truckWeight}T`;
}

export function convertTruckOptionToEnglish(truckOption: string) {
  const truckOptions: TruckOptionsType = {
    카고: "/CARGO",
    호루: "/CANVAS",
    탑차: "/BOX",
    윙바디: "/WING",
    냉장탑차: "/CHILLER/BOX",
    냉장윙바디: "/CHILLER/WING",
    냉동탑차: "/FREEZER/BOX",
    냉동윙바디: "/FREEZER/WING",
  };

  return truckOptions[truckOption] ? truckOptions[truckOption] : "/CARGO";
}

export const checkVehecleTypeIsTruck = (vehicleType: string) =>
  vehicleType === "TRUCK" ? true : false;

/* 대량요금조회 사용 로직*/
export function convertTruckOptionToLargeCharge(truckOption: string) {
  const truckOptions: TruckOptionsType = {
    카고: "CARGO",
    호루: "CANVAS",
    탑차: "BOX",
    윙바디: "WING",
    냉장탑차: "CHILLER/BOX",
    냉장윙바디: "CHILLER/WING",
    냉동탑차: "FREEZER/BOX",
    냉동윙바디: "FREEZER/WING",
  };

  return truckOptions[truckOption] ? truckOptions[truckOption] : "/CARGO";
}
export function convertVehicleTypeToLargeCharge(vehicle: string) {
  switch (vehicle) {
    case "오토바이":
      return "MOTORCYCLE";
    case "다마스":
      return "DAMAS";
    case "라보":
      return "LABO";
    case "트럭":
      return "TRUCK";
    case "":
      return "";
    default:
      return vehicle;
  }
}
