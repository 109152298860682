import ko from "date-fns/locale/ko";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePickerWrapper } from "../../pages/GroupOrder/style";

interface InputTimeFormProps {
  time: string;
  label: string;
  handleReceiverTime: any;
}

function InputTimeForm({
  time,
  label,
  handleReceiverTime,
}: InputTimeFormProps) {
  const initialTime = time ? new Date(time) : null;
  return (
    <DateTimePickerWrapper>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ko}>
        <DateTimePicker
          value={initialTime}
          disablePast
          onChange={handleReceiverTime}
          format="yyyy-MM-dd HH:mm"
          label={label}
          showTodayButton
          fullWidth
          minDateMessage={
            "예약배송 시간은 현재시간보다 과거이면 안됩니다. 시간을 수정해주세요"
          }
        />
      </MuiPickersUtilsProvider>
    </DateTimePickerWrapper>
  );
}

export default InputTimeForm;
