import React from "react";
import styled from "styled-components";
import { Paper as MaterialPaper } from "@material-ui/core";

const PaddingPaper = styled(MaterialPaper)`
  padding: 0 1rem;
`;

const Paper = (props) => {
  return <PaddingPaper {...props} />;
};

export default Paper;
