import React from "react";
import styled from "styled-components";

import CircularLoading from "../common/CircularLoading";
import DateContainer from "../../containers/sendBill/DateContainer";
import ExcelButtonsContainer from "../../containers/sendBill/ExcelButtonsContainer";
import SendButtonContainer from "../../containers/sendBill/SendButtonContainer";
import DialogContainer from "../../containers/sendBill/DialogContainer";
import SenderMailContainer from "../../containers/sendBill/SenderMailContainer";
import ExtraChargeCheckContainer from "../../containers/sendBill/ExtraChargeCheckContainer";

const SendBillForm = styled.form`
  width: 30%;
  margin: 0 auto;
  text-align: center;

  .excel-button-container {
    display: flex;
    flex-direction: column;
  }
  .bill-button {
    width: 100%;
    margin: 10px 0;
  }

  p {
    margin: 5px 0;
  }
`;

type result = {
  success: boolean;
  errorMessage: string;
};
type SendBillProps = {
  isSendingAvailable: boolean;
  date: string;
  result: result;
  resetResult: () => void;
  postExcelBill: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  loading: boolean;
  extraCharge: boolean;
};

function SendBill({
  isSendingAvailable,
  date,
  result,
  resetResult,
  postExcelBill,
  loading,
  extraCharge,
}: SendBillProps) {
  return (
    <>
      {loading ? (
        <CircularLoading />
      ) : (
        <SendBillForm>
          <h2>청구서 발송</h2>

          <SenderMailContainer />

          <DateContainer />

          <p>{`이용기간 : ${date}`}</p>

          <ExtraChargeCheckContainer extraCharge={extraCharge} />
          <ExcelButtonsContainer />

          <SendButtonContainer
            isSendingAvailable={isSendingAvailable}
            postExcelBill={postExcelBill}
          />
        </SendBillForm>
      )}
      <DialogContainer result={result} resetResult={resetResult} />
    </>
  );
}

export default SendBill;
