import React from "react";
import styled from "styled-components";
import OrdersComparedList from "./OrdersComparedList";

const StyledWrapper = styled.div`
  width: 100%;
  margin-top: 0.2rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const OrdersCompared = ({ ordersComparedProps }) => {
  return (
    <>
      <StyledWrapper>
        <OrdersComparedList ordersComparedProps={ordersComparedProps} />
      </StyledWrapper>
    </>
  );
};

export default OrdersCompared;
