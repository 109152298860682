import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

import EnhancedTableHead from "../dataTable/EnhancedTableHead";
import { UsersListType, headRows } from "../../containers/users/types";
import { stableSort, getSorting } from "../dataTable/base";

import { CustomLegend } from "../common/Base";
import Paper from "../common/Paper";

interface UsersListProps {
  rows: UsersListType[];
}

function UsersList({ rows }: UsersListProps) {
  const history = useHistory();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (e: React.MouseEvent, property: string) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleRowClick = (id: any) => history.push(`/user/${id}`);

  return (
    <CustomPaper>
      <CustomLegend>회원 관리 ({rows.length})</CustomLegend>
      <Table>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headRows={headRows}
        />
        <TableBody>
          {stableSort(rows, getSorting(order, orderBy)).map(
            (row: UsersListType) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  onClick={() => handleRowClick(row.id)}
                >
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.isd_c_code}</TableCell>
                  <TableCell>{row.fullname}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>
                    {row.corporations_corporation?.name ?? ""}
                  </TableCell>
                  <TableCell>
                    {row.corporations_department?.name ?? ""}
                  </TableCell>
                  <TableCell>{row.platform.join(", ")}</TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </CustomPaper>
  );
}

export default UsersList;

const CustomPaper = styled(Paper)`
  position: relative;
  padding: 1.5rem;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
`;
