import React from "react";
import { useHistory } from "react-router-dom";
import UserDetailContainer from "../containers/user/UserDetailContainer";
import UserAddContainer from "../containers/user/UserAddContainer";

export default function UserDetailPage() {
  const history = useHistory();
  const route = history.location.pathname.replace("/user/", "");
  return (
    <>{route === "add" ? <UserAddContainer /> : <UserDetailContainer />}</>
  );
}
