import styled from "styled-components";

const Detail = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  box-shadow: 0 2px 1rem rgba(0, 0, 0, 0.08);
`;

export default Detail;
