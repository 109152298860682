import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Snackbar } from "@material-ui/core";

import DepartmentDetail from "../../components/department/DepartmentDetail";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import { Corporation, DEFAULT_ADD_DEPART, DepartmentData } from "./types";
import { createDepart, updateCorpCredit } from "../../lib/hasura/departments";
import { destructResponse } from "../../lib/hasura/common";
import { getCorporations } from "../../lib/hasura/corporations";
import { listCorpUser, postLog } from "../../lib/hasura/users";
import { validateDepart } from "../../lib/validate";
import { LOG_TYPE } from "../../lib/constants/constants";

function DepartmentAddContainer() {
  const history = useHistory();
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const [corporations, setCorporations] = useState<Corporation[]>([]);
  const [depart, setDepart] = useState<DepartmentData>(DEFAULT_ADD_DEPART);
  const [users, setUsers] = useState([]);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  const fetchData = async () => {
    const channel = user?.channel || "algoquick";
    try {
      const result = await destructResponse<Corporation[]>(
        "corporations_corporation",
        () => getCorporations(channel)
      );
      setCorporations(result);
    } catch (err) {
      console.log(err);
    }
  };

  // mounted
  useEffect(() => {
    fetchData();
  }, []);

  const handleCorpUser = async (id: string) => {
    const { data } = await listCorpUser(id);

    if (!data.errors) {
      const { users_user } = data.data;
      setUsers(users_user);
    } else setUsers([]);
  };

  const handleCorp = async (name: string, value: string) => {
    if (!value.trim()) {
      setDepart({
        ...depart,
        administrator: " ",
        corporate_credit: 0,
      });
      return;
    }

    // 법인명 검색시 해당 법인 크레딧 호출
    const { data } = await updateCorpCredit(value);
    const { corporations_corporation_by_pk: corp } = data.data;
    setDepart({
      ...depart,
      [name]: value,
      administrator: " ",
      corporate_credit: corp.corporate_credit,
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;

    switch (name) {
      case "corporation_id":
        handleCorp(name, value);
        handleCorpUser(value);
        break;
      case "address_book_enable":
      case "department_credit_enable":
        setDepart({
          ...depart,
          [name]: checked,
        });
        break;
      default:
        setDepart({
          ...depart,
          [name]: value,
        });
        break;
    }
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (depart.corporation_id.trim() === "")
        throw new Error("법인 선택은 필수입니다.");

      // 저장시 잔여 법인 크레딧 "재호출"
      const { data } = await updateCorpCredit(depart.corporation_id);
      const { corporations_corporation_by_pk: corp } = data.data;

      const errorMessage = validateDepart(depart, 0, corp);
      if (errorMessage !== "") throw new Error(errorMessage);

      const {
        data: { errors },
      } = await createDepart(depart);
      if (errors !== undefined) throw new Error();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `부서 생성: ${depart.name} (예산한도: ${depart.limit_budget})`,
        "부서 관리"
      );
      openSnackbar(`${depart.name} 생성에 성공하였습니다.`);
    } catch (error) {
      const message =
        (error as Error).message ||
        `${depart.name} 생성에 실패했습니다. 부서명 중복여부를 확인해주세요.`;
      openSnackbar(message, true);
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
    if (!error) history.push("/departments");
  };

  return (
    <>
      <DepartmentDetail
        row={depart}
        users={users}
        corporations={corporations}
        onChange={onChange}
        onSubmit={onSubmit}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default DepartmentAddContainer;
