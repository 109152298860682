import React, { useState } from "react";
import styled from "styled-components";

export const StyledButton = styled.button`
  width: 6rem;
  margin: 0.6rem 1rem;
  line-height: 2;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 0.3rem;
  border: none;
  color: white;
  outline: 0;
  &:hover {
    opacity: 0.6;
  }
`;

const UtilButton = ({ fn1, fn2, on, off }) => {
  const [isOn, setIsOn] = useState(false);

  const handleClick = () => {
    if (!isOn) {
      setIsOn(true);
      fn1();
    } else {
      setIsOn(false);
      fn2();
    }
  };

  return (
    <StyledButton
      style={{
        backgroundColor: `${!isOn ? "#ff9e00" : "rgb(126, 208, 219)"}`,
      }}
      onClick={handleClick}
    >
      {!isOn ? on : off}
    </StyledButton>
  );
};

export default UtilButton;
