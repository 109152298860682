import styled from "styled-components";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { RoundTripType } from "../../containers/corporation/extraTypes";
import { VehicleTypes } from "../../containers/corporation/utils";

interface RoundTripChargeListProps {
  roundTripData: RoundTripType[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function ExtraRoundTripChargeList({
  roundTripData,
  onChange,
}: RoundTripChargeListProps) {
  const classes = useStyles();

  const sortedRoundTripList = roundTripData.sort(
    (a, b) =>
      VehicleTypes.indexOf(a.vehicle_type) -
      VehicleTypes.indexOf(b.vehicle_type)
  );

  return (
    <TableBox>
      <TableHead className={classes.root}>
        <TableRow>
          <TableCell align="center">기본요금x00%</TableCell>
          <TableCell align="center">오토바이(%)</TableCell>
          <TableCell align="center">다마스(%)</TableCell>
          <TableCell align="center">라보(%)</TableCell>
          <TableCell align="center">트럭(%)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="center">할증률</TableCell>
          {sortedRoundTripList.map(
            ({ vehicle_type, round_trip_charge_rate }, idx) => (
              <TableCell key={idx} align="center">
                <StyledTextField
                  type="number"
                  id={`${vehicle_type}_rate`}
                  name={`${vehicle_type}`}
                  defaultValue={round_trip_charge_rate}
                  variant="outlined"
                  margin="normal"
                  inputProps={{
                    min: 0,
                    max: 100,
                    step: 5,
                    style: { textAlign: "right" },
                  }}
                  onChange={onChange}
                />
              </TableCell>
            )
          )}
        </TableRow>
      </TableBody>
    </TableBox>
  );
}

export default ExtraRoundTripChargeList;

const useStyles = makeStyles(() => ({
  root: { backgroundColor: "#E7E6E6" },
}));

const TableBox = styled(Table)`
  margin: 1rem auto;
  width: 80% !important;
  border: 1px solid #e7e6e6;
`;

const StyledTextField = styled(TextField)`
  margin: 0;
  width: 50% !important;
`;
