import React from "react";
import styled from "styled-components";
import { NavigateNext, NavigateBefore } from "@material-ui/icons";

interface paginationProps {
  postsPerPage: number;
  totalPosts: number;
  getPaginate: number;
  setPaginate: React.Dispatch<React.SetStateAction<number>>;
  viewData: string;
}

function Pagination({
  getPaginate,
  postsPerPage,
  totalPosts,
  setPaginate,
  viewData,
}: paginationProps) {
  /* 데이터 삭제시 현재 페이지 계산도 이 로직 복사하여 활용 */
  const pageNumbers: any[] = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const deleteCount = (): number => {
    if (getPaginate <= 1) {
      return getPaginate;
    } else {
      return getPaginate - 1;
    }
  };

  const addCount = (): number => {
    if (getPaginate >= pageNumbers.length) {
      return getPaginate;
    } else {
      return getPaginate + 1;
    }
  };

  return (
    <>
      <div>
        <nav>
          <PageUl className="pagination">
            {pageNumbers.length > 0 ? (
              <ArrowBtn>
                <NavigateBefore onClick={() => setPaginate(deleteCount)} />
              </ArrowBtn>
            ) : null}
            {pageNumbers.map((number) => (
              <PageLi key={number} className="page-item">
                <span onClick={() => setPaginate(number)}>
                  {number === getPaginate ? (
                    <Color>{number}</Color>
                  ) : (
                    <span>{number}</span>
                  )}
                </span>
              </PageLi>
            ))}
            {pageNumbers.length > 0 ? (
              <ArrowBtn>
                <NavigateNext onClick={() => setPaginate(addCount)} />
              </ArrowBtn>
            ) : null}
          </PageUl>
        </nav>
        <ViewData>{viewData}</ViewData>
      </div>
    </>
  );
}
export default Pagination;

const PageUl = styled.ul`
  margin-top: 1rem;
  width: 17.7rem;
  text-align: center;
  color: #a5a5a5;
`;

const ArrowBtn = styled.span`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  line-height: 1rem;
  vertical-align: middle;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    color: white;
    background-color: #4472c5;
  }
  svg {
    margin-top: 0.2rem;
  }
`;

const PageLi = styled.li`
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  &:hover {
    cursor: pointer;
  }
`;

const Color = styled.span`
  color: #4472c5;
`;

const ViewData = styled.div`
  // margin-top: 4.9rem;
  width: 18rem;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 700;
  color: #4472c5;
`;
