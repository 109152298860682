import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SingleCorpCharge from "../../components/singleCorpCharge/SingleCorpCharge";
import { ascendingFunc } from "../../lib/core";
import {
  arrayDestructResponse,
  destructResponse,
} from "../../lib/hasura/common";
import { defaultBaseCharge } from "./SingleCorpChargeCalculate";
import {
  DEFAULT_SEARCH_DATA,
  SearchDataType,
  CorporationsListType,
  CorpChargeByLocVeh,
} from "./types";
import {
  getSingleCorp,
  getSingleCorpCharge,
  networkSingleCorpCharge,
} from "../../lib/hasura/corporations";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";

function SingleCorpChargeContainer() {
  const dispatch = useDispatch();

  const [search, setSearch] = useState<SearchDataType>(DEFAULT_SEARCH_DATA);
  const [corps, setCorps] = useState<CorporationsListType[]>([]);
  const [chargeList, setChargeList] = useState<CorpChargeByLocVeh[]>([]);
  const [corpName, setCorpName] = useState("");
  const [corpId, setCorpId] = useState("");
  const [open, setOpen] = useState(false);

  const [thisPage, setThisPage] = useState(1); // 페이지네이션

  const fetchCorp = async () => {
    try {
      const result = await destructResponse<CorporationsListType[]>(
        "corporations_corporation",
        () => getSingleCorp()
      );
      setCorps(result);
    } catch (err) {
      alert(`법인 정보를 불러올 수 없습니다. ${err}`);
    }
  };

  // mounted
  useEffect(() => {
    fetchCorp();
  }, []);

  // 법인명 정렬 + 오름차순(ascendingFunc)
  const sort_corp = useMemo(
    () =>
      corps.map((el: CorporationsListType) => {
        const letter = el.name.toUpperCase().replace(/ /g, "");
        return {
          ...el,
          letter,
        };
      }),
    [corps]
  );

  const fetchData = useCallback(async () => {
    dispatch(startGlobalLoading());
    setThisPage(1);
    try {
      const [corporationCharge, networkCharge] = await arrayDestructResponse(
        [
          "corporations_corporationcharge",
          "corporations_corporationnetworkcharge",
        ],
        () => getSingleCorpCharge(search?.id)
      );

      /**
       * @description 표준요금 baseCharge를 새로 생성
       * @return { ...prevValue, baseCharge }
       * 오토바이, 다마스, 라보는 charge_algoquickcharge 필드에 표준요금이 저장되어 있지만
       * 트럭은 "전체"인 표준요금만 저장되어있다.
       * 따라서 다른 트럭종류의 표준 요금을 출력하려면 algoquick_truckoptioncharge을 일일이 출력
       */
      const arr: CorpChargeByLocVeh[] = [];
      for (const row of corporationCharge) {
        const baseCharge = await defaultBaseCharge(
          row.charge_algoquickcharge,
          row.vehicle_type
        );
        arr.push({
          id: row.id,
          chargeId: row.AlgoQuickCharge_id,
          departure: row.chargeLocationl3ByDepartureId,
          destination: row.charge_locationl3,
          baseCharge,
          customCharge: row.charge,
          vehicleType: row.vehicle_type,
        });
      }

      // 연계배송 요금을 추가한다.
      for (const row of networkCharge) {
        const baseCharge = await networkSingleCorpCharge({
          departure_id: row.departure_id,
          destination_id: row.destination_id,
        });
        arr.push({
          id: row.id,
          chargeId: row.departure_id + row.destination_id,
          departure: row.chargeLocationl3ByDepartureId,
          destination: row.chargeLocationl3ByDestinationId,
          baseCharge: baseCharge?.[row.vehicle_type.toLowerCase()] || 0,
          customCharge: row.network_charge,
          vehicleType: row.vehicle_type,
        });
      }

      // 등록된 법인
      setChargeList(arr);
      setCorpId(search?.id);
      setCorpName(search?.name);
    } catch (err) {
      console.log(err);
      alert(`법인 정보를 불러오는데 실패하였습니다.\n${err}`);
    } finally {
      dispatch(finishGlobalLoading());
    }
  }, [search]);

  const getFetchData = (e: React.FormEvent) => {
    e.preventDefault();
    fetchData();
  };

  const handleModal = () => {
    setOpen(!open);
  };
  const closeRefresh = () => {
    setOpen(false);
    fetchData();
  };

  // <Autocomplete> Enter칠때는 onInputChange, 클릭시에는 onChange
  return (
    <Wrapper>
      <form onSubmit={getFetchData}>
        <Autocomplete
          id="coporation-charge-box"
          options={sort_corp.sort(ascendingFunc)}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              name="corporation"
              label="법인 이름"
              variant="outlined"
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === "Enter" && e.target instanceof HTMLInputElement) {
                  const inputValue: string = e.target.value;
                  const pressEnter = sort_corp.filter(
                    (el) => el.name === inputValue
                  )[0];
                  setSearch(pressEnter);
                }
              }}
            />
          )}
        />
      </form>
      <SingleCorpCharge
        search={search}
        chargeList={chargeList}
        corpId={corpId}
        corpName={corpName}
        open={open}
        thisPage={thisPage}
        setThisPage={setThisPage}
        fetchData={fetchData}
        handleModal={handleModal}
        closeRefresh={closeRefresh}
      />
    </Wrapper>
  );
}

export default SingleCorpChargeContainer;

const Wrapper = styled.div`
  margin: 3rem 15rem;
`;
