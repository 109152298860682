import React from "react";
import { Button } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";

interface ExcelFileDownProps {
  onClick: (e: React.MouseEvent) => void;
}

function ExcelFileDown({ onClick }: ExcelFileDownProps) {
  return (
    <Button
      className="bill-button"
      variant="outlined"
      color="default"
      size="large"
      startIcon={<CloudDownload />}
      onClick={onClick}
    >
      엑셀양식 다운로드
    </Button>
  );
}

export default ExcelFileDown;
