import { createAction, handleActions } from "redux-actions";
import createRequestSaga, {
  createRequestActionTypes,
} from "../lib/createRequestSaga";
import { takeLatest } from "redux-saga/effects";
import * as API from "../lib/api/auth";

// Action Types
const [CHECK, CHECK_SUCCESS, CHECK_FAILURE] =
  createRequestActionTypes("user/CHECK");
const LOGOUT = "user/LOGOUT";

// Action Creator 생성
export const check = createAction(CHECK);
export const logout = createAction(LOGOUT);

// Reducer 생성
const initialState = {
  user: JSON.parse(sessionStorage.getItem("user")) || null,
  checkError: null,
};
const user = handleActions(
  {
    [CHECK_SUCCESS]: (state, { payload: user }) => {
      sessionStorage.setItem("user", JSON.stringify(user));
      return {
        ...state,
        user,
        checkError: null,
      };
    },
    [CHECK_FAILURE]: (state, { payload: error }) => {
      if (error?.response?.status === 400) {
        return {
          ...state,
          user: null,
          checkError: error,
        };
      } else {
        console.log("3개월 변경 외 오류");
        sessionStorage.clear();
        return {
          ...state,
          user: null,
          checkError: error,
        };
      }
    },
    [LOGOUT]: (state) => ({
      ...state,
      user: null,
    }),
  },
  initialState
);
export default user;

// Saga
const checkSaga = createRequestSaga(CHECK, API.getUserData);
const logoutSaga = () => {
  try {
    sessionStorage.clear();
  } catch (e) {
    console.log("sessionStorage.removeItem Error");
  }
};

export function* userSaga() {
  yield takeLatest(CHECK, checkSaga);
  yield takeLatest(LOGOUT, logoutSaga);
}
