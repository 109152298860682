import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import CircularLoading from "../../components/common/CircularLoading";
import AddressCheckContainer from "../../containers/groupOrder/AddressCheckContainer";
import SenderInfoFormContainer from "../../containers/groupOrder/SenderInfoFormContainer";
import ResultTableContainer from "../../containers/groupOrder/ResultTableContainer";

function GroupOrderPage() {
  const loading = useSelector(
    ({ groupOrder }: RootStateOrAny) => groupOrder
  ).loading;

  return (
    <>
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <AddressCheckContainer />
          <SenderInfoFormContainer />
          <ResultTableContainer />
        </>
      )}
    </>
  );
}

export default GroupOrderPage;
