import React from "react";
import styled from "styled-components";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@material-ui/core";
import { ChannelData } from "../../lib/constants/constants";
import { SchedulerType } from "../../containers/scheduler/types";
import { FlexBox, FlexColumnCenter, ColumnDivider } from "../common/Base";

interface SchedulerInputProps {
  form: SchedulerType;
  handleStatus: (event: React.MouseEvent) => void;
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  onSubmit: (event: React.FormEvent) => void;
}

function SchedulerHeader({
  form,
  handleStatus,
  onChange,
  onSubmit,
}: SchedulerInputProps) {
  return (
    <FlexColumnCenter>
      <h1>스케줄러 유저(인성) 관리</h1>
      <StyledFlexBox>
        <FormControl>
          <InputLabel id="schedule-select-label">채널</InputLabel>
          <PaddingRightSelect
            labelId="schedule-select-label"
            id="schedule-select"
            name="channel"
            value={form.channel}
            onChange={onChange}
          >
            {ChannelData.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.label}
              </MenuItem>
            ))}
          </PaddingRightSelect>
        </FormControl>
        <StyledTextField
          label="인성 콜센터 거래처 명"
          id="company_name"
          name="company_name"
          value={form.company_name}
          onChange={onChange}
          variant="outlined"
          margin="normal"
        />
        <StyledTextField
          label="인성 콜센터 가입 회원 아이디"
          id="user_id"
          name="user_id"
          value={form.user_id}
          onChange={onChange}
          variant="outlined"
          margin="normal"
          type="number"
        />
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={onSubmit}
        >
          추가
        </Button>
        <ColumnDivider />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleStatus}
        >
          스케줄러 상태 보기
        </Button>
      </StyledFlexBox>
    </FlexColumnCenter>
  );
}
export default SchedulerHeader;

const StyledFlexBox = styled(FlexBox)`
  margin: 2rem auto 1rem;
  gap: 15px;
`;

const PaddingRightSelect = styled(Select)`
  width: 150px;
`;

const StyledTextField = styled(TextField)`
  margin: 0 !important;
  width: 250px;
`;
