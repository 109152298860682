import styled from "styled-components";
import { Button, TextField } from "@material-ui/core";
import {
  FlexBox,
  FlexBoxContentEnd,
  FlexColumnCenter,
  RowDivider,
  ColumnDivider,
} from "../common/Base";
import Detail from "../common/Detail";
import { formatDate } from "../../lib/datetime";
import { InquiryType } from "../../containers/corporationInquiry/types";

interface InquiryDetailProps {
  inquiryData: InquiryType;
  handleCheckInquiry: () => void;
  handleInquiryOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function InquiryDetail({
  inquiryData: {
    address,
    api_enable,
    check_memo,
    consignment,
    created,
    customer_email,
    customer_name,
    customer_phone,
    inquiry,
    monthly_usage_amount,
    name,
    users_user: user,
  },
  handleCheckInquiry,
  handleInquiryOnChange,
}: InquiryDetailProps) {
  return (
    <FlexColumnCenterInquiry>
      <Detail>
        <FlexBox>
          <TextField
            label="등록날짜"
            id="created"
            name="created"
            value={formatDate(created) || " "}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="확인 관리자"
            id="fullname"
            name="fullname"
            value={user?.fullname || ""}
            variant="outlined"
            margin="normal"
            fullWidth
            disabled
          />
        </FlexBox>
      </Detail>
      <RowDivider size="2" />
      <Detail>
        <FlexBox>
          <TextField
            label="회사명"
            id="name"
            name="name"
            value={name || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="회사주소"
            id="address"
            name="address"
            value={address || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="퀵서비스 월간 사용금액"
            id="monthly_usage_amount"
            name="monthly_usage_amount"
            value={monthly_usage_amount || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="알고퀵 API 연동 희망 여부"
            id="api_enable"
            name="api_enable"
            value={api_enable ? "O" : "X"}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="주요 배송품목"
            id="consignment"
            name="consignment"
            value={consignment || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="담당자 이름"
            id="customer_name"
            name="customer_name"
            value={customer_name || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="담당자 연락처"
            id="customer_phone"
            name="customer_phone"
            value={customer_phone || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="담당자 이메일주소"
            id="customer_email"
            name="customer_email"
            value={customer_email || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="문의사항"
            id="inquiry"
            name="inquiry"
            multiline
            minRows={3}
            value={inquiry || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="메모 기록"
            id="check_memo"
            name="check_memo"
            multiline
            minRows={3}
            value={check_memo || ""}
            variant="filled"
            margin="normal"
            fullWidth
            onChange={handleInquiryOnChange}
          />
        </FlexBox>
        <RowDivider />
        <FlexBoxContentEnd>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleCheckInquiry()}
          >
            문의확인 및 메모저장
          </Button>
        </FlexBoxContentEnd>
      </Detail>
    </FlexColumnCenterInquiry>
  );
}
export default InquiryDetail;

export const FlexColumnCenterInquiry = styled(FlexColumnCenter)`
  margin-top: 2rem;
`;
