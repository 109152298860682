export const headRows = [
  { id: "created", numeric: false, disablePadding: false, label: "등록시간" },
  { id: "channel", numeric: false, disablePadding: false, label: "채널" },
  { id: "is_isd_order", numeric: false, disablePadding: false, label: "구분" },
  {
    id: "isd_order_number",
    numeric: false,
    disablePadding: false,
    label: "인성번호",
  },
  {
    id: "call24_order_number",
    numeric: false,
    disablePadding: false,
    label: "화물번호",
  },
  { id: "client_name", numeric: false, disablePadding: false, label: "의뢰인" },
  {
    id: "sender_jibun_address",
    numeric: false,
    disablePadding: false,
    label: "출발지 주소",
  },
  {
    id: "sender_name",
    numeric: false,
    disablePadding: false,
    label: "출발지 이름",
  },
  {
    id: "receiver_jibun_address",
    numeric: false,
    disablePadding: false,
    label: "도착지 주소",
  },
  {
    id: "receiver_name",
    numeric: false,
    disablePadding: false,
    label: "도착지 이름",
  },
  {
    id: "location_charge",
    numeric: true,
    disablePadding: false,
    label: "배송요금",
  },
  { id: "vehicle_type", numeric: false, disablePadding: false, label: "차량" },
  { id: "payment", numeric: false, disablePadding: false, label: "결제방식" },
  { id: "status", numeric: false, disablePadding: false, label: "상태" },
];
