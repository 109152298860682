import React, { useState } from "react";
import styled from "styled-components";
import { Snackbar, TextField, Chip, Button } from "@material-ui/core";
import Notice from "../../components/common/Notice";
import { ContainerWrapper } from "../../components/common/Base";
import { RowDivider } from "../../components/common/Base";
import Map from "../../components/bundle/Map";
import {
  InlineBlockPaper,
  WhiteSpacePaper,
} from "../../components/common/MaterialBase";
import { bundleOrders } from "../../lib/api/orders";

// TODO: 변수명 수정

const RowContainerWrapper = styled(ContainerWrapper)`
  flex-direction: row;
`;

const HalfContainer = styled.div`
  width: 50%;
  padding: 1rem;
`;

const MarginChip = styled(Chip)`
  margin: 0.3rem;
`;

const TextWrapper = styled.div`
  padding-top: 2rem;
  text-align: center;
`;

const StyledTextBox = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const BundleContainer = () => {
  // 확인 결과 메시지 출력(SnackBar)
  const [error, setError] = useState(false);
  const [noticeOpen, setNoticeOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // 스낵바 닫힘
  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNoticeOpen(false);
  };

  // 경로 수
  const [pathCount, setPathCount] = useState(1);

  // 보내는 사람 주소
  const [senderAddress, setSenderAddress] = useState("");
  const [senderBcode, setSenderBcode] = useState("");

  // 받는 사람 주소 목록
  // 칩으로 쌓임
  const [receiverAddressList, setReceiverAddressList] = useState([]);
  // 결과 주소 목록
  const [resultAddressList, setResultAddressList] = useState([]);

  const [log, setLog] = useState("");

  // 칩삭제
  // 선택한 칩을 제외한 나머지 칩들로만 주소목록 상태를 update
  const handleDelete = (chipToDelete) => () => {
    setReceiverAddressList((receiverAddressList) =>
      receiverAddressList.filter(
        (data) => data.receiverAddressId !== chipToDelete
      )
    );
  };

  const handleReset = () => {
    setSenderAddress("");
    setSenderBcode("");
    setReceiverAddressList([]);
    setResultAddressList([]);
    setPathCount(1);
    setLog("");
  };

  // 경로수 변화할때 호출되는 handler 함수
  const handleOnChange = (e) => {
    setResultAddressList([]);
    setLog("");
    setPathCount(e.target.value);
  };

  // ANALYSIS 버튼 클릭하면 호출
  const handleSubmit = async (e) => {
    e.preventDefault();

    // alert("해당 기능은 잠시 개발 및 테스트 중입니다.");

    // FIXME: 해당 기능은 추가 테스트 및 안정성 확보 필요
    // 경로수 0 일때 예외처리
    if (pathCount <= 0) {
      setMessage(`경로 수 확인해주세요`);
      setError(true);
      setNoticeOpen(true);
      return;
    }
    // path = 도착지를 지나가는 경로의 수 이기 때문에 도착지 보다 경로의 수가 많을 수 없다.
    // ex> 도착지 3 개이면 경로의 최대값은 각각의 도착지에 이동하는 3개이다.
    // 경로의 수가 도착지보다 클 때 예외처리
    if (pathCount > receiverAddressList.length) {
      setMessage(
        `경로 수(${pathCount})는 도착지의 수(${receiverAddressList.length})보다 클 수 없습니다.`
      );
      setError(true);
      setNoticeOpen(true);
      return;
    }
    // 도착지 주소목록에서 B code 를 따로 뽑아서 list 만듬
    const receiverBcodeList = receiverAddressList.map(
      (el) => el["receiverBcode"]
    );

    // 서버에 보내는 사람 Bcode 도착지 b code list, 경로수를 보낸다.
    // 응답으로 성공, 실패 목록을 받는다.

    let res_success = [];
    let res_failed = [];
    setLoading(true);
    await bundleOrders({
      senderBcode,
      receiverBcodeList,
      pathCount,
    })
      .then((response) => {
        res_success = response.data["success"];
        res_failed = response.data["failed"];
      })
      .catch(() => {
        setMessage("계산중 에러가 발생하였습니다.");
        setError(true);
        setNoticeOpen(true);
      })
      .finally(() => setLoading(false));

    let logs_receiver = "";

    let totalReceiverAddressList = [];

    if (res_failed.length === 0) {
      /* res_success.length !==0 은 에러도착지 2개부터 [""]받아와서 카운트1.. */
      for (let index = 0; index < res_success.length; index++) {
        let innerRes_successList = res_success[index];
        let innerReceiverAddressList = [];
        logs_receiver += `출발지[0] : ${senderAddress}\n`;
        for (
          let innerIndex = 0;
          innerIndex < innerRes_successList.length;
          innerIndex++
        ) {
          // 도착지 주소는 사용자가 설정한 도착지와 서버에서 계산 후 돌려준 bcode 가 일치하는 도착지 목록안에 있는 도착지 주소
          const receiverAddress = receiverAddressList.filter(
            (el) => el.receiverBcode === innerRes_successList[innerIndex]
          )[0].receiverAddress;
          // 계산에 성공한 도착지 들을 log 로 보여줌
          logs_receiver += `도착지[${innerIndex + 1}] : ${receiverAddress}\n`;
          // innerReceiverAddressList 에 주소를 배열로 해서 넣음
          innerReceiverAddressList.push([receiverAddress]);
        }

        if (index !== res_success.length - 1) {
          logs_receiver += `----------------------------------------------\n`;
        }
        // 주소를 배열로 넣은 innerReceiverAddressList 를 totalReceiverAddressList에 넣음
        totalReceiverAddressList.push(innerReceiverAddressList);
      }
    }
    if (res_success.length === 0) {
      logs_receiver += `출발지[0] : ${senderAddress}\n`;
    }

    for (let index = 0; index < res_failed.length; index++) {
      const receiverAddress = receiverAddressList.filter(
        // eslint-disable-next-line
        (el) => el.receiverBcode === res_failed[index]
      )[0].receiverAddress;

      if (index === 0) {
        logs_receiver += `----------------------------------------------\n`;
      }
      logs_receiver += `도착지[ERROR] : ${receiverAddress}\n`;
    }

    setLog(logs_receiver);

    setResultAddressList(totalReceiverAddressList);
  };
  // Input 창 클릭하면 다음 지도검색 창을 open 함
  const openDaumPostcode = (flag) => {
    if (receiverAddressList.length === 7) {
      setMessage("도착지는 7개까지 가능합니다");
      setError(true);
      setNoticeOpen(true);
      return;
    }
    // 다음 주소 검색 창이 load 되면 실행
    // eslint-disable-next-line
    daum.postcode.load(() => {
      // eslint-disable-next-line
      new daum.Postcode({
        oncomplete: (data) => {
          let jibunAddress = data.address;
          let bcode = data.bcode;
          bcode = bcode.slice(0, bcode.length - 2);

          const geocoder = new window.daum.maps.services.Geocoder();
          geocoder.addressSearch(jibunAddress, function (results, status) {
            // 검색한 주소로 좌표검색이 안될경우 예외처리
            if (results.length === 0) {
              jibunAddress = `${data.sido} ${data.sigungu} ${data.bname}`;
              setMessage(
                `좌표 검색 불가지역으로 \n[${data.address}] ->\n[${jibunAddress}] 으로 검색합니다`
              );
              setNoticeOpen(true);
            }
            if (flag === "sender") {
              // 출발지 검색했을 때
              setSenderAddress(jibunAddress);
              setSenderBcode(bcode);
            } else {
              // DB 처럼
              // 도착지 목록이 추가될 때 마다 id 를 auto increment 하기 위한 코드
              // 초기값은 1 이고 이후 추가되면 기존 id 에 +1 씩 해준다.
              let receiverAddressId;
              if (receiverAddressList.length > 0) {
                receiverAddressId =
                  receiverAddressList[receiverAddressList.length - 1]
                    .receiverAddressId + 1;
              } else {
                receiverAddressId = 1;
              }

              setReceiverAddressList([
                ...receiverAddressList,
                {
                  receiverAddressId,
                  receiverAddress: jibunAddress,
                  receiverBcode: bcode,
                },
              ]);
            }
          });
        },
      }).open();
    });
  };

  return (
    <>
      <TextWrapper>
        <StyledTextBox>Algolab`s Analysis for Combined delivery</StyledTextBox>
      </TextWrapper>
      <RowContainerWrapper>
        <Map
          senderAddress={senderAddress}
          receiverAddressList={receiverAddressList}
          resultAddressList={resultAddressList}
        />
        <HalfContainer>
          <TextField
            label="Department Address"
            id="sender_address"
            name="sender_address"
            value={senderAddress}
            variant="outlined"
            margin="normal"
            fullWidth
            onClick={() => openDaumPostcode("sender")}
          />
          <TextField
            label="Destination Address"
            id="receiver_address"
            name="receiver_address"
            value={""}
            variant="outlined"
            margin="normal"
            fullWidth
            onClick={() => openDaumPostcode("receiver")}
          />
          <TextField
            label="Path"
            id="path_count"
            name="path_count"
            value={pathCount}
            variant="outlined"
            margin="normal"
            onChange={handleOnChange}
            fullWidth
            type="number"
          />
          {receiverAddressList.length > 0 ? (
            <InlineBlockPaper>
              {receiverAddressList.map((data) => {
                return (
                  <MarginChip
                    key={data.receiverAddressId}
                    label={data.receiverAddress}
                    onDelete={handleDelete(data.receiverAddressId)}
                  />
                );
              })}
            </InlineBlockPaper>
          ) : null}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            Analysis
          </Button>
          <RowDivider />
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleReset}
          >
            Reset
          </Button>
          {loading ? <WhiteSpacePaper>계산중입니다</WhiteSpacePaper> : null}
          {log && <WhiteSpacePaper>{log}</WhiteSpacePaper>}
        </HalfContainer>
      </RowContainerWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={noticeOpen}
        autoHideDuration={5000}
        onClose={closeSnackbar}
        key={message}
      >
        {!error ? (
          <Notice variant="success" message={message} onClose={closeSnackbar} />
        ) : (
          <Notice variant="error" message={message} onClose={closeSnackbar} />
        )}
      </Snackbar>
    </>
  );
};

export default BundleContainer;
