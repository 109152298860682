import graphqlQuery from "./base";
import {
  radioVehicleTypes,
  AddLoadingChargeType,
  RoundTripType,
  WeatherChargeType,
  TRUCK_OPTION,
  BulkChargeType,
  ExceptHolidayType,
  HolidayChargeType,
} from "../../containers/corporation/extraTypes";
import {
  calcExpressCharge,
  calcTimeCharge,
  calcDamasLaboCharge,
  calcTruckCharge,
  calcVehicleDefaultCharge,
} from "../../containers/corporation/utils";

export const getCorporationName = (corpId: string) =>
  graphqlQuery(`
    query {
      corporations_corporation(where: {id: {_eq: "${corpId}"}}) {
        name
      }
    }
  `);

// 급송요금
export const getCorpExpressCharge = (corpId: string) =>
  graphqlQuery(`
    query {
      corporations_corporationextracharge(where: {corporation_id: {_eq: "${corpId}"}}) {
        base_charge
        express_charge
        id
      }
    }
  `);
export const createCorpExpressCharge = (
  corpId: string,
  baseChargeRange: number[]
) => {
  const timeCheck = new Date().toISOString();

  // 단일 Objects에 51개 인입(기본요금 범위)
  return graphqlQuery(`
    mutation {
      insert_corporations_corporationextracharge(
        objects: [
          ${baseChargeRange.map(
            (baseCharge) => `
              {
                created: "${timeCheck}",
                modified: "${timeCheck}",
                corporation_id: "${corpId}",
                base_charge: ${baseCharge},
                express_charge: ${calcExpressCharge(baseCharge)}
              }
            `
          )}
        ]
      ) {
        returning {
          id
        }
      }
    }
  `);
};
export const updateCorpExpressCharge = (
  corpId: string,
  data: { baseCharge: number; extraCharge: number }
) => {
  return graphqlQuery(`
    mutation {
      update_corporations_corporationextracharge(
        where: {
          corporation_id: {_eq: "${corpId}"},
          base_charge: {_eq: ${data.baseCharge}}
        },
        _set: {
          express_charge: ${data.extraCharge}
        }
      ) {
        returning {
          id
        }
      }
    }
  `);
};
export const deleteCorpExpressCharge = (corpId: string) => {
  return graphqlQuery(`
    mutation {
      delete_corporations_corporationextracharge(
        where: {
          corporation_id: {_eq: "${corpId}"}
      }) {
        returning {
          id
        }
      }
    }
  `);
};

// 시간요금
export const getCorpTimeCharge = (corpId: string, time: number) =>
  graphqlQuery(`
    query {
      corporations_corporationtimecharge(
        where: {
          corporation_id: {_eq: "${corpId}"}, time: {_eq: ${time}}
      }) {
        base_charge
        time_charge
        time
        vehicle_type
        id
      }
    }
  `);
export const createCorpTimeCharge = (
  corpId: string,
  baseChargeRange: number[],
  vehicleTypes: string[],
  time: number // 0 ~ 23
) => {
  const timeCheck = new Date().toISOString();

  /**
   * @description Mutiple Insert 활용 + time단위로 요금 생성
   * @property { object } 천원단위 기본요금 51가지 X 차량타입별 시간요금 4가지 = 204개 요금 생성
   * 단일 Objects에 204개 인입
   */
  return graphqlQuery(`
    mutation {
      insert_corporations_corporationtimecharge(
        objects: [
          ${baseChargeRange.map((baseCharge) =>
            vehicleTypes.map(
              (vehicleType) => `
                {
                  corporation_id: "${corpId}",
                  created: "${timeCheck}",
                  modified: "${timeCheck}",
                  base_charge: ${baseCharge},
                  time: ${time},
                  time_charge: ${calcTimeCharge(time, baseCharge)},
                  vehicle_type: "${vehicleType}"
                }`
            )
          )}
        ]
      ) {
        returning {
          id
        }
      }
    }
  `);
};
export const updateCorpTimeCharge = (
  corpId: string,
  time: number,
  data: { baseCharge: number; extraCharge: number; vehicleType: string }
) => {
  /**
   * @description Multiple Update 불가능
   * mutiple insert와 달리 mulitple update는 hasura 2.10.0 이상부터 가능하다.
   * hasura 2.10.0부터 updates: []를 활용하여 다량 데이터를 업데이트할 수 있다.
   */
  return graphqlQuery(`
  mutation {
    update_corporations_corporationtimecharge(
      where: {
        corporation_id: {_eq: "${corpId}"},
        time: {_eq: ${time}},
        base_charge: {_eq: ${data.baseCharge}}
        vehicle_type: {_eq: "${data.vehicleType}"},
      }, 
      _set: {
        time_charge: ${data.extraCharge},
      }
    ) {
        returning {
          base_charge
        }
      }
    }
  `);
};
export const deleteCorpTimeCharge = (corpId: string, time: number) => {
  // time단위로 요금 삭제
  return graphqlQuery(`
    mutation {
      delete_corporations_corporationtimecharge(
        where: {
          corporation_id: {_eq: "${corpId}"}, time: {_eq: ${time}}
        }) {
        returning {
          id
        }
      }
    }
  `);
};

// 왕복요금
export const getCorpRoundTripCharge = (corpId: string) =>
  graphqlQuery(`
    query {
      corporations_corporationroundtripcharge(
        where: {
          corporation_id: {_eq: "${corpId}"}
      }) {
        vehicle_type
        round_trip_charge_rate
      }
    }
  `);
export const createCorpRoundTripCharge = (corpId: string) => {
  const timeCheck = new Date().toISOString();

  // 단일 Objects에 4개 인입(차량별)
  return graphqlQuery(`
    mutation {
      insert_corporations_corporationroundtripcharge(
        objects: [
          ${radioVehicleTypes.map(
            ({ value }) => `
            {
              corporation_id: "${corpId}",
              created: "${timeCheck}",
              modified: "${timeCheck}",
              round_trip_charge_rate: 100,
              vehicle_type: "${value}"
            }`
          )}
        ]
      ) {
        returning {
          id
        }
      }
    }
  `);
};
export const updateCorpRoundTripCharge = (
  corpId: string,
  data: RoundTripType
) => {
  return graphqlQuery(`
    mutation {
      update_corporations_corporationroundtripcharge(
        where: {
          corporation_id: {_eq: "${corpId}"},
          vehicle_type: {_eq: "${data.vehicle_type}"}
        },
        _set: {
          round_trip_charge_rate: ${data.round_trip_charge_rate}
        }
      ) {
        returning {
          id
        }
      }
    }
  `);
};
export const deleteCorpRoundTripCharge = (corpId: string) => {
  return graphqlQuery(`
    mutation {
      delete_corporations_corporationroundtripcharge(
        where: {corporation_id: {_eq: "${corpId}"}
      }) {
        returning {
          id
        }
      }
    }

  `);
};

// 과적요금
export const getCorpBulkCharge = (corpId: string) => {
  return graphqlQuery(`
    query {
      corporations_corporationbulkcharge(
        where: {
          corporation_id: {_eq: "${corpId}"}
      }) {
        bulk_score
        weight
        bulk_charge
      }
    }
  `);
};
export const createCorpBulkCharge = (
  corpId: string,
  charges: BulkChargeType[]
) => {
  const timeCheck = new Date().toISOString();
  return graphqlQuery(`
    mutation {
      insert_corporations_corporationbulkcharge(
        objects: [
          ${charges.map(
            ({ bulk_score, weight, bulk_charge }) => `
              {
                created: "${timeCheck}",
                modified: "${timeCheck}",
                corporation_id: "${corpId}",
                vehicle_type: "MOTORCYCLE",
                bulk_score: ${bulk_score},
                weight: ${weight},
                bulk_charge: ${bulk_charge},
              }`
          )}
        ]) {
        returning {
          id
        }
      }
    }
  `);
};
export const updateCorpBulkCharge = (corpId: string, data: BulkChargeType) => {
  return graphqlQuery(`
    mutation {
      update_corporations_corporationbulkcharge(
        where: {
          corporation_id: {_eq: "${corpId}"},
          bulk_score: {_eq: ${data.bulk_score}},
          weight: {_eq: ${data.weight}}
        },
        _set: {
          bulk_charge: ${data.bulk_charge}
        }) {
        returning {
          id
        }
      }
    }
  `);
};
export const deleteCorpBulkCharge = (corpId: string) => {
  return graphqlQuery(`
    mutation {
      delete_corporations_corporationbulkcharge(
        where: {corporation_id: {_eq: "${corpId}"}
      }) {
        returning {
          id
        }
      }
    }
  `);
};

// 상하차요금 (다마스/라보)
export const getCorpLoadingDamasLaboCharge = (
  corpId: string,
  vehicleType: string
) => {
  return graphqlQuery(`
    query {
      corporations_corporationloadingcharge(
        where: {
          corporation_id: {_eq: "${corpId}"}, vehicle_type: {_eq: "${vehicleType}"}
        }
      ) {
        vehicle_type
        loading_level
        loading
        unloading
        loading_charge
      }
    }
  `);
};
export const createCorpLoadingDamasLaboCharge = (corpId: string) => {
  const timeCheck = new Date().toISOString();

  // 단일 Objects에 72개 인입(다마스36 + 라보36)
  return graphqlQuery(`
    mutation {
      insert_corporations_corporationloadingcharge(
        objects: [
          ${damasLaboLoadingsType.map(({ loading, unloading }) =>
            loadingLevel.map(
              (level) => `
                {
                  corporation_id: "${corpId}",
                  created: "${timeCheck}",
                  modified: "${timeCheck}",
                  vehicle_type: "DAMAS",
                  loading_level: ${level},
                  loading: "${loading}",
                  unloading: "${unloading}",
                  loading_charge: ${calcDamasLaboCharge(
                    level,
                    loading,
                    unloading
                  )},
                }`
            )
          )},
          ${damasLaboLoadingsType.map(({ loading, unloading }) =>
            loadingLevel.map(
              (level) => `
                {
                  corporation_id: "${corpId}",
                  created: "${timeCheck}",
                  modified: "${timeCheck}",
                  vehicle_type: "LABO",
                  loading_level: ${level},
                  loading: "${loading}",
                  unloading: "${unloading}",
                  loading_charge: ${calcDamasLaboCharge(
                    level,
                    loading,
                    unloading
                  )},
                }`
            )
          )}
        ]
      ) {
        returning {
          id
        }
      }
    }
  `);
};

export const updateCorpLoadingDamasLaboCharge = (
  corpId: string,
  data: AddLoadingChargeType
) => {
  // 다마스 + 라보 상하차요금 둘다 등록
  return graphqlQuery(`
    mutation {
      update_corporations_corporationloadingcharge(
        where: {
          corporation_id: {_eq: "${corpId}"},
          _or: [
            {vehicle_type: {_eq: "DAMAS"}}, {vehicle_type: {_eq: "LABO"}}
          ]
          loading_level: {_eq: ${data.loading_level}},
          loading: {_eq: "${data.loading}"},
          unloading: {_eq: "${data.unloading}"}
        },
        _set: {
          loading_charge: ${data.loading_charge}
        }
      ) {
        returning {
          id
        }
      }
    }
  `);
};

export const deleteCorpLoadingDamLaboCharge = (corpId: string) =>
  // 다마스 + 라보 상하차요금 전체 삭제
  graphqlQuery(`
    mutation {
      delete_corporations_corporationloadingcharge(
        where: {
          corporation_id: {_eq: "${corpId}"},
          _or: [
            {vehicle_type: {_eq: "DAMAS"}}, {vehicle_type: {_eq: "LABO"}}
          ]
        }) {
        returning {
          id
        }
      }
    }
  `);

export const getCorpLoadingTruckCharge = (corpId: string) => {
  return graphqlQuery(`
    query {
      corporations_corporationloadingcharge(
        where: {
          corporation_id: {_eq: "${corpId}"}, vehicle_type: {_eq: "TRUCK"}
        }
      ) {
        vehicle_type
        vehicle_option
        loading
        unloading
        loading_charge
      }
    }
  `);
};
export const createCorpLoadingTruckCharge = async (corpId: string) => {
  const timeCheck = new Date().toISOString();
  /**
   * 단일 Objects에 16개 인입
   * - 트럭은 1T만 전체 생성하고, 이후 톤수 요금추가는 Selector로 추가한다.
   * - 미등록시 기본 상하차 요금 적용되고 있기 때문.
   * - loading_level은 필수값 (Not-NULL violation)
   */
  return graphqlQuery(`
    mutation {
      insert_corporations_corporationloadingcharge(
        objects: [
          ${truckLoadingsType.map(({ loading, unloading }) =>
            TRUCK_OPTION.map(
              (truckOption) => `
                {
                  corporation_id: "${corpId}",
                  created: "${timeCheck}",
                  modified: "${timeCheck}",
                  vehicle_type: "TRUCK",
                  vehicle_option: "${truckOption}",
                  loading_level: 1,
                  loading: "${loading}",
                  unloading: "${unloading}",
                  loading_charge: ${calcTruckCharge(
                    truckOption,
                    loading,
                    unloading
                  )}
                }`
            )
          )}
        ]
      ) {
        returning {
          id
        }
      }
    }
  `);
};
export const updateCorpLoadingTruckCharge = (
  corpId: string,
  data: AddLoadingChargeType
) => {
  return graphqlQuery(`
    mutation {
      update_corporations_corporationloadingcharge(
        where: {
          corporation_id: {_eq: "${corpId}"},
          vehicle_type: {_eq: "TRUCK"},
          vehicle_option: {_eq: "${data.vehicle_option}"},
          loading: {_eq: "${data.loading}"},
          unloading: {_eq: "${data.unloading}"}
        },
        _set: {
          loading_charge: ${data.loading_charge}
        }
      ) {
        returning {
          id
        }
      }
    }
  `);
};
export const deleteCorpLoadingTruckCharge = (corpId: string) =>
  // 트럭 상하차요금 전체 삭제
  graphqlQuery(`
    mutation {
      delete_corporations_corporationloadingcharge(
        where: {
          corporation_id: {_eq: "${corpId}"},
          vehicle_type: {_eq: "TRUCK"},
        }) {
        returning {
          id
        }
      }
    }
  `);

// 제외 휴일
export const getCorpExceptHoliday = (corpId: string) =>
  graphqlQuery(`
    query {
      corporations_corporationexceptholiday(
        where: {
          corporation_id: {_eq: "${corpId}"}
        }) {
          except_date
          id
        }
    }
`);
export const createCorpExceptHoliday = (
  corpId: string,
  addHoliday: ExceptHolidayType[]
) => {
  const timeCheck = new Date().toISOString();
  return graphqlQuery(`
    mutation {
      insert_corporations_corporationexceptholiday(
        objects: [
          ${addHoliday.map(
            ({ except_date }: ExceptHolidayType) => `
              {
                corporation_id: "${corpId}",
                created: "${timeCheck}",
                modified: "${timeCheck}",
                except_date: "${except_date}"
              }    
            `
          )}
        ]
      ) {
        returning {
          id
        }
      }
    }
  `);
};
export const deleteCorpExceptHoliday = (corpId: string, exceptDate: string) =>
  graphqlQuery(`
  mutation {
    delete_corporations_corporationexceptholiday(
      where: {
        corporation_id: {_eq: "${corpId}"},
        except_date: {_eq: "${exceptDate}"}
    }) {
      returning {
        id
      }
    }
  }
`);

// 날씨요금
export const getCorpWeatherCharge = (corpId: string) =>
  graphqlQuery(`
    query {
      corporations_corporationweathercharge(
        where: {
          corporation_id: {_eq: "${corpId}"}
      }) {
        is_icy_road
        vehicle_type
        weather_charge
      }
    }
  `);
export const createCorpWeatherCharge = (corpId: string) => {
  const timeCheck = new Date().toISOString();

  // 단일 Objects에 4개 인입(차량별)
  return graphqlQuery(`
    mutation {
      insert_corporations_corporationweathercharge(
        objects: [
          ${radioVehicleTypes.map(
            ({ value }) => `
            {
              corporation_id: "${corpId}",
              created: "${timeCheck}",
              is_icy_road: false,
              modified: "${timeCheck}",
              vehicle_type: "${value}",
              weather_charge: ${calcVehicleDefaultCharge(value)}
            }`
          )}
        ]
      ) {
        returning {
          id
        }
      }
    }
  `);
};
export const updateCorpWeatherCharge = (
  corpId: string,
  data: WeatherChargeType
) =>
  graphqlQuery(`
    mutation {
      update_corporations_corporationweathercharge(
        where: {
          corporation_id: {_eq: "${corpId}"},
          vehicle_type: {_eq: "${data.vehicle_type}"}
        },
        _set: {
          weather_charge: ${data.weather_charge}
        }
      ) {
        returning {
          id
        }
      }
    }
  `);
export const deleteCorpWeatherCharge = (corpId: string) =>
  graphqlQuery(`
    mutation {
      delete_corporations_corporationweathercharge(
        where: {
          corporation_id: {_eq: "${corpId}"}
        }) {
        returning {
          id
        }
      }
    }
  `);

// 휴일/명절요금
export const getCorpHolidayCharge = (corpId: string) =>
  graphqlQuery(`
    query {
      corporations_corporationholidaycharge(
        where: {
          corporation_id: {_eq: "${corpId}"}
        }) {
        vehicle_type
        holiday_charge
        is_special
      }
    }
  `);
export const createCorpHolidayCharge = (corpId: string) => {
  const timeCheck = new Date().toISOString();
  return graphqlQuery(`
    mutation {
      insert_corporations_corporationholidaycharge(
        objects: [
          ${radioVehicleTypes.map(
            ({ value }) => `
            {
              corporation_id: "${corpId}",
              created: "${timeCheck}",
              modified: "${timeCheck}",
              vehicle_type: "${value}",
              is_special: false
              holiday_charge: ${calcVehicleDefaultCharge(value)},
            }`
          )}
          ${radioVehicleTypes.map(
            ({ value }) => `
            {
              corporation_id: "${corpId}",
              created: "${timeCheck}",
              modified: "${timeCheck}",
              vehicle_type: "${value}",
              is_special: true
              holiday_charge: ${calcVehicleDefaultCharge(value)},
            }`
          )}
        ]
      ) {
        returning {
          id
        }
      }
    }
`);
};
export const updateCorpHolidayCharge = (
  corpId: string,
  data: HolidayChargeType
) =>
  graphqlQuery(`
    mutation {
      update_corporations_corporationholidaycharge(
        where: {
          corporation_id: {_eq: "${corpId}"},
          vehicle_type: {_eq: "${data.vehicle_type}"},
          is_special: {_eq: ${data.is_special}}
        }, 
        _set: {
          holiday_charge: ${data.holiday_charge}
        }
      ) {
        returning {
          id
        }
      }
    }
`);
export const deleteCorpHolidayCharge = (corpId: string) =>
  graphqlQuery(`
    mutation {
      delete_corporations_corporationholidaycharge(
        where: {
          corporation_id: {_eq: "${corpId}"}
        }) {
        returning {
          id
        }
      }
    }
  `);

const loadingLevel = [1, 2, 3, 4];
const damasLaboLoadingsType = [
  {
    loading: "CUSTOMER",
    unloading: "CUSTOMER",
  },
  {
    loading: "CUSTOMER",
    unloading: "TOGETHER",
  },
  {
    loading: "CUSTOMER",
    unloading: "RIDER",
  },
  {
    loading: "TOGETHER",
    unloading: "CUSTOMER",
  },
  {
    loading: "TOGETHER",
    unloading: "TOGETHER",
  },
  {
    loading: "TOGETHER",
    unloading: "RIDER",
  },
  {
    loading: "RIDER",
    unloading: "CUSTOMER",
  },
  {
    loading: "RIDER",
    unloading: "TOGETHER",
  },
  {
    loading: "RIDER",
    unloading: "RIDER",
  },
];

const truckLoadingsType = [
  {
    loading: "CUSTOMER",
    unloading: "CUSTOMER",
  },
  {
    loading: "CUSTOMER",
    unloading: "FORK_LIFT",
  },
  {
    loading: "CUSTOMER",
    unloading: "TOGETHER",
  },
  {
    loading: "CUSTOMER",
    unloading: "RIDER",
  },
  {
    loading: "FORK_LIFT",
    unloading: "CUSTOMER",
  },
  {
    loading: "FORK_LIFT",
    unloading: "FORK_LIFT",
  },
  {
    loading: "FORK_LIFT",
    unloading: "TOGETHER",
  },
  {
    loading: "FORK_LIFT",
    unloading: "RIDER",
  },
  {
    loading: "TOGETHER",
    unloading: "CUSTOMER",
  },
  {
    loading: "TOGETHER",
    unloading: "FORK_LIFT",
  },
  {
    loading: "TOGETHER",
    unloading: "TOGETHER",
  },
  {
    loading: "TOGETHER",
    unloading: "RIDER",
  },
  {
    loading: "RIDER",
    unloading: "CUSTOMER",
  },
  {
    loading: "RIDER",
    unloading: "FORK_LIFT",
  },
  {
    loading: "RIDER",
    unloading: "TOGETHER",
  },
  {
    loading: "RIDER",
    unloading: "RIDER",
  },
];
export const radioTruckOptions = [
  {
    value: "1T",
    label: "1톤",
    disable: false,
  },
  {
    value: "1_4T",
    label: "1.4톤",
    disable: true,
  },
  {
    value: "2_5T",
    label: "2.5톤",
    disable: true,
  },
  {
    value: "3_5T",
    label: "3.5톤",
    disable: true,
  },
  {
    value: "5T",
    label: "5톤",
    disable: true,
  },
  {
    value: "8T",
    label: "8톤",
    disable: true,
  },
  {
    value: "11T",
    label: "11톤",
    disable: true,
  },
  {
    value: "14T",
    label: "14톤",
    disable: true,
  },
  {
    value: "15T",
    label: "15톤",
    disable: true,
  },
  {
    value: "18T",
    label: "18톤",
    disable: true,
  },
  {
    value: "25T",
    label: "25톤",
    disable: true,
  },
];
