import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { check } from "./modules/user";
import store from "./modules/store";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

/* 최상단이라 새로고침해도 check() 살아남음 */
function loadUser() {
  try {
    const user = sessionStorage.getItem("authorization");
    if (!user) return; // 로그인 상태가 아니라면 아무것도 안함

    store.dispatch(check(user));
  } catch (e) {
    console.log("sessionStorage is not working");
  }
}
loadUser();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
document.getElementById("root")
);

serviceWorker.unregister();
