import styled from "styled-components";
import Button from "../common/Button";

export const AuthFormBlock = styled.div`
  h3 {
    margin: 0;
    color: grey;
    margin-bottom: 1rem;
  }
`;

export const StyledInput = styled.input`
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid gray;
  padding-bottom: 0.5rem;
  outline: none;
  width: 100%;
  &:focus {
    border-bottom: 1px solid gray;
  }
  & + & {
    margin-top: 1rem;
  }
`;

export const ButtonWithMarginTop = styled(Button)`
  margin-top: 1rem;
  background: lightgray;
  width: 100%;
`;

export const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  margin-top: 1rem;
`;
export const passwordCheck = (password: string) => {
  const passwordRule =
    /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[`~!@#$%^&*()\-_=+[\]{}|\\;:'",<.>/?₩]).{8,16}$/;
  return passwordRule.test(password);
};
