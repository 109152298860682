import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";

import styled from "styled-components";
import React from "react";

export type SendTextMessageDialog = {
  open: boolean;
  text: string;
  onClose: (event: React.MouseEvent) => void;
  onChange: (text: string) => void;
  onSubmit: (event: React.MouseEvent) => void;
};

const TextInput = styled(TextField)`
  white-space: pre;
`;

function SendTextMessageDialog({
  open,
  onClose,
  onChange,
  onSubmit,
  text,
}: SendTextMessageDialog) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`배차문자 전송`}</DialogTitle>

      <TextInput
        variant="outlined"
        fullWidth={true}
        onChange={(event) => onChange(event.target.value)}
        multiline={true}
        value={text}
      />

      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="secondary">
          취소
        </Button>
        <Button
          type="submit"
          variant="outlined"
          onClick={onSubmit}
          color="primary"
          autoFocus
        >
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SendTextMessageDialog;
