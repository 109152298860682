import React from "react";
import styled from "styled-components";

import { ModalWrapper } from "../components/common/modal/Modal";

function IpBlockingPage() {
  return (
    <ModalWrapper>
      <ModalForm>
        <section>
          <Title>허용되지 않은 접근입니다.</Title>
          <Content>
            <div>허용되지 않은 IP로 접근하여 접속이 차단되었습니다.</div>
            <div>Algolab은 허용된 IP로만 접근하실 수 있습니다.</div>
            <div>접근권한이 필요하신 분은 개발팀에 문의해주세요.</div>
          </Content>
        </section>
      </ModalForm>
    </ModalWrapper>
  );
}

export default IpBlockingPage;

const ModalForm = styled.div`
  position: relative;
  padding: 1.5rem 2rem;
  width: 40rem;
  height: auto;

  background-color: #ffffff;
  letter-spacing: 1px;
  border-radius: 20px;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);

  section {
    margin: 2rem auto;
    width: 24.9rem;
  }
`;

const Title = styled.div`
  font-size: 2.3rem;
  font-weight: 600;
`;

const Content = styled.div`
  margin-top: 1rem;
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 700;
  color: #808080;
`;
