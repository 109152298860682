import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import AddressModal from "./AddressModal";
import Pagination from "../common/Pagination";
import {
  localKeyword,
  getAddressDetail,
  postAddressDetail,
  patchAddressDetail,
  deleteAddressDetail,
} from "../../lib/api/base";

import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
  Button,
  DialogActions,
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";

function AddressDetailList() {
  const classes = useStyles();
  const history = useHistory();

  // 저장된 상세주소 DB
  const [storage, setStorage] = useState([]);

  const [search, setSearch] = useState("");
  const [searchList, setSearchList] = useState([]);

  const [open, setOpen] = useState(false);

  /* Pagination */
  const [thisPage, setThisPage] = useState(1);
  const [dataPerPage] = useState(5);
  const idxOfLast = thisPage * dataPerPage;
  const idxOfFirst = idxOfLast - dataPerPage;
  const currentData = (storage: any): any => {
    const sorting = storage.sort((a: any, b: any) => {
      return b.desc - a.desc;
    }); // 오름차순
    return sorting.slice(idxOfFirst, idxOfLast);
  };
  /* "없는 데이터 공간"(emptyRows)을 계산해서 <1,2,3,4> 높이 위치 고정 */
  const emptyRows =
    storage.length !== 0
      ? dataPerPage -
        Math.min(dataPerPage, storage.length - (thisPage - 1) * dataPerPage)
      : 0;

  /**
   * @description 저장버튼 클릭시 POST PATCH 분리
   * 프론트에서 데이터 객체에 pk값 넣어서 구분한다.
   * @return { isUpdate: false } 초기 렌더링시
   * @return { isUpdate: true } 수정시
   * @return { isNew: true } 추가시
   */

  /* 초기 렌더링 */
  const fetchData = async () => {
    const fetchList = await getAddressDetail();
    let storedList = fetchList && fetchList.data;

    // 초기 렌더링시 { isUpdate: false }
    for (let index in storedList) {
      storedList[index] = {
        ...storedList[index],
        isUpdate: false, // 추가
        desc: Number(index), // 정렬 추가
      };
    }
    setStorage(storedList);
  };
  useEffect(() => {
    fetchData();
  }, []);

  /* 키워드 검색 */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  /* keyword API */
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const submitData = () => {
      localKeyword(search)
        .then((res) => {
          if (res.length === 0) {
            alert("검색 결과가 없습니다.");
          } else {
            setSearchList(res);
          }
        })
        .catch((err) => console.log(err));
    };
    submitData();
  };

  /* 검색한 키워드 클릭 */
  const clickedList = (searchList: any) => {
    // 추가시 { isNew: true }
    const object: any = {
      id: searchList.id,
      keyword: search, // 추가
      searchAddress: `${searchList.address}(${searchList.road_address_name})`,
      addressDetail: searchList.address_detail, // From address_detail To addressDetail
      isNew: true, // 추가
      desc: Number(storage.length), // 정렬 추가
    };

    // 중복 데이터 filter
    const filterData = storage.filter((el: any) => el.id === object.id);
    if (filterData.length > 0) {
      alert("이미 등록된 키워드입니다.");
      setSearchList([]);
      return;
    } else {
      setStorage(storage.concat(object));
    }

    closeModal();
  };

  /* 상세주소 입력 */
  const registDetail = (e: React.ChangeEvent<HTMLInputElement>, el: any) => {
    // 기존 데이터 { isUpdate: true }
    if (el.isUpdate !== undefined) {
      el.addressDetail = e.target.value;
      el.isUpdate = true;
    }
    // 생성 데이터 { isNew: true }
    else {
      el.addressDetail = e.target.value;
    }
  };

  /* 삭제 */
  const removeList = useCallback(
    async (el: any) => {
      // 기존 데이터 { isUpdate }
      if (el.isUpdate !== undefined) {
        if (
          window.confirm(
            "현재 등록되어 있는 키워드입니다. 정말 삭제하시겠습니까?\n저장하지 않은 데이터들도 함께 초기화됩니다."
          )
        ) {
          await deleteAddressDetail(el.id)
            .then(() => {
              fetchData();
              alert("삭제가 완료되었습니다.");
            })
            .catch((err) => {
              alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
              console.log(err);
            });
        }
      }
      // 생성 데이터 { isNew }
      else {
        if (
          window.confirm("아직 등록하지 않은 키워드입니다. 삭제하시겠습니까?")
        ) {
          const leftData = storage.filter((stored: any) => stored.id !== el.id);
          setStorage(leftData);
        }
      }
      setThisPage(1);
    },
    [storage]
  );

  /* 주소 저장 */
  const updateList = async () => {
    if (window.confirm("저장하시겠습니까?")) {
      await updateData()
        .then(() => {
          alert("저장 되었습니다.");
          window.location.reload();
        })
        .catch((err) => {
          if (
            err.response.status === 400 &&
            err.response.data.addressDetail[0]
          ) {
            alert("상세주소 입력란은 빈칸일 수 없습니다.");
          } else {
            alert(err.response.data.addressDetail[0]);
            console.log(err);
          }
        });
    }
  };
  const updateData = async () => {
    for (let idx in storage) {
      const refinedData: any = storage[idx]; // Property 'isUpdate' does not exist on type 'never'.ts(2339)

      // 기존 데이터 { isUpdate } 전송 값
      if (refinedData.isUpdate) {
        await patchAddressDetail({
          id: refinedData.id,
          addressDetail: refinedData.addressDetail,
        });
      }
      // 생성 데이터 { isNew } 전송 값
      else if (refinedData.isNew) {
        await postAddressDetail({
          id: refinedData.id,
          keyword: refinedData.keyword,
          searchAddress: refinedData.searchAddress,
          addressDetail: refinedData.addressDetail,
        });
      }
    }
  };

  const openModal = () => {
    setOpen(!open);
  };
  const closeModal = () => {
    setSearch("");
    setSearchList([]);
    setOpen(!open);
  };
  return (
    <SubWrapper>
      <Table>
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">키워드</TableCell>
            <TableCell align="center">검색주소</TableCell>
            <TableCell align="center">상세주소</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {storage &&
            currentData(storage).map((el: any) => (
              <TableRow key={el.id}>
                <TableCell align="center">{el.id}</TableCell>
                <TableCell align="center">{el.keyword}</TableCell>
                <TableCell align="center">{el.searchAddress}</TableCell>
                <TableCell align="center">
                  <input
                    className="detailInput"
                    type="text"
                    placeholder="등록할 상세주소를 입력"
                    defaultValue={el.addressDetail}
                    onChange={(e) => registDetail(e, el)}
                  />
                </TableCell>
                <TableCell align="center">
                  <Button onClick={() => removeList(el)}>
                    <Close />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 69.3 * emptyRows }}></TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        postsPerPage={dataPerPage}
        totalPosts={storage.length}
        getPaginate={thisPage}
        setPaginate={setThisPage}
        viewData={""}
      />
      <AddButton>
        <Button onClick={openModal}>
          <Add fontSize={"large"} />
        </Button>
      </AddButton>
      <DialogActions>
        <SubmitButton>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={updateList}
          >
            저장
          </Button>
        </SubmitButton>
        <SubmitButton>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => history.goBack()}
          >
            취소
          </Button>
        </SubmitButton>
      </DialogActions>
      {open && (
        <AddressModal
          closeModal={closeModal}
          onSubmit={onSubmit}
          onChange={onChange}
          searchList={searchList}
          clickedList={clickedList}
        />
      )}
    </SubWrapper>
  );
}

export default AddressDetailList;

const useStyles = makeStyles(() => ({
  root: { backgroundColor: "#E7E6E6" },
}));

const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;

  input.detailInput {
    padding: 0.2rem 0.4rem;
    width: 23rem;
    font-size: 0.9rem;
  }
`;

const AddButton = styled.div`
  margin: 1.5rem auto;
`;

const SubmitButton = styled.div`
  margin: 0 2rem;
`;
