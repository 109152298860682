import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  width: auto;
  padding: 0 0.5rem;
  margin: 0rem 1rem;
  line-height: 2.5;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
  border-radius: 0.3rem;
  border: none;
  color: white;
  outline: 0;
  background: rgb(126, 208, 219);
  &:hover {
    opacity: 0.6;
  }
`;

const StyledPanel = styled.div`
  width: 100%;
  height: 3rem;
  margin: 0.8rem 0 0;
  padding: 3rem 1rem 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bolder;
  color: #797979;
`;

const StyledOrderList = styled.div`
  width: ${(props) => (props.listType === "orderMissed" ? "49%" : "100%")};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0 1.2rem 0;
  font-size: 1.3rem;
  font-weight: bold;
`;

const StyledListTitle = styled.div`
  margin: 0 0 0.5rem 1rem;
  color: #797979;
  font-size: 1.5rem;
  font-weight: bolder;
`;

const StyledListBorder = styled.div`
  position: relative;
  width: 100%;
  border: solid 0.2rem #ff9e00;
`;

const StyledContents = styled.ul`
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const StyledList = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 4.5rem;
  padding: 1rem 1rem;
  background: ${(props) => (props.index % 2 === 1 ? "#f2f2f2" : "white")};
  text-align: center;
  font-size: 0.9rem;
  font-weight: ${(props) => (props.columnName ? "bolder" : "inherit")};
`;

const StyledColumn = styled.div`
  width: 7rem;
  margin-right: 1.2rem;
  font-size: 0.9rem;
  color: #404040;
`;

const StyledTaskCheckColumn = styled(StyledColumn)`
  text-align: center;
`;

const StyledTabTitle = styled.div`
  width: ${(props) => (props.tab ? "auto" : "0.6rem")};
  height: 3.2rem;
  padding: 0 0.5rem;
  margin: 0 1.8rem 0.6rem 0;
  border-radius: 0.6rem;
  line-height: 3.2rem;
  cursor: ${(props) => (props.tab ? "pointer" : "cursor")};
  background: ${(props) => (props.isSelected ? "#d6d4e0" : "white")};
  font-weight: bolder;
  font-size: 1rem;
  &:hover {
    opacity: 0.6;
  }
`;

const StyledNumber = styled.span`
  color: ${(props) => (props.number ? "#b30000" : "inherit")};
  font-weight: bolder;
  font-size: 1.6rem;
`;

export {
  StyledLink,
  StyledPanel,
  StyledOrderList,
  StyledListBorder,
  StyledContents,
  StyledList,
  StyledListTitle,
  StyledColumn,
  StyledTaskCheckColumn,
  StyledTabTitle,
  StyledNumber,
};
