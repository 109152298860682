import { handleActions } from "redux-actions";
import produce from "immer";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { finishLoading, startLoading } from "./loading";
import { postSquareBundleOrder } from "../lib/api/orders";

const SET_IS_CAPITAL = "squareBundleOrder/SET_IS_CAPITAL";
const SET_RIDER_NUMBER = "squareBundleOrder/SET_RIDER_NUMBER";
const SET_SENDER_ADDRESS = "squareBundleOrder/SET_SENDER_ADDRESS";
const DELETE_SENDER_ADDRESS = "squareBundleOrder/DELETE_SENDER_ADDRESS";
const SET_SENDER_BCODE = "squareBundleOrder/SET_SENDER_BCODE";
const SET_RECEIVER_ADDRESS_LIST = "squareBundleOrder/SET_RECEIVER_ADDRESS_LIST";
const DELETE_RECEIVER_ADDRESS = "squareBundleOrder/DELETE_RECEIVER_ADDRESS";
const SET_ERROR = "squareBundleOrder/SET_ERROR";
const SET_SUCCESS = "squareBundleOrder/SET_SUCCESS";
const SET_LOG = "squareBundleOrder/SET_LOG";
const SET_MESSAGE = "squareBundleOrder/SET_MESSAGE";
const SET_NOTICE_OPEN = "squareBundleOrder/SET_NOTICE_OPEN";
const SET_RESET = "squareBundleOrder/SET_RESET";
const SET_RESULT_POINTS = "squareBundleOrder/SET_RESULT_POINTS";

const POST_SQUARE_BUNDLE_ORDER = "squareBundleOrder/POST_SQUARE_BUNDLE_ORDER";

export const setIsCapital = (isCapital) => ({
  type: SET_IS_CAPITAL,
  payload: isCapital,
});

export const setRidersNumber = (ridersNumber) => ({
  type: SET_RIDER_NUMBER,
  payload: ridersNumber,
});

export const setSenderAddress = ({ name, startPoint }) => ({
  type: SET_SENDER_ADDRESS,
  payload: { name, startPoint },
});

export const deleteSenderAddress = () => ({
  type: DELETE_SENDER_ADDRESS,
});

export const setSenderBcode = (senderBcode) => ({
  type: SET_SENDER_BCODE,
  payload: senderBcode,
});

export const setReceiverAddressList = ({ name, bundlePoint }) => ({
  type: SET_RECEIVER_ADDRESS_LIST,
  payload: { name, bundlePoint },
});

export const deleteReceiverAddress = (chipId) => ({
  type: DELETE_RECEIVER_ADDRESS,
  payload: chipId,
});

export const setError = (error) => ({
  type: SET_ERROR,
  payload: error,
});

export const setSuccess = (success) => ({
  type: SET_SUCCESS,
  payload: success,
});

export const setLog = (log) => ({
  type: SET_LOG,
  payload: log,
});

export const setMessage = (message) => ({
  type: SET_MESSAGE,
  payload: message,
});

export const setNoticeOpen = (noticeOpen) => ({
  type: SET_NOTICE_OPEN,
  payload: noticeOpen,
});

export const setReset = () => ({
  type: SET_RESET,
});

export const submitSquareBundle = (squareBundleData) => ({
  type: POST_SQUARE_BUNDLE_ORDER,
  payload: squareBundleData,
});

export const setResultPoints = (resultPoints) => ({
  type: SET_RESULT_POINTS,
  payload: resultPoints,
});

const initialState = {
  ridersNumber: 2,
  isCapital: false,
  senderAddress: { name: "", startPoint: [] },
  senderBcode: "",
  receiverAddressList: [],
  resultPoints: [],
  receiverNumbers: [],
  log: "",
  message: "",
  noticeOpen: false,
  error: false,
  success: false,
};

const squareBundleOrder = handleActions(
  {
    [SET_IS_CAPITAL]: (state, { payload: isCapital }) => {
      return produce(state, (draft) => {
        draft.isCapital = isCapital;
      });
    },
    [SET_RIDER_NUMBER]: (state, { payload: ridersNumber }) => {
      return produce(state, (draft) => {
        draft.ridersNumber = Number(ridersNumber);
      });
    },
    [SET_SENDER_ADDRESS]: (state, { payload: { name, startPoint } }) => {
      return produce(state, (draft) => {
        draft.senderAddress.name = name;
        draft.senderAddress.startPoint = startPoint;
      });
    },
    [SET_SENDER_BCODE]: (state, { payload: senderBcode }) => {
      return produce(state, (draft) => {
        draft.senderBcode = senderBcode;
      });
    },
    [DELETE_SENDER_ADDRESS]: (state) => {
      return produce(state, (draft) => {
        draft.senderAddress = { name: "", startPoint: [] };
      });
    },
    [SET_RECEIVER_ADDRESS_LIST]: (
      state,
      { payload: { name, bundlePoint } }
    ) => {
      const currentReceiverAddressList = state.receiverAddressList;
      const id =
        currentReceiverAddressList.length > 0
          ? currentReceiverAddressList[currentReceiverAddressList.length - 1]
              .id + 1
          : 1;

      return produce(state, (draft) => {
        draft.receiverAddressList = [
          ...currentReceiverAddressList,
          { id, name, bundlePoint },
        ];
      });
    },
    [DELETE_RECEIVER_ADDRESS]: (state, { payload: chipId }) => {
      const currentReceiverAddressList = state.receiverAddressList;

      return produce(state, (draft) => {
        draft.receiverAddressList = currentReceiverAddressList.filter(
          ({ id }) => chipId !== id
        );
      });
    },
    [SET_RESULT_POINTS]: (state, { payload: resultPoints }) => {
      const receiverNumbers = resultPoints.map((points) => {
        return points.map((point) => {
          return (
            state.receiverAddressList.findIndex(
              (address) => address.name === point[2]
            ) + 1
          );
        });
      });
      return produce(state, (draft) => {
        draft.receiverNumbers = receiverNumbers;
        draft.resultPoints = resultPoints;
      });
    },
    [SET_ERROR]: (state, { payload: error }) => {
      return produce(state, (draft) => {
        draft.error = error;
      });
    },
    [SET_SUCCESS]: (state, { payload: success }) => {
      return produce(state, (draft) => {
        draft.success = success;
      });
    },
    [SET_LOG]: (state, { payload: log }) => {
      return produce(state, (draft) => {
        draft.log = log;
      });
    },
    [SET_MESSAGE]: (state, { payload: message }) => {
      return produce(state, (draft) => {
        draft.message = message;
      });
    },
    [SET_NOTICE_OPEN]: (state, { payload: noticeOpen }) => {
      return produce(state, (draft) => {
        draft.noticeOpen = noticeOpen;
      });
    },
    [SET_RESET]: () => initialState,
  },
  initialState
);

function* postSquareBundleOrderSaga() {
  yield put(startLoading("squareBundleOrder"));

  try {
    const { isCapital, ridersNumber, senderAddress, receiverAddressList } =
      yield select((state) => state.squareBundleOrder);

    const squareBundleData = {
      isCapital,
      ridersNumber,
      startPoint: senderAddress.startPoint,
      bundlePoints: receiverAddressList.map(({ name, bundlePoint }) => [
        ...bundlePoint,
        name,
      ]),
    };

    const response = yield call(postSquareBundleOrder, squareBundleData);

    yield put(setResultPoints(response[1]));
    yield put(setSuccess(true));
  } catch (error) {
    console.log("error", error);
    yield put(setError(error.message));
  }

  yield put(finishLoading("squareBundleOrder"));
}

export function* squareBundleOrderSaga() {
  yield takeLatest(POST_SQUARE_BUNDLE_ORDER, postSquareBundleOrderSaga);
}

export default squareBundleOrder;
