import React from "react";
import styled from "styled-components";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import SchedulerHeader from "./SchedulerHeader";
import {
  SchedulerType,
  SchedulerStatus,
} from "../../containers/scheduler/types";
import { ChannelData } from "../../lib/constants/constants";
import { FlexColumnCenter, RowDivider } from "../common/Base";
import Detail from "../common/Detail";

type schedulersProps = {
  listSchedulers: SchedulerType[];
  listSchedulerStatus: SchedulerStatus[];
  form: SchedulerType;
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  onSubmit: (event: React.FormEvent) => void;
  onDelete: (schedule: SchedulerType) => void;
  handleStatus: (event: React.MouseEvent) => void;
  handleSchedulerRestart: (event: React.MouseEvent) => void;
  status: boolean;
};

function Scheduler({
  form,
  listSchedulers,
  listSchedulerStatus,
  handleSchedulerRestart,
  onChange,
  onSubmit,
  onDelete,
  status,
  handleStatus,
}: schedulersProps) {
  return (
    <FlexColumnCenter>
      <Detail>
        <SchedulerHeader
          form={form}
          handleStatus={handleStatus}
          onChange={onChange}
          onSubmit={onSubmit}
        />
        {status && listSchedulerStatus.length > 0 && (
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell align="center">이름</TableHeaderCell>
                  <TableHeaderCell align="center">
                    다음 스케줄러 작동 시간
                  </TableHeaderCell>
                  <TableHeaderCell align="center">동작 여부</TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {listSchedulerStatus.map((sch) => {
                  return (
                    <TableRow hover tabIndex={-1} key={sch.id}>
                      <TableCell align="center">{sch.name}</TableCell>
                      <TableCell align="center">
                        {sch.nextTime ? sch.nextTime : "중지됨"}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={handleSchedulerRestart}
                        >
                          재시작
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableWrapper>
        )}
        {status && listSchedulerStatus.length === 0 && (
          <StyledTextBoxStatus>
            현재 작동중인 스케줄러가 없습니다
          </StyledTextBoxStatus>
        )}
        <RowDivider />
        <RowDivider />

        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell align="center">채널</TableHeaderCell>
                <TableHeaderCell align="center">인성 거래처 명</TableHeaderCell>
                <TableHeaderCell align="center">
                  인성 콜센터 가입 회원 아이디
                </TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {listSchedulers.map((sch) => {
                return (
                  <TableRow hover tabIndex={-1} key={sch.user_id}>
                    <TableCell align="center">{sch.channel}</TableCell>
                    <TableCell align="center">{sch.company_name}</TableCell>
                    <TableCell align="center">{sch.user_id}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => onDelete(sch)}
                        disabled={
                          !ChannelData.find((el) => el.key === sch.channel)
                        }
                      >
                        삭제
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableWrapper>
      </Detail>
    </FlexColumnCenter>
  );
}
export default Scheduler;

const TableHeaderCell = styled(TableCell)`
  font-weight: 900 !important;
  font-size: 1rem !important;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
`;

const StyledTextBoxStatus = styled.div`
  padding: 2rem 0;
  font-size: 2rem;
  text-align: center;
  font-weight: 900;
  color: red;
`;
