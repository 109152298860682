import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { SheetJSFT } from "../../pages/GroupOrder/utils";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";

interface ExcelUpDownloadProps {
  channel: string;
  calculateFunction: (channel: string, data: string[][]) => any;
}

/**
 * @description [대량 주소 표준화, 대량 요금 조회] 공통 EXCEL 함수
 * channel은 대량 요금 조회에서만 사용
 */
function CustomExcelUpDownload({
  channel,
  calculateFunction,
}: ExcelUpDownloadProps) {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("");

  const handleFile = (file: File) => {
    dispatch(startGlobalLoading());
    const reader = new FileReader();

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      /* - - - - - 업로드 - - - - - */
      const data = e.target && e.target.result;
      const workBook = XLSX.read(data, { type: "array" });
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];

      const result: string[][] = XLSX.utils.sheet_to_json(workSheet, {
        header: 1,
        defval: "",
        blankrows: false,
      });

      const removedHeaderData = result.slice(1);
      if (removedHeaderData.length < 1) {
        alert("데이터를 입력하지 않은 엑셀파일인지 확인해주세요!");
        dispatch(finishGlobalLoading());
        return;
      }

      try {
        const resolvedData = await calculateFunction(
          channel,
          removedHeaderData
        );
        /* - - - - - 다운로드 - - - - - */
        const reviveHeaderData = [[...result[0]], ...resolvedData];
        const workSheet = XLSX.utils.aoa_to_sheet(reviveHeaderData);
        const workBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workBook, workSheet, "조회내역");

        const today = new Date().toISOString().split("T")[0];
        XLSX.writeFile(workBook, `${today} ${channel} ${file.name}`);
      } catch (error) {
        const message =
          (error as Error).message || `주소 검색에 실패했습니다. ${error}`;
        alert(message);
      } finally {
        dispatch(finishGlobalLoading());
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleExcel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    // FileList 객체 {0: File, length: 1}

    if (files && files[0]) {
      handleFile(files[0]);
      setFileName(files[0].name);
    }
  };

  return (
    <>
      <Button
        className="up-download-button"
        variant="outlined"
        color="default"
        size="large"
        component="label"
        htmlFor="excel-upload"
        startIcon={<CloudUpload />}
      >
        <input
          type="file"
          className="form-control"
          id="excel-upload"
          accept={SheetJSFT}
          style={{ display: "none" }}
          onChange={handleExcel}
        />
        엑셀파일 업로드
      </Button>
      <p>{fileName && `파일이름 : ${fileName}`}</p>
    </>
  );
}

export default CustomExcelUpDownload;
