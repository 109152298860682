import React, { useMemo } from "react";
import styled from "styled-components";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  makeStyles,
} from "@material-ui/core";

interface ExtraTimeChargeTableProps {
  timeTable: number;
  setTimeTable: React.Dispatch<React.SetStateAction<number>>;
}

function ExtraTimeChargeTable({
  timeTable,
  setTimeTable,
}: ExtraTimeChargeTableProps) {
  const classes = useStyles();

  const allOfTime = useMemo(() => Array.from({ length: 24 }, (_, i) => i), []);

  const handleTime = (time: number) => {
    setTimeTable(time);
  };

  return (
    <TableBox>
      <TableHead className={classes.root}>
        <TableRow>
          <TableCell align="center">시간 선택</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <StyledCell align="center">
            {allOfTime.map((time) => (
              <span className="time-button" key={time}>
                <StyledButton
                  className={time === timeTable ? classes.selected : ""}
                  onClick={() => handleTime(time)}
                >
                  {time}
                </StyledButton>
              </span>
            ))}
          </StyledCell>
        </TableRow>
      </TableBody>
    </TableBox>
  );
}

export default ExtraTimeChargeTable;

const useStyles = makeStyles(() => ({
  root: { backgroundColor: "#E7E6E6" },
  selected: {
    minWidth: "30px",
    width: "30px",
    fontWeight: 800,
    color: "#fff",
    backgroundColor: "#303f9f",
  },
}));

const TableBox = styled(Table)`
  margin: 1rem auto;
  width: 10% !important;
  height: fit-content;
  border: 1px solid #e7e6e6;
`;

const StyledCell = styled(TableCell)`
  .time-button {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  height: 30px;
  line-height: 30px;
`;
