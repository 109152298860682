import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 800;
  padding: 0.25rem 1rem;
  color: black;
  outline: none;
  cursor: pointer;
  background-color: white;
`;

export default function Button({
  children,
  ...rest
}: React.HTMLProps<HTMLButtonElement>) {
  const htmlProps = rest as any;
  return <StyledButton {...htmlProps}>{children}</StyledButton>;
}
