import React, { useState } from "react";
import { Dialog, DialogContent, Snackbar } from "@material-ui/core";

import OrderSwap from "../../components/order/OrderSwap";
import Notice from "../../components/common/Notice";

import { OrderByPkType } from "./types";
import { getOrderWithIsdOrderNumber } from "../../lib/hasura/orders";
import { swapOrder as apiSwapOrder } from "../../lib/api/orders";
import { StyledDialogTitle } from "../../components/common/modal/Modal";

export type SwapOrderProps = {
  id: string;
  channel: string;
  client_name: string;
  client_phone_number: string;
  sender_name: string;
  sender_phone_number: string;
  sender_address: string;
  sender_address_detail: string;
  receiver_name: string;
  receiver_phone_number: string;
  receiver_address: string;
  receiver_address_detail: string;
  vehicle_type: string;
  vehicle_option: string;
  payment: string;
  trip: string;
  algoquick_orders: string;
  users_user: string;
  usersUserByRelatedUserId: string;
  express: boolean;
  isd_order_number: string;
  call24_order_number: string;
  location_charge: string;
  extra_charge: string;
  delivery_charge: string;
  rider_name: string;
  rider_phone_number: string;
  status: string;
};

type Call24OrderDetailProps = {
  swapPopup: boolean;
  setSwapPopup: (OrderSwapPopup: boolean) => void;
  order: OrderByPkType;
  fetchData: () => void;
};

function OrderSwapContainer({
  swapPopup,
  setSwapPopup,
  order,
  fetchData,
}: Call24OrderDetailProps) {
  const [swap, setSwap] = useState(false);
  const [isdNumber, setIsdNumber] = useState("");
  const [swapOrder, setSwapOrder] = useState({
    id: "",
    channel: "",
    client_name: "",
    client_phone_number: "",
    sender_name: "",
    sender_phone_number: "",
    sender_address: "",
    sender_address_detail: "",
    receiver_name: "",
    receiver_phone_number: "",
    receiver_address: "",
    receiver_address_detail: "",
    vehicle_type: "",
    vehicle_option: "",
    payment: "",
    trip: "",
    algoquick_orders: "",
    users_user: "",
    usersUserByRelatedUserId: "",
    express: false,
    isd_order_number: "",
    call24_order_number: "",
    location_charge: "",
    extra_charge: "",
    delivery_charge: "",
    rider_name: "",
    rider_phone_number: "",
    status: "",
  });
  const [error, setError] = useState(false);
  const [noticeOpen, setNoticeOpen] = useState(false);
  const [message, setMessage] = useState("");

  function handleClose(event: React.SyntheticEvent, reason?: string) {
    if (reason === "clickaway") {
      return;
    }
    setSwapPopup(false);
  }

  function handleManualClose() {
    setSwapPopup(false);
    fetchData();
  }

  function handleNoticeClose(event: React.SyntheticEvent, reason?: string) {
    if (reason === "clickaway") {
      return;
    }
    setNoticeOpen(false);
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsdNumber(e.target.value);
  }

  async function getSwapOrder(isdNumber: string) {
    const { data } = await getOrderWithIsdOrderNumber(isdNumber);
    const { orders_order } = data.data;
    return orders_order;
  }

  async function onSubmit() {
    if (isdNumber.trim() === "") {
      setMessage("인성 주문 번호를 입력해주세요");
      setError(true);
      setNoticeOpen(true);
      return;
    }

    if (isdNumber.trim() === order.isd_order_number) {
      setMessage("동일한 주문 번호로는 교환 할 수 없습니다.");
      setError(true);
      setNoticeOpen(true);
      return;
    }

    const orders_order = await getSwapOrder(isdNumber);

    if (orders_order.length > 0) {
      setSwapOrder(orders_order[0]);
      setSwap(true);
      setError(false);
      setMessage("주문을 가져왔습니다. 확인 후 변경 버튼을 클릭해주세요");
      setNoticeOpen(true);
    } else {
      setMessage("주문이 존재하지 않습니다. 주문상세 정보에서 수정해주세요");
      setError(true);
      setNoticeOpen(true);
    }
  }

  async function swapSubmit() {
    await apiSwapOrder(order.isd_order_number || "", isdNumber)
      .then((res) => {
        const { data } = res;
        const { success } = data;
        if (success) {
          setMessage("주문이 변경되었습니다.");
          setNoticeOpen(true);
          handleManualClose();
        } else {
          setMessage(`${data.errors}`);
          setError(true);
          setNoticeOpen(true);
        }
      })
      .catch(() => {
        setMessage(`서버오류입니다 개발자에게 문의 주세요`);
        setError(true);
        setNoticeOpen(true);
      });
  }

  return (
    <Dialog
      open={swapPopup}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
    >
      <StyledDialogTitle id="alert-dialog-title">{`주문 교환`}</StyledDialogTitle>
      <DialogContent>
        <OrderSwap
          row={order}
          isdNumber={isdNumber}
          onChange={onChange}
          swapOrder={swapOrder}
          onSubmit={onSubmit}
          swap={swap}
          swapSubmit={swapSubmit}
        />
      </DialogContent>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={noticeOpen}
        autoHideDuration={6000}
      >
        {!error ? (
          <Notice
            variant="success"
            message={message}
            onClose={handleNoticeClose}
          />
        ) : (
          <Notice
            variant="error"
            message={message}
            onClose={handleNoticeClose}
          />
        )}
      </Snackbar>
    </Dialog>
  );
}

export default OrderSwapContainer;
