import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { CorporateUser } from "../../containers/corporation/types";
import {
  DEPART_DATA_LABEL,
  Corporation,
  DepartmentData,
} from "../../containers/department/types";
import { checkField } from "../../lib/validate";
import { ascendingFunc } from "../../lib/core";
import {
  FlexBox,
  FlexCenterSpaceBetween,
  FlexColumnCenter,
  RowDivider,
  CustomLegend,
  CustomTextField,
} from "../common/Base";
import Detail from "../common/Detail";

interface DepartmentDetailProps {
  row: DepartmentData;
  users: CorporateUser[];
  corporations?: Corporation[];
  handleDeleteDepart?: () => void;
  onChange: (e: any) => void;
  onSubmit: (event: React.FormEvent) => void;
}

function DepartmentDetail({
  row,
  users,
  corporations,
  onChange,
  onSubmit,
  handleDeleteDepart,
}: DepartmentDetailProps) {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // 법인명 정렬 + 오름차순(ascendingFunc)
  const sort_corporation =
    corporations?.map((el: Corporation) => {
      const letter = el.name.toUpperCase().replace(/ /g, "");
      return {
        letter,
        ...el,
      };
    }) || [];

  // 관리자 정렬
  const sort_administrator = users.map((el: CorporateUser) => {
    const letter = el.username.toUpperCase().replace(/ /g, "");
    return {
      letter,
      ...el,
    };
  });

  return (
    <FlexColumnCenter>
      <Detail>
        {row.id ? (
          <TextField
            label="법인명"
            id="corporation"
            name="corporation"
            value={checkField(row.corporation_name)}
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={onChange}
            disabled
          />
        ) : (
          <Autocomplete
            id="corporation_id"
            fullWidth
            options={sort_corporation.sort(ascendingFunc)}
            onChange={(
              event,
              newValue: (Corporation & { letter: string }) | null
            ) => {
              const value = newValue?.id || " ";
              onChange({ target: { name: "corporation_id", value } });
            }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                name="corporation_id"
                label="법인명*"
                variant="outlined"
              />
            )}
          />
        )}
        <TextField
          label={`${DEPART_DATA_LABEL.name}*`}
          id="name"
          name="name"
          value={checkField(row.name)}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
        />
        <TextField
          label={DEPART_DATA_LABEL.limit_budget}
          type="number"
          id="limit_budget"
          name="limit_budget"
          value={row.limit_budget}
          inputProps={{ min: "0", step: "1000" }}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
        />
        <RowDivider />
        <FormControl variant="outlined" fullWidth>
          <InputLabel>{DEPART_DATA_LABEL.administrator}</InputLabel>
          <Select
            value={sort_administrator.length ? row.administrator : " "}
            onChange={onChange}
            input={<OutlinedInput name="administrator" id="administrator" />}
          >
            <MenuItem value=" ">
              <em>None</em>
            </MenuItem>
            {sort_administrator.sort(ascendingFunc).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <RowDivider size="2" />
        <CustomFlexWapper>
          <CustomFlexBox width="30%">
            <CustomLegend top="-7" font="13">
              {DEPART_DATA_LABEL.department_credit_enable}
            </CustomLegend>
            <FormControlLabel
              control={
                <Switch
                  checked={row.department_credit_enable}
                  onChange={onChange}
                />
              }
              id="department_credit_enable"
              name="department_credit_enable"
              label={DEPART_DATA_LABEL.department_credit_enable}
              labelPlacement="start"
            />
          </CustomFlexBox>
          <CustomTextField
            label={DEPART_DATA_LABEL.department_credit}
            type="number"
            id="department_credit"
            name="department_credit"
            value={row.department_credit}
            inputProps={{ min: 0, step: 1000 }}
            variant="outlined"
            width="35%"
            onChange={onChange}
          />
          <CustomTextField
            label={DEPART_DATA_LABEL.corporate_credit}
            type="number"
            id="corporate_credit"
            name="corporate_credit"
            value={row.corporate_credit}
            disabled
            variant="filled"
            width="35%"
          />
        </CustomFlexWapper>
        <RowDivider size="2" />
        <FormControlLabel
          control={
            <Switch checked={row.address_book_enable} onChange={onChange} />
          }
          id="address_book_enable"
          name="address_book_enable"
          label={DEPART_DATA_LABEL.address_book_enable}
          labelPlacement="start"
        />
        <RowDivider size="2" />
        <FlexCenterSpaceBetween>
          {row.id ? (
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClickOpen}
            >
              삭제
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => history.goBack()}
            >
              취소
            </Button>
          )}
          <Button variant="outlined" color="primary" onClick={onSubmit}>
            저장
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{`${row.name}을 삭제 하시겠습니까?`}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                취소
              </Button>
              <Button onClick={handleDeleteDepart} color="primary" autoFocus>
                삭제
              </Button>
            </DialogActions>
          </Dialog>
        </FlexCenterSpaceBetween>
      </Detail>
    </FlexColumnCenter>
  );
}

export default DepartmentDetail;

const CustomFlexWapper = styled(FlexBox)`
  gap: 1rem;
`;

const CustomFlexBox = styled(FlexBox)<{ width?: string }>`
  position: relative;
  padding: 8px 0 6px;
  width: ${({ width }) => width || "50%"};
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`;
