import React from "react";
import { useHistory } from "react-router-dom";
import { DialogActions, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import styled from "styled-components";

interface ChargeFooterProps {
  corpId: string;
  handleModal: () => void;
  updateCharge: () => Promise<void>;
}

function CorpChargeFooter({
  corpId,
  handleModal,
  updateCharge,
}: ChargeFooterProps) {
  const history = useHistory();

  return (
    <>
      <AddButton>
        {corpId ? (
          <Button onClick={handleModal}>
            <Add fontSize={"large"} />
          </Button>
        ) : (
          <Button disabled>
            <Add fontSize={"large"} />
          </Button>
        )}
      </AddButton>
      <DialogActions>
        <SubmitButton>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={updateCharge}
          >
            저장
          </Button>
        </SubmitButton>
        <SubmitButton>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => history.goBack()}
          >
            취소
          </Button>
        </SubmitButton>
      </DialogActions>
    </>
  );
}

export default CorpChargeFooter;

const AddButton = styled.div`
  margin: 1.5rem auto;
`;

const SubmitButton = styled.div`
  margin: 0 2rem;
`;
