import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { AxiosError } from "axios";

import { check } from "../../modules/user";
import { resetPassword } from "../../lib/api/auth";

import { ModalWrapper } from "../common/modal/Modal";
import { passwordCheck } from "../common/Login";
import {
  ChangeModalForm,
  ChangeTitle,
  ChangeInformation,
} from "./AuthThreshold";

interface threeMonthProps {
  setThreeMonth: React.Dispatch<React.SetStateAction<boolean>>;
}

function AuthThreeMonth({ setThreeMonth }: threeMonthProps) {
  const dispatch = useDispatch();
  const token = useSelector(({ auth }: RootStateOrAny) => auth?.token);

  const [find, setFind] = useState(false);
  const [loginState, setLoginState] = useState({
    password: "",
    passwordConfirm: "",
  });

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = e;

      setLoginState({ ...loginState, [name]: value });
    },
    [loginState]
  );
  const { password, passwordConfirm } = loginState;

  /* 나중에 변경 */
  // const changeLater = () => {
  //   if (token) {
  //     resetPassword(token, "", "")
  //       .then(() => dispatch(check()))
  //       .then(() => {
  //         setFind(false);
  //         setThreeMonth(false);
  //       })
  //       .catch(err => {
  //         alert(err.response.data && err.response.data.detail);
  //         // localStorage.removeItem('authorization');
  //         sessionStorage.clear();
  //         // CHECK_FAILURE 400일때 token 제거해주는 곳이 없음
  //       }
  //       );
  //   };
  // };

  // 지금 변경하기
  const changeNow = () => {
    const errMsg = `새로고침시 접속 정보가 초기화되어 변경할 수 없습니다.\n새 웹페이지를 열고 다시 시도해주세요.`;
    if (token) setFind(true);
    else {
      alert(errMsg);
      setFind(false);
    }
  };

  // 비밀번호 변경
  const changePassword = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (password.trim() === "") return alert("새 비밀번호를 입력해주세요");
    else if (passwordConfirm.trim() === "")
      return alert("새 비밀번호 확인을 입력해주세요");
    else if (!passwordCheck(password))
      return alert("8~16자 영문, 숫자, 특수문자를 사용하세요.");
    else if (password !== passwordConfirm)
      return alert("비밀번호가 일치하지 않습니다");

    try {
      await resetPassword(token, password, passwordConfirm);
      await dispatch(check());
      alert("비밀번호가 성공적으로 변경되었습니다.");

      setFind(false);
      setThreeMonth(false);
    } catch (err) {
      if (err instanceof AxiosError)
        alert(`변경에 실패했습니다. ${err.response?.data?.detail}`);
      else alert(`변경에 실패했습니다 ${(err as Error).message}`);

      sessionStorage.clear(); // CHECK_FAILURE 400일때 token 제거해주는 곳이 없음
    }
  };

  return (
    <ModalWrapper>
      {find && token ? (
        <ChangeModalForm>
          <section className="change-modal-content">
            <ChangeTitle>새로운 비밀번호를 입력해주세요.</ChangeTitle>
            <ChangeInformation>
              <input
                type="password"
                placeholder="새 비밀번호"
                className="password"
                name="password"
                onChange={onChange}
              />
              <input
                type="password"
                placeholder="새 비밀번호 확인"
                className="passwordConfirm"
                name="passwordConfirm"
                onChange={onChange}
              />
              <button type="button" onClick={changePassword}>
                확인
              </button>
            </ChangeInformation>
          </section>
        </ChangeModalForm>
      ) : (
        <ModalForm>
          <section className="modal-content">
            <Title>비밀번호 변경 안내</Title>
            <Information>
              <section className="contents">
                <div className="contents-detail">
                  개인정보보호와 안전한 사용을 위해 3개월마다 <br />
                  비밀번호 변경을 권장하고 있습니다.
                </div>
                <div className="contents-detail">
                  비밀번호는 <strong>영문, 숫자, 특수문자</strong>를 조합하여{" "}
                  <br />
                  <strong>9자리 이상</strong>으로 만들어주세요.
                </div>
              </section>
              <section className="buttons">
                {/* <button
                  type="button"
                  className="later"
                  onClick={changeLater}
                >
                  나중에 변경
                </button> */}
                <button type="button" className="now" onClick={changeNow}>
                  지금 변경하기
                </button>
              </section>
            </Information>
          </section>
        </ModalForm>
      )}
    </ModalWrapper>
  );
}

export default AuthThreeMonth;

export const ModalForm = styled.article`
  position: relative;
  padding: 1.5rem 2rem;
  width: 30rem;
  height: 20rem;

  background-color: #ffffff;
  letter-spacing: 1px;
  border-radius: 20px;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);

  section.modal-content {
    margin: 1.5rem auto;
    width: 21rem;
    height: auto;
  }
`;

export const Title = styled.header`
  margin: 0 auto;
  width: 20rem;
  line-height: 1.7rem;
  font-size: 1.6rem;
  font-weight: 700;
`;

export const Information = styled.main`
  margin: 0 auto;
  width: 20rem;

  section.contents {
    margin-top: 1rem;
    line-height: 1.4rem;

    .contents-detail {
      margin-top: 1rem;
    }
  }
  section.buttons {
    display: flex;
    justify-content: center;
    margin: 2rem auto 0;
    width: 20rem;

    button {
      width: 100%;
      height: 3rem;
      font-size: 0.94rem;
      font-weight: 600;
      color: #fff;

      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
    button.later {
      background-color: #999;
    }
    button.now {
      background-color: #3f51b5;
    }
  }
`;
