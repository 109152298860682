import styled from "styled-components";
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
} from "@material-ui/core";
import {
  AddLoadingChargeType,
  DamasLaboLoadingLevel,
} from "../../containers/corporation/extraTypes";
import { radioTruckOptions } from "../../lib/hasura/extraCharge";
import { ColumnDivider } from "../common/Base";

interface ExtraLoadingSelectProps {
  addCharge: AddLoadingChargeType;
  selector: { key: string; label: string }[];
  onChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  onSubmit: (e: React.FormEvent) => void;
  onAllDelete: () => void;
  isTruckOption: boolean;
}

function AddLoadingChargeSelector({
  addCharge,
  selector,
  onChange,
  onSubmit,
  onAllDelete,
  isTruckOption,
}: ExtraLoadingSelectProps) {
  const { loading, unloading, loading_level, loading_charge, vehicle_option } =
    addCharge;

  return (
    <FormSelector>
      <FormWrapper>
        <BaseChargeForm>
          <FormControl variant="outlined">
            <InputLabel id="loading-select-label">상차 방법</InputLabel>
            <StyledSelect
              labelId="loading-select-label"
              id="loading-select"
              name="loading"
              value={loading}
              onChange={onChange}
              label="상차 방법"
            >
              <MenuItem value=""></MenuItem>
              {selector.map(({ key, label }) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          <ColumnDivider />
          <FormControl variant="outlined">
            <InputLabel id="unloading-select-label">하차 방법</InputLabel>
            <StyledSelect
              labelId="unloading-select-label"
              id="unloading-select"
              name="unloading"
              value={unloading}
              onChange={onChange}
              label="하차 방법"
            >
              <MenuItem value=""></MenuItem>
              {selector.map(({ key, label }) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          <ColumnDivider />
          {!isTruckOption && (
            <FormControl variant="outlined">
              <InputLabel id="loading-level-label">회전수</InputLabel>
              <StyledSelect
                labelId="loading-level-label"
                id="loading-level"
                name="loading_level"
                value={loading_level}
                onChange={onChange}
                label="회전수"
              >
                {DamasLaboLoadingLevel.map(({ key, label }) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          )}
        </BaseChargeForm>
        <InputChargeForm>
          <StyledTextField
            label="고정금액"
            placeholder="ex) 7000"
            type="number"
            id="loading_charge"
            name="loading_charge"
            value={loading_charge}
            variant="outlined"
            margin="normal"
            inputProps={{ min: 0, step: 1000 }}
            onChange={onChange}
          />
          <StyledButton variant="contained" color="primary" onClick={onSubmit}>
            요금 등록
          </StyledButton>
          <StyledButton
            variant="contained"
            color="secondary"
            onClick={onAllDelete}
          >
            전체 삭제
          </StyledButton>
        </InputChargeForm>
      </FormWrapper>
      {isTruckOption && (
        <StyledRadioGroup
          row
          name="vehicle_option"
          onChange={onChange}
          value={vehicle_option}
        >
          {radioTruckOptions.map(({ value, label, disable }, idx: number) => {
            return (
              <FormControlLabel
                control={<Radio />}
                key={idx}
                label={label}
                value={value}
                disabled={disable}
              />
            );
          })}
        </StyledRadioGroup>
      )}
    </FormSelector>
  );
}

export default AddLoadingChargeSelector;

const FormSelector = styled.nav`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 3rem;
`;

const BaseChargeForm = styled.section`
  display: flex;
  margin: auto 0;
`;

const InputChargeForm = styled.section`
  display: flex;
  position: relative;
`;

const StyledTextField = styled(TextField)`
  width: 200px;
`;

const StyledButton = styled(Button)`
  margin: 1rem 0.5rem !important;
  height: 3.5rem !important;
`;

const StyledSelect = styled(Select)`
  width: 200px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  margin: 0 auto;
`;
