import { useMemo } from "react";
import styled from "styled-components";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
} from "@material-ui/core";
import {
  TimeChargeType,
  RefinedTimeChargeType,
} from "../../containers/corporation/extraTypes";

interface ExtraTimeChargeListProps {
  timeData: TimeChargeType[];
}

function ExtraTimeChargeList({ timeData }: ExtraTimeChargeListProps) {
  const classes = useStyles();

  /**
   * base_charge 단위마다 존재하는 4가지 차량타입별 요금을
   * base_charge 기준으로 하나의 객체씩 묶어 배열 생성
   * @return { RefinedTimeChargeType }
   */
  const refinedData = (
    acc: any,
    cur: TimeChargeType
  ): RefinedTimeChargeType => {
    const key = cur.base_charge;
    if (!acc[key]) {
      acc[key] = {
        base_charge: cur.base_charge,
        time: cur.time,
        [`${cur.vehicle_type.toLowerCase()}_charge`]: cur.time_charge,
      };
    } else {
      acc[key][`${cur.vehicle_type.toLowerCase()}_charge`] = cur.time_charge;
    }

    return acc;
  };
  const timeList = useMemo(() => {
    const refinedList: RefinedTimeChargeType[] = Object.values(
      timeData.reduce(refinedData, {})
    );
    return refinedList.sort((a, b) => a.base_charge - b.base_charge);
  }, [timeData]);

  return (
    <TableBox>
      <TableHead className={classes.root}>
        <TableRow>
          <TableCell align="center">시간</TableCell>
          <TableCell align="center">기본요금</TableCell>
          <TableCell align="center">오토바이</TableCell>
          <TableCell align="center">다마스</TableCell>
          <TableCell align="center">라보</TableCell>
          <TableCell align="center">1톤 트럭</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {timeList.map((el: RefinedTimeChargeType) => (
          <TableRow key={el.base_charge}>
            <TableCell align="center">{el.time}</TableCell>
            <TableCell align="center">{el.base_charge}</TableCell>
            <TableCell align="center">{el.motorcycle_charge}</TableCell>
            <TableCell align="center">{el.damas_charge}</TableCell>
            <TableCell align="center">{el.labo_charge}</TableCell>
            <TableCell align="center">{el.truck_charge}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TableBox>
  );
}

export default ExtraTimeChargeList;

const useStyles = makeStyles(() => ({
  root: { backgroundColor: "#E7E6E6" },
}));

const TableBox = styled(Table)`
  margin: 1rem auto;
  width: 80% !important;
  height: fit-content;
  border: 1px solid #e7e6e6;
`;
