import { useMemo } from "react";
import styled from "styled-components";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
} from "@material-ui/core";
import { ExpressChargeType } from "../../containers/corporation/extraTypes";
interface ExpressChargeListProps {
  expressData: ExpressChargeType[];
}

function ExtraExpressChargeList({ expressData }: ExpressChargeListProps) {
  const classes = useStyles();

  const expressList = useMemo(
    () => expressData.sort((a, b) => a.base_charge - b.base_charge),
    [expressData]
  );

  return (
    <TableBox>
      <TableHead className={classes.root}>
        <TableRow>
          <TableCell align="center">기본요금</TableCell>
          <TableCell align="center">급송요금</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {expressList.map((el) => (
          <TableRow key={el.id}>
            <TableCell align="center">{el.base_charge}</TableCell>
            <TableCell align="center">{el.express_charge}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TableBox>
  );
}

export default ExtraExpressChargeList;

const useStyles = makeStyles(() => ({
  root: { backgroundColor: "#E7E6E6" },
}));

const TableBox = styled(Table)`
  margin: 1rem auto;
  width: 80% !important;
  border: 1px solid #e7e6e6;
`;
