import { CorpProps, ExcelColumn } from "../../containers/corporation/types";

export type SearchTarget = "corporate_name" | "keyword";
export const TargetData: { key: SearchTarget; label: string }[] = [
  { key: "corporate_name", label: "법인명" },
  { key: "keyword", label: "키워드" },
];

export type ChannelMenu = "algoquick" | "algoquick_g1" | "naver" | "cake";
export const ChannelData: { key: ChannelMenu; label: string }[] = [
  {
    key: "algoquick",
    label: "알고퀵",
  },
  {
    key: "algoquick_g1",
    label: "경기지사",
  },
  {
    key: "naver",
    label: "네이버",
  },
];

type Channels = {
  [index: string]: string;
  algoquick: string;
  cake: string;
  naver: string;
};
export const CHANNEL: Channels = {
  algoquick: "알고퀵",
  algoquick_g1: "경기지사",
  cake: "고바이크",
  naver: "네이버",
};

type Vehicles = {
  [index: string]: string;
  SUBWAY: string;
  MOTORCYCLE: string;
  DAMAS: string;
  LABO: string;
  TRUCK: string;
};
export const VEHICLE: Vehicles = {
  SUBWAY: "지하철",
  MOTORCYCLE: "오토바이",
  DAMAS: "다마스",
  LABO: "라보",
  TRUCK: "트럭",
};

type CorpVehicles = {
  [index: string]: string;
  motorcycle: string;
  damas: string;
  labo: string;
  truck_1t: string;
  truck_1_4t: string;
  truck_2_5t: string;
  truck_3_5t: string;
  truck_5t: string;
  truck_8t: string;
  truck_11t: string;
  truck_14t: string;
  truck_15t: string;
  truck_18t: string;
  truck_25t: string;
};
export const CORPVEHICLE: CorpVehicles = {
  motorcycle: "오토바이",
  damas: "다마스",
  labo: "라보",
  truck_1t: "트럭1T",
  truck_1_4t: "트럭1_4T",
  truck_2_5t: "트럭2_5T",
  truck_3_5t: "트럭3_5T",
  truck_5t: "트럭5T",
  truck_8t: "트럭8T",
  truck_11t: "트럭11T",
  truck_14t: "트럭14T",
  truck_15t: "트럭15T",
  truck_18t: "트럭18T",
  truck_25t: "트럭25T",
  MOTORCYCLE: "연계배송/오토바이",
  DAMAS: "연계배송/다마스",
};

type TruckOptions = {
  [index: string]: string;
  _truck: string;
  _canvas: string;
  _box: string;
  _wing: string;
  _chiller_box: string;
  _chiller_wing: string;
  _freezer_box: string;
  _freezer_wing: string;
};
export const TRUCKOPTION: TruckOptions = {
  _truck: "전체/카고",
  _canvas: "호루",
  _box: "탑차",
  _wing: "윙바디",
  _chiller_box: "냉장탑차",
  _chiller_wing: "냉장윙바디",
  _freezer_box: "냉동탑차",
  _freezer_wing: "냉동윙바디",
};

type Payments = {
  [index: string]: string;
  ADVANCED_CASH: string;
  CASH_ON_DELIVERY: string;
  CREDIT_CARD: string;
  CORPORATE_ACCOUNT: string;
  CUSTOMER_ACCOUNT: string;
  CREDIT: string;
  TRANSFER: string;
};
export const PAYMENTS: Payments = {
  ADVANCED_CASH: "현금선불",
  CASH_ON_DELIVERY: "현금착불",
  CREDIT_CARD: "신용카드",
  CORPORATE_ACCOUNT: "법인신용",
  CUSTOMER_ACCOUNT: "신용(고객)",
  CREDIT: "크레딧",
  TRANSFER: "송금",
};

type Trips = {
  [index: string]: string;
  ONE_WAY: string;
  MULTI_TRIP: string;
  ROUND_TRIP: string;
  NETWORK: string;
};
export const TRIP: Trips = {
  ONE_WAY: "편도",
  MULTI_TRIP: "경유",
  ROUND_TRIP: "왕복",
  NETWORK: "연계",
};

type Status = {
  [index: string]: string;
  BEFORE_WAITING: string;
  RESERVATION: string;
  WAITING: string;
  RECEIPT: string;
  ALLOCATION: string;
  BEFORE_PICK_UP: string;
  PICK_UP: string;
  BEFORE_COMPLETION: string;
  COMPLETION: string;
  CANCELLATION: string;
  CALL24_RIDER: string;
};
export const STATUS: Status = {
  BEFORE_WAITING: "승인대기",
  REJECTION: "반려",
  RESERVATION: "예약",
  WAITING: "대기",
  RECEIPT: "접수",
  ALLOCATION: "배차",
  BEFORE_PICK_UP: "픽업대기",
  PICK_UP: "픽업",
  BEFORE_COMPLETION: "완료대기",
  COMPLETION: "완료",
  CANCELLATION: "취소",
  CALL24_RIDER: "인수증 요청",
};

export const MODALVEHICLE = [
  "motorcycle",
  "damas",
  "labo",
  "truck_1t",
  "truck_1_4t",
  "truck_2_5t",
  "truck_3_5t",
  "truck_5t",
  "truck_8t",
  "truck_11t",
  "truck_14t",
  "truck_15t",
  "truck_18t",
  "truck_25t",
  "MOTORCYCLE",
  "DAMAS",
];

export const MODALTRUCKOPTION = [
  "_truck",
  "_canvas",
  "_box",
  "_wing",
  "_chiller_box",
  "_chiller_wing",
  "_freezer_box",
  "_freezer_wing",
];

export const ORDERLOADING = {
  CUSTOMER: "고객님이 직접 상차",
  TOGETHER: "고객님과 기사님이 함께 수작업",
  RIDER: "기사님이 단독 수작업",
  FORK_LIFT: "지게차를 통한 상차",
};

export const ORDERUNLOADING = {
  CUSTOMER: "고객님이 직접 하차",
  TOGETHER: "고객님과 기사님이 함께 수작업",
  RIDER: "기사님이 단독 수작업",
  FORK_LIFT: "지게차를 통한 하차",
};

// 칼럼의 default 값이 바뀌면 biz에서도 바꿔줘야 한다.
export const EXCEL_COLUMNS: ExcelColumn[] = [
  { key: "created", label: "접수시간", default: true, hide: true },
  {
    key: "aq_pickup_request_time",
    label: "예약시간",
    default: true,
    hide: true,
  },
  { key: "id", label: "주문번호", default: true, hide: true },
  { key: "corporation", label: "법인명", default: true, hide: true },
  {
    key: "client_department",
    label: "의뢰인 부서명",
    default: true,
    hide: true,
  },
  { key: "client_name", label: "의뢰인 이름", default: true, hide: true },
  {
    key: "client_phone_number",
    label: "의뢰인 연락처",
    default: true,
    hide: true,
  },
  { key: "sender_name", label: "출발지 이름", default: true, hide: true },
  { key: "sender_staff", label: "출발지 담당자", default: true, hide: true },
  {
    key: "sender_phone_number",
    label: "출발지 연락처",
    default: true,
    hide: true,
  },
  {
    key: "sender_address",
    label: "출발지 검색주소",
    default: true,
    hide: true,
  },
  {
    key: "sender_address_detail",
    label: "출발지 상세주소",
    default: true,
    hide: true,
  },
  { key: "receiver_name", label: "도착지 이름", default: true, hide: true },
  { key: "receiver_staff", label: "도착지 담당자", default: true, hide: true },
  {
    key: "receiver_phone_number",
    label: "도착지 연락처",
    default: true,
    hide: true,
  },
  {
    key: "receiver_address",
    label: "도착지 검색주소",
    default: true,
    hide: true,
  },
  {
    key: "receiver_address_detail",
    label: "도착지 상세주소",
    default: true,
    hide: true,
  },
  { key: "vehicle", label: "차량", default: true, hide: true },
  { key: "vehicle_option", label: "차량 옵션", default: false, hide: false },
  { key: "payment", label: "결제방식", default: true, hide: false },
  { key: "is_ev_priorty", label: "전기차 여부", default: false, hide: false },
  { key: "memo", label: "메모", default: true, hide: true },
  {
    key: "corporate_account_memo",
    label: "배송사유",
    default: true,
    hide: true,
  },
  { key: "rider_name", label: "배송기사 이름", default: false, hide: false },
  {
    key: "rider_phone_number",
    label: "배송기사 연락처",
    default: false,
    hide: false,
  },
  {
    key: "rider_vehicle_number",
    label: "배송기사 차량번호",
    default: false,
    hide: false,
  },
  {
    key: "call24_order_receipt",
    label: "인수증 여부",
    default: true,
    hide: true,
  },
  { key: "location_charge", label: "기본요금", default: true, hide: false },
  { key: "extra_charge", label: "추가요금", default: true, hide: false },
  {
    key: "location_extra_charge",
    label: "배송요금(기본요금 + 추가요금)",
    default: false,
    hide: false,
  },
  { key: "delivery_charge", label: "지급실비", default: true, hide: true },
  {
    key: "calc_total_charge_discount_charge",
    label: "조정금액",
    default: true,
    hide: true,
  },
  { key: "total_charge", label: "최종금액", default: true, hide: true },
  { key: "status", label: "주문상태", default: true, hide: true },
  { key: "allocated", label: "배차시간", default: false, hide: false },
  { key: "picked_up", label: "픽업시간", default: false, hide: false },
  { key: "completed", label: "완료시간", default: false, hide: false },
  { key: "distance", label: "배송거리", default: false, hide: false },
  { key: "auth_code", label: "카드승인번호", default: false, hide: false },
  { key: "username", label: "접수계정", default: false, hide: false },
  {
    key: "user_department",
    label: "접수계정 부서명",
    default: false,
    hide: false,
  },
  { key: "user_fullname", label: "접수계정 이름", default: false, hide: false },
  { key: "user_phone", label: "접수계정 연락처", default: false, hide: false },
  {
    key: "approval_user",
    label: "승인관리자",
    default: false,
    hide: false,
  },
  {
    key: "costcenter_type",
    label: "비용 청구 타입",
    default: false,
    hide: false,
  },
  {
    key: "costcenter",
    label: "비용 청구처",
    default: false,
    hide: false,
  },
  {
    key: "costcenter_change_memo",
    label: "비용청구부서 변경 사유",
    default: false,
    hide: false,
  },
];

export const ORDER_DATA_LABEL = {
  isd_order_number: "배송번호(인성주문)",
  external_isd_order_number: "지방 인성번호",
  call24_order_number: "전국24시콜화물 화물번호",
};

export const CORPORATAE_DATA_LABEL: Record<
  Exclude<keyof CorpProps, "logo">,
  string
> = {
  id: "법인번호",
  name: "법인명",
  channel: "채널",
  phone: "연락처",
  address: "주소",
  registration_number: "사업자등록번호",
  contracted: "계약일",
  limit_budget: "예산한도",
  fixed_date: "기준일",
  discount_rate: "할인율",
  administrator_id: "관리자",
  budget_enable: "예산사용여부",
  restrict_by_budget: "주문제한",
  order_approval_amt: "주문승인 기준금액",
  order_approval_enable: "주문승인 사용여부",
  order_approval_user_select_enable: "승인관리자 지정여부",
  order_approval_time_enable: "승인시간 적용여부",
  order_approval_time_holiday: "공휴일 주문승인",
  order_approval_time_weekend: "주말 주문승인",
  order_approval_time_start: "평일 영업 시작시간",
  order_approval_time_end: "평일 영업 종료시간",
  order_client_fix_enable: "의뢰인고정 사용여부",
  billing_department_enable: "비용청구처 사용여부",
  costcenter_change_memo_enable: "비용청구부서 변경 사유",
  corporate_account_enable: "법인거래 사용여부",
  corporate_order_memo_enable: "법인거래 배송사유여부",
  corporate_card_memo_enable: "법인카드 배송사유여부",
  express_order_memo_enable: "급송 사유",
  corporate_credit_enable: "법인크레딧 사용여부",
  corporate_department_enable: "부서등록 기능 사용여부",
  corporate_department_select_enable: "법인회원가입 부서선택여부",
  corporate_department_select_type: "부서 선택 모듈",
  corporate_email_address: "법인 이메일", // 옛날
  corporate_email_address_list: "법인 이메일",
  corporate_credit: "법인 크레딧",
  address_book_enable: "공용주소록 사용여부",
  address_book_type: "공용주소록 구분",
};

export const LOG_TYPE = {
  SEARCH: "SEARCH",
  AUTH: "AUTH",
  EXCEL: "EXCEL",
  FUNC: "FUNC",
  MANAGE: "MANAGE",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
};

export const STRING_ON_EMPTY = "(빈 값)";
export const STRING_ON_NOT_FOUND = "(찾을 수 없음)";
