import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Button,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { CorpChargeByLocVeh } from "../../../containers/singleCorpCharge/types";
import { truckNaming } from "../../../containers/singleCorpCharge/SingleCorpChargeCalculate";
import { CORPVEHICLE } from "../../../lib/constants/constants";

const TABLE_HEAD = [
  "출발지",
  "도착지",
  "차량종류",
  "표준요금",
  "거래처요금",
  "",
];

interface ChargeBodyProps {
  emptyRows: number;
  tableRows: CorpChargeByLocVeh[];
  editCharge: (e: React.ChangeEvent<HTMLInputElement>, idx: number) => void;
  deleteCharge: (idx: number) => void;
}

function CorpChargeBody({
  emptyRows,
  tableRows,
  editCharge,
  deleteCharge,
}: ChargeBodyProps) {
  const classes = useStyles();
  return (
    <Table>
      <TableHead className={classes.root}>
        <TableRow>
          {TABLE_HEAD.map((el: string, idx) => (
            <TableCell key={idx} align="center">
              {el}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableRows.map((el, idx: number) => (
          <TableRow key={el.id}>
            <TableCell align="center">
              {`${el.departure.charge_locationl1.name} 
          ${el.departure.charge_locationl2.name} 
          ${el.departure.name}`}
            </TableCell>
            <TableCell align="center">
              {`${el.destination.charge_locationl1.name} 
            ${el.destination.charge_locationl2.name} 
            ${el.destination.name}`}
            </TableCell>
            <TableCell align="center">
              {el.vehicleType.includes("truck")
                ? truckNaming(el.vehicleType)
                : CORPVEHICLE[el.vehicleType]}
            </TableCell>
            <TableCell align="center">{el.baseCharge}</TableCell>
            <TableCell align="center">
              <input
                type="number"
                defaultValue={el.customCharge}
                style={{
                  color: el.customCharge !== el.baseCharge ? "red" : "",
                }}
                onChange={(e) => editCharge(e, idx)}
              />
            </TableCell>
            <TableCell align="center">
              <Button onClick={() => deleteCharge(idx)}>
                <Close />
              </Button>
            </TableCell>
          </TableRow>
        ))}
        {emptyRows > 0 && (
          <TableRow style={{ height: 69.3 * emptyRows }}></TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default CorpChargeBody;

const useStyles = makeStyles(() => ({
  root: { backgroundColor: "#E7E6E6" },
}));
