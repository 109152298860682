import React from "react";
import styled from "styled-components";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Table,
} from "@material-ui/core";
import { RowDivider } from "../common/Base";
import {
  SettingsState,
  SettingsLogType,
} from "../../containers/settings/types";
import { formatDate } from "../../lib/datetime";

type SettingsProps = {
  weather: SettingsState["weather"];
  invalidWeather: boolean;
  notice: SettingsState["notice"];
  isIcyRoad: SettingsState["isIcyRoad"];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  reset: () => void;
  handleLog: () => void;
  log: boolean;
  settingsLog: SettingsLogType[];
};

function SettingsList({
  weather,
  invalidWeather,
  notice,
  isIcyRoad,
  onChange,
  onSubmit,
  reset,
  handleLog,
  log,
  settingsLog,
}: SettingsProps) {
  return (
    <SettingsWrapper>
      <TextField
        label="공지사항 샘플(배차가 원활한 경우)"
        id="notice-sample"
        name="notice-sample"
        value={`*현재 수도권에 비가(눈이) 내리고 있으므로, 오토바이 및 개방형 차량 접수 시 참고 부탁드립니다.`}
        variant="outlined"
        margin="normal"
        multiline
        fullWidth
        disabled
      />
      <TextField
        label="공지사항 샘플(배차가 지연될 경우)"
        id="notice-sample"
        name="notice-sample"
        value={`*현재 우천 영향으로 배차가 지연되고 있습니다. 특히 오토바이는 배차가 제한되고 있으니 이 점 양해바랍니다.
        \n*현재 눈이 내려(폭설이 내려) 배차가 지연되고 있습니다. 특히 오토바이는 배차가 제한되고 있으니 이 점 양해바랍니다.
        \n*현재 한파로 인해 배차가 지연되고 있습니다. 특히 오토바이는 배차가 제한되고 있으니 이 점 양해바랍니다.`}
        variant="outlined"
        margin="normal"
        multiline
        fullWidth
        disabled
      />
      <StyledFormLabel
        control={
          <Checkbox
            checked={isIcyRoad}
            name="isIcyRoad"
            color="primary"
            onChange={onChange}
          />
        }
        label="눈길 또는 빙판길"
        labelPlacement="end"
        disabled
      />
      <TextField
        label="할증요금관리"
        id="weather"
        name="weather"
        value={weather}
        variant="outlined"
        margin="normal"
        type="number"
        inputProps={{ step: 1000 }}
        error={invalidWeather}
        helperText={invalidWeather && "0 또는 1,000원 단위로 입력 바랍니다."}
        onChange={onChange}
        fullWidth
      />
      <TextField
        label="공지사항"
        id="notice"
        name="notice"
        value={notice ? notice : ""}
        variant="outlined"
        margin="normal"
        minRows="5"
        multiline
        onChange={onChange}
        fullWidth
      />
      <RowDivider />
      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled={invalidWeather}
        onClick={onSubmit}
        fullWidth
      >
        저장
      </Button>
      <RowDivider />
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={reset}
        fullWidth
      >
        공지사항&할증 제거
      </Button>
      <RowDivider />
      <Button variant="outlined" size="large" onClick={handleLog} fullWidth>
        기록보기
      </Button>
      {log && (
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell align="center">시간</TableHeaderCell>
                <TableHeaderCell align="center">공지 사항</TableHeaderCell>
                <TableHeaderCell align="center">
                  눈길 또는 빙판길
                </TableHeaderCell>
                <TableHeaderCell align="center">할증요금</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {settingsLog.map((setting) => {
                return (
                  <TableRow hover tabIndex={-1} key={setting.id}>
                    <TableCell align="center">
                      {formatDate(setting.created)}
                    </TableCell>
                    <TableCell align="center">{setting.notice}</TableCell>
                    <TableCell align="center">
                      {setting.is_icy_road ? "O" : "X"}
                    </TableCell>
                    <TableCell align="center">{setting.charge}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableWrapper>
      )}
    </SettingsWrapper>
  );
}

export default SettingsList;

const SettingsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem auto 0;
  width: 50rem;
`;

const StyledFormLabel = styled(FormControlLabel)`
  padding: 2.5rem 0 0 10px;
  width: 100%;
`;

const TableWrapper = styled.div`
  width: 80rem;
  margin: 1rem;
  overflow: auto;
`;
const TableHeaderCell = styled(TableCell)`
  font-weight: 900 !important;
  font-size: 1rem !important;
`;
