import { useState, useMemo } from "react";
import styled from "styled-components";
import {
  Table,
  TableRow,
  TableBody,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
import {
  ADD_AUTH_DATA,
  AddAuthDataType,
  authCheckboxProps,
  filteredMenu,
} from "../../../containers/users/types";
import { AdminUserAuthsType } from "../../../containers/users/types";

interface UsersAuthAddTableProps {
  authInfo: AdminUserAuthsType[];
  onAuthsRowCreate: (e: AddAuthDataType) => void;
}

function UsersAuthAddTable({
  authInfo,
  onAuthsRowCreate,
}: UsersAuthAddTableProps) {
  const [addAuth, setAddAuth] = useState<AddAuthDataType>(ADD_AUTH_DATA);

  // 저장된 권한(authInfo)을 제외한 셀렉터를 보여준다
  const creatableMenu = useMemo(() => {
    return filteredMenu.filter(
      (el) => !authInfo.some((auth) => el.menuId === auth.menu_id)
    );
  }, [authInfo]);

  const onChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
      checked?: boolean;
    }>
  ) => {
    const { name, value, checked } = e.target;
    switch (name) {
      case "menu_id":
      case "custom1":
        setAddAuth({ ...addAuth, [name]: value });
        break;
      default:
        setAddAuth({ ...addAuth, [name as string]: checked });
        break;
    }
  };

  return (
    <>
      {creatableMenu.length > 0 && (
        <CustomTable>
          <TableBody>
            <BodyRow>
              <th>
                <CustomFormControl variant="outlined" fullWidth>
                  <InputLabel id="filled-auth-menu-id">권한 추가</InputLabel>
                  <Select
                    labelId="filled-auth-menu-id"
                    id="filled-auth-menu-id"
                    name="menu_id"
                    value={addAuth.menu_id}
                    onChange={onChange}
                  >
                    {creatableMenu.map((option) => (
                      <MenuItem key={option.menuId} value={option.menuId}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </CustomFormControl>
              </th>
              {authCheckboxProps.map(({ key }) => (
                <td key={key}>
                  <FormLabel
                    control={
                      <Checkbox
                        name={key}
                        checked={addAuth[key]}
                        onChange={onChange}
                      />
                    }
                    label={""}
                    labelPlacement="start"
                  />
                </td>
              ))}
              <td>
                <TextField
                  label="외부 채널 등록"
                  placeholder="ex) algoquick_g1"
                  id="custom1"
                  name="custom1"
                  value={addAuth.custom1 || ""}
                  onChange={onChange}
                  variant="outlined"
                  size="small"
                />
              </td>
              <td>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onAuthsRowCreate(addAuth)}
                >
                  추가
                </Button>
              </td>
            </BodyRow>
          </TableBody>
        </CustomTable>
      )}
    </>
  );
}

export default UsersAuthAddTable;

const CustomTable = styled(Table)`
  margin-top: 1rem;
`;

const BodyRow = styled(TableRow)`
  height: 2.5rem;
  line-height: 2.5rem;
  border: 1px solid #e4e4e4;
  border-right: none;
  border-left: none;

  th {
    width: 15%;
    padding-left: 1rem;
    text-align: left;
    font-weight: 500;
  }
  td {
    text-align: center;
    width: 10%;
  }
`;

const CustomFormControl = styled(FormControl)`
  margin: 8px 0 !important;
`;

const FormLabel = styled(FormControlLabel)`
  margin-left: 0 !important;
`;
