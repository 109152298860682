import { useState, useEffect } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Button, Snackbar } from "@material-ui/core";
import useSnackbar from "../../hooks/useSnackbar";
import ExtraWeatherChargeList from "../../components/corporation/ExtraWeatherChargeList";
import NoExtraChargeList from "../../components/corporation/NoExtraChargeList";
import Notice from "../../components/common/Notice";
import { LOG_TYPE, VEHICLE } from "../../lib/constants/constants";
import { postLog } from "../../lib/hasura/users";
import { destructResponse } from "../../lib/hasura/common";
import {
  getCorpWeatherCharge,
  createCorpWeatherCharge,
  updateCorpWeatherCharge,
  deleteCorpWeatherCharge,
} from "../../lib/hasura/extraCharge";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { WeatherChargeType } from "./extraTypes";

interface WeatherChargeContainerProps {
  corpId: string;
  corpName: string;
}

function CorporationWeatherChargeContainer({
  corpId,
  corpName,
}: WeatherChargeContainerProps) {
  const dispatch = useDispatch();
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const [addWeather, setAddWeather] = useState({}); // 등록할 날씨요금
  const [weatherData, setWeatherData] = useState<WeatherChargeType[]>([]); // 날씨요금 데이터

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchWeatherData();
  }, [corpId]);

  const fetchWeatherData = async () => {
    dispatch(startGlobalLoading());
    try {
      const response = await destructResponse<WeatherChargeType[]>(
        "corporations_corporationweathercharge",
        () => getCorpWeatherCharge(corpId)
      );
      setWeatherData(response);
    } catch (err) {
      console.log(err);
      openSnackbar("날씨요금을 불러오는데 실패하였습니다.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // 전체 날씨요금 생성
  const onAllRegist = async () => {
    const confirmMsg = `${corpName}의 날씨요금을 생성하시겠습니까?`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await createCorpWeatherCharge(corpId);
      await fetchWeatherData();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 날씨요금 전체생성`,
        "법인 관리"
      );
    } catch (err) {
      const failMsg = (err as Error).message || "등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddWeather({
      ...addWeather,
      [name]: Number(value),
    });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const confirmMsg = `날씨요금을 등록하시겠습니까?`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      /**
       * @description onChange함수를 통해 입력받은 { [name]: value } 객체를
       * @return { [{ vehicle_type: name, weather_charge: value }] } 형태로 변환
       */
      const refinedAddData: WeatherChargeType[] = Object.entries(
        addWeather
      ).map(([vehicle_type, weather_charge]) => ({
        vehicle_type,
        weather_charge: weather_charge as number,
      }));

      const promise = refinedAddData.map(
        async (el) => await updateCorpWeatherCharge(corpId, el)
      );
      const result = await Promise.allSettled(promise);
      await fetchWeatherData();

      const isRejected = result.some((el) => el.status === "rejected");
      if (isRejected) throw new Error("등록에 실패한 요금이 있습니다.");

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 날씨요금 요금등록(${arrayToString(refinedAddData)})`,
        "법인 관리"
      );
      openSnackbar("요금이 등록되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "요금 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };
  const arrayToString = (data: WeatherChargeType[]) => {
    const textData = data.map(
      (el) => `${VEHICLE[el.vehicle_type]} : ${el.weather_charge}원`
    );
    if (textData.length === 0) return "변경없음";
    else return textData.join(", ");
  };

  const onAllDelete = async () => {
    const deleteMsg = `날씨요금을 전체 삭제하시곘습니까?\n전체 삭제시 "기존 날씨요금"으로 자동 적용됩니다.`;
    if (!confirm(deleteMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await deleteCorpWeatherCharge(corpId);
      await fetchWeatherData();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 날씨요금 전체삭제`,
        "법인 관리"
      );
      openSnackbar("삭제가 완료되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "삭제에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <WeatherWrapper>
        <WeatherHeader>
          <h1 className="title">날씨요금 DB화 ({corpName})</h1>
          <div className="description">
            *날씨요금은 부분삭제가 불가하며, 전체삭제시 "기존 날씨요금"으로 자동
            적용됩니다.
          </div>
        </WeatherHeader>
        {weatherData.length > 0 ? (
          <WeatherBody>
            <ExtraWeatherChargeList
              weatherData={weatherData}
              onChange={onChange}
            />
            <ButtonWrapper>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                요금 등록
              </StyledButton>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={onAllDelete}
              >
                전체 삭제
              </StyledButton>
            </ButtonWrapper>
          </WeatherBody>
        ) : (
          <NoExtraChargeList type={"날씨"} onAllRegist={onAllRegist} />
        )}
      </WeatherWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default CorporationWeatherChargeContainer;

const WeatherWrapper = styled.section`
  margin-top: 20px;
  text-align: center;
`;

const WeatherHeader = styled.header`
  padding-bottom: 1.5rem;

  .title {
    margin: 10px;
    font-size: 1.7rem;
  }
  .description {
    font-size: 18px;
    font-weight: 800;
    color: #9e9e9e;
  }
`;

const WeatherBody = styled.section`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.section`
  display: flex;
  margin: 1rem auto;
  gap: 4rem;
`;

const StyledButton = styled(Button)`
  margin: 2rem auto !important;
  width: 10rem;
  height: 3rem;
  font-size: 1rem;
`;
