import React, { useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { TextField, Button } from "@material-ui/core";
import styled from "styled-components";
import ConfirmModal from "../common/ConfirmModal";
import {
  CorporateProject,
  validateProject,
} from "../../containers/corporation/types";
import { updateProject, deleteProject } from "../../lib/hasura/departments";
import { ModalWrapper } from "../common/modal/Modal";
import { postLog } from "../../lib/hasura/users";
import { LOG_TYPE } from "../../lib/constants/constants";
import { FlexCenterSpaceBetween, RowDivider } from "../common/Base";

interface ProjectModalProps {
  row: CorporateProject;
  setProject: React.Dispatch<React.SetStateAction<CorporateProject>>;
  fetchProjects: () => void;
  modalRef: React.ForwardedRef<HTMLDivElement>;
  outSideModal: (e: React.MouseEvent) => void; // 프로젝트정보 모달 (외부) 핸들러
  closeProjectModal: () => void; // 프로젝트정보 모달 (내부) 핸들러
  openSnackbar: (message: string, error?: boolean) => void;
}

function ProjectModifyModal({
  row,
  setProject,
  fetchProjects,
  modalRef,
  outSideModal,
  closeProjectModal,
  openSnackbar,
}: ProjectModalProps) {
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const [deleteOpen, setDeleteOpen] = useState(false);

  // 확인메시지 모달 핸들러
  const openConfirmMessage = () => setDeleteOpen(true);
  const closeConfirmMessage = () => setDeleteOpen(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProject({ ...row, [name]: value });
  };

  /**
   * 프로젝트 수정
   * @property { closeProjectModal, openSnackbar } 성공시 모달창 종료
   * @property { openSnackbar } 실패시 모달창 유지
   */
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const invalidMessage = validateProject(row);
      if (invalidMessage !== "") throw new Error(invalidMessage);

      const {
        data: { errors },
      } = await updateProject(row);
      if (errors !== undefined) throw new Error();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `프로젝트 수정: ${row.name}`,
        "법인 관리"
      );
      closeProjectModal();
      openSnackbar("수정을 완료했습니다.");
    } catch (error) {
      const message =
        (error as Error).message || `${row.name} 수정에 실패하였습니다.`;
      openSnackbar(message, true);
    } finally {
      fetchProjects();
    }
  };

  /**
   * 프로젝트 삭제
   * @property { closeConfirmMessage, closeProjectModal, openSnackbar } 성공시 전부 종료
   * @property { closeConfirmMessage, openSnackbar } 실패시 확인창 종료 + 모달창 유지
   * */
  const handleDeleteProject = async () => {
    try {
      const {
        data: { errors },
      } = await deleteProject(row.id);
      if (errors !== undefined) throw new Error();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `프로젝트 삭제: ${row.name}`,
        "법인 관리"
      );
      closeConfirmMessage();
      closeProjectModal();
      openSnackbar("삭제되었습니다.");
    } catch (error) {
      const message =
        (error as Error).message || `${row.name} 삭제에 실패하였습니다.`;
      closeConfirmMessage();
      openSnackbar(message, true);
    } finally {
      fetchProjects();
    }
  };

  return (
    <>
      <ModalWrapper ref={modalRef} onClick={(e) => outSideModal(e)}>
        <DetailModal>
          <h3 className="header">
            <span>{row.name}</span> 프로젝트 수정
          </h3>
          <TextField
            label="법인명"
            id="corporation"
            name="corporation"
            value={row.corporations_corporation.name}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <RowDivider size="0.5" />
          <TextField
            label="프로젝트명*"
            id="name"
            name="name"
            value={row.name}
            onChange={onChange}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <RowDivider size="0.5" />
          <TextField
            label="예산한도*"
            type="number"
            id="limit_budget"
            name="limit_budget"
            value={row.limit_budget}
            inputProps={{ min: 0, step: 1000 }}
            onChange={onChange}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <RowDivider size="2" />
          <FlexCenterSpaceBetween>
            <Button
              variant="outlined"
              color="secondary"
              onClick={openConfirmMessage}
            >
              삭제
            </Button>
            <Button variant="outlined" color="primary" onClick={onSubmit}>
              수정
            </Button>
          </FlexCenterSpaceBetween>
        </DetailModal>
      </ModalWrapper>
      <ConfirmModal
        open={deleteOpen}
        onClose={closeConfirmMessage}
        onEvent={handleDeleteProject}
        message={`${row.name}을 삭제 하시겠습니까?`}
        yesButton={"확인"}
        noButton={"취소"}
      />
    </>
  );
}

export default ProjectModifyModal;

const DetailModal = styled.section`
  padding: 2.5rem 4rem;
  width: 700px;
  max-height: 95%;
  background-color: #fff;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);
  overflow-y: auto;

  .header {
    span {
      color: #007fff;
    }
  }
`;
