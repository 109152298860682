import React from "react";
import styled from "styled-components";
import AddressDetailList from "../../components/addressDetail/AddressDetailList";

function AddressDetailConatiner() {
  return (
    <Wrapper>
      <section className="description">
        <h1 className="title">상세주소입력 DB화</h1>
        <div className="sub-title">
          *DB에 등록된 데이터를 삭제 시, 현재 저장하지 않은 데이터들도 초기화
          됩니다.
        </div>
      </section>
      <AddressDetailList />
    </Wrapper>
  );
}

export default AddressDetailConatiner;

export const Wrapper = styled.div`
  margin: 3rem 10rem;
  .description {
    text-align: center;
    .title {
      margin: 15px 0;
    }
    .sub-title {
      font-weight: 800;
      font-size: 1.1rem;
      color: #9e9e9e;
    }
  }
`;
