import React from "react";
import { CHANNEL } from "../../lib/constants/constants";
import { Button, TextField } from "@material-ui/core";
import {
  FlexCenterSpaceBetween,
  FlexColumnCenter,
  RowDivider,
} from "../common/Base";
import { ModalContainer } from "../common/modal/Modal";
import { checkField } from "../../lib/validate";

const ReOrder = ({ row, onChange, reOrderClose, onSubmit }) => {
  return (
    <FlexColumnCenter>
      <ModalContainer>
        <h1>{CHANNEL[row.channel]}</h1>
        <TextField
          label="주문 번호"
          id="id"
          name="id"
          value={checkField(row.id)}
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          label="의뢰인 이름"
          id="client_name"
          name="client_name"
          value={checkField(row.client_name)}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
          required
        />
        <TextField
          label="출발지 이름"
          id="sender_name"
          name="sender_name"
          value={checkField(row.sender_name)}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
          required
        />
        <TextField
          label="도착지 이름"
          id="receiver_name"
          name="receiver_name"
          value={checkField(row.receiver_name)}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
          required
        />
        <TextField
          label="도착지 주소"
          id="receiver_address"
          name="receiver_address"
          value={checkField(row.receiver_address)}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
        />
        <TextField
          label="도착지 지번 주소"
          id="receiver_jibun_address"
          name="receiver_jibun_address"
          value={row.receiver_jibun_address}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
        />
        <TextField
          label="메모"
          id="memo"
          name="memo"
          value={checkField(row.memo)}
          variant="outlined"
          margin="normal"
          fullWidth
          multiline={true}
          minRows={3}
          onChange={onChange}
          required
        />
        <TextField
          label="접수 회원"
          id="username"
          name="username"
          value={checkField(row.username)}
          variant="outlined"
          margin="normal"
          disabled
          fullWidth
        />
        <RowDivider />
        <FlexCenterSpaceBetween>
          <Button variant="outlined" color="secondary" onClick={reOrderClose}>
            취소
          </Button>
          <Button variant="outlined" color="primary" onClick={onSubmit}>
            저장
          </Button>
        </FlexCenterSpaceBetween>
      </ModalContainer>
    </FlexColumnCenter>
  );
};

export default ReOrder;
