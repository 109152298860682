import React from "react";
import styled from "styled-components";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TableContainer,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { ascending } from "../../lib/core";
import { PAYMENTS } from "../../lib/constants/constants";
import {
  ColumnDivider,
  FlexBox,
  FlexCenterSpaceBetween,
  FlexRow,
  RowDivider,
} from "../common/Base";
import { ModalContainer } from "../common/modal/Modal";
import { checkField } from "../../lib/validate";

const SelectVehicleType = [
  {
    value: "DAMAS",
    label: "다마스",
  },
  {
    value: "LABO",
    label: "라보",
  },
  {
    value: "TRUCK",
    label: "트럭",
  },
];

const SelectWeight = [
  {
    value: "",
    label: "",
  },
  {
    value: "1T",
    label: "1톤",
  },
  {
    value: "1_4T",
    label: "1.4톤",
  },
  {
    value: "2_5T",
    label: "2.5톤",
  },
  {
    value: "3_5T",
    label: "3.5톤",
  },
  {
    value: "5T",
    label: "5톤",
  },
  {
    value: "8T",
    label: "8톤",
  },
  {
    value: "11T",
    label: "11톤",
  },
  {
    value: "14T",
    label: "14톤",
  },
  {
    value: "15T",
    label: "15톤",
  },
  {
    value: "18T",
    label: "18톤",
  },
  {
    value: "25T",
    label: "25톤",
  },
];

const SelectTruckOptionList5TonUnder = [
  "냉동탑",
  "리프트",
  "윙바디",
  "초장축",
  "카고",
  "탑",
  "호루",
  "리프트윙",
  "전체",
  "초장축리",
  "리프트호",
  "리프트탑",
  "초장축호",
  "초장축탑",
  "초장축윙",
  "냉장탑",
  "초장축호리",
  "초장축탑리",
  "초장축윙리",
  "초장축냉동탑",
  "초냉장윙리",
  "냉장탑리",
  "초냉장탑리",
  "초장축냉동윙",
  "초냉동윙리",
  "초냉동탑리",
  "초장축냉장윙",
  "냉장윙",
  "냉동윙",
  "냉동탑리",
  "냉동윙리",
  "냉장윙리",
];

const SelectTruckOptionList = [
  "냉동탑",
  "리프트",
  "윙바디",
  "윙축",
  "초장축",
  "축카고",
  "카고",
  "탑",
  "플러스카",
  "플축카고",
  "호루",
  "플러스윙",
  "리프트윙",
  "전체",
  "플축윙",
  "플호리",
  "플축호리",
  "플축호루",
  "플러스리",
  "플러스호",
  "리프트호",
  "리프트탑",
  "냉장탑",
  "냉장리플윙",
  "냉동리플윙",
  "플축윙리",
  "플러스윙리",
  "냉장탑리",
  "냉장윙축리",
  "냉동윙축리",
  "축카고리",
  "윙축리",
  "냉동탑리",
  "플축카리",
  "냉동탑축",
  "냉장윙축",
  "냉동윙축",
  "냉장플윙",
  "냉동플윙",
  "플러스탑",
  "플축탑",
  "플축탑리",
  "냉장플축윙",
  "냉동플축윙",
  "플러스탑리",
  "초냉동탑리",
  "호루축",
  "냉장윙",
  "냉동플축윙리",
  "냉장플축윙리",
  "냉동탑플축",
  "냉동탑플",
  "냉동탑플리",
  "냉동탑플축리",
  "냉장탑플",
  "냉장탑플축",
  "냉장탑플축리",
  "냉장탑플리",
  "냉동윙",
  "냉동윙리",
  "냉장윙리",
];

const Call24Order = ({
  row,
  riderList,
  call24,
  handleCallOrderOnChange,
  handleCallOrderClose,
  handleCall24Api,
}) => {
  // 의뢰인 이름
  let call24ClientName;
  let corporation = null;
  const {
    client_name,
    users_user,
    usersUserByRelatedUserId,
    payment,
    isd_order_number,
  } = row;

  if (users_user) {
    const { corporations_corporation } = users_user;
    corporation = corporations_corporation
      ? corporations_corporation.name
      : null;
  } else if (usersUserByRelatedUserId) {
    const { corporations_corporation } = usersUserByRelatedUserId;
    corporation = corporations_corporation
      ? corporations_corporation.name
      : null;
  }

  if (corporation) {
    call24ClientName = corporation;
  } else {
    call24ClientName = client_name;
  }

  let SelectTruckOption;
  if (["1T", "1_4T", "2_5T", "3_5T"].indexOf(call24.weight) >= 0) {
    SelectTruckOption = SelectTruckOptionList5TonUnder.sort(ascending).map(
      (option) => {
        return { value: option, label: option };
      }
    );
  } else {
    SelectTruckOption = SelectTruckOptionList.sort(ascending).map((option) => {
      return { value: option, label: option };
    });
  }

  return (
    <Call24OrderDetailContainer>
      <ModalContainer>
        <FlexRow>
          <TextField
            label="인성 주문 번호"
            id="isd_order_number"
            name="isd_order_number"
            value={checkField(isd_order_number)}
            variant="outlined"
            margin="normal"
            fullWidth
            disabled
          />
          <Call24ColumnDivider />
          <TextField
            label="의뢰인 이름"
            id="client_name"
            name="client_name"
            value={call24ClientName}
            variant="outlined"
            margin="normal"
            fullWidth
            disabled
          />
        </FlexRow>
        <TextField
          label="출발지 주소"
          id="sender"
          name="sender"
          value={call24.sender}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={handleCallOrderOnChange}
        />
        <TextField
          label="도착지 주소"
          id="receiver"
          name="receiver"
          value={call24.receiver}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={handleCallOrderOnChange}
        />
        <TextField
          label="결제 방법"
          id="payment"
          name="payment"
          value={checkField(PAYMENTS[payment])}
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <FlexRow>
          <TextField
            type="number"
            label="합계 금액"
            id="charge"
            name="charge"
            value={call24.charge || ""}
            inputProps={{ min: "0", step: "1000" }}
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={handleCallOrderOnChange}
          />
          <Call24ColumnDivider />
          <TextField
            type="number"
            label="수수료"
            id="fee"
            name="fee"
            value={call24.fee}
            variant="outlined"
            margin="normal"
            fullWidth
            disabled={
              !(
                payment === "ADVANCED_CASH" ||
                payment === "CASH_ON_DELIVERY" ||
                payment === "TRANSFER"
              )
            }
            onChange={handleCallOrderOnChange}
          />
          <Call24ColumnDivider />
          <TextField
            label="운송료"
            id="location_charge"
            name="location_charge"
            value={(call24.charge || 0) - call24.fee}
            variant="outlined"
            margin="normal"
            fullWidth
            disabled
          />
          <Call24ColumnDivider />
          <TextField
            type="number"
            label="최소 배차 금액"
            id="minimum_charge"
            name="minimum_charge"
            value=""
            variant="outlined"
            margin="normal"
            fullWidth
            disabled
          />
        </FlexRow>
        <FlexRow>
          <TextField
            select
            label="차량 종류"
            id="vehicle_type"
            name="vehicle_type"
            value={call24.vehicle_type}
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={handleCallOrderOnChange}
          >
            {SelectVehicleType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Call24ColumnDivider />
          <TextField
            select
            label="트럭 톤수"
            id="weight"
            name="weight"
            value={call24.weight}
            variant="outlined"
            margin="normal"
            onChange={handleCallOrderOnChange}
            fullWidth
            disabled={call24.vehicle_type !== "TRUCK"}
          >
            {SelectWeight.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Call24ColumnDivider />
          <TextField
            select
            label="차량 옵션"
            id="option"
            name="option"
            value={call24.option}
            disabled={call24.vehicle_type !== "TRUCK"}
            variant="outlined"
            margin="normal"
            onChange={handleCallOrderOnChange}
            fullWidth
          >
            {SelectTruckOption.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FlexRow>
        <RowDivider />
        <FlexRow>
          <FormControlLabel
            control={<Switch checked={call24.multiCargoGub} />}
            id="multiCargoGub"
            name="multiCargoGub"
            label="혼적"
            labelPlacement="start"
            onChange={handleCallOrderOnChange}
          />
          <Call24ColumnDivider />
          <FormControlLabel
            control={<Switch checked={call24.express} />}
            id="express"
            name="express"
            label="급송"
            labelPlacement="start"
            onChange={handleCallOrderOnChange}
          />
          <Call24ColumnDivider />
          <FormControlLabel
            control={<Switch checked={call24.trip} />}
            id="trip"
            name="trip"
            label="왕복"
            labelPlacement="start"
            onChange={handleCallOrderOnChange}
          />
          <Call24ColumnDivider />
          <FormControlLabel
            control={<Switch checked={call24.startPlanDt} />}
            id="startPlanDt"
            name="startPlanDt"
            label="내일상하차"
            labelPlacement="start"
            onChange={handleCallOrderOnChange}
          />
          <FormControlLabel
            control={<Switch checked={call24.firstType} />}
            id="firstType"
            name="firstType"
            label="운수사"
            labelPlacement="start"
            onChange={handleCallOrderOnChange}
          />
        </FlexRow>
        <RowDivider />
        <TextField
          label="적요란"
          id="note"
          name="note"
          value={call24.note}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={handleCallOrderOnChange}
          multiline={true}
          minRows={3}
        />
        <RowDivider />
        <FlexCenterSpaceBetween>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCallOrderClose}
          >
            취소
          </Button>
          <Button variant="outlined" color="primary" onClick={handleCall24Api}>
            접수
          </Button>
        </FlexCenterSpaceBetween>
      </ModalContainer>
      <Call24ModalRightContainer>
        <Call24ModalContainerTitle>
          해당 지역 수행 배송 기사 리스트(최근 순)
        </Call24ModalContainerTitle>
        <Call24TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">기사이름</TableCell>
                <TableCell align="center">연락처</TableCell>
                <TableCell align="center">차량타입</TableCell>
                <TableCell align="center">배차요금</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {riderList.map((rider) => {
                return (
                  <TableRow hover tabIndex={-1} key={rider.id}>
                    <TableCell align="center">{rider.rider_name}</TableCell>
                    <TableCell align="center">
                      {rider.rider_phone_number}
                    </TableCell>
                    <TableCell align="center">
                      {rider.assigned_vehicle_option}
                    </TableCell>
                    <TableCell align="center">
                      {rider.assigned_charge !== 0
                        ? rider.assigned_charge.toLocaleString()
                        : "연동안됨"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Call24TableContainer>
      </Call24ModalRightContainer>
    </Call24OrderDetailContainer>
  );
};

export default Call24Order;

const Call24OrderDetailContainer = styled(FlexBox)`
  width: 1600px;
`;

const Call24ModalRightContainer = styled(ModalContainer)`
  width: 50%;
  margin: 1rem;
`;

const Call24TableContainer = styled(TableContainer)`
  border: 1px solid lightgray;
  max-height: 30rem;
`;

const Call24ModalContainerTitle = styled.h2`
  text-align: center;
`;

const Call24ColumnDivider = styled(ColumnDivider)`
  width: 3rem;
`;
