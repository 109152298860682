import {
  AuthFormBlock,
  StyledInput,
  ErrorMessage,
  ButtonWithMarginTop,
} from "../common/Login";

const AuthForm = ({ username, password, onChange, onSubmit, error }) => {
  return (
    <AuthFormBlock>
      <h3> ALADMIN LOGIN</h3>
      <form onSubmit={onSubmit}>
        <StyledInput
          autoComplete="username"
          name="username"
          placeholder="아이디"
          onChange={onChange}
          value={username}
        />
        <StyledInput
          type="password"
          autoComplete="password"
          name="password"
          placeholder="비밀번호"
          onChange={onChange}
          value={password}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <ButtonWithMarginTop>로그인</ButtonWithMarginTop>
      </form>
    </AuthFormBlock>
  );
};

export default AuthForm;
