import React from "react";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";

import * as locale from "react-date-range/dist/locale";

import { useDispatch, useSelector } from "react-redux";
import { selectDate } from "../../modules/sendBill";

export default function DateContainer() {
  const dispatch = useDispatch();

  const { dateRange, isRangeChangeAvailable } = useSelector(
    ({ sendBill }) => sendBill
  );

  function handleDateChange(dateItem) {
    if (isRangeChangeAvailable) dispatch(selectDate([dateItem.selection]));
  }

  return (
    <DateRange
      locale={locale["ko"]}
      editableDateInputs={true}
      onChange={handleDateChange}
      moveRangeOnFirstSelection={false}
      ranges={dateRange}
      rangeColors={isRangeChangeAvailable ? ["#3d91ff"] : ["#eff2f7"]}
      dateDisplayFormat={"yyyy년 MM월 dd일"}
    />
  );
}
