import React from "react";

import { useDispatch } from "react-redux";

import { setAddExtraCharge } from "../../modules/sendBill";

import CheckBox from "../../components/sendBill/CheckBox";

type ExtraChargeCheckContainerProps = {
  extraCharge: boolean;
};

export default function ExtraChargeCheckContainer({
  extraCharge,
}: ExtraChargeCheckContainerProps) {
  const dispatch = useDispatch();

  function handleCheckBox(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setAddExtraCharge(event.target.checked));
  }

  return <CheckBox extraCharge={extraCharge} onChange={handleCheckBox} />;
}
