import axios, { AxiosRequestHeaders } from "axios";
import graphqlQuery from "../hasura/base";

// HeadersDefaults에는 Authorization 속성이 없으므로 추가해서 사용
interface CommonHeaderProperties extends AxiosRequestHeaders {
  Authorization: string;
}
type LoginProps = {
  username: string;
  password: string;
};

/* 인증이 필요없는 axios 인스턴스 */
export const defaultInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
});

/* 인증이 필요한 axios 인스턴스 */
export const http = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
});
http.interceptors.request.use((request) => {
  // [2] authorization header 설정
  if (sessionStorage.authorization) {
    request.headers = {
      ...request.headers,
      Authorization: `AlgoLab ${sessionStorage.getItem("authorization")}`,
    } as CommonHeaderProperties;
  }

  return request;
});

// [1] 로그인시 token 발급 (API.login)
export const login = async ({ username, password }: LoginProps) =>
  await http.post("/user/login", {
    username,
    password,
    source: "admin",
  });

// [3] 유저정보 업데이트 (API.getUserData)
export const getUserData = async () =>
  await http.get("/user/", { params: { source: "admin" } });

export const checkOTP = async (ipAddress: string) =>
  await http.post("/user/auth/check", { ip: ipAddress });

export const googleOTP = async (otp: string) =>
  await http.get(`/user/otp/${otp}`);

export const getUserAuth = async (id: string) =>
  graphqlQuery(`{
      users_adminuserauth(where: {users_user: {id: {_eq: "${id}"}}}) {
        menu_id
        search
        privacy
        excel
        etc1
        custom1
      }
    }`);

/* ----- 임계값 관련 API ----- */
/* 1. 문자 발송 */
export const postSendSMS = async (phone: string) =>
  await http.post("/user/sms", { phone });

/* 2. 인증번호(6자리) 입력 */
export const postAuthSMS = async (id: string, code: string) =>
  await http.post(`/user/sms/${id}/authenticate`, {
    code: code,
  });

/* 3. 문자 인증한 userName 확인 */
export const findUsername = async (id: string) =>
  await http.post("/user/username", { auth: id });

/* 4. 유저 토큰값 */
export const getToken = async (id: string, username: string) =>
  await http.post("/user/token", {
    auth: id,
    username: username,
  });

/* 5. 비밀번호 변경 */
// 아직 로그인 성공 전이기 때문에 authorization header를 별도 전송!
export const resetPassword = async (
  token: string,
  password: string,
  passwordConfirm: string
) => {
  const body = {
    password: password,
    passwordConfirm: passwordConfirm,
    source: "admin",
  };
  const config = { headers: { Authorization: `AlgoLab ${token}` } };

  return await http.post("/user/reset_password", body, config);
};
