export type descriptionType = {
  id: number;
  vehicleExample: string;
};

export type PostDataType = {
  receiver: string;
  sender: string;
  vehicleOption: string;
  vehicleType: string;
};

export type ConvertedDataType = {
  charge: string | number;
  distance: number;
  originalReceiver: string;
  originalSender: string;
  receiverAddress: string;
  senderAddress: string;
  vehicleOption: string;
  vehicleType: string;
  senderLatitude: string;
  senderLongitude: string;
  receiverLatitude: string;
  receiverLongitude: string;
};

export const description_A = [
  { id: 0, vehicleExample: "오토바이" },
  { id: 1, vehicleExample: "다마스" },
  { id: 2, vehicleExample: "라보" },
  { id: 3, vehicleExample: "트럭1T" },
  { id: 4, vehicleExample: "트럭1_4T" },
  { id: 5, vehicleExample: "트럭2_5T" },
  { id: 6, vehicleExample: "트럭3_5T" },
  { id: 7, vehicleExample: "트럭5T" },
];

export const description_B = [
  { id: 0, vehicleExample: "트럭1T/카고" },
  { id: 1, vehicleExample: "트럭1_4T/탑차" },
  { id: 2, vehicleExample: "트럭2_5T/윙바디" },
  { id: 3, vehicleExample: "트럭3_5T/냉장탑차" },
  { id: 4, vehicleExample: "트럭5T/냉동윙바디" },
  { id: 5, vehicleExample: "트럭1T/카고/리프트" },
  { id: 6, vehicleExample: "트럭1_4T/탑차/리프트" },
  { id: 7, vehicleExample: "트럭2_5T/윙바디/리프트" },
];
