import { useState } from "react";

const useSnackbar = () => {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [snackbarOpen, setIsSnackbarOpen] = useState(false);

  const openSnackbar = (message: string, isError = false) => {
    setError(isError);
    setMessage(message);
    setIsSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  return { error, message, snackbarOpen, openSnackbar, closeSnackbar };
};

export default useSnackbar;
