import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Snackbar } from "@material-ui/core";

import UsersAuthDetail from "../../components/users/UsersAuthDetail";
import UsersAuthDetailModal from "../../components/users/authDetailModal/UserAuthDetailModal";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import {
  MENU,
  DEFAULT_AUTH_USER,
  AddAuthDataType,
  AdminUserType,
  AdminUserAuthsType,
  AuthUserDetailType,
} from "./types";
import {
  getAdminUser,
  updateAdminUser,
  updateAdminUserAuths,
  postLog,
  createAdmiinUserAuthRow,
  deleteAdminUserAuthsRow,
  deleteAdminUserAuthsAll,
} from "../../lib/hasura/users";
import { postCreateUser } from "../../lib/api/users";
import { destructResponse } from "../../lib/hasura/common";
import { LOG_TYPE } from "../../lib/constants/constants";

function UsersAuthDetailContainer({ history }: RouteComponentProps) {
  const dispatch = useDispatch();
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);
  const authId: string = history.location.pathname.replace("/users/auth/", "");

  const [userInfo, setUserInfo] =
    useState<AuthUserDetailType>(DEFAULT_AUTH_USER);

  const [authInfo, setAuthInfo] = useState<AdminUserAuthsType[]>([]); // 권한정보
  const [prevAuthInfo, setPrevAuthInfo] = useState<AdminUserAuthsType[]>([]); // 기존 권한정보

  const [prevLevel, setPrevLevel] = useState(""); // 기존 권한
  const [modalOpen, setModalOpen] = useState(false);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  const fetchData = async () => {
    dispatch(startGlobalLoading());
    try {
      const {
        auth_level,
        id,
        ip_address,
        is_external_access,
        otp_key,
        user_id,
        users_user,
      } = await destructResponse<AdminUserType>("users_adminuser_by_pk", () =>
        getAdminUser(authId)
      );
      const { users_adminuserauths } = users_user;

      setUserInfo({
        auth_level,
        id,
        ip_address: ip_address || [],
        is_external_access,
        otp_key: otp_key || " ",
        user_id,
        corporation_id: users_user?.corporations_corporation?.id || "",
        corporation_name: users_user?.corporations_corporation?.name || "",
        department_id: users_user?.corporations_department?.id || "",
        department_name: users_user?.corporations_department?.name || "",
        fullname: users_user?.fullname || "",
        username: users_user?.username || "",
      });
      setPrevLevel(auth_level);
      setAuthInfo(
        users_adminuserauths.filter(({ menu_id }) => menu_id !== "MONTH")
      );
      setPrevAuthInfo(
        users_adminuserauths.filter(({ menu_id }) => menu_id !== "MONTH")
      );
    } catch (err) {
      console.log(err);
      openSnackbar(
        "유저 정보를 받아올 수 없습니다. 개발팀에 문의해주세요.",
        true
      );
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // mounted
  useEffect(() => {
    fetchData();
  }, [authId]);

  const onChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name as string]: value });
  };

  const onDelete = async () => {
    if (!window.confirm("해당 회원의 권한을 삭제하시겠습니까?")) return;

    try {
      dispatch(startGlobalLoading());
      const { data } = await deleteAdminUserAuthsAll(userInfo.user_id);
      if (data.errors !== undefined)
        throw new Error("삭제에 실패하였습니다. 잠시후 다시 시도해주세요.");

      // 권한 삭제 시 로그 등록
      await postLog(
        user.id, // 로그인 아이디
        userAuth.ip,
        LOG_TYPE.AUTH,
        `${userInfo.username} 권한삭제`,
        "회원권한관리"
      );
      openSnackbar("권한이 삭제되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "삭제에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    try {
      if (prevLevel === userInfo.auth_level)
        throw new Error("현재권한과 변경할 권한이 동일합니다.");

      const { data } = await updateAdminUser({
        id: userInfo.id,
        auth_level: userInfo.auth_level,
        is_external_access:
          userInfo.auth_level === "master" || userInfo.auth_level === "manager",
      });
      if (data.errors !== undefined)
        throw new Error("변경에 실패하였습니다. 잠시후 다시 시도해주세요.");

      // adminuserauth 권한 등록
      const id = userInfo.user_id;
      await postCreateUser(id).catch(() => {
        throw new Error(
          `${userInfo.auth_level}로 권한을 변경하였으나 권한등록 API 호출에 실패하였습니다.`
        );
      });

      // 권한 변경시 로그 등록
      await postLog(
        user.id, // 로그인 아이디
        userAuth.ip,
        LOG_TYPE.AUTH,
        `${userInfo.username} 계정이 ${prevLevel}에서 ${userInfo.auth_level}로 권한변경`,
        "회원권한관리"
      );

      const successMsg = `${userInfo.auth_level}로 권한변경 및 권한등록 되었습니다.`;
      openSnackbar(successMsg, false);
    } catch (err) {
      const failMsg = (err as Error).message || "변경에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // 권한정보 설정하기
  const modalRef = useRef<HTMLDivElement>(null);
  const openAuthModal = () => {
    setModalOpen(true);
    document.body.style.overflow = "hidden";
  };
  const closeAuthModal = () => {
    setAuthInfo(prevAuthInfo); // 데이터 초기화
    setModalOpen(false);
    document.body.style.overflow = "";
  };
  const outSideAuthModal = (e: React.MouseEvent) => {
    if (modalRef.current === e.target) {
      setAuthInfo(prevAuthInfo); // 데이터 초기화
      setModalOpen(false);
      document.body.style.overflow = "";
    }
  };

  const onAuthsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    /**
     * @description name
     * @return ["ORDERS", search]
     */
    const [menuName, query] = name.split(" ");
    const changeData = authInfo.map((el) => {
      if (menuName !== el.menu_id) return el;
      else {
        return query === "custom1"
          ? { ...el, [query]: value }
          : { ...el, [query]: checked };
      }
    });
    setAuthInfo(changeData);
  };

  const onAuthsSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    try {
      const changedRows = authInfo.reduce((acc: AdminUserAuthsType[], cur) => {
        const prev = prevAuthInfo.find(
          (prev) => prev.id === cur.id && prev.menu_id === cur.menu_id
        );

        if (prev) {
          const isChanged = Object.keys(cur).some(
            (key) => cur[key] !== prev[key]
          );
          if (isChanged) acc.push(cur);
        }

        return acc;
      }, []);

      // 변경이 없는 경우
      if (changedRows.length === 0) throw new Error("변경된 기능이 없습니다.");

      // 변경이 있는 경우
      const promise = changedRows.map(
        async (row) => await updateAdminUserAuths(userInfo.user_id, row)
      );
      const result = await Promise.allSettled(promise);
      const isRejected = result.some((el) => el.status === "rejected");
      if (isRejected) throw new Error("변경에 실패한 기능이 있습니다.");

      // 로그 등록
      const logMessage =
        changedRows.map((el) => MENU[el.menu_id]).join(", ") || "";
      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.AUTH,
        `${userInfo.username} 계정의 '${logMessage}' 권한기능 변경`,
        "회원권한관리"
      );

      openSnackbar("변경이 완료되었습니다.", false);
      closeAuthModal();
    } catch (err) {
      const message = (err as Error).message || "변경에 실패하였습니다.";
      openSnackbar(message, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onAuthsRowDelete = async (row: AdminUserAuthsType) => {
    if (!window.confirm(`${row.label} 기능을 삭제하시겠습니까?`)) return;

    try {
      dispatch(startGlobalLoading());
      const { data } = await deleteAdminUserAuthsRow(row.id);
      if (data.errors !== undefined)
        throw new Error("삭제에 실패하였습니다. 잠시후 다시 시도해주세요.");

      // 권한 삭제 시 로그 등록
      await postLog(
        user.id, // 로그인 아이디
        userAuth.ip,
        LOG_TYPE.AUTH,
        `${userInfo.username} '${row.label}' 권한기능 삭제`,
        "회원권한관리"
      );
      await fetchData();
    } catch (err) {
      const failMsg = (err as Error).message || "삭제에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onAuthsRowCreate = async (auth: AddAuthDataType) => {
    const menuId = auth?.menu_id;
    if (!menuId) return openSnackbar("추가할 기능을 선택해주세요.", true);

    try {
      dispatch(startGlobalLoading());
      const { data } = await createAdmiinUserAuthRow(userInfo.user_id, auth);
      if (data.errors !== undefined)
        throw new Error("추가에 실패하였습니다. 잠시후 다시 시도해주세요.");

      // 권한 추가 시 로그 등록
      await postLog(
        user.id, // 로그인 아이디
        userAuth.ip,
        LOG_TYPE.AUTH,
        `${userInfo.username} '${MENU[menuId]}' 권한기능 추가`,
        "회원권한관리"
      );
      await fetchData();
    } catch (err) {
      const failMsg = (err as Error).message || "추가에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();

    if (!error) history.push("/users/auth");
  };

  return (
    <>
      <UsersAuthDetail
        data={userInfo}
        prevLevel={prevLevel}
        onChange={onChange}
        onDelete={onDelete}
        onSubmit={onSubmit}
        openAuthModal={openAuthModal}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
      {modalOpen && (
        <UsersAuthDetailModal
          authInfo={authInfo}
          modalRef={modalRef}
          outSideAuthModal={outSideAuthModal}
          closeAuthModal={closeAuthModal}
          onAuthsChange={onAuthsChange}
          onAuthsSubmit={onAuthsSubmit}
          onAuthsRowCreate={onAuthsRowCreate}
          onAuthsRowDelete={onAuthsRowDelete}
        />
      )}
    </>
  );
}

export default withRouter(UsersAuthDetailContainer);
