import React from "react";
import PaymentForm from "./PaymentForm";
import {
  ButtonWrapper,
  FormWrapper,
  FormInput,
  GroupWrapper,
  HeaderText,
} from "../../pages/GroupOrder/style";

type SenderInfoFormProps = {
  senderState: any;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onClick: () => void;
};

function SenderInfoForm({
  senderState,
  onChange,
  onClick,
}: SenderInfoFormProps) {
  const { clientName, clientPhoneNumber, payment } = senderState;

  return (
    <>
      <GroupWrapper>
        <FormWrapper>
          <HeaderText>의뢰인 정보입력</HeaderText>
          <FormInput
            label="의뢰인 이름"
            value={clientName}
            name="clientName"
            onChange={onChange}
          />
          <FormInput
            label="의뢰인 휴대폰번호"
            value={clientPhoneNumber}
            name="clientPhoneNumber"
            onChange={onChange}
          />
          <PaymentForm onChange={onChange} payment={payment} />
          <ButtonWrapper variant="contained" color="primary" onClick={onClick}>
            결과확인
          </ButtonWrapper>
        </FormWrapper>
      </GroupWrapper>
    </>
  );
}

export default SenderInfoForm;
