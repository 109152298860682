import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import SendBill from "../../components/sendBill/SendBill";
import {
  POST_EXCEL,
  postExcel,
  resetPostExcelResult,
} from "../../modules/sendBill";
import { emailCheckFailed } from "./utils";

function SendBillContainer() {
  const dispatch = useDispatch();

  const {
    isSendingAvailable,
    date,
    result,
    excelData,
    sender,
    billDate,
    extraCharge,
  } = useSelector(({ sendBill }: RootStateOrAny) => ({
    isSendingAvailable: sendBill.isSendingAvailable,
    date: sendBill.date,
    result: sendBill.result,
    excelData: sendBill.excelData,
    sender: sendBill.sender,
    billDate: sendBill.billDate,
    extraCharge: sendBill.extraCharge,
  }));

  const { loading } = useSelector(({ loading }: RootStateOrAny) => ({
    loading: loading[POST_EXCEL] ? loading[POST_EXCEL] : false,
  }));

  function postExcelBill(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const { emailAddress, emailTitle, emailText } = sender;

    if (emailCheckFailed(emailAddress)) {
      alert("올바른 이메일 주소를 입력하세요!");
      return;
    }

    if (!emailTitle) {
      alert("이메일 제목을 입력해주세요!");
      return;
    }

    if (!emailText) {
      alert("이메일 본문을 입력해주세요!");
      return;
    }

    dispatch(
      postExcel({
        date,
        billDate,
        emailAddress,
        emailTitle,
        emailText,
        excelData,
        extraCharge,
      })
    );
  }

  function resetResult() {
    dispatch(resetPostExcelResult());
  }

  return (
    <SendBill
      isSendingAvailable={isSendingAvailable}
      date={date}
      result={result}
      resetResult={resetResult}
      postExcelBill={postExcelBill}
      loading={loading}
      extraCharge={extraCharge}
    />
  );
}

export default SendBillContainer;
