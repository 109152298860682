import React, { useCallback } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import styled from "styled-components";

const StyledText = styled(DialogContentText)`
  white-space: pre-line !important;
`;
interface ResultDialogProps {
  result: boolean;
  title: string;
  text: string;
  error?: boolean;
}

function ResultDialog({
  result,
  title,
  text,
  error = false,
}: ResultDialogProps) {
  const [open, setOpen] = React.useState(result);
  const handleClose = () => {
    setOpen(false);
  };

  const goToMainPage = useCallback(() => {
    handleClose();
    if (error === false) return;
    window.location.href = "/";
  }, [error]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <StyledText id="alert-dialog-description">{text}</StyledText>
      </DialogContent>
      <DialogActions>
        <Button onClick={goToMainPage} color="primary">
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResultDialog;
