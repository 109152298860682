import React, { useMemo } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  TextField,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
} from "@material-ui/core";

import Detail from "../common/Detail";
import { AuthUserDetailType } from "../../containers/users/types";
import {
  FlexBox,
  FlexCenterSpaceBetween,
  CustomInputLabel,
  ColumnDivider,
  RowDivider,
} from "../common/Base";

interface UsersAuthDetailProps {
  data: AuthUserDetailType;
  prevLevel: string;
  onChange: (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => void;
  onDelete: () => void;
  onSubmit: (e: React.FormEvent) => void;
  openAuthModal: () => void;
}

function UsersAuthDetail({
  data: {
    auth_level,
    corporation_name,
    department_name,
    fullname,
    ip_address,
    is_external_access,
    otp_key,
    username,
  },
  prevLevel,
  onChange,
  onDelete,
  onSubmit,
  openAuthModal,
}: UsersAuthDetailProps) {
  const history = useHistory();

  // customer 및 g1_master는 전체 권한이 생성되는걸 막기위해 비활성화
  const isDisabledAuth = useMemo(
    () => auth_level === "customer" || auth_level === "g1_master",
    [auth_level]
  );

  return (
    <FlexColumnCenterAuth>
      <Detail>
        <FlexBox>
          <TextField
            label="아이디"
            id="username"
            name="username"
            value={username}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="이름"
            id="fullname"
            name="fullname"
            value={fullname}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="법인"
            id="corporation_name"
            name="corporation_name"
            value={corporation_name}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="부서"
            id="department_name"
            name="department_name"
            value={department_name}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="IP주소"
            id="ip_address"
            name="ip_address"
            value={ip_address.join(", ")}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="OTP 키"
            id="otp_key"
            name="otp_key"
            value={otp_key}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
      </Detail>
      <RowDivider size="2" />
      <Detail>
        <AuthBtnWrapper>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={openAuthModal}
          >
            권한정보 설정하기
          </Button>
        </AuthBtnWrapper>
        <FlexBox>
          <TextField
            label="현재 권한"
            id="prevLevel"
            name="prevLevel"
            value={prevLevel}
            disabled
            variant="filled"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="외부 IP 접근 허용여부"
            id="is_external_access"
            name="is_external_access"
            value={is_external_access}
            disabled
            variant="filled"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <RowDivider />
        <FormControl variant="outlined" fullWidth>
          <CustomInputLabel>변경할 권한</CustomInputLabel>
          <Select
            value={auth_level}
            onChange={onChange}
            input={<OutlinedInput name="auth_level" id="auth_level" />}
            disabled={isDisabledAuth}
          >
            {auth_level === "customer" && (
              <MenuItem value="customer">customer</MenuItem>
            )}
            {auth_level === "g1_master" && (
              <MenuItem value="g1_master">g1_master</MenuItem>
            )}
            <MenuItem value="master">master</MenuItem>
            <MenuItem value="manager">manager</MenuItem>
            <MenuItem value="user">user</MenuItem>
          </Select>
        </FormControl>
        <RowDivider />
        <FlexCenterSpaceBetween>
          <Button variant="contained" color="secondary" onClick={onDelete}>
            전체권한 삭제
          </Button>
          <SpaceButtonGroup>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => history.goBack()}
            >
              취소
            </Button>
            <Button variant="outlined" color="primary" onClick={onSubmit}>
              저장
            </Button>
          </SpaceButtonGroup>
        </FlexCenterSpaceBetween>
      </Detail>
    </FlexColumnCenterAuth>
  );
}

export default UsersAuthDetail;

const FlexColumnCenterAuth = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto 0;
  width: 90%;
`;

const AuthBtnWrapper = styled.div`
  width: 90%;
`;

const SpaceButtonGroup = styled.div`
  > :not(:first-child) {
    margin-left: 12px;
  }
`;
