import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { Redirect } from "react-router-dom";
import Header from "../../components/common/Header";
import { finishGlobalLoading, startGlobalLoading } from "../../modules/loading";
import { logout } from "../../modules/user";
import { initializeForm } from "../../modules/auth";
import { postLog } from "../../lib/hasura/users";
import { LOG_TYPE } from "../../lib/constants/constants";

function HeaderContainer() {
  const { user, userAuth, loading } = useSelector(
    ({ user, userAuth, loading }: RootStateOrAny) => ({
      user: user.user,
      userAuth: userAuth,
      loading: loading["user/CHECK"],
    })
  );

  const dispatch = useDispatch();

  const onLogout = async () => {
    dispatch(startGlobalLoading());
    try {
      /* 로그아웃시 로그 등록 */
      await postLog(user.id, userAuth.ip, LOG_TYPE.LOGOUT, "로그아웃", null);
      await dispatch(initializeForm()); // auth, token 전부 초기화
      await dispatch(logout());
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  if (loading) return null;
  if (!user) return <Redirect to="/login" />;

  return <Header user={user} onLogout={onLogout} />;
}

export default HeaderContainer;
