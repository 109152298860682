import graphqlQuery from "./base";

export const getSettings = async () =>
  await graphqlQuery(`{   
    algoquick_notice(
      order_by: { id: desc }
    ) {
        charge
        created
        is_icy_road
        id
        notice
      }
    }
  `);

export const setSettings = async (
  notice: string,
  weather: number,
  isIcyRoad: boolean
) =>
  await graphqlQuery(`
    mutation {
      insert_algoquick_notice(
        objects: {
          charge: ${weather},
          notice: "${notice}",
          is_icy_road: ${isIcyRoad}
        }
      ) {
        returning {
          id
        }
      }
      update_algoquick_extracharge(
        where: {
          weather_charge: {_is_null: false}
        },
        _set: {
          weather_charge: ${weather}
        }
      ) {
        returning {
          modified
        }
      }
    }
  `);
