import styled from "styled-components";
import { Select, TextField, Button, withStyles } from "@material-ui/core";

export const SearchWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6rem 3rem 3rem;
  width: 100%;
`;

export const StyledButtonArea = styled.section`
  display: flex;
  justify-content: space-around;
  width: 14rem;
`;

export const FormControlWrapper = styled.section`
  display: flex;
  width: auto;
`;

export const StyledSelect = styled(Select)`
  width: 145px;
  font-size: 18px !important;
`;

export const StyledTextField = styled(TextField)`
  width: 400px;
`;

export const StyledFormButton = withStyles({
  root: {
    margin: "8px 0 0 1rem",
  },
  textSizeLarge: {
    fontSize: "18px",
    fontWeight: 800,
  },
})(Button);
