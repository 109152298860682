import styled from "styled-components";
import { TextField, Select, Button, InputLabel } from "@material-ui/core";

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;

export const FormWrapper = styled.div`
  margin-top: 10px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1,
  h2,
  h3 {
    margin: 15px 0;
  }
  h3 {
    margin-top: 30px;
  }
`;

export const DescriptWrapper = styled.div`
  margin: 1rem auto 2rem;
  width: 30%;
  div {
    margin-bottom: 2rem;
    font-size: 1.1rem;
    text-align: center;
    p {
      margin: 15px 0;
    }
  }
  header {
    text-align: center;
    font-weight: 800;
    color: #9e9e9e;
  }
`;

export const ButtonWideWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 1rem auto 0 auto;

  width: 30%;

  .bill-button {
    width: 100%;
    margin: 0.5rem 0;
  }
`;

export const ExcelUploadForm = styled.form`
  margin-bottom: 20px;
  width: 100%;
  .form-group {
    width: 100%;
    p {
      margin: 10px 0;
    }
  }

  .excel-button {
    width: 100%;
    margin: 10px 0;
  }
  .down-container {
    width: 100%;
  }
`;

export const FormInput = styled(TextField)`
  width: 100%;

  & > div {
    margin: 15px 0;
  }
`;

export const SelectWrapper = styled(Select)`
  width: 100%;
  margin: 15px 0;
`;

export const ButtonWrapper = styled(Button)`
  width: 100%;
`;
export const HeaderText = styled.div`
  color: #000;
  font-weight: 700;
  font-size: 20px;
  margin: 10px 0;
`;

export const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
  font-size: 12px !important;
`;

export const DateTimePickerWrapper = styled.div`
  margin-top: 10px;
`;
