import styled from "styled-components";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { HolidayChargeType } from "../../containers/corporation/extraTypes";
import { VehicleTypes } from "../../containers/corporation/utils";

const HOLIDAY_TITLE = "1. 휴일요금 (토요일, 법정공휴일, 근로자의날)";
const SPECIAL_TITLE = "2. 명절요금 (설과 추석 연휴 시작 전 N 영업일)";

interface HolidayChargeListProps {
  holidayData: HolidayChargeType[];
  isSpecial: boolean;
  onChange: (vehicle: string, special: boolean, charge: number) => void;
}

function ExtraHolidayChargeList({
  holidayData,
  isSpecial,
  onChange,
}: HolidayChargeListProps) {
  const classes = useStyles();

  const sortedHolidayList = holidayData
    .filter(({ is_special }) => is_special === isSpecial)
    .sort(
      (a, b) =>
        VehicleTypes.indexOf(a.vehicle_type) -
        VehicleTypes.indexOf(b.vehicle_type)
    );

  return (
    <HolidayList>
      <HolidayTitle>{isSpecial ? SPECIAL_TITLE : HOLIDAY_TITLE}</HolidayTitle>
      <TableBox>
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell align="center">오토바이</TableCell>
            <TableCell align="center">다마스</TableCell>
            <TableCell align="center">라보</TableCell>
            <TableCell align="center">트럭</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">요금(원)</TableCell>
            {sortedHolidayList.map(
              ({ vehicle_type, is_special, holiday_charge }, idx) => (
                <TableCell key={idx} align="center">
                  <StyledTextField
                    type="number"
                    id={`${vehicle_type}_${is_special}`}
                    name={vehicle_type}
                    defaultValue={holiday_charge}
                    variant="outlined"
                    margin="normal"
                    inputProps={{
                      min: 0,
                      step: 1000,
                      style: { textAlign: "right" },
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChange(vehicle_type, is_special, +e.target.value)
                    }
                  />
                </TableCell>
              )
            )}
          </TableRow>
        </TableBody>
      </TableBox>
    </HolidayList>
  );
}

export default ExtraHolidayChargeList;

const useStyles = makeStyles(() => ({
  root: { backgroundColor: "#E7E6E6" },
}));

const HolidayList = styled.section`
  margin: 35px auto 0;
  width: 80%;
`;

const HolidayTitle = styled.h3`
  text-align: left;
  font-size: 22px;
  font-weight: 500;
`;

const TableBox = styled(Table)`
  margin: 7px auto 1rem;
  border: 1px solid #e7e6e6;
`;

const StyledTextField = styled(TextField)`
  margin: 0;
  width: 50% !important;
`;
