import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import createRequestSaga, {
  createRequestActionTypes,
} from "../lib/createRequestSaga";
import * as API from "../lib/api/auth";

// Action Types
const INITIALIZE_FORM = "auth/INITIALIZE_FORM";
const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] =
  createRequestActionTypes("auth/LOGIN");

// Action Creator 생성
export const initializeForm = createAction(INITIALIZE_FORM, (form) => form);
export const login = createAction(LOGIN, ({ username, password }) => ({
  username,
  password,
}));

// Reducer 생성
const initialState = {
  login: {
    username: "",
    password: "",
  },
  auth: null,
  authError: null,
  token: null,
};
const auth = handleActions(
  {
    [INITIALIZE_FORM]: (state, { payload: form }) => ({
      ...initialState,
    }),
    /**
     * 로그인 성공
     * @return { [2] sessionStorage.authorization 등록 }
     * @description payload => loginAPI로 발급받은 token
     */
    [LOGIN_SUCCESS]: (state, { payload: auth }) => {
      sessionStorage.setItem("authorization", auth.token);
      return {
        ...state,
        authError: null,
        auth,
        token: auth.token,
      };
    },
    [LOGIN_FAILURE]: (state, { payload: error }) => {
      const { detail } = error.response.data;
      return {
        ...state,
        authError: detail,
      };
    },
  },
  initialState
);
export default auth;

/**
 * saga
 * @return { [1] loginAPI => token발급 }
 */
const loginSaga = createRequestSaga(LOGIN, API.login);

export function* authSaga() {
  yield takeLatest(LOGIN, loginSaga);
}
