import React from "react";
import { Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { SheetJSFT } from "../../pages/GroupOrder/utils";

interface ExcelUploadProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileName: string;
  disabled?: boolean;
}

function ExcelUpload({ onChange, fileName, disabled }: ExcelUploadProps) {
  return (
    <>
      <Button
        className="bill-button"
        variant="outlined"
        color="default"
        size="large"
        component="label"
        htmlFor="excel-upload"
        startIcon={<CloudUpload />}
        disabled={disabled}
      >
        <input
          type="file"
          className="form-control"
          id="excel-upload"
          accept={SheetJSFT}
          style={{ display: "none" }}
          onChange={onChange}
        />
        엑셀파일 업로드
      </Button>
      <p>{fileName && `파일이름 : ${fileName}`}</p>
    </>
  );
}

export default ExcelUpload;
