import React, { useState, useEffect, useMemo, useCallback } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useIdleTimer } from "react-idle-timer";
import {
  Toolbar,
  IconButton,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Collapse,
} from "@material-ui/core";
import {
  Reorder,
  PostAdd,
  EditLocation,
  ViewModule,
  Domain,
  ExpandLess,
  ExpandMore,
  PlaylistAddCheck,
  People,
  Schedule,
  Mail,
  LibraryAdd,
  SportsMotorsports,
  AttachMoney,
  ContactMail,
  MenuBook,
  Settings,
  Menu,
  ChevronLeft,
  DirectionsBus,
} from "@material-ui/icons";
import Button from "./Button";
import { DEFAULT_MENU } from "../../pages/MenuPage";
import { AdminUserAuthsType } from "../../containers/users/types";

interface HeaderProps {
  user: {
    fullname: string;
  };
  onLogout: () => void;
}

function Header({ user, onLogout }: HeaderProps) {
  const adminUserAuth = useSelector(
    ({ userAuth }: RootStateOrAny) =>
      userAuth.userAuth?.data?.users_adminuserauth
  );
  const [open, setOpen] = useState(false);
  const [openNetwork, setOpenNetwork] = useState(false);
  const [openCorp, setOpenCorp] = useState(false);
  const [openUser, setOpenUser] = useState(false);

  // 메뉴명 권한
  const [
    ORDERS,
    GROUPORDERS,
    BUNDLE,
    SQUAREBUNDLE,
    NETWORK,
    CORPORATIONS,
    CORPORATIONS_DEPARTMENTS,
    CORPORATIONS_CHARGE,
    USERS,
    USERS_AUTH,
    SCHEDULER,
    SENDBILL,
    ADDRESSDETAIL,
    LARGECHARGE,
    INQUIRY,
    RIDERREVIEW,
    LOGS,
    SETTINGS,
  ] = useMemo(
    () =>
      DEFAULT_MENU.reduce((acc: AdminUserAuthsType[], cur: string) => {
        const isMatched = adminUserAuth?.find(
          ({ menu_id }: AdminUserAuthsType) => menu_id === cur
        );
        acc.push(isMatched);
        return acc;
      }, []),
    [adminUserAuth]
  );

  const handleDrawerOpen = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const onNetworkClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    setOpenNetwork(!openNetwork);
  };
  const onCorpClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    setOpenCorp(!openCorp);
  };
  const onUserClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    setOpenUser(!openUser);
  };

  const timer = (ms: number) => {
    let minutes = Math.floor(ms / 60000);
    let seconds = ((ms % 60000) / 1000).toFixed(0);
    return `${minutes}분 ${parseFloat(seconds) < 10 ? "0" : ""}${seconds}초`;
  };

  // useIdleTimer
  // const timeout = 60000 * 30 - 1000; // 60000ms = 1min
  const timeout = 60000 * 60 * 9 - 1000;
  const [remaining, setRemaining] = useState(timeout);
  const [isIdle, setIsIdle] = useState(false);

  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  const idleTimer = useCallback(() => {
    let inner = () => {
      setRemaining(getRemainingTime());
      if (isIdle) return clearInterval(intervalID);
    };
    let intervalID: NodeJS.Timeout | undefined = setInterval(inner, 1000);
  }, [getRemainingTime, isIdle]);

  useEffect(() => {
    if (!isIdle && user) idleTimer();
    else if (isIdle && user) {
      alert("장시간 미사용으로 로그아웃 됩니다.");
      setIsIdle(false); // isIdle 초기화

      onLogout();
    }
  }, [isIdle, user]);

  return (
    <>
      <HeaderBlock>
        <Wrapper>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen(!open)}
              edge="start"
            >
              <Menu />
            </IconButton>
          </Toolbar>
          <Link to="/" className="logo">
            Algolab ADMIN
          </Link>
          {user && (
            <div className="right">
              <TimeInfo>
                <span>자동 로그아웃</span>({timer(remaining)})
              </TimeInfo>
              <UserInfo>{user.fullname}</UserInfo>
              <Button onClick={onLogout}>로그아웃</Button>
            </div>
          )}
        </Wrapper>
      </HeaderBlock>
      <Spacer />
      <Drawer
        open={open}
        variant="temporary"
        anchor="left"
        onClose={handleDrawerOpen(false)}
      >
        <DrawerIcon>
          <IconButton onClick={handleDrawerOpen(!open)}>
            <ChevronLeft />
          </IconButton>
        </DrawerIcon>
        <Divider />
        <List onClick={handleDrawerOpen(false)}>
          {ORDERS?.search && (
            <ListItem component={Link} to="/orders" button divider>
              <ListItemIcon>
                <Reorder />
              </ListItemIcon>
              <MenuSpan>주문 정보</MenuSpan>
            </ListItem>
          )}
          {GROUPORDERS?.search && (
            <ListItem component={Link} to="/group-orders" button divider>
              <ListItemIcon>
                <PostAdd />
              </ListItemIcon>
              <MenuSpan>단체 주문</MenuSpan>
            </ListItem>
          )}
          {BUNDLE?.search && (
            <ListItem component={Link} to="/bundle" button divider>
              <ListItemIcon>
                <EditLocation />
              </ListItemIcon>
              <MenuSpan>법정동 단위 묶음배송</MenuSpan>
            </ListItem>
          )}
          {SQUAREBUNDLE?.search && (
            <ListItem component={Link} to="/square-bundle" button divider>
              <ListItemIcon>
                <ViewModule />
              </ListItemIcon>
              <MenuSpan>Square 단위 묶음배송</MenuSpan>
            </ListItem>
          )}
          {NETWORK?.search && (
            <>
              <ListItem button divider onClick={onNetworkClick}>
                <ListItemIcon>
                  <DirectionsBus />
                </ListItemIcon>
                <MenuSpan>터미널 연계배송</MenuSpan>
                {openNetwork ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openNetwork} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <InnerListItem component={Link} to="/network" button divider>
                    <ListItemIcon>
                      <PlaylistAddCheck />
                    </ListItemIcon>
                    <MenuSpan>지역 등록</MenuSpan>
                  </InnerListItem>
                </List>
              </Collapse>
            </>
          )}
          {CORPORATIONS?.search && (
            <>
              <ListItem button divider onClick={onCorpClick}>
                <ListItemIcon>
                  <Domain />
                </ListItemIcon>
                <MenuSpan>법인 관리</MenuSpan>
                {openCorp ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openCorp} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <InnerListItem
                    component={Link}
                    to="/corporations"
                    button
                    divider
                  >
                    <ListItemIcon>
                      <PlaylistAddCheck />
                    </ListItemIcon>
                    <MenuSpan>법인 관리</MenuSpan>
                  </InnerListItem>
                  {CORPORATIONS_DEPARTMENTS?.search && (
                    <InnerListItem
                      component={Link}
                      to="/departments"
                      button
                      divider
                    >
                      <ListItemIcon>
                        <PlaylistAddCheck />
                      </ListItemIcon>
                      <MenuSpan>부서 관리</MenuSpan>
                    </InnerListItem>
                  )}
                  {CORPORATIONS_CHARGE?.search && (
                    <InnerListItem
                      component={Link}
                      to="/coporate-charge"
                      button
                      divider
                    >
                      <ListItemIcon>
                        <PlaylistAddCheck />
                      </ListItemIcon>
                      <MenuSpan>거래처요금</MenuSpan>
                    </InnerListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}
          {USERS?.search && (
            <>
              <ListItem button divider key={"users"} onClick={onUserClick}>
                <ListItemIcon>
                  <People />
                </ListItemIcon>
                <MenuSpan>회원 관리</MenuSpan>
                {openUser ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openUser} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <InnerListItem component={Link} to="/users" button divider>
                    <ListItemIcon>
                      <PlaylistAddCheck />
                    </ListItemIcon>
                    <MenuSpan>회원 관리</MenuSpan>
                  </InnerListItem>
                  {USERS_AUTH?.search && (
                    <InnerListItem
                      component={Link}
                      to="/users/auth"
                      button
                      divider
                    >
                      <ListItemIcon>
                        <PlaylistAddCheck />
                      </ListItemIcon>
                      <MenuSpan>회원 권한 관리</MenuSpan>
                    </InnerListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}
          {SCHEDULER?.search && (
            <ListItem component={Link} to="/scheduler" button divider>
              <ListItemIcon>
                <Schedule />
              </ListItemIcon>
              <MenuSpan>스케줄러 설정</MenuSpan>
            </ListItem>
          )}
          {SENDBILL?.search && (
            <ListItem component={Link} to="/send-bill" button divider>
              <ListItemIcon>
                <Mail />
              </ListItemIcon>
              <MenuSpan>청구서 발송</MenuSpan>
            </ListItem>
          )}
          {ADDRESSDETAIL?.search && (
            <ListItem component={Link} to="/addressdetail" button divider>
              <ListItemIcon>
                <LibraryAdd />
              </ListItemIcon>
              <MenuSpan>상세 주소 등록</MenuSpan>
            </ListItem>
          )}
          {LARGECHARGE?.search && (
            <ListItem component={Link} to="/large-charge" button divider>
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>
              <MenuSpan>대량 요금 조회</MenuSpan>
            </ListItem>
          )}
          {INQUIRY?.search && (
            <ListItem component={Link} to="/inquiry" button divider>
              <ListItemIcon>
                <ContactMail />
              </ListItemIcon>
              <MenuSpan>법인 신청 조회</MenuSpan>
            </ListItem>
          )}
          {RIDERREVIEW?.search && (
            <ListItem component={Link} to="/rider-review" button divider>
              <ListItemIcon>
                <SportsMotorsports />
              </ListItemIcon>
              <MenuSpan>기사 평점 조회</MenuSpan>
            </ListItem>
          )}
          {LOGS?.search && (
            <ListItem component={Link} to="/logs" button divider>
              <ListItemIcon>
                <MenuBook />
              </ListItemIcon>
              <MenuSpan>로그 기록 조회</MenuSpan>
            </ListItem>
          )}
          {SETTINGS?.search && (
            <ListItem component={Link} to="/settings" button divider>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <MenuSpan>환경 설정</MenuSpan>
            </ListItem>
          )}
        </List>
      </Drawer>
    </>
  );
}

export default Header;

const HeaderBlock = styled.div`
  position: fixed;
  width: 100%;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  z-index: 99;
`;

const Wrapper = styled.div`
  height: 4rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between; /* 자식 엘리먼트 사이에 여백을 최대로 설정 */
  .logo {
    font-size: 1.125rem;
    font-weight: 800;
    letter-spacing: 2px;
  }
  .right {
    display: flex;
    align-items: center;
  }
`;

const Spacer = styled.div`
  height: 4rem;
`;

const TimeInfo = styled.div`
  margin-right: 1rem;
  span {
    font-weight: 800;
  }
`;

const UserInfo = styled.div`
  font-weight: 800;
  margin-right: 1rem;
`;

const DrawerIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  justify-content: flex-end;
`;

const MenuSpan = styled.span`
  width: 10rem;
`;

const InnerListItem = styled(ListItem)`
  padding-left: 3rem !important;
` as typeof ListItem;
