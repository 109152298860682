import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Snackbar } from "@material-ui/core";

import UserAuthList from "../../components/users/UserAuthList";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { DEFAULT_AUTH_USER, AdminUserType, AuthUserDetailType } from "./types";
import { destructResponse } from "../../lib/hasura/common";
import { getAdminUsers } from "../../lib/hasura/users";

function UsersAuthContainer() {
  const dispatch = useDispatch();

  const [users, setUsers] = useState<AuthUserDetailType[]>([DEFAULT_AUTH_USER]);
  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  const fetchData = async () => {
    dispatch(startGlobalLoading());
    try {
      const authUsers = await destructResponse<AdminUserType[]>(
        "users_adminuser",
        () => getAdminUsers()
      );

      const usersInfo =
        authUsers.map(
          ({
            auth_level,
            id,
            ip_address,
            is_external_access,
            otp_key,
            user_id,
            users_user,
          }: AdminUserType) => {
            return {
              auth_level,
              id,
              ip_address,
              is_external_access,
              otp_key,
              user_id,
              corporation_id: users_user?.corporations_corporation?.id || "",
              corporation_name:
                users_user?.corporations_corporation?.name || "",
              department_id: users_user?.corporations_department?.id || "",
              department_name: users_user?.corporations_department?.name || "",
              fullname: users_user?.fullname || "",
              username: users_user?.username || "",
            };
          }
        ) || [];
      setUsers(usersInfo);
    } catch (err) {
      console.log(err);
      openSnackbar(
        "권한 정보를 받아올 수 없습니다. 개발팀에 문의해주세요.",
        true
      );
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // mounted
  useEffect(() => {
    fetchData();
  }, []);

  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <Title>회원 권한 관리</Title>
      <UserAuthList users={users} />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default UsersAuthContainer;

const Title = styled.h1`
  margin: 1.3rem 0;
  text-align: center;
`;
