import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { Button, MenuItem, Snackbar } from "@material-ui/core";
import AsyncAutocomplete from "../../components/common/AsyncAutocomplete";
import { FlexCenterSpaceBetween } from "../../components/common/Base";
import Detail from "../../components/common/Detail";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";
import {
  coord2regioncode,
  getLocationL1,
  getLocationL2,
  localAddress,
  localKeyword,
  LocationL1,
  LocationL2,
} from "../../lib/api/base";
import { destructResponse } from "../../lib/hasura/common";
import { createNetworkTerminal } from "../../lib/hasura/network";
import { AddressType } from "../singleCorpCharge/types";
import { finishGlobalLoading, startGlobalLoading } from "../../modules/loading";

const fetchAddresses = async (searchText: string) => {
  const results = await Promise.allSettled([
    localAddress(searchText, 5),
    localKeyword(searchText, 5),
  ]);
  return results.reduce<AddressType[]>((acc, result) => {
    if (result.status === "fulfilled") acc.push(...result.value);
    return acc;
  }, []);
};

type Info = {
  city: string;
  towns: string[];
  type: string;
  terminal: AddressType | null;
  name: string;
};

type NetworkAddCardContentProps = {
  info: Info;
  setInfo: React.Dispatch<React.SetStateAction<Info>>;
};

function NetworkAddCardContent({ info, setInfo }: NetworkAddCardContentProps) {
  const [locationL1, setLocationL1] = useState<LocationL1[]>([]);
  const [locationL2, setLocationL2] = useState<LocationL2[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const results = await getLocationL1();
      setLocationL1(results);
    };
    fetch();
  }, []);

  const fetchLocationL2 = async (l1Id: string) => {
    const results = await getLocationL2(l1Id);
    const sorted = results.sort((a, b) => a.name.localeCompare(b.name)) || [];
    setLocationL2(sorted);
  };

  return (
    <CardContent component={Grid} container columns={8} spacing={2}>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="l1-select-label">시/도</InputLabel>
          <Select
            labelId="l1-select-label"
            id="l1-select"
            value={info.city}
            label="시/도"
            onChange={(e) => {
              setInfo((info) => ({ ...info, city: e.target.value, towns: [] }));
              fetchLocationL2(e.target.value);
            }}
          >
            {locationL1.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="l2-select-label">시/군/구</InputLabel>
          <Select
            labelId="l2-select-label"
            id="l2-select"
            multiple
            disabled={!info.city}
            value={info.towns}
            label="시/군/구"
            onChange={(e) => {
              const value = e.target.value;
              const towns =
                typeof value === "string" ? value.split(",") : value;
              setInfo((info) => ({ ...info, towns }));
            }}
          >
            {locationL2.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {!info.city
              ? "시/도를 먼저 선택해주세요."
              : "여러 개의 시/군/구를 선택할 수 있습니다."}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <FormControl fullWidth>
          <AsyncAutocomplete
            id="terminal-address-search"
            label="터미널 주소 검색"
            getOptions={fetchAddresses}
            getOptionLabel={(option) => {
              const addr = [];
              if (option.address) addr.push(option.address);
              if (option.road_address_name) addr.push(option.road_address_name);

              const name = option.place_name ?? "";
              if (addr.length === 0) return name;
              return `${name} (${addr.join(", ")})`;
            }}
            filterOptions={(options) => options}
            noOptionsText="검색 결과가 없습니다."
            value={info.terminal}
            onChange={(_, option) => {
              setInfo((info) => ({
                ...info,
                terminal: option,
                name: option?.place_name || info.name,
              }));
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <FormControl fullWidth>
          <TextField
            id="name"
            label="이름"
            variant="outlined"
            value={info.name}
            onChange={(e) => {
              e.persist();
              setInfo((info) => ({ ...info, name: e.target?.value }));
            }}
          />
        </FormControl>
      </Grid>
    </CardContent>
  );
}

const InitialInfo: Info = {
  city: "",
  towns: [],
  type: "",
  terminal: null,
  name: "",
};
function NetworkAddContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [type, setType] = useState<string>("");
  const [sender, setSender] = useState<Info>({ ...InitialInfo });
  const [receiver, setReceiver] = useState<Info>({ ...InitialInfo });

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();
  const handleClose = (_: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnackbar();
    if (!error) history.goBack();
  };

  const save = async () => {
    if (!type) return openSnackbar("연계 구분을 선택해주세요.", true);
    if (!sender.city || !receiver.city)
      return openSnackbar("시/도 항목을 선택해주세요.", true);
    if (sender.towns.length === 0 || receiver.towns.length === 0)
      return openSnackbar("시/군/구 항목을 선택해주세요.", true);
    if (!sender.terminal || !receiver.terminal)
      return openSnackbar("터미널 주소를 선택해주세요.", true);
    if (!sender.name || !receiver.name)
      return openSnackbar("이름을 입력해주세요.", true);

    try {
      dispatch(startGlobalLoading());
      const promises = await generateCreatePromises();
      const results = await Promise.allSettled(promises);
      if (results.some((result) => result.status === "rejected"))
        throw new Error("저장에 실패한 데이터가 있습니다.");

      openSnackbar("저장되었습니다.", false);
    } catch (e) {
      if (e instanceof Error) openSnackbar(e.message, true);
      else openSnackbar("저장에 실패했습니다.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }

    async function generateCreatePromises() {
      if (!sender.terminal || !receiver.terminal)
        throw new Error("터미널 주소를 선택해주세요.");

      const senderBcodeId = await getBcodeId(sender.terminal);
      const receiverBcodeId = await getBcodeId(receiver.terminal);

      type CreateReturnType = Promise<{ id: number }>;
      return sender.towns.reduce<CreateReturnType[]>((acc, senderTown) => {
        const cur = receiver.towns.map<CreateReturnType>((receiverTown) =>
          destructResponse("insert_algoquick_network", () =>
            createNetworkTerminal({
              network_type: type,
              sender_l1_code_id: sender.city,
              sender_l2_code_id: senderTown,
              sender_network_name: sender.name,
              sender_network_bcode_id: senderBcodeId,
              receiver_l1_code_id: receiver.city,
              receiver_l2_code_id: receiverTown,
              receiver_network_name: receiver.name,
              receiver_network_bcode_id: receiverBcodeId,
            })
          )
        );
        return [...acc, ...cur];
      }, []);
    }

    async function getBcodeId({ longitude, latitude, b_code }: AddressType) {
      let bcode = b_code;
      if (!bcode) {
        const { b_code } = await coord2regioncode(longitude, latitude);
        bcode = b_code;
      }
      if (!bcode) throw new Error("BCODE를 찾을 수 없습니다.");
      return bcode.substring(0, 8);
    }
  };

  return (
    <>
      <Detail>
        <Card elevation={0}>
          <CardHeader title="연계 구분" />
          <CardContent>
            <FormControl fullWidth>
              <InputLabel id="type-select-label">구분</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={type}
                label="구분"
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                <MenuItem value="EXPRESSBUS">고속버스</MenuItem>
                <MenuItem value="KTX">KTX</MenuItem>
                <MenuItem value="AIR">항공</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>
        <Divider />
        <Card elevation={0}>
          <CardHeader title="출발지" />
          <NetworkAddCardContent info={sender} setInfo={setSender} />
        </Card>
        <Divider />
        <Card elevation={0}>
          <CardHeader title="도착지" />
          <NetworkAddCardContent info={receiver} setInfo={setReceiver} />
        </Card>
        <FlexCenterSpaceBetween>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => history.goBack()}
          >
            취소
          </Button>
          <Button variant="outlined" color="primary" onClick={save}>
            저장
          </Button>
        </FlexCenterSpaceBetween>
      </Detail>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default NetworkAddContainer;
