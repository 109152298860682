import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { OPEN_SENDERFORM } from "../../modules/groupOrder";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import ReceiverTable from "../../components/groupOrder/ReceiverTable";

const ButtonWrapper = styled(Button)`
  width: 33%;
  display: block !important;
  margin: 0 auto !important;
`;

function ReceiverTableContainer() {
  const dispatch = useDispatch();

  const {
    result: { getJibunAddress },
  } = useSelector(({ groupOrder }: RootStateOrAny) => groupOrder);

  const {
    success: { state, data },
  } = getJibunAddress;

  if (!state) {
    return <></>;
  }
  function onClick() {
    dispatch({ type: OPEN_SENDERFORM });
  }

  return (
    <>
      <ReceiverTable data={data} />
      <ButtonWrapper variant="contained" color="primary" onClick={onClick}>
        의뢰인 정보입력
      </ButtonWrapper>
    </>
  );
}

export default ReceiverTableContainer;
