import React from "react";
import styled from "styled-components";
import { ModalWrapper } from "../common/modal/Modal";

import { Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface modalProps {
  closeModal: () => void;
  onSubmit: (e: React.FormEvent) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clickedList: (el: any) => void;
  searchList: Array<string>;
}

function AddressModal({
  closeModal,
  onSubmit,
  onChange,
  searchList,
  clickedList,
}: modalProps) {
  return (
    <>
      <ModalWrapper>
        <ModalForm>
          <section>
            <Title className="title">키워드 검색</Title>
            <CloseButton>
              <Button onClick={closeModal}>
                <Close />
              </Button>
            </CloseButton>
            <Form onSubmit={onSubmit}>
              <input
                type="search"
                placeholder="키워드를 검색하세요."
                onChange={onChange}
              />
              <SearchList>
                <section>
                  {searchList &&
                    searchList.map((el: any) => (
                      <button
                        type="button"
                        key={el.id}
                        onClick={() => clickedList(el)}
                      >
                        <div>{el.place_name || el.address_detail}</div>
                        {el.road_address_name ? (
                          <div>{`${el.address} (${el.road_address_name})`}</div>
                        ) : (
                          <div>{el.address}</div>
                        )}
                      </button>
                    ))}
                </section>
              </SearchList>
            </Form>
          </section>
        </ModalForm>
      </ModalWrapper>
    </>
  );
}

export default AddressModal;

export const ModalForm = styled.div`
  position: relative;
  padding: 1.5rem 2rem;
  width: 33rem;
  height: auto;

  background-color: #ffffff;
  letter-spacing: 1px;
  border-radius: 20px;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);
`;

export const Title = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  margin: 1.5rem 1.5rem 0 0;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 0.5rem;
`;

//
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
  justify-content: center;

  input {
    box-sizing: border-box;
    height: 50px;
    padding: 0 9px;
    border: 1px solid #e5e5e5;
    font-size: 1rem;
    width: 100%;
  }
`;

export const SearchList = styled.div`
  display: flex;
  flex-direction: column;
  section {
    display: flex;
    flex-direction: column;
    height: 20rem;
    button {
      margin-top: 0.5rem;
      padding-top: 0.4rem;
      height: 3.6rem;
      font-size: 1.1rem;
      font-weight: 800;
      line-height: 1.3rem;
      text-align: inherit;
      border: none;
      &:hover {
        cursor: pointer;
        background-color: #4472c5;
      }
      div {
        font-size: 0.9rem;
        font-weight: 350;
      }
    }
  }
`;
