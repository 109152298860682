import React, { useState } from "react";
import styled from "styled-components";
import OrderDelayedList from "./OrdersDelayedList";

import {
  StyledOrderList,
  StyledListBorder,
  StyledListTitle,
  StyledTabTitle,
  StyledNumber,
} from "../../styles";

const overTimeCase = {
  wait: "대기시간초과",
  allocation: "예약배차시간초과",
  pickup: "픽업시간초과",
  run: "운행시간기준초과",
  etc: "기타",
};

const StyledOrdersDelayedTitle = styled(StyledListTitle)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const OrdersDelayed = ({ orderMissedProps }) => {
  const [tab, setTab] = useState({ name: "wait" });

  const switchTab = (name) => {
    setTab({ ...tab, name: name });
  };

  return (
    <>
      <StyledOrderList>
        <StyledOrdersDelayedTitle>지연 중인 주문</StyledOrdersDelayedTitle>
        <StyledOrdersDelayedTitle>
          <StyledTabTitle
            tab={true}
            isSelected={tab.name === "wait" ? true : false}
            onClick={() => switchTab("wait")}
          >
            {overTimeCase.wait}(
            <StyledNumber number={orderMissedProps.ordersDelayed.wait.length}>
              {orderMissedProps.ordersDelayed.wait.length}
            </StyledNumber>
            건)
          </StyledTabTitle>
          <StyledTabTitle tab={false}>&nbsp;|&nbsp;</StyledTabTitle>
          <StyledTabTitle
            tab={true}
            isSelected={tab.name === "allocation" ? true : false}
            onClick={() => switchTab("allocation")}
          >
            {overTimeCase.allocation}(
            <StyledNumber
              number={orderMissedProps.ordersDelayed.allocation.length}
            >
              {orderMissedProps.ordersDelayed.allocation.length}
            </StyledNumber>
            건)
          </StyledTabTitle>
          <StyledTabTitle tab={false}>&nbsp;|&nbsp;</StyledTabTitle>
          <StyledTabTitle
            tab={true}
            isSelected={tab.name === "pickup" ? true : false}
            onClick={() => switchTab("pickup")}
          >
            {overTimeCase.pickup}(
            <StyledNumber number={orderMissedProps.ordersDelayed.pickup.length}>
              {orderMissedProps.ordersDelayed.pickup.length}
            </StyledNumber>
            건)
          </StyledTabTitle>
          <StyledTabTitle tab={false}>&nbsp;|&nbsp;</StyledTabTitle>

          <StyledTabTitle
            tab={true}
            isSelected={tab.name === "run" ? true : false}
            onClick={() => switchTab("run")}
          >
            {overTimeCase.run}(
            <StyledNumber number={orderMissedProps.ordersDelayed.run.length}>
              {orderMissedProps.ordersDelayed.run.length}
            </StyledNumber>
            건)
          </StyledTabTitle>
          <StyledTabTitle tab={false}>&nbsp;|&nbsp;</StyledTabTitle>

          <StyledTabTitle
            tab={true}
            isSelected={tab.name === "etc" ? true : false}
            onClick={() => switchTab("etc")}
          >
            {overTimeCase.etc}(
            <StyledNumber number={orderMissedProps.ordersDelayed.etc.length}>
              {orderMissedProps.ordersDelayed.etc.length}
            </StyledNumber>
            건)
          </StyledTabTitle>
        </StyledOrdersDelayedTitle>
        <StyledListBorder>
          <OrderDelayedList
            tabName={tab.name}
            orderMissedProps={orderMissedProps}
          />
        </StyledListBorder>
      </StyledOrderList>
    </>
  );
};

export default OrdersDelayed;
