import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import useSnackbar from "../../hooks/useSnackbar";
import AddExtraChargeSelector from "../../components/corporation/AddExtraChargeSelector";
import ExtraTimeChargeTable from "../../components/corporation/ExtraTimeChargeTable";
import ExtraTimeChargeList from "../../components/corporation/ExtraTimeChargeList";
import NoExtraChargeList from "../../components/corporation/NoExtraChargeList";
import Notice from "../../components/common/Notice";
import { LOG_TYPE } from "../../lib/constants/constants";
import { postLog } from "../../lib/hasura/users";
import { destructResponse } from "../../lib/hasura/common";
import {
  getCorpTimeCharge,
  createCorpTimeCharge,
  updateCorpTimeCharge,
  deleteCorpTimeCharge,
} from "../../lib/hasura/extraCharge";
import { sleep } from "../../lib/core";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { ADD_EXTRA_CHARGE, TimeChargeType, selectorUnit } from "./extraTypes";
import {
  validateExtraCharge,
  displayNumberRange,
  extraCalculation,
} from "./utils";
import { convertVehicleTypeToKorean } from "../../pages/GroupOrder/utils";

interface TimeChargeContainerProps {
  corpId: string;
  corpName: string;
}

function CorporationTimeChargeContainer({
  corpId,
  corpName,
}: TimeChargeContainerProps) {
  const dispatch = useDispatch();
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const [createRange, setCreateRange] = useState(50000); // 전체생성시 최대 기본요금
  const [updateMaxCharge, setUpdateMaxCharge] = useState(50000); // update 가능한 최대 기본요금

  const [addTimeCharge, setAddTimeCharge] = useState(ADD_EXTRA_CHARGE); // 등록할 시간요금
  const [timeData, setTimeData] = useState<TimeChargeType[]>([]); // 시간요금 데이터
  const [timeTable, setTimeTable] = useState(0); // 0 ~ 23시

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // computed
  useEffect(() => {
    // 첫 마운트시 0시 요금
    fetchTimeData(timeTable);
  }, [corpId, timeTable]);

  const fetchTimeData = async (time: number) => {
    dispatch(startGlobalLoading());
    try {
      const response = await destructResponse<TimeChargeType[]>(
        "corporations_corporationtimecharge",
        () => getCorpTimeCharge(corpId, time)
      );
      setTimeData(response);
      setUpdateMaxCharge((response.length / 4 - 1) * 1000); // update 가능한 기본요금 최대범위 산출
    } catch (err) {
      console.log(err);
      openSnackbar("시간요금을 불러오는데 실패하였습니다.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleMaxBaseCharge = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const number = Number(value);

    setCreateRange(number);
  };

  // 전체 시간요금 생성 (생성요금은 전부 0원)
  const onAllRegist = async () => {
    const confirmMsg = `[${corpName}] 0~${createRange}원 범위의 시간요금을 생성하시겠습니까?\n초기 생성시 1분정도 소요됩니다.`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      const baseChargeRange = displayNumberRange(0, createRange); // 기본요금 단위
      const vehicleTypes = ["MOTORCYCLE", "DAMAS", "LABO", "TRUCK"]; // 차량타입별 요금

      for (let time = 0; time < 24; time++) {
        await createCorpTimeCharge(corpId, baseChargeRange, vehicleTypes, time);
        await sleep(2000);
      }
      await fetchTimeData(0);

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 시간요금 전체생성`,
        "법인 관리"
      );
    } catch (err) {
      const failMsg = (err as Error).message || "요금 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "calc_selector":
        setAddTimeCharge({
          ...addTimeCharge,
          percent_of_charge: 0,
          fixed_charge: 0,
          [name]: value,
        });
        break;
      default:
        setAddTimeCharge({ ...addTimeCharge, [name]: value });
        break;
    }
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const vehicleName = convertVehicleTypeToKorean(addTimeCharge.vehicle_type);
    const confirmMsg = `[${timeTable}시] 해당 범위의 ${vehicleName} 시간요금을 등록하시겠습니까?`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      const invalidMessage = validateExtraCharge(
        addTimeCharge,
        updateMaxCharge
      );
      if (invalidMessage) throw new Error(invalidMessage);

      const refinedData = extraCalculation(addTimeCharge, timeData);
      const promise = refinedData.map(
        async (el) => await updateCorpTimeCharge(corpId, timeTable, el)
      );

      const result = await Promise.allSettled(promise);
      await fetchTimeData(timeTable);

      const isRejected = result.some((el) => el.status === "rejected");
      if (isRejected) throw new Error("등록에 실패한 요금이 있습니다.");

      const { min_base_charge, max_base_Charge, vehicle_type } = addTimeCharge;
      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 시간요금 요금등록(${timeTable}시 / ${min_base_charge}원 ~ ${max_base_Charge}원 / ${vehicle_type}) : ${selectorUnit(
          addTimeCharge
        )}`,
        "법인 관리"
      );
      openSnackbar("요금이 등록되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "요금 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onAllDelete = async () => {
    const deleteMsg = `0~23시 전체 시간요금을 삭제하시겠습니까?\n전체 삭제시 "기존 시간요금"으로 자동 적용됩니다.`;
    if (!confirm(deleteMsg)) return;

    dispatch(startGlobalLoading());
    try {
      for (let time = 0; time < 24; time++) {
        await deleteCorpTimeCharge(corpId, time);
        await sleep(700);
      }
      await fetchTimeData(0);

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 시간요금 전체삭제`,
        "법인 관리"
      );
      openSnackbar("삭제가 완료되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "요금 삭제에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <TimeWrapper>
        <TimeHeader>
          <h1 className="title">시간요금 DB화 ({corpName})</h1>
          <div className="description">
            *시간요금은 부분삭제가 불가하며, 전체삭제시 "기존 시간요금"으로 자동
            적용됩니다.
          </div>
        </TimeHeader>
        {timeData.length > 0 ? (
          <TimeBody>
            <AddExtraChargeSelector
              maxCharge={updateMaxCharge}
              addCharge={addTimeCharge}
              onChange={onChange}
              onSubmit={onSubmit}
              onAllDelete={onAllDelete}
              isVehicleType={true}
            />
            <TimeTable>
              <ExtraTimeChargeList timeData={timeData} />
              <ExtraTimeChargeTable
                timeTable={timeTable}
                setTimeTable={setTimeTable}
              />
            </TimeTable>
          </TimeBody>
        ) : (
          <NoExtraChargeList
            type={"시간"}
            onAllRegist={onAllRegist}
            handleMaxBaseCharge={handleMaxBaseCharge}
          />
        )}
      </TimeWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default CorporationTimeChargeContainer;

const TimeWrapper = styled.section`
  margin-top: 20px;
  text-align: center;
`;

const TimeHeader = styled.header`
  padding-bottom: 1.5rem;

  .title {
    margin: 10px;
    font-size: 1.7rem;
  }
  .description {
    font-size: 18px;
    font-weight: 800;
    color: #9e9e9e;
  }
`;

const TimeBody = styled.section`
  display: flex;
  flex-direction: column;
`;

const TimeTable = styled.section`
  display: flex;
  align-content: center;
  justify-content: center;
`;
