import React, { useState, useEffect, useCallback } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";
import {
  getReviewData,
  getReviewInfo,
  updateReviewDataChecker,
} from "../../lib/hasura/orders";
import {
  ReviewDataType,
  ReviewInfoType,
} from "../../components/riderReview/ReviewListType";
import CircularLoading from "../../components/common/CircularLoading";
import ReviewDetail from "../../components/riderReview/ReviewDetail";
import { destructResponse } from "../../lib/hasura/common";

function ReviewDetailConainer({ match }: RouteComponentProps<{ id: string }>) {
  const userId = useSelector(({ user: { user } }: RootStateOrAny) => user?.id); // uuid
  const orderId: string = match.params.id;

  // loading
  const [loading, setLoading] = useState<boolean>(false);

  // reviews
  const [reviewData, setReviewData] = useState<ReviewDataType>({
    created: "",
    id: "",
    is_broken: false,
    is_delayed: false,
    is_unkind: false,
    review: "",
    rider_id: "",
    rider_score: 0,
    orders_rider: {
      id: "",
      rider_name: "",
      rider_phone_number: "",
    },
    users_user: null,
  });
  const [reviewInfo, setReviewInfo] = useState<ReviewInfoType>({
    id: "",
    client_name: "",
    client_phone_number: "",
    sender_name: "",
    sender_staff: "",
    sender_phone_number: "",
    sender_address: "",
    sender_jibun_address: "",
    sender_address_detail: "",
    receiver_name: "",
    receiver_staff: "",
    receiver_phone_number: "",
    receiver_address: "",
    receiver_jibun_address: "",
    receiver_address_detail: "",
  });

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const [reviewData] = await destructResponse<ReviewDataType[]>(
        "orders_riderreview",
        () => getReviewData(orderId)
      );
      setReviewData(reviewData);
    } catch (err) {
      console.log("orders_riderreview", err);
    }

    try {
      const [reviewInfo] = await destructResponse<ReviewInfoType[]>(
        "orders_order",
        () => getReviewInfo(orderId)
      );
      setReviewInfo(reviewInfo);
    } catch (err) {
      console.log("orders_order", err);
    }

    setLoading(false);
  }, [orderId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCheckReview = useCallback(async () => {
    if (!window.confirm("해당 기사 평점을 확인 완료 처리합니다.")) return;

    setLoading(true);
    try {
      const { users_user } = await destructResponse<ReviewDataType>(
        "update_orders_riderreview_by_pk",
        () => updateReviewDataChecker(orderId, userId)
      );
      setReviewData((reviewData) => ({
        ...reviewData,
        users_user,
      }));
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, [orderId, userId]);

  return loading ? (
    <CircularLoading />
  ) : (
    <ReviewDetail
      data={reviewData}
      info={reviewInfo}
      handleCheckReview={handleCheckReview}
    />
  );
}

export default withRouter(ReviewDetailConainer);
