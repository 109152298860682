import React from "react";
import ExcelFormContainer from "../../containers/groupOrder/ExcelFormContainer";
import ReceiverTableContainer from "../../containers/groupOrder/ReceiverTableContainer";
import ResultDialogContainer from "../../containers/groupOrder/ResultDialogContainer";

function AddressCheck() {
  return (
    <>
      <ExcelFormContainer />
      <ReceiverTableContainer />
      <ResultDialogContainer />
    </>
  );
}

export default AddressCheck;
