import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";

type CheckBoxProps = {
  extraCharge: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function CheckBox({ extraCharge, onChange }: CheckBoxProps) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={extraCharge}
          onChange={onChange}
          name="calculate-extracharge"
        />
      }
      label="추가요금계산"
    />
  );
}
