import React, { useState, useMemo, useEffect } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Snackbar } from "@material-ui/core";

import CorpChargeHeader from "./form/CorpChargeHeader";
import CorpChargeBody from "./form/CorpChargeBody";
import CorpChargeFooter from "./form/CorpChargeFooter";
import AddChargeModal from "./AddChargeModal";
import Pagination from "../common/Pagination";
import {
  SearchDataType,
  UploadDataType,
  CorpChargeByLocVeh,
} from "../../containers/singleCorpCharge/types";
import { sleep } from "../../lib/core";
import { postLog } from "../../lib/hasura/users";
import { LOG_TYPE } from "../../lib/constants/constants";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import {
  deleteNetworkSingleCorpCharge,
  deleteSingleCorpCharge,
  updateNetworkSingleCorpCharge,
  updateSingleCorpCharge,
} from "../../lib/hasura/corporations";
import useSnackbar from "../../hooks/useSnackbar";
import Notice from "../../components/common/Notice";

interface CorpChargeProps {
  search: SearchDataType;
  chargeList: CorpChargeByLocVeh[];
  corpId: string;
  corpName: string;
  open: boolean;
  thisPage: number;
  setThisPage: React.Dispatch<React.SetStateAction<number>>;
  fetchData: () => void;
  handleModal: () => void;
  closeRefresh: () => void;
}

function SingleCorpCharge({
  search,
  chargeList,
  corpId,
  corpName,
  open,
  thisPage,
  setThisPage,
  fetchData,
  handleModal,
  closeRefresh,
}: CorpChargeProps) {
  const dataPerPage = 10;
  const dispatch = useDispatch();
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const [uploadData, setUploadData] = useState<UploadDataType[]>([]);
  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // computed: 법인 재검색시 업로드 파일 초기화
  useEffect(() => {
    setUploadData([]);
  }, [search]);

  // Pagination perpage에 따른 데이터
  const currentData = (list: CorpChargeByLocVeh[]) => {
    const idxOfLast = thisPage * dataPerPage;
    const idxOfFirst = idxOfLast - dataPerPage;

    return list.slice(idxOfFirst, idxOfLast);
  };
  const tableRows = currentData(chargeList);
  const emptyRows = useMemo(() => {
    return chargeList.length !== 0
      ? dataPerPage -
          Math.min(
            dataPerPage,
            chargeList.length - (thisPage - 1) * dataPerPage
          )
      : 0;
  }, [chargeList]);

  /**
   * @description 데이터 저장버튼(updateData로 두 함수 연결)
   * @return { editCharge } el로부터 해당 열의 데이터를 모두 가져옴
   * @return { updateCharge } updateData로 el값을 넘겨받는다.
   * useState는 한 종류의 데이터일때 사용
   */
  const updateData: Record<number, number> = {};

  const editCharge = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    // 금액 천단위 조정
    const targetValue = Math.floor(Number(e.target.value) / 1000) * 1000;
    if (e.target.defaultValue !== targetValue.toString()) {
      updateData[idx] = targetValue; // 변경 요금
    } else {
      delete updateData[idx];
    }
  };

  // 요금 저장
  const updateCharge = async () => {
    if (Object.keys(updateData).length === 0)
      return openSnackbar("변경된 요금이 없습니다.", true);

    dispatch(startGlobalLoading());
    try {
      for (let index in updateData) {
        const data = tableRows[index];
        const timeModify = new Date().toISOString();
        if (data.vehicleType === "MOTORCYCLE" || data.vehicleType === "DAMAS") {
          await updateNetworkSingleCorpCharge({
            id: data.id,
            charge: updateData[index],
            modified: timeModify,
          });
        } else {
          await updateSingleCorpCharge({
            id: data.id,
            charge: updateData[index],
            modified: timeModify,
          });
        }
        await sleep(1000);
      }
      openSnackbar("해당 요금이 변경되었습니다.");
    } catch (err) {
      openSnackbar(
        `요금 변경에 실패하였습니다. 확인 후 다시 시도해주세요. ${err}`,
        true
      );
    } finally {
      fetchData();
      dispatch(finishGlobalLoading());
    }
  };

  // 요금 삭제
  const deleteCharge = async (index: number) => {
    if (!window.confirm("정말 삭제하시겠습니까?")) return;

    dispatch(startGlobalLoading());
    try {
      const data = tableRows[index];
      if (data.vehicleType === "MOTORCYCLE" || data.vehicleType === "DAMAS")
        await deleteNetworkSingleCorpCharge(data.id);
      else await deleteSingleCorpCharge(data.id);

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 거래처요금 삭제`,
        "거래처요금"
      );
      openSnackbar("해당 요금이 삭제되었습니다.");
    } catch (err) {
      openSnackbar(
        `삭제에 실패하였습니다. 확인 후 다시 시도해주세요. ${err}`,
        true
      );
    } finally {
      fetchData();
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <SubWrapper>
        {corpName && (
          <CorpChargeHeader
            corpId={corpId}
            corpName={corpName}
            chargeList={chargeList}
            uploadData={uploadData}
            setUploadData={setUploadData}
            fetchData={fetchData}
            openSnackbar={openSnackbar}
          />
        )}
        <CorpChargeBody
          emptyRows={emptyRows}
          tableRows={tableRows}
          editCharge={editCharge}
          deleteCharge={deleteCharge}
        />
        <Pagination
          postsPerPage={dataPerPage}
          totalPosts={chargeList.length}
          getPaginate={thisPage}
          setPaginate={setThisPage}
          viewData={""}
        />
        <CorpChargeFooter
          corpId={corpId}
          handleModal={handleModal}
          updateCharge={updateCharge}
        />
        {open && (
          <AddChargeModal
            corpId={corpId}
            chargeList={chargeList}
            handleModal={handleModal}
            closeRefresh={closeRefresh}
          />
        )}
      </SubWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}
export default SingleCorpCharge;

const SubWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;
