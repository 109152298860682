import { AdminUserAuthsType } from "../users/types";
import { setHipenInPhoneNumber } from "../../lib/orders/charge";
import { OrderByPkType } from "./types";

export const allocationMessage = (order: OrderByPkType) => {
  const getConsignment = order.note?.split("/")[0] || "없음";
  const getEtc = order.note?.split("/").slice(2).join("/") || "없음";

  const message = `    수신번호 : ${
    setHipenInPhoneNumber(order.rider_phone_number) || "없음"
  }

  출발지 이름 : ${order.sender_name || "없음"}
  출발지 연락처 : ${setHipenInPhoneNumber(order.sender_phone_number) || "없음"}
  출발지 주소 : ${order.sender_jibun_address} ${
    order.sender_address_detail || ""
  }

  도착지 이름 : ${order.receiver_name || "없음"}
  도착지 연락처 : ${
    setHipenInPhoneNumber(order.receiver_phone_number) || "없음"
  }
  도착지 주소 : ${order.receiver_jibun_address} ${
    order.receiver_address_detail || ""
  }
  
  배송품: ${getConsignment}
  적요: ${getEtc}
  `;
  return message;
};

export function setTruckOption(vehicle_option?: string): string {
  if (!vehicle_option) return "";
  if (vehicle_option.trim() === "") return "";

  const vehicleOptionList = vehicle_option.split("T/");

  switch (vehicleOptionList.slice(1)[0]) {
    case "CARGO":
      return "카고";
    case "CANVAS":
      return "호루";
    case "BOX":
      return "탑";
    case "WING":
      return "윙바디";
    case "CHILLER/BOX":
      return "냉장탑";
    case "CHILLER/WING":
      return "냉장윙";
    case "FREEZER/BOX":
      return "냉동탑";
    case "FREEZER/WING":
      return "냉동윙";
    case "LIFT":
      return "리프트";
    case "CARGO/LIFT":
      return "리프트";
    case "CANVAS/LIFT":
      return "리프트호";
    case "BOX/LIFT":
      return "리프트탑";
    case "WING/LIFT":
      return "리프트윙";
    case "CHILLER/BOX/LIFT":
      return "냉장탑리";
    case "CHILLER/WING/LIFT":
      return "냉장윙리";
    case "FREEZER/BOX/LIFT":
      return "냉동탑리";
    case "FREEZER/WING/LIFT":
      return "냉동윙리";
    default:
      return "전체";
  }
}

// 연락처 마스킹
export const maskingPhoneNumber = (value: string, auth: AdminUserAuthsType) => {
  if (!value) return "";

  if (auth?.privacy) return value;
  else return value.replace(value.slice(-4), "****");
};

// 주소 마스킹
export const maskingAddress = (
  value: string | null,
  auth: AdminUserAuthsType
) => {
  if (!value) return "";

  if (auth?.privacy) return value;
  else {
    const arr = value.split("");
    const changeArr = arr.map((el) => {
      if (Number(el) || el === "0" || el === "-") return "*";
      else return el;
    });
    return changeArr.join("");
  }
};

export const setCostCenterTypeText = (type: string) => {
  switch (type) {
    case "PROJECT":
      return "프로젝트";
    default:
      return "부서";
  }
};
