import graphqlQuery from "./base";
import { SchedulerType } from "../../containers/scheduler/types";

export const listScheduler = async () =>
  await graphqlQuery(`{
    orders_scheduleruser(
      order_by: {created: asc}
    ) {
      channel
      company_name
      user_id
    }
  }`);

export const insertScheduler = async (form: SchedulerType) =>
  await graphqlQuery(`
  mutation {
    insert_orders_scheduleruser(
      objects: {
        channel: "${form.channel}",
        company_name: "${form.company_name}",
        user_id: "${form.user_id}"
      }) {
        returning {
          user_id
        }
      }
    }`);

export const deleteScheduler = async (userId: string) =>
  await graphqlQuery(`
  mutation {
    delete_orders_scheduleruser(where: {user_id: {_eq: "${userId}"}}) {
      returning {
        user_id
      }
    }
  }`);
