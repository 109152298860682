import axios, { AxiosError, AxiosInstance } from "axios";

const AWS_AXIOS: { [key: string]: AxiosInstance } = [
  {
    // AWS Rest API
    key: "DELIVERY_RECEIPT",
    url: "https://iymh5tbvgl.execute-api.ap-northeast-2.amazonaws.com/anon",
  },
  {
    // AWS HTTP API
    key: "DELIVERY_RECEIPT_HTTP",
    url: "https://jbfm1jipsh.execute-api.ap-northeast-2.amazonaws.com",
  },
].reduce(
  (map, { key, url }) => Object.assign(map, { [key]: createHttp(url) }),
  {}
);

function createHttp(baseURL: string) {
  const instance = axios.create({ baseURL });
  // 참고: https://stackoverflow.com/questions/48990632/how-to-manage-axios-errors-globally-or-from-one-point
  instance.interceptors.response.use(
    ({ data }) => data,
    (error: AxiosError<{ message?: string }>) => {
      const message = error?.response?.data?.message;
      if (message !== undefined) throw new Error(message);
      throw error;
    }
  );
  return instance;
}

export const uploadDeliveryReceipts = (id: string, files: File[]) => {
  const payload = new FormData();

  // FormData에 id 값이 다른 여러 개의 데이터를 append하면 안 된다.
  // AWS Lambda 함수에서 FormData를 파싱하는 라이브러리가 여러 값의 id 파싱을 지원하지 않기 때문이다.
  // 참고: https://github.com/freesoftwarefactory/parse-multipart#data-fields
  files.forEach((file) => payload.append("image", file));
  return AWS_AXIOS.DELIVERY_RECEIPT.post(`/receipt/${id}`, payload);
};

export const zipDeliveryReceipts = async (id: string) => {
  const arrayBuffer: ArrayBuffer = await AWS_AXIOS.DELIVERY_RECEIPT.get(
    `/zip/${id}`,
    {
      responseType: "arraybuffer",
    }
  );
  const blob = new Blob([arrayBuffer], { type: "application/zip" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = `${id}.zip`;
  link.click();

  URL.revokeObjectURL(url);
};

export const delteDeliveryReceipt = (filename: string) =>
  AWS_AXIOS.DELIVERY_RECEIPT_HTTP.delete(`/receipt/${filename}`);

export const deleteCorpLogo = (id: string) => {
  return AWS_AXIOS.DELIVERY_RECEIPT_HTTP.post(`/corporate/logo/${id}`);
};
