import React, { useState, useEffect } from "react";
import styled from "styled-components";
import senderMakerImage from "../../static/map/sender-marker.png";
import receiverMakerImage from "../../static/map/receiver-marker.png";

const MapWrapper = styled.div`
  width: 100%;
  height: 35rem;
`;

const randomColor = () => "#" + (((1 << 24) * Math.random()) | 0).toString(16);

const Map = ({ senderAddress, receiverAddressList, resultAddressList }) => {
  const appkey = process.env.REACT_APP_KAKAO_MAP_APP_KEY;
  const [mapContainer, setMapContainer] = useState(null);
  const pathCount = resultAddressList.length;

  function toObject(arr) {
    let rv = {};
    for (let i = 0; i < arr.length; ++i) rv[arr[i]] = "";
    return rv;
  }

  useEffect(() => {
    const selectMap = document.getElementById("map");
    setMapContainer(selectMap);
  }, []);

  useEffect(() => {
    const renderMap = () => {
      let senderPosition;
      const map = new window.daum.maps.Map(mapContainer, {
        center: new window.daum.maps.LatLng(37.5061305556, 127.0416805556),
        level: 12,
      });

      let path = [];
      let pathObject = {};
      function makePath() {
        if (pathCount > 1) {
          for (let index = 0; index < resultAddressList.length; index++) {
            const element = resultAddressList[index];

            // if (index === pathCount - 1)
            path.push(senderPosition);

            for (
              let innerIndex = 0;
              innerIndex < element.length;
              innerIndex++
            ) {
              const innerElement = element[innerIndex];
              path.push(pathObject[innerElement]);
            }
            new window.daum.maps.Polyline({
              map,
              path,
              strokeColor: randomColor(),
              strokeOpacity: 1,
              endArrow: true,
              strokeWeight: 5,
            });
            path = [];
            // path.push(new window.daum.maps.LatLng(0, 0));
          }
        } else {
          path.push(senderPosition);
          Object.values(pathObject).map((el) => path.push(el));
          new window.daum.maps.Polyline({
            map,
            path,
            strokeColor: randomColor(),
            strokeOpacity: 1,
            endArrow: true,
            strokeWeight: 5,
          });
        }

        new window.daum.maps.Polyline({
          map,
          path,
          strokeColor: randomColor(),
          strokeOpacity: 1,
          endArrow: true,
          strokeWeight: 5,
        });
      }

      const zoomControl = new window.daum.maps.ZoomControl();
      map.addControl(zoomControl, window.daum.maps.ControlPosition.RIGHT);
      const bounds = new window.daum.maps.LatLngBounds();
      const geocoder = new window.daum.maps.services.Geocoder();

      if (senderAddress) {
        // https://apis.map.kakao.com/web/sample/addr2coord/
        geocoder.addressSearch(senderAddress, function (results, status) {
          senderPosition = new window.daum.maps.LatLng(
            results[0].y,
            results[0].x
          );
          // path.push(senderPosition);
          bounds.extend(senderPosition);
          map.setBounds(bounds);
          new window.daum.maps.Marker({
            map: map,
            position: senderPosition,
            image: new window.daum.maps.MarkerImage(
              senderMakerImage,
              new window.daum.maps.Size(100, 90)
            ),
          });
        });
      }

      if (receiverAddressList.length > 0) {
        receiverAddressList.map((receiverAddress) =>
          geocoder.addressSearch(
            receiverAddress.receiverAddress,
            function (results, status) {
              const receiverPosition = new window.daum.maps.LatLng(
                results[0].y,
                results[0].x
              );
              bounds.extend(receiverPosition);
              map.setBounds(bounds);
              new window.daum.maps.Marker({
                map: map,
                position: receiverPosition,
                image: new window.daum.maps.MarkerImage(
                  receiverMakerImage,
                  new window.daum.maps.Size(100, 90)
                ),
              });
            }
          )
        );
      }

      if (resultAddressList.length > 0) {
        let popResultAddressList = [];
        // 서버로 부터 받은 성공한 결과 주소 list
        // popResultAddressList 에 성공한 결과 주소 list element 를 집어 넣음
        resultAddressList.forEach((element) => {
          if (typeof element === "object") {
            // element 가 배열일때 Array.isArray 로 변경할 것
            popResultAddressList = popResultAddressList.concat(...element);
          } else {
            popResultAddressList = popResultAddressList.concat(element);
          }
        });
        // popResultAddressList 의 element 를 key 로 하는 hashtable ( = pathObject ) 을 생성
        pathObject = toObject(popResultAddressList);
        const total = popResultAddressList.length;
        let counter = 0;

        popResultAddressList.map((address) =>
          geocoder.addressSearch(address, function (result, status) {
            const receiverPosition = new window.daum.maps.LatLng(
              result[0].y,
              result[0].x
            );
            // popResultAddressList 에 있는 주소들로 검색해서 좌표를 받아옴
            // 주소 key 로 초기화된 pathObject 에 다음에서 받아온 좌표를 할당함
            // popResultAddressList 안에 있는 전체 주소들에 대해서 좌표를 할당한 이후 경로를 그린다.

            pathObject[result[0].address_name] = receiverPosition;
            counter++;
            if (total === counter) {
              // 모든 비동기 콜백이 수행되었다면
              makePath(); // 다음 로직으로 넘어갑니다.
            }
          })
        );
      }
    };

    // if (window.daum) {
    //   const scripts = document.getElementsByTagName("script");
    //   for (let i = 0; i < scripts.length; i++) {
    //     if (scripts[i].src.indexOf(appkey) > -1) {
    //       document.body.removeChild(scripts[i]);
    //     }
    //   }
    // }

    if (mapContainer) {
      const daumMapScript = document.createElement("script");
      daumMapScript.src = `https://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=${appkey}&libraries=services`;
      document.body.appendChild(daumMapScript);
      daumMapScript.onload = () => window.daum.maps.load(renderMap);
    }
  }, [
    appkey,
    mapContainer,
    pathCount,
    receiverAddressList,
    resultAddressList,
    senderAddress,
  ]);

  return <MapWrapper id="map"></MapWrapper>;
};

export default Map;
