import styled from "styled-components";
import { Button } from "@material-ui/core";
import { ChargeOpen } from "../../containers/corporation/CorporationExtraChargeContainer";

interface ManageCorporateExtraChargeProps {
  handleChargeButton: (params: Partial<ChargeOpen>) => void;
}

function ManageCorporateExtraCharge({
  handleChargeButton,
}: ManageCorporateExtraChargeProps) {
  return (
    <ButtonBox>
      <ContainedPrimaryButton
        variant="contained"
        color="primary"
        size="large"
        onClick={() => handleChargeButton({ expressOpen: true })}
      >
        급송 요금
      </ContainedPrimaryButton>
      <ContainedPrimaryButton
        variant="contained"
        color="primary"
        size="large"
        onClick={() => handleChargeButton({ timeOpen: true })}
      >
        시간 요금
      </ContainedPrimaryButton>
      <ContainedPrimaryButton
        variant="contained"
        color="primary"
        size="large"
        onClick={() => handleChargeButton({ loadingOpen: true })}
      >
        상하차 요금 (다마스/라보)
      </ContainedPrimaryButton>
      <ContainedPrimaryButton
        variant="contained"
        color="primary"
        size="large"
        onClick={() => handleChargeButton({ truckLoadingOpen: true })}
      >
        상하차 요금 (트럭)
      </ContainedPrimaryButton>
      <ContainedPrimaryButton
        variant="contained"
        color="primary"
        size="large"
        onClick={() => handleChargeButton({ roundTripOpen: true })}
      >
        왕복 요금
      </ContainedPrimaryButton>
      <ContainedPrimaryButton
        variant="contained"
        color="primary"
        size="large"
        onClick={() => handleChargeButton({ bulkOpen: true })}
      >
        과적 요금
      </ContainedPrimaryButton>
      <ContainedPrimaryButton
        variant="contained"
        color="primary"
        size="large"
        onClick={() => handleChargeButton({ exceptOpen: true })}
      >
        제외 휴일
      </ContainedPrimaryButton>
      <ContainedPrimaryButton
        variant="contained"
        color="primary"
        size="large"
        onClick={() => handleChargeButton({ weatherOpen: true })}
      >
        날씨 요금
      </ContainedPrimaryButton>
      <ContainedPrimaryButton
        variant="contained"
        color="primary"
        size="large"
        onClick={() => handleChargeButton({ holidayOpen: true })}
      >
        휴일/명절 요금
      </ContainedPrimaryButton>
    </ButtonBox>
  );
}

export default ManageCorporateExtraCharge;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ContainedPrimaryButton = styled(Button)`
  margin: 0 2rem 1rem 0 !important;
  border-radius: 20px !important;
  background-color: RGB(63, 81, 181, 0.8) !important;
`;
