// 배송요금(기본요금 + 추가요금)
export const locationExtraCharge = (
  locationCharge: number,
  extraCharge: number
) => {
  return locationCharge + extraCharge;
};

// 조정금액
export const calcTotalChargeDiscountCharge = (
  discountCharge: number,
  corporateCreditAmt?: number | null
): number => {
  return -discountCharge - (corporateCreditAmt || 0);
};

// 최종금액
export const totalCharge = (
  paymentCharge: number,
  vat: number,
  corporateCreditAmt?: number | null,
  insuranceCharge = 0
): number => {
  return paymentCharge - (corporateCreditAmt || 0) + insuranceCharge + vat;
};

export const beforeVehicleOption = (vehicleOption: string) => {
  if (!vehicleOption) return "";
  const replaceOption = vehicleOption.replace("_", ".").replace("T", "톤");
  return replaceOption.split("/");
};

export const chargeFilter = (charge: number) => {
  return charge?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || charge;
};

export const vehicleFilter = (vehicleType: string, riderName: string) => {
  if (riderName === "터미널탁송") return "탁송";

  switch (vehicleType) {
    case "MOTORCYCLE":
      return "오토바이";
    case "DAMAS":
      return "다마스";
    case "LABO":
      return "라보";
    case "TRUCK":
      return "트럭";
  }
};

export const vehicleOptionFilter = (
  vehicleOption: string | null,
  riderName?: string
) => {
  if (!vehicleOption) return "";
  if (riderName === "터미널탁송") return "탁송";

  let result = "";
  let checkLift = false;
  let optionList = beforeVehicleOption(vehicleOption);

  // LIFT CHECK
  const indexLift = optionList.indexOf("LIFT");

  if (indexLift > -1) {
    optionList = optionList.slice(0, indexLift);
    checkLift = true;
  }

  for (let index = 0; index < optionList.length; index++) {
    let option = "";
    const value = optionList[index];
    switch (value) {
      case "EXPRESSBUS":
        option = "고속버스 탁송";
        break;
      case "KTX":
        option = "KTX 탁송";
        break;
      case "BOX":
        option = "탑차";
        break;
      case "WING":
        option = "윙바디";
        break;
      case "CANVAS":
        option = "호루";
        break;
      case "CHILLER":
        option = "냉장";
        break;
      case "FREEZER":
        option = "냉동";
        break;
      case "INTERCITY":
        option = "시외버스 탁송";
        break;
      case "AIR":
        option = "항공 탁송";
        break;
      case "CARGO":
        option = "카고";
        break;
      default:
        option = value;
    }

    if (index !== 0) {
      if (result.endsWith("냉장") || result.endsWith("냉동")) {
        result += option;
      } else {
        result += " " + option;
      }
    } else {
      result = option;
    }
  }

  if (result.endsWith("톤")) {
    result += " 트럭";
  }

  if (checkLift) {
    result += "(리프트)";
  }

  return result;
};

export const vehicleByRiderFilter = (data: string, riderName: string | null) =>
  riderName === "터미널탁송" ? "탁송" : data;

export const setHipenInPhoneNumber = (phoneNumber: string | null) =>
  phoneNumber
    ? phoneNumber.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
        "$1-$2-$3"
      )
    : "";

export const distanceFilter = (value: number) => {
  if (!value) return 0;
  // convert meter to kilometer
  return Math.floor(value / 10) / 100;
};
