import { useEffect, useMemo, useState } from "react";
import { debounce, TextField } from "@mui/material";
import { Autocomplete } from "@material-ui/lab";

interface AsyncAutocompleteProps<T>
  extends Omit<
    React.ComponentPropsWithoutRef<typeof Autocomplete<T>>,
    "options" | "renderInput"
  > {
  label: string;
  value: T | null | undefined;
  getOptions: (searchText: string) => Promise<T[]>;
}
function AsyncAutocomplete<T>({
  label,
  value,
  getOptions,
  ...props
}: AsyncAutocompleteProps<T>) {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState<T[]>([]);

  const fetch = useMemo(
    () =>
      debounce(async (searchText: string, callback: (options: T[]) => void) => {
        try {
          const options = await getOptions(searchText);
          callback(options);
        } catch {
          callback([]);
        }
      }, 300),
    [getOptions]
  );

  useEffect(() => {
    let active = true;

    if (!open) {
      setOptions(value ? [value] : []);
      return;
    }
    if (searchText === "") {
      setOptions(value ? [value] : []);
      return;
    }

    fetch(searchText, (results) => {
      if (active) {
        setOptions(value ? [value, ...results] : results);
      }
    });

    return () => {
      active = false;
    };
  }, [open, searchText, fetch]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      options={options}
      renderInput={(params) => <TextField {...params} label={label} />}
      onInputChange={(_, newSearchText) => {
        setSearchText(newSearchText);
      }}
      {...props}
    />
  );
}

export default AsyncAutocomplete;
