import React from "react";

import ResultDialog from "../../components/sendBill/ResultDialog";

type result = {
  success: boolean;
  errorMessage: string;
};

type DialogContainer = {
  result: result;
  resetResult: () => void;
};

export default function DialogContainer({
  result,
  resetResult,
}: DialogContainer) {
  const { success, errorMessage } = result;

  if (success) {
    return (
      <ResultDialog
        result={success}
        title={"🎉 청구서 메일전송 성공 🎉"}
        text={`청구서 자동발송에 성공했습니다.`}
      />
    );
  }
  if (errorMessage) {
    return (
      <ResultDialog
        result={errorMessage !== ""}
        title={"🚨 청구서 메일전송 실패 🚨"}
        text={`개발팀에 문의바랍니다. (${errorMessage})`}
        onClose={resetResult}
      />
    );
  }

  return <></>;
}
