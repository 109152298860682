import { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import ManageCorporateExtraCharge from "../../components/corporation/ManageCorporateExtraCharge";
import CorporationExpressChargeContainer from "./CorporationExpressChargeContainer";
import CorporationTimeChargeContainer from "./CorporationTimeChargeContainer";
import CorporationLoadingChargeContainer from "./CorporationLoadingChargeContainer";
import CorporationRoundTripChargeContainer from "./CorporationRoundTripChargeContainer";
import Detail from "../../components/common/Detail";
import { destructResponse } from "../../lib/hasura/common";
import { getCorporationName } from "../../lib/hasura/extraCharge";
import CorporationTruckChargeContainer from "./CorporationTruckChargeContainer";
import CorporationBulkChargeContainer from "./CorporationBulkChargeContainer";
import CorporationExceptHolidayContainer from "./CorporationExceptHolidayContainer";
import CorporationWeatherChargeContainer from "./CorporationWeatherChargeContainer";
import CorporationHolidayChargeContainer from "./CorporationHolidayChargeContainer";

const defaultChargeOpen = {
  expressOpen: false,
  timeOpen: false,
  loadingOpen: false,
  truckLoadingOpen: false,
  roundTripOpen: false,
  bulkOpen: false,
  exceptOpen: false,
  weatherOpen: false,
  holidayOpen: false,
};
export type ChargeOpen = typeof defaultChargeOpen;
type CorporationNameType = {
  name: string;
};

function CorporationExtraChargeContainer({
  match,
}: RouteComponentProps<{ id: string }>) {
  const corpId = match.params.id;
  const [corpName, setCorpName] = useState("");
  const [
    {
      expressOpen, // 급송 요금
      timeOpen, // 시간 요금
      loadingOpen, // 상하차 요금(다마스/라보)
      truckLoadingOpen, // 상하차 요금(트럭)
      roundTripOpen, // 왕복 요금
      bulkOpen, // 과적 요금
      exceptOpen, // 제외 휴일
      weatherOpen, // 날씨 요금
      holidayOpen, // 휴일 요금
    },
    setChargeOpen,
  ] = useState(defaultChargeOpen);

  useEffect(() => {
    fetchCorpName();
    handleChargeButton({ expressOpen: true });
  }, []);

  const fetchCorpName = async () => {
    const response = await destructResponse<CorporationNameType[]>(
      "corporations_corporation",
      () => getCorporationName(corpId)
    );
    setCorpName(response[0]?.name || "");
  };

  const handleChargeButton = (chargeOpen: Partial<ChargeOpen>) => {
    setChargeOpen({ ...defaultChargeOpen, ...chargeOpen });
  };

  return (
    <ExchargeWrapper>
      <Detail>
        <ManageCorporateExtraCharge handleChargeButton={handleChargeButton} />
        {expressOpen && (
          <CorporationExpressChargeContainer
            corpId={corpId}
            corpName={corpName}
          />
        )}
        {timeOpen && (
          <CorporationTimeChargeContainer corpId={corpId} corpName={corpName} />
        )}
        {loadingOpen && (
          <CorporationLoadingChargeContainer
            corpId={corpId}
            corpName={corpName}
          />
        )}
        {truckLoadingOpen && (
          <CorporationTruckChargeContainer
            corpId={corpId}
            corpName={corpName}
          />
        )}
        {roundTripOpen && (
          <CorporationRoundTripChargeContainer
            corpId={corpId}
            corpName={corpName}
          />
        )}
        {bulkOpen && (
          <CorporationBulkChargeContainer corpId={corpId} corpName={corpName} />
        )}
        {exceptOpen && (
          <CorporationExceptHolidayContainer
            corpId={corpId}
            corpName={corpName}
          />
        )}
        {weatherOpen && (
          <CorporationWeatherChargeContainer
            corpId={corpId}
            corpName={corpName}
          />
        )}
        {holidayOpen && (
          <CorporationHolidayChargeContainer
            corpId={corpId}
            corpName={corpName}
          />
        )}
      </Detail>
    </ExchargeWrapper>
  );
}

export default withRouter(CorporationExtraChargeContainer);

const ExchargeWrapper = styled.section`
  display: flex;
  justify-content: center;
`;
