export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isEmptyObject(obj: any) {
  for (const i in obj) {
    return true;
  }
  return false;
}

export function isEmptyString(value: string) {
  return value.trim() === "";
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/* 오름차순 정렬 함수 */
export const ascending = (a: any, b: any) => {
  if (a > b) return 1;
  else if (a < b) return -1;
  else return 0;
};
export const ascendingFunc = (a: any, b: any) => {
  if (a.letter < b.letter) return -1;
  else if (a.letter > b.letter) return 1;
  else return 0;
};

/* uuid version4 generator */
export const uuidV4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : r & (0x3 | 0x8);
    return v.toString(16);
  });
};

/* 회원관리 - 회원등록 */
export const idCheck = /^[0-9a-z._-]{5,20}$/;
export const corpIdCheck = /^[0-9a-z._@-]{5,30}$/;
export const passwordCheck =
  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[`~!@#$%^&*()\-_=+[\]{}|\\;:'",<.>/?₩]).{8,16}$/;
export const emailIdCheck =
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

export const regPhone1 = /^\d{4}-?\d{4}$/; // 대표번호
export const regPhone2 = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; // 폰번호
export const regPhone3 =
  /^(070|02|031|032|033|041|042|043|044|0502|0505|0506|0507|051|052|053|054|055|061|062|063|064)-?(\d{3,4})-?(\d{4})$/; // 지역번호 + 안심번호
