import { AxiosError } from "axios";
import { http, defaultInstance } from "./auth";

export const parseError = (error: unknown) => {
  let message;
  if (error instanceof AxiosError) {
    if (error.response?.status === 401)
      message = "인증키가 유효하지 않습니다. 로그아웃 후 재접속 부탁드립니다.";
    else if (error.response && error.response?.status < 500)
      message = setErrorMessage(error);
    else message = `네트워크 오류가 발생했습니다. ${error.message}`;
  }
  return Promise.reject(message);
};
const setErrorMessage = (error: unknown) => {
  let message;
  if (error instanceof AxiosError) {
    const errors = error.response?.data;
    const keys = Object.keys(errors);
    const value = errors[keys[0]];

    if (!(typeof value === "string" || value instanceof String)) {
      // employeeNumber: ["이 필드는 필수 항목입니다."]
      message = keys[0] + ": " + value[0];
    } else {
      // detail: "사용할 수 없는 아이디입니다."
      message = errors instanceof Object ? value : errors;
    }
  }
  return message;
};

export const graphqlQuery = (query: string) => {
  return http.post("/graphql/", {
    query,
  });
};

export const localAddress = async (query: string, size?: number) => {
  return await defaultInstance
    .get("/local/address", {
      params: { query, size },
    })
    .then((response) => response.data)
    .catch((err) => console.log(err));
};

export const localKeyword = async (query: string, size?: number) => {
  return await defaultInstance
    .get("/local/keyword", {
      params: { query, size },
    })
    .then((response) => response.data)
    .catch((err) => console.log(err));
};

/**
 * @param x longitude
 * @param y latitude
 */
export const coord2regioncode = async (x: string, y: string) => {
  return await defaultInstance
    .get("/local/coord2regioncode", {
      params: { x, y },
    })
    .then((response) => response.data)
    .catch((err) => console.log(err));
};

/* 상세주소입력 DB화 */
export const getAddressDetail = async () => {
  return await http
    .get("/algoquick/address-detail")
    .catch((err) => console.log(err));
};
export const postAddressDetail = async (data: any) => {
  return await http.post("/algoquick/address-detail", data);
};
export const patchAddressDetail = async (data: any) => {
  return await http.patch(`/algoquick/address-detail/${data.id}`, data);
};
export const deleteAddressDetail = async (id: string) => {
  return await http
    .delete(`/algoquick/address-detail/${id}`)
    .catch((err) => console.log(err));
};

export type LocationL1 = {
  id: string;
  name: string;
};
export const getLocationL1 = (): Promise<LocationL1[]> => {
  return http
    .get("/charge/admin/l1")
    .then(({ data }) => data)
    .catch((err) => console.log(err));
};

export type LocationL2 = {
  id: string;
  name: string;
  area: number;
};
export const getLocationL2 = (l1: string): Promise<LocationL2[]> => {
  return http
    .get("/charge/admin/l2", { params: { l1 } })
    .then(({ data }) => data)
    .catch((err) => console.log(err));
};
