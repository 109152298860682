const entry = {
  clientName: "의뢰인 이름",
  clientPhoneNumber: "의뢰인 연락처",
  senderAddress: "출발지 주소",
  senderPhoneNumber: "출발지 연락처",
  senderName: "출발지 이름",
  receiverAddress: "도착지 주소",
  receiverPhoneNumber: "도착지 연락처",
  receiverName: "도착지 이름",
  vehicleType: "차량 타입",
  payment: "결제 방법",
  totalCharge: "총 요금",
  senderLongitude: "출발지 경도",
  senderLatitude: "출발지 위도",
  receiverLongitude: "도착지 경도",
  receiverLatitude: "도착지 위도",
};
const addressExcept = (address) => {
  if (address.startsWith("세종")) {
    const replaceAddress = address.replace("세종", "세종세종시");
    return replaceAddress;
  } else {
    return address;
  }
};
const compareOrders = (algo, isd) => {
  let result = { algoId: algo.id, isdId: isd.serialNumber, details: {} };
  const isd_departureAddress = addressExcept(isd.departureAddress);
  const isd_destinationAddress = addressExcept(isd.destinationAddress);

  // 의뢰인 이름
  const clientName = isd.customerName + isd.customerDuty;
  if (!clientName.includes(algo.clientName)) {
    result.details[entry.clientName] = [algo.clientName, clientName];
  }
  // 의뢰인 연락처
  if (!algo.clientPhoneNumber.includes(isd.customerTelNumber)) {
    result.details[entry.clientPhoneNumber] = [
      algo.clientPhoneNumber,
      isd.customerTelNumber,
    ];
  }
  // 출발지 주소
  // 세부 주소가 없는 경우
  if (
    !algo.senderAddressDetail &&
    !isd_departureAddress.includes(algo.senderJibunAddress)
  ) {
    result.details[entry.senderAddress] = [
      algo.senderJibunAddress,
      isd_departureAddress,
    ];
    // 세부 주소가 있는 경우
  } else if (
    algo.senderAddressDetail &&
    !isd_departureAddress.includes(
      algo.senderJibunAddress + algo.senderAddressDetail
    )
  ) {
    result.details[entry.senderAddress] = [
      algo.senderJibunAddress + algo.senderAddressDetail,
      isd_departureAddress,
    ];
  }
  // 출발지 연락처
  if (!algo.senderPhoneNumber.includes(isd.departureTelNumber)) {
    result.details[entry.senderPhoneNumber] = [
      algo.senderPhoneNumber,
      isd.departureTelNumber,
    ];
  }
  // 출발지 이름
  if (
    !(algo.senderName === "" && isd.departureCompanyName) &&
    !algo.senderName.includes(isd.departureCompanyName)
    // 알고퀵 "", isd '고객님' 인 경우 정상 케이스로 인식
  ) {
    result.details[entry.senderName] = [
      algo.senderName,
      isd.departureCompanyName,
    ];
  }
  // 도착지 주소
  // 세부 주소가 없는 경우
  if (
    !algo.receiverAddressDetail &&
    !isd_destinationAddress.includes(algo.receiverJibunAddress)
  ) {
    result.details[entry.receiverAddress] = [
      algo.receiverJibunAddress,
      isd_destinationAddress,
    ];
    // 세부 주소가 있는 경우
  } else if (
    algo.receiverAddressDetail &&
    !isd_destinationAddress.includes(
      algo.receiverJibunAddress + algo.receiverAddressDetail
    )
  ) {
    result.details[entry.receiverAddress] = [
      algo.receiverJibunAddress + algo.receiverAddressDetail,
      isd_destinationAddress,
    ];
  }
  // 도착지 연락처
  if (!algo.receiverPhoneNumber.includes(isd.destinationTelNumber)) {
    result.details[entry.receiverPhoneNumber] = [
      algo.receiverPhoneNumber,
      isd.destinationTelNumber,
    ];
  }
  // 도착지 이름
  if (
    !(algo.receiverName === "" && isd.destinationCompanyName) &&
    !algo.receiverName.includes(isd.destinationCompanyName)
    // 알고퀵 "", isd '고객님' 인 경우 정상 케이스로 인식
  ) {
    result.details[entry.receiverName] = [
      algo.receiverName,
      isd.destinationCompanyName,
    ];
  }
  // 차량 타입
  if (!algo.vehicleType.includes(isd.carType)) {
    result.details[entry.vehicleType] = [algo.vehicleType, isd.carType];
  }
  // 결제 방법
  if (!isd.payment.includes(algo.payment)) {
    result.details[entry.payment] = [algo.payment, isd.payment];
  }
  // 총 요금
  if (!algo.totalCharge.includes(isd.totalCost)) {
    result.details[entry.totalCharge] = [algo.totalCharge, isd.totalCost];
  }
  // 출발지 경도
  if (!algo.senderLongitude.includes(isd.startLon)) {
    result.details[entry.senderLongitude] = [
      algo.senderLongitude,
      isd.startLon,
    ];
  }
  // 출발지 위도
  if (!algo.senderLatitude.includes(isd.startLat)) {
    result.details[entry.senderLatitude] = [algo.senderLatitude, isd.startLat];
  }
  // 도착지 경도
  if (!algo.receiverLongitude.includes(isd.destLon)) {
    result.details[entry.receiverLongitude] = [
      algo.receiverLongitude,
      isd.destLon,
    ];
  }
  // 도착지 위도
  if (!algo.receiverLatitude.includes(isd.destLat)) {
    result.details[entry.receiverLatitude] = [
      algo.receiverLatitude,
      isd.destLat,
    ];
  }
  return result;
};

const createIsdOrders = (order) => {
  const obj = {};
  for (let key in order) {
    if (order[key] === null) obj[key] = "";
    if (
      // 숫자의 경우 공백, 원, () 제거
      (key === "customerTelNumber" ||
        key === "serialNumber" ||
        key === "departureTelNumber" ||
        key === "destinationTelNumber" ||
        key === "totalCost") &&
      order[key] !== null
    ) {
      obj[key] = order[key].replace(/[-,원()\s]/g, "").trim();
    } else if (
      // 이름의 경우 공백, () 제거
      (key === "customerName" ||
        key === "customerDuty" ||
        key === "startDuty" ||
        key === "destDuty") &&
      order[key] !== null
    ) {
      obj[key] = order[key].replace(/[()\s]/g, "").trim();
    } else if (
      // 주소의 경우 공백, () 제거
      (key === "departureAddress" ||
        key === "destinationAddress" ||
        key === "departureCompanyName" ||
        key === "destinationCompanyName") &&
      order[key] !== null
    ) {
      obj[key] = order[key].replace(/[()\s]/g, "").trim();
    } else if (key === "carType") {
      if (order[key] === "오토") {
        order[key] = "MOTORCYCLE";
      } else if (order[key] === "다마스") {
        order[key] = "DAMAS";
      } else if (order[key] === "라보") {
        order[key] = "LABO";
      } else if (order[key] === "트럭") {
        order[key] = "TRUCK";
      } else if (order[key] === "지하철") {
        order[key] = "SUBWAY";
      }
      obj[key] = order[key].trim();
    } else if (key === "payment") {
      if (order[key] === "카드") {
        order[key] = "CREDIT_CARD, CASH_ON_DELIVERY";
      } else if (order[key] === "신용") {
        order[key] = "CORPORATE_ACCOUNT, CUSTOMER_ACCOUNT";
      } else if (order[key] === "착불" || order[key] === "송금") {
        order[key] = "CASH_ON_DELIVERY";
      } else if (order[key] === "선불") {
        order[key] = "ADVANCED_CASH";
      }
      obj[key] = order[key].trim();
    } else if (
      (key === "payment" ||
        key === "startLon" ||
        key === "startLat" ||
        key === "destLon" ||
        key === "destLat") &&
      order[key] !== null
    ) {
      obj[key] = order[key].trim();
    }
  }
  return obj;
};

let createAlgoOrders = (order) => {
  for (let key in order) {
    if (order[key] === null) order[key] = "";
    if (
      // 이름, 연락처의 경우 공백, -, () 제거
      key === "clientName" ||
      key === "senderName" ||
      key === "receiverName" ||
      key === "clientPhoneNumber" ||
      key === "senderPhoneNumber" ||
      key === "receiverPhoneNumber"
    ) {
      order[key] = order[key].replace(/[()\s]/g, "").trim();
    } else if (
      // 주소의 경우 공백, () 제거
      key === "senderJibunAddress" ||
      key === "senderAddressDetail" ||
      key === "receiverJibunAddress" ||
      key === "receiverAddressDetail"
    ) {
      order[key] = order[key].replace(/[()\s]/g, "").trim();
    } else if (key === "totalCharge") {
      // 금액의 경우 문자열 변환
      order[key] = order[key].toString().trim();
    } else {
      order[key] = order[key].trim();
    }
  }
  return order;
};

const sortDateByCreateAt = (list) => {
  return list.sort((a, b) => {
    let numberA = parseInt(a.created.replace(/[-:\s]/g, ""));
    let numberB = parseInt(b.created.replace(/[-:\s]/g, ""));

    return numberA < numberB ? -1 : numberA > numberB ? 1 : 0;
  });
};

export { compareOrders, createIsdOrders, createAlgoOrders, sortDateByCreateAt };
