import { createAction, handleActions } from "redux-actions";
import createRequestSaga, {
  createRequestActionTypes,
} from "../lib/createRequestSaga";
import { takeLatest } from "redux-saga/effects";
import * as authAPI from "../lib/api/auth";

const [USERAUTH, USERAUTH_SUCCESS, USERAUTH_FAILURE] =
  createRequestActionTypes("userAuth/USERAUTH");
const USERIP = "userAuth/USERIP";

export const checkAuth = createAction(USERAUTH, (id) => id);
export const userIP = createAction(USERIP);

const userAuthSaga = createRequestSaga(USERAUTH, authAPI.getUserAuth);

const userIPSaga = (e) => {
  try {
    return e;
  } catch (e) {
    console.log("userIPSaga is not worked.");
  }
};

export function* adminAuthSaga() {
  yield takeLatest(USERAUTH, userAuthSaga);
  yield takeLatest(USERIP, userIPSaga);
}

const initialState = {
  ip: JSON.parse(sessionStorage.getItem("USER_IP")) || "",
  userAuth: JSON.parse(sessionStorage.getItem("admin_user_auth")) || null,
  authError: null,
};

const userAuth = handleActions(
  {
    [USERAUTH_SUCCESS]: (state, { payload: userAuth }) => {
      sessionStorage.setItem("admin_user_auth", JSON.stringify(userAuth));
      return {
        ...state,
        userAuth,
        authError: null,
      };
    },
    [USERAUTH_FAILURE]: (state, { payload: error }) => ({
      ...state,
      userAuth,
      authError: error,
    }),
    [USERIP]: (state, { payload: ip }) => {
      sessionStorage.setItem("USER_IP", JSON.stringify(ip));
      return {
        ...state,
        ip,
      };
    },
  },
  initialState
);

export default userAuth;
