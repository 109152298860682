import { AxiosResponse } from "axios";

type FuncDestructResponseType = <T>(
  key: string,
  api: () => Promise<AxiosResponse>
) => Promise<T>;

export const destructResponse: FuncDestructResponseType = async (key, api) => {
  const {
    data: {
      data: { [key]: ret },
    },
  } = await api();
  return ret;
};

export const arrayDestructResponse = async (
  keys: string[],
  api: () => Promise<AxiosResponse>
) => {
  const {
    data: { data },
  } = await api();
  return keys.map((key) => data[key]);
};
