import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import EnhancedTableHead from "../dataTable/EnhancedTableHead";

enum SORT_ORDER {
  ASC = 1,
  DESC = -1,
}

export type PaginatedTableHeadCell<T> = {
  id: keyof T;
  label: string;
  formatter?: (row: T) => string | number;
  numeric?: boolean;
};

type PaginatedTableRowAction<T> = {
  id: string;
  label: string;
  component: (row: T) => React.ReactNode;
};

interface PaginatedTableProps<T> {
  headCells: PaginatedTableHeadCell<T>[];
  rows: T[];
  rowActions?: PaginatedTableRowAction<T>[];
}

function PaginatedTable<T extends { id: string | number }>({
  headCells,
  rows,
  rowActions = [],
}: PaginatedTableProps<T>) {
  const actionHeadCells = rowActions.map((action) => ({
    id: action.id,
    label: action.label,
    hideSortIcon: true,
  }));
  const finalHeadCells = [...headCells, ...actionHeadCells];

  const [order, setOrder] = useState<SORT_ORDER>(SORT_ORDER.DESC);
  const [orderBy, setOrderBy] = useState<PaginatedTableHeadCell<T>>(
    headCells[0]
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const sortedVisibleRows = rows
    .sort((a: T, b: T) => {
      let av = (a[orderBy.id] || "") as unknown as string | number;
      let bv = (b[orderBy.id] || "") as unknown as string | number;
      if (orderBy?.formatter !== undefined) {
        av = orderBy?.formatter(a);
        bv = orderBy?.formatter(b);
      }
      return av > bv ? order : -order;
    })
    .slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  const emptyRowCnt = rowsPerPage - sortedVisibleRows.length;

  return (
    <>
      <Table>
        <EnhancedTableHead
          headRows={finalHeadCells}
          order={order > 0 ? "asc" : "desc"}
          orderBy={orderBy.id as string}
          onRequestSort={(_, key: keyof T) => {
            const newOrderBy =
              headCells.find((cell) => key === cell.id) || headCells[0];
            setOrder(key === orderBy.id ? -order : SORT_ORDER.DESC);
            setOrderBy(newOrderBy);
          }}
        />
        <TableBody>
          {sortedVisibleRows.map((row: T) => (
            <TableRow key={row.id} tabIndex={-1} hover>
              {headCells.map(({ id, numeric, formatter }) => (
                <TableCell
                  key={id as string}
                  align={numeric ? "right" : "inherit"}
                >
                  {formatter ? formatter(row) : row[id]}
                </TableCell>
              ))}
              {rowActions.map(({ id, component }) => (
                <TableCell key={id}>{component(row)}</TableCell>
              ))}
            </TableRow>
          ))}
          {emptyRowCnt > 0 && (
            <TableRow style={{ height: 49 * emptyRowCnt }}>
              <TableCell colSpan={headCells.length} tabIndex={-1} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        page={page}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={(e) => {
          setPage(0);
          setRowsPerPage(+e.target.value);
        }}
      />
    </>
  );
}

export default PaginatedTable;
