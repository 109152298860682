import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import * as XLSX from "xlsx";

import { GET_JIBUN_ADDRESS, READ_EXCEL } from "../../modules/groupOrder";
import ExcelForm from "../../components/groupOrder/ExcelForm";
import {
  GroupWrapper,
  FormWrapper,
  DescriptWrapper,
} from "../../pages/GroupOrder/style";

type descriptionType = {
  id: number;
  vehicleType: string;
  truckWeight: string;
  truckOption: string;
};

const description = [
  { id: 0, vehicleType: "오토바이", truckWeight: "", truckOption: "" },
  { id: 1, vehicleType: "다마스", truckWeight: "", truckOption: "" },
  { id: 2, vehicleType: "라보", truckWeight: "", truckOption: "" },
  { id: 3, vehicleType: "트럭", truckWeight: "1", truckOption: "" },
  { id: 4, vehicleType: "트럭", truckWeight: "1.4", truckOption: "카고" },
  { id: 5, vehicleType: "트럭", truckWeight: "2.5", truckOption: "탑차" },
  { id: 6, vehicleType: "트럭", truckWeight: "3.5", truckOption: "윙바디" },
  { id: 7, vehicleType: "트럭", truckWeight: "5", truckOption: "냉장탑차" },
  { id: 8, vehicleType: "트럭", truckWeight: "8", truckOption: "냉동윙바디" },
];

function ExcelFormContainer() {
  const dispatch = useDispatch();

  const {
    originData,
    result: { getJibunAddress },
  } = useSelector(({ groupOrder }: RootStateOrAny) => groupOrder);

  const [fileName, setFileName] = useState("");

  const { success } = getJibunAddress;

  const { state: isSucceed } = success;

  const handleFile = useCallback(
    (file: File) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const bstr = e.target && e.target.result;

        const wb = XLSX.read(bstr, { type: "array" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          defval: "",
          blankrows: false,
        });

        dispatch({ type: READ_EXCEL, payload: data });
      };
      reader.readAsArrayBuffer(file);
    },
    [dispatch]
  );

  const handleExcel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files && files[0]) {
        handleFile(files[0]);
        setFileName(files[0].name);
      }
    },
    [handleFile, setFileName]
  );

  const fetchJibunAddress = useCallback(() => {
    if (originData.length < 1) {
      alert("excel 파일을 업로드해주세요!");
      return;
    }
    const addressChange = originData.map((addressInfo: any) => {
      return {
        senderData: addressInfo.senderAddress,
        receiverData: addressInfo.receiverAddress,
      };
    });
    dispatch({ type: GET_JIBUN_ADDRESS, payload: addressChange });
  }, [originData, dispatch]);

  function sampleDownload(e: React.MouseEvent) {
    e.preventDefault();
    const link = document.createElement("a");
    link.href = `https://algoquick-email-asset.s3.ap-northeast-2.amazonaws.com/group_order_sample_220121.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (isSucceed) {
    return <></>;
  }

  return (
    <GroupWrapper>
      <h1>EXCEL 단체주문</h1>
      <DescriptWrapper>
        <header>* 차량별 입력텍스트 예시입니다. *</header>
        <GroupTable>
          <TableHead>
            <TableRow>
              <TableCell align={"center"}>차량</TableCell>
              <TableCell align={"center"}>트럭톤수</TableCell>
              <TableCell align={"center"}>트럭종류</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {description.map((el: descriptionType) => (
              <TableRow key={el.id}>
                <TableCell align={"center"}>{el.vehicleType}</TableCell>
                <TableCell align={"center"}>{el.truckWeight}</TableCell>
                <TableCell align={"center"}>{el.truckOption}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </GroupTable>
      </DescriptWrapper>
      <FormWrapper>
        <ExcelForm
          onChange={handleExcel}
          fileName={fileName}
          fetchJibunAddress={fetchJibunAddress}
          sampleDownload={sampleDownload}
        />
      </FormWrapper>
    </GroupWrapper>
  );
}

export default ExcelFormContainer;

const GroupTable = styled(Table)`
  margin-top: 0.5rem;
  border: 1px solid rgba(224, 224, 224, 1);

  thead {
    background-color: whitesmoke;
  }
`;
