import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const WhiteSpacePaper = styled(Paper)`
  border: 1px solid black;
  margin: 2rem 0 0 0;
  padding: 1rem 2rem;
  white-space: pre;
  font-weight: bold;
  overflow: auto;
`;

export const HalfInlineBlockPaper = styled(Paper)`
  width: 50%;
  display: inline-block;
  padding: 1%;
  overflow: auto;
`;

export const InlineBlockPaper = styled(Paper)`
  display: inline-block;
  padding: 1%;
  overflow: auto;
`;
