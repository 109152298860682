import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import ResultTable from "../../components/groupOrder/ResultTable";

function ResultTableContainer() {
  const {
    isResultTableOpen,
    convertedData,
    result: { postExcelOrder },
  } = useSelector(({ groupOrder }: RootStateOrAny) => groupOrder);
  if (!isResultTableOpen) return <></>;

  return <ResultTable convertedData={convertedData} result={postExcelOrder} />;
}

export default ResultTableContainer;
