import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  FormControlLabel,
  MenuItem,
  Switch,
} from "@material-ui/core";
import { swapOrderStatus } from "../../containers/order/types";
import {
  maskingPhoneNumber,
  maskingAddress,
} from "../../containers/order/utils";
import { PAYMENTS, TRIP, VEHICLE } from "../../lib/constants/constants";
import {
  FlexBox,
  RowDivider,
  ColumnDivider,
  FlexColumnCenterHalf,
} from "../common/Base";
import { HalfTextField } from "../common/StyledTextField";
import Detail from "../common/Detail";
import { checkField } from "../../lib/validate";

function OrderSwapDetail({ row }) {
  const adminUserAuth = useSelector(
    ({ userAuth }) => userAuth.userAuth?.data?.users_adminuserauth
  );
  /**
   * @description 메뉴명 권한
   * @returns { string{} | undefined }
   */
  const ORDERS = useMemo(
    () => adminUserAuth?.find(({ menu_id }) => menu_id === "ORDERS"),
    [adminUserAuth]
  );

  const {
    id,
    client_name,
    client_phone_number,
    sender_name,
    sender_phone_number,
    sender_address,
    sender_address_detail,
    receiver_name,
    receiver_phone_number,
    receiver_address,
    receiver_address_detail,
    vehicle_type,
    vehicle_option,
    payment,
    rider_vehicle_number,
    trip,
    algoquick_orders,
    users_user,
    usersUserByRelatedUserId,
    express,
    isd_order_number,
    call24_order_number,
    location_charge,
    extra_charge,
    delivery_charge,
    rider_name,
    rider_phone_number,
    status,
    is_isd_order,
  } = row;

  // 주문 관련 법인, 부서명
  let corporation = " ";
  let department = " ";
  let user = " ";

  if (users_user) {
    const { username, corporations_corporation, corporations_department } =
      users_user;
    user = username;
    corporation = corporations_corporation
      ? corporations_corporation.name
      : " ";
    department = corporations_department ? corporations_department.name : " ";
  } else if (usersUserByRelatedUserId) {
    const { corporations_corporation, corporations_department } =
      usersUserByRelatedUserId;
    corporation = corporations_corporation
      ? corporations_corporation.name
      : " ";
    department = corporations_department ? corporations_department.name : " ";
  }

  // 추가 요금 관련
  let extraCharge = {};
  if (algoquick_orders) {
    const {
      vehicle_charge,
      bulk_charge,
      express_charge,
      holiday_charge,
      time_charge,
      weather_charge,
      round_trip_charge,
    } = algoquick_orders[0];

    extraCharge = {
      vehicle_charge,
      bulk_charge,
      express_charge,
      holiday_charge,
      time_charge,
      weather_charge,
      round_trip_charge,
    };
  }

  return (
    <FlexColumnCenterHalf>
      <Detail>
        <h2>
          {is_isd_order ? "전화접수" : "웹접수"} {id}
        </h2>
        <FormControlLabel
          control={<Switch checked={express} />}
          id="express"
          name="express"
          label="급송"
          labelPlacement="start"
          disabled
        />
        <FlexBox>
          <TextField
            label="배송번호(인성주문)"
            id="isd_order_number"
            name="isd_order_number"
            value={checkField(isd_order_number)}
            variant="filled"
            margin="normal"
            disabled
            fullWidth
          />
          <ColumnDivider />
          <ColumnDivider />
          <TextField
            label="전국24시콜화물 화물번호"
            id="call24_order_number"
            name="call24_order_number"
            value={checkField(call24_order_number)}
            variant="filled"
            margin="normal"
            disabled
            fullWidth
          />
        </FlexBox>
        {corporation.trim() !== "" && (
          <FlexBox>
            <TextField
              label="법인명"
              id="corporation"
              name="corporation"
              value={corporation}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <ColumnDivider />
            <ColumnDivider />
            <TextField
              label="부서명"
              id="department"
              name="department"
              value={department}
              disabled
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </FlexBox>
        )}
        <FlexBox>
          <TextField
            label="의뢰인 이름"
            id="client_name"
            name="client_name"
            value={client_name}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider />
          <ColumnDivider />
          <TextField
            label="의뢰인 연락처"
            id="client_phone_number"
            name="client_phone_number"
            value={maskingPhoneNumber(client_phone_number, ORDERS)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="출발지 이름"
            id="sender_name"
            name="sender_name"
            value={checkField(sender_name)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider />
          <ColumnDivider />
          <TextField
            label="출발지 연락처"
            id="sender_phone_number"
            name="sender_phone_number"
            value={maskingPhoneNumber(sender_phone_number, ORDERS)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider />
          <ColumnDivider />
          <TextField
            label="출발지 지번주소"
            id="sender_jibun_address"
            name="sender_jibun_address"
            value={
              maskingAddress(sender_address, ORDERS) +
              " " +
              maskingAddress(sender_address_detail, ORDERS)
            }
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="도착지 이름"
            id="receiver_name"
            name="receiver_name"
            value={checkField(receiver_name)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider />
          <ColumnDivider />
          <TextField
            label="도착지 연락처"
            id="receiver_phone_number"
            name="receiver_phone_number"
            value={maskingPhoneNumber(receiver_phone_number, ORDERS)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider />
          <ColumnDivider />
          <TextField
            label="도착지 지번주소"
            id="receiver_jibun_address"
            name="receiver_jibun_address"
            value={
              maskingAddress(receiver_address, ORDERS) +
              " " +
              maskingAddress(receiver_address_detail, ORDERS)
            }
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="배송요금"
            id="location_charge"
            name="location_charge"
            value={location_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="추가요금"
            id="extra_charge"
            name="extra_charge"
            value={extra_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="배송실비"
            id="delivery_charge"
            name="delivery_charge"
            value={delivery_charge}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="왕복요금"
            id="round_trip_charge"
            name="round_trip_charge"
            value={extraCharge.round_trip_charge || 0}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="차량요금"
            id="vehicle_charge"
            name="vehicle_charge"
            value={extraCharge.vehicle_charge || 0}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="물품요금"
            id="bulk_charge"
            name="bulk_charge"
            value={extraCharge.bulk_charge || 0}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="급송요금"
            id="express_charge"
            name="express_charge"
            value={extraCharge.express_charge || 0}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="휴일요금"
            id="holiday_charge"
            name="holiday_charge"
            value={extraCharge.holiday_charge || 0}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="시간요금"
            id="time_charge"
            name="time_charge"
            value={extraCharge.time_charge || 0}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
          <ColumnDivider size="2" />
          <TextField
            label="날씨요금"
            id="weather_charge"
            name="weather_charge"
            value={extraCharge.weather_charge || 0}
            variant="filled"
            margin="normal"
            fullWidth
            disabled
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="차량타입"
            id="vehicle_type"
            name="vehicle_type"
            value={checkField(VEHICLE[vehicle_type])}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="차량옵션"
            id="vehicle_option"
            name="vehicle_option"
            value={checkField(vehicle_option)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="배송구분"
            id="trip"
            name="trip"
            value={checkField(TRIP[trip])}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="결제방법"
            id="payment"
            name="payment"
            value={checkField(PAYMENTS[payment])}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="기사이름"
            id="rider_name"
            name="rider_name"
            value={checkField(rider_name)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="기사 연락처"
            id="rider_phone_number"
            name="rider_phone_number"
            value={checkField(rider_phone_number)}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="2" />
          <TextField
            label="기사 차량 번호"
            id="rider_vehicle_number"
            name="rider_vehicle_number"
            value={checkField(rider_vehicle_number)}
            variant="outlined"
            margin="normal"
            fullWidth
            disabled
          />
        </FlexBox>
        <FlexBox>
          <HalfTextField
            label="관련회원"
            id="relatedUsername"
            name="relatedUsername"
            value={
              usersUserByRelatedUserId ? usersUserByRelatedUserId.username : ""
            }
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider />
          <HalfTextField
            label="접수회원"
            id="username"
            name="username"
            value={user}
            variant="outlined"
            margin="normal"
            disabled
          />
        </FlexBox>
        <RowDivider />
        <HalfTextField
          select
          id="status"
          name="status"
          label="상태"
          value={status}
          disabled
          variant="filled"
        >
          {swapOrderStatus.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </HalfTextField>
        <RowDivider />
      </Detail>
    </FlexColumnCenterHalf>
  );
}

export default OrderSwapDetail;
