import React from "react";
import styled from "styled-components";
import { Button, MenuItem, TextField, Paper } from "@material-ui/core";
import {
  FlexCenterSpaceBetween,
  FlexColumnCenter,
  RowDivider,
} from "../common/Base";
import { ModalContainer } from "../common/modal/Modal";

const cancelCardCode = [
  {
    value: "0",
    label: "전체 취소",
  },
  {
    value: "1",
    label: "부분 취소",
  },
];

type CancelCardProps = {
  card: {
    id: string;
    tid: string;
    orderCharge: string;
    charge: string;
    cancelMessage: string;
    code: string;
  };
  handleCancelCardChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClose: (event: React.SyntheticEvent, reason?: string) => void;
  submitCancelCard: () => void;
};

const CancelCard = ({
  card,
  handleCancelCardChange,
  handleClose,
  submitCancelCard,
}: CancelCardProps) => {
  return (
    <FlexColumnCenter>
      <ModalContainer>
        <RowDivider size="2" />
        <TextField
          select
          id="code"
          name="code"
          label="부분 취소 여부"
          value={card.code}
          variant="outlined"
          fullWidth
          onChange={handleCancelCardChange}
        >
          {cancelCardCode.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="주문 금액"
          id="orderCharge"
          name="orderCharge"
          value={card.orderCharge}
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          label="취소 금액"
          id="charge"
          name="charge"
          value={card.charge}
          disabled={card.code === "0"}
          variant="outlined"
          margin="normal"
          onChange={handleCancelCardChange}
          fullWidth
          required={true}
        />
        <TextField
          label="취소 사유"
          id="cancelMessage"
          name="cancelMessage"
          value={card.cancelMessage}
          variant="outlined"
          margin="normal"
          onChange={handleCancelCardChange}
          fullWidth
          required={true}
        />
        <TextField
          type="password"
          label="취소 비밀번호"
          id="password"
          name="password"
          value="********"
          variant="outlined"
          margin="normal"
          fullWidth
          disabled
        />
        <RowDivider />
        <StyledPaper variant="outlined" elevation={24}>
          <p>* 취소가 성공한 경우에는 다시 승인상태로 복구 할 수 없습니다.</p>
          <p>* 취소 요청시 상단의 모든 값을 입력 하세요.</p>
          <p>
            * 신용카드결제, 계좌이체, 가상계좌만 부분취소/부분환불이 가능합니다.
          </p>
        </StyledPaper>
        <RowDivider />
        <FlexCenterSpaceBetween>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            취소
          </Button>
          <Button variant="outlined" color="primary" onClick={submitCancelCard}>
            취소 요청
          </Button>
        </FlexCenterSpaceBetween>
      </ModalContainer>
    </FlexColumnCenter>
  );
};

export default CancelCard;

const StyledPaper = styled(Paper)`
  padding: 1rem;
  p {
    margin: 10px 0;
  }
`;
