import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import useSnackbar from "../../hooks/useSnackbar";
import AddLoadingChargeSelector from "../../components/corporation/AddLoadingChargeSelector";
import ExtraTruckChargeList from "../../components/corporation/ExtraTruckChargeList";
import NoExtraChargeList from "../../components/corporation/NoExtraChargeList";
import Notice from "../../components/common/Notice";
import { LOG_TYPE } from "../../lib/constants/constants";
import { postLog } from "../../lib/hasura/users";
import { destructResponse } from "../../lib/hasura/common";
import {
  getCorpLoadingTruckCharge,
  createCorpLoadingTruckCharge,
  updateCorpLoadingTruckCharge,
  deleteCorpLoadingTruckCharge,
} from "../../lib/hasura/extraCharge";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import {
  ADD_LOADING_CHARGE,
  TruckLoadingSelector,
  TruckLoadingChargeType,
  LOADING_UNIT,
} from "./extraTypes";
import { validateExtraLoadingCharge } from "./utils";

interface TruckChargeContainerProps {
  corpId: string;
  corpName: string;
}

function CorporationTruckChargeContainer({
  corpId,
  corpName,
}: TruckChargeContainerProps) {
  const dispatch = useDispatch();
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const [addtruckCharge, setAddTruckCharge] = useState(ADD_LOADING_CHARGE); // 등록할 상하차요금
  const [truckData, setTruckData] = useState<TruckLoadingChargeType[]>([]); // 상하차요금 데이터 (트럭)

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchTruckData();
  }, [corpId]);

  const fetchTruckData = async () => {
    dispatch(startGlobalLoading());
    try {
      const response = await destructResponse<TruckLoadingChargeType[]>(
        "corporations_corporationloadingcharge",
        () => getCorpLoadingTruckCharge(corpId)
      );
      setTruckData(response);
    } catch (err) {
      console.log(err);
      openSnackbar("상하차요금을 불러오는데 실패하였습니다.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // 전체 상하차요금 생성
  const onAllRegist = async () => {
    const confirmMsg = `${corpName}의 트럭 상하차요금을 생성하시겠습니까?`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await createCorpLoadingTruckCharge(corpId);
      await fetchTruckData();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 트럭상하차요금 전체생성`,
        "법인 관리"
      );
    } catch (err) {
      const failMsg = (err as Error).message || "요금 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    setAddTruckCharge({ ...addtruckCharge, [name as string]: value });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    try {
      const invalidMessage = validateExtraLoadingCharge(addtruckCharge);
      if (invalidMessage) throw new Error(invalidMessage);

      const confirmMsg = `해당 범위의 "트럭" 상하차요금을 등록하시겠습니까?`;
      if (!confirm(confirmMsg)) return;

      const { loading, unloading, vehicle_option, loading_charge } =
        addtruckCharge;
      if (loading === unloading)
        await updateCorpLoadingTruckCharge(corpId, addtruckCharge);
      else {
        // loading unloading의 반대 데이터를 한번 더 등록
        await updateCorpLoadingTruckCharge(corpId, addtruckCharge);
        await updateCorpLoadingTruckCharge(corpId, {
          ...addtruckCharge,
          loading: unloading,
          unloading: loading,
        });
      }

      fetchTruckData();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 트럭상하차요금 요금등록(${LOADING_UNIT[loading]} / ${LOADING_UNIT[unloading]} / ${vehicle_option}) : ${loading_charge}원`,
        "법인 관리"
      );
      openSnackbar("요금이 등록되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "요금 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onAllDelete = async () => {
    const deleteMsg = `"트럭" 상하차요금을 전체 삭제하시곘습니까?\n전체 삭제시 "기존 상하차요금"으로 자동 적용됩니다.`;
    if (!confirm(deleteMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await deleteCorpLoadingTruckCharge(corpId);
      await fetchTruckData();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 트럭상하차요금 전체삭제`,
        "법인 관리"
      );
      openSnackbar("삭제가 완료되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "요금 삭제에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <LoadingWrapper>
        <LoadingHeader>
          <h1 className="title">트럭 상하차요금 DB화 ({corpName})</h1>
          <div className="description">
            *요금 등록시, 상차와 하차의 방법이 다른경우, 반대의 상하차 방법도
            함께 등록됩니다.
          </div>
          <div className="description">
            *"5톤"이상 + "기사님 단독"인 경우, 전화상담이므로 0원으로
            등록됩니다.
          </div>
        </LoadingHeader>
        {truckData.length > 0 ? (
          <LoadingBody>
            <AddLoadingChargeSelector
              addCharge={addtruckCharge}
              selector={TruckLoadingSelector}
              onChange={onChange}
              onSubmit={onSubmit}
              onAllDelete={onAllDelete}
              isTruckOption={true}
            />
            <ExtraTruckChargeList truckData={truckData} />
          </LoadingBody>
        ) : (
          <NoExtraChargeList type={"트럭 상하차"} onAllRegist={onAllRegist} />
        )}
      </LoadingWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default CorporationTruckChargeContainer;

const LoadingWrapper = styled.section`
  margin-top: 20px;
  text-align: center;
`;

const LoadingHeader = styled.header`
  padding-bottom: 12px;

  .title {
    margin: 10px;
    font-size: 1.7rem;
  }
  .description {
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 800;
    color: #9e9e9e;
  }
`;

const LoadingBody = styled.section`
  display: flex;
  flex-direction: column;
`;
