import React from "react";
import styled from "styled-components";
import { sortDateByCreateAt } from "../../../../../lib/dashboard";
import {
  StyledLink,
  StyledContents,
  StyledList,
  StyledColumn,
  StyledTaskCheckColumn,
} from "../../styles";
import { STATUS } from "../../../../../lib/constants/constants";

const StyledIndexColumn = styled(StyledColumn)`
  width: 3.2rem;
  text-align: center;
`;

const StyledCreatedColumn = styled(StyledColumn)`
  width: 10rem;
`;

const OrdersDelayedList = ({ tabName, orderMissedProps }) => {
  /**
   * @description 리스트를 구성하는 개별 아이템 컴포넌트
   * @param {{ tabName, orderMissedProps }} 리스트 구성에 필요한 props
   * @return { object } 개별 아이템 컴포넌트
   */
  const RowList = ({
    index,
    id,
    isdOrderNumber,
    created,
    columnName,
    state,
    status,
  }) => {
    return (
      <StyledList index={index} columnName={columnName} success={true}>
        <StyledIndexColumn>{index}</StyledIndexColumn>
        <StyledCreatedColumn>{created}</StyledCreatedColumn>
        <StyledColumn>{id}</StyledColumn>
        <StyledColumn>{isdOrderNumber}</StyledColumn>
        <StyledColumn>
          {status === "배송상태" ? "배송상태" : STATUS[status]}
        </StyledColumn>
        <StyledTaskCheckColumn columnName={columnName}>
          {state}
        </StyledTaskCheckColumn>
        <StyledLink
          style={{ visibility: columnName ? "hidden" : "visible" }}
          to={`/order/${id}`}
        >
          주문 상세 보기
        </StyledLink>
      </StyledList>
    );
  };

  /**
   * @description 리스트에 표시할 아이템 컴포넌트 배열을 생성하는 함수
   * @return { object } 개별 아이템 컴포넌트 배열
   */
  const getRowList = (tabName) => {
    let sortedOrdersDelayed = sortDateByCreateAt(
      orderMissedProps.ordersDelayed[tabName]
    );
    return sortedOrdersDelayed.map((item, index) => (
      <RowList
        key={index}
        index={index + 1}
        id={item.id}
        isdOrderNumber={item.isdOrderNumber}
        created={item.created}
        status={item.status}
        state={item.state}
      />
    ));
  };

  return (
    <>
      <StyledContents>
        {/* 리스트 항목 표시 */}
        <RowList
          index="순번"
          created="주문 시간"
          id="알고퀵 주문 번호"
          isdOrderNumber="인성 주문 번호"
          status="배송상태"
          state="문제상태"
          columnName={true}
        />
        {/* 리스트 아이템 표시 */}
        {orderMissedProps.isLoading ? null : getRowList(tabName)}
      </StyledContents>
    </>
  );
};

export default OrdersDelayedList;
