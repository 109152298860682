import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { removeDash } from "../../pages/GroupOrder/utils";
import { CHANGE_SENDER_INFO } from "../../modules/groupOrder";

interface SenderDialogProps {
  dialogState: any;
  handleDialog: any;
  convertedData: any;
}

function SenderDialog({
  dialogState,
  handleDialog,
  convertedData,
}: SenderDialogProps) {
  const dispatch = useDispatch();

  const { index, open } = dialogState;
  const { clientName, clientPhoneNumber } = convertedData[index];

  const [clientInfo, setClientInfo] = useState({
    clientName,
    clientPhoneNumber,
  });

  const handleSenderInfo =
    (key: string) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setClientInfo({ ...clientInfo, [key]: e.target.value });
    };

  const changeSenderInfo = () => {
    const clientPhoneNumber = removeDash(clientInfo.clientPhoneNumber);
    dispatch({
      type: CHANGE_SENDER_INFO,
      payload: {
        index,
        ...clientInfo,
        clientPhoneNumber,
      },
    });
    handleDialog(false)();
  };
  return (
    <Dialog open={open} onClose={handleDialog(false)} fullWidth maxWidth={"sm"}>
      <DialogTitle>{"🔧 의뢰인 정보수정"}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="client-name"
          label="의뢰인 이름"
          multiline
          fullWidth
          value={clientInfo.clientName}
          onChange={handleSenderInfo("clientName")}
        />
        <TextField
          margin="dense"
          id="client-phone"
          label="의뢰인 휴대폰번호"
          multiline
          fullWidth
          value={clientInfo.clientPhoneNumber}
          onChange={handleSenderInfo("clientPhoneNumber")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialog(false)} color="primary">
          취소
        </Button>
        <Button onClick={changeSenderInfo} color="primary">
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SenderDialog;
