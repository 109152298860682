import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { useLocation, withRouter } from "react-router";
import { TextField } from "@material-ui/core";
import { Box } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ko from "date-fns/locale/ko";
import { format } from "date-fns";

import { formatDate } from "../../lib/datetime";
import { destructResponse } from "../../lib/hasura/common";
import { getCorporateCreditHistory } from "../../lib/hasura/credit";
import { finishGlobalLoading, startGlobalLoading } from "../../modules/loading";
import { postLog } from "../../lib/hasura/users";
import { LOG_TYPE } from "../../lib/constants/constants";

import Button from "../../components/common/Button";
import PaginatedTable from "../../components/common/PaginatedTable";

type CreditHistoryRow = {
  id: number;
  created: string;
  order_id: string;
  // 크레딧 구분: 법인, 부서, 회원
  credit_type: "CORPORATE" | "DEPARTMENT" | "USER";
  // 사용 구분: 사용, 복구, 크레딧 신규 할당, 크레딧 추가 할당, 크레딧 차감
  query_type: "USE" | "RESTORE" | "CREATE" | "UPDATE_PLUS" | "UPDATE_MINUS";
  memo: string;
  credit_amt: number;
  current_credit: number;
};

function CreditContainer() {
  const dispatch = useDispatch();
  const { state } = useLocation<{
    corporationId: string;
    corporationName: string;
  }>();
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const d = new Date();
  d.setDate(d.getDate() + 1);
  d.setHours(0, 0, 0, 0);
  const [end, setEnd] = useState<Date>(new Date(d.getTime()));
  d.setMonth(d.getMonth() - 1);
  const [start, setStart] = useState<Date>(new Date(d.getTime()));
  const [historyRows, setHistoryRows] = useState<CreditHistoryRow[]>([]);

  const handleDateChange =
    (setDate: React.Dispatch<React.SetStateAction<Date>>) =>
    (date: Date | null) => {
      if (date != null) setDate(date);
    };
  const handleCreditAmt = (row: CreditHistoryRow) => {
    const { query_type, order_id, credit_amt } = row;

    // "주문크레딧 추가할당" or "법인크레딧 차감"은 음수
    return query_type === "USE" ||
      (order_id && query_type === "UPDATE_PLUS") ||
      (!order_id && query_type === "UPDATE_MINUS")
      ? -credit_amt
      : credit_amt;
  };
  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    const startDate = format(start, "yyyy-MM-dd");
    const endDate = format(end, "yyyy-MM-dd");
    if (e) e.preventDefault();

    try {
      dispatch(startGlobalLoading());
      const rows = await destructResponse<CreditHistoryRow[]>(
        "orders_credit",
        () => getCorporateCreditHistory(start, end, state.corporationId)
      );
      setHistoryRows(rows);

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.SEARCH,
        `크레딧 내역: ${state.corporationName} (${startDate} ~ ${endDate}) 검색`,
        "법인 관리"
      );
    } catch (err) {
      console.error((err as Error).message);
      alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            my: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
            <MobileDatePicker
              format="yyyy-MM-dd"
              label="시작일"
              value={start}
              slotProps={{
                actionBar: { actions: [] },
                day: { disableMargin: true },
                textField: { variant: "standard" },
                toolbar: { toolbarFormat: "MM월 dd일", hidden: false },
              }}
              closeOnSelect
              onChange={handleDateChange(setStart)}
            />
            <MobileDatePicker
              format="yyyy-MM-dd"
              label="종료일"
              value={end}
              slotProps={{
                actionBar: { actions: [] },
                day: { disableMargin: true },
                textField: { variant: "standard" },
                toolbar: { toolbarFormat: "MM월 dd일", hidden: false },
              }}
              closeOnSelect
              onChange={handleDateChange(setEnd)}
            />
          </LocalizationProvider>
          <TextField label="법인명" value={state.corporationName} disabled />
          <Button type="submit">검색</Button>
        </Box>
      </form>
      <PaginatedTable<CreditHistoryRow>
        headCells={[
          {
            id: "created",
            label: "등록일",
            formatter: (row) => formatDate(row.created),
          },
          { id: "order_id", label: "알고퀵 주문번호" },
          {
            id: "credit_type",
            label: "크레딧 구분",
            formatter: (row) =>
              ({
                CORPORATE: "법인",
                DEPARTMENT: "부서",
                USER: "회원",
              })[row.credit_type],
          },
          {
            id: "query_type",
            label: "사용 구분",
            formatter: (row) =>
              ({
                USE: "사용",
                RESTORE: "복구",
                CREATE: "신규 할당",
                UPDATE_PLUS: "추가 할당",
                UPDATE_MINUS: "차감",
              })[row.query_type],
          },
          { id: "memo", label: "메모" },
          {
            id: "credit_amt",
            label: "변동 크레딧",
            numeric: true,
            formatter: (row) => handleCreditAmt(row),
          },
          { id: "current_credit", label: "잔여 크레딧", numeric: true },
        ]}
        rows={historyRows}
      />
    </>
  );
}
export default withRouter(CreditContainer);
