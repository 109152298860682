import styled from "styled-components";
import { ModalWrapper } from "./modal/Modal";
import { CircularProgress } from "@material-ui/core";

function CircularLoading() {
  return (
    <ModalWrapper size={0.05}>
      <StyledCircular color="primary" size="5rem" thickness={3} />
    </ModalWrapper>
  );
}

export default CircularLoading;

const StyledCircular = styled(CircularProgress)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
