/**
 * @description 트럭톤수/종류/리프트
 * @return { 트럭톤수 }
 */
export const extractTruckTons = (truck: string): string => {
  const match = truck.match(/^truck_\d+t|^truck_\d+_\d+t/);
  return match ? match[0] : "";
};

/**
 * @description 트럭톤수/종류/리프트
 * @return { 종류 }
 */
export const extractTruckTypes = (truck: string): string => {
  return truck.replace(/truck_\d+t|truck_\d+_\dt|_lift/g, "");
};

/**
 * @description 트럭톤수/종류/리프트
 * @return { 리프트 }
 */
export const extractTruckLift = (truck: string): string => {
  const match = truck.match(/_lift/);
  return match ? match[0] : "";
};

/**
 * @description 트럭톤수/종류/리프트
 * - 트럭 전체/카고의 경우
 * - 일반은 truck_charge, 리프트는 lift_cargo_charge로 반환되어야 한다
 * @return { 종류/리프트 }
 */
export const extractTruckOpts = (truck: string): string => {
  const match = truck.replace(/truck_\d+t|truck_\d+_\dt/g, "");
  switch (match) {
    case "":
    case "_cargo":
      return "_truck";
    case "_lift":
      return "_cargo_lift";
    default:
      return match;
  }
};

export const registTruckCharge = (truck: string) => {
  let first = truck.includes("_lift") ? "_lift" : "";
  let second = extractTruckTypes(truck);
  return `${first}${second}_charge`;
};
