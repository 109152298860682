import React, { useState, useEffect } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import useSnackbar from "../../hooks/useSnackbar";
import AddExtraChargeSelector from "../../components/corporation/AddExtraChargeSelector";
import ExtraExpressChargeList from "../../components/corporation/ExtraExpressChargeList";
import NoExtraChargeList from "../../components/corporation/NoExtraChargeList";
import Notice from "../../components/common/Notice";
import { LOG_TYPE } from "../../lib/constants/constants";
import { postLog } from "../../lib/hasura/users";
import { destructResponse } from "../../lib/hasura/common";
import {
  getCorpExpressCharge,
  createCorpExpressCharge,
  updateCorpExpressCharge,
  deleteCorpExpressCharge,
} from "../../lib/hasura/extraCharge";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import {
  ADD_EXTRA_CHARGE,
  ExpressChargeType,
  selectorUnit,
} from "./extraTypes";
import {
  validateExtraCharge,
  displayNumberRange,
  extraCalculation,
} from "./utils";

interface ExpressChargeContainerProps {
  corpId: string;
  corpName: string;
}

function CorporationExpressChargeContainer({
  corpId,
  corpName,
}: ExpressChargeContainerProps) {
  const dispatch = useDispatch();
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const [createRange, setCreateRange] = useState(50000); // 전체생성시 최대 기본요금
  const [updateMaxCharge, setUpdateMaxCharge] = useState(50000); // update 가능한 최대 기본요금

  const [addExpressCharge, setAddExpressCharge] = useState(ADD_EXTRA_CHARGE); // 등록할 급송요금
  const [expressData, setExpressData] = useState<ExpressChargeType[]>([]); // 급송요금 데이터

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchExpressData();
  }, [corpId]);

  const fetchExpressData = async () => {
    dispatch(startGlobalLoading());
    try {
      const response = await destructResponse<ExpressChargeType[]>(
        "corporations_corporationextracharge",
        () => getCorpExpressCharge(corpId)
      );
      setExpressData(response);
      setUpdateMaxCharge((response.length - 1) * 1000); // update 가능한 기본요금 최대범위 산출
    } catch (err) {
      console.log(err);
      openSnackbar("급송요금을 불러오는데 실패하였습니다.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleMaxBaseCharge = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const number = Number(value);

    setCreateRange(number);
  };

  // 전체 급송요금 생성
  const onAllRegist = async () => {
    const confirmMsg = `[${corpName}] 0~${createRange}원 범위의 급송요금을 생성하시겠습니까?`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      const baseChargeRange = displayNumberRange(0, createRange); // 기본요금 단위
      await createCorpExpressCharge(corpId, baseChargeRange);
      await fetchExpressData();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 급송요금 전체생성`,
        "법인 관리"
      );
    } catch (err) {
      const failMsg = (err as Error).message || "요금 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "calc_selector":
        setAddExpressCharge({
          ...addExpressCharge,
          percent_of_charge: 0,
          fixed_charge: 0,
          [name]: value,
        });
        break;
      default:
        setAddExpressCharge({ ...addExpressCharge, [name]: value });
        break;
    }
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const confirmMsg = `해당 범위의 급송요금을 등록하시겠습니까?`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      const invalidMessage = validateExtraCharge(
        addExpressCharge,
        updateMaxCharge
      );
      if (invalidMessage) throw new Error(invalidMessage);

      // 급송요금에서는 vehicleType 미사용
      const refinedData = extraCalculation(addExpressCharge, expressData);
      const promise = refinedData.map(
        async (el) => await updateCorpExpressCharge(corpId, el)
      );

      const result = await Promise.allSettled(promise);
      await fetchExpressData();

      const isRejected = result.some((el) => el.status === "rejected");
      if (isRejected) throw new Error("등록에 실패한 요금이 있습니다.");

      const { min_base_charge, max_base_Charge } = addExpressCharge;
      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 급송요금 요금등록(${min_base_charge}원 ~ ${max_base_Charge}원) : ${selectorUnit(
          addExpressCharge
        )}`,
        "법인 관리"
      );
      openSnackbar("요금이 등록되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "요금 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onAllDelete = async () => {
    const deleteMsg = `급송요금을 전체 삭제하시곘습니까?\n전체 삭제시 "기존 급송요금"으로 자동 적용됩니다.`;
    if (!confirm(deleteMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await deleteCorpExpressCharge(corpId);
      await fetchExpressData();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 급송요금 전체삭제`,
        "법인 관리"
      );
      openSnackbar("삭제가 완료되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "요금 삭제에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <ExpressWrapper>
        <ExpressHeader>
          <h1 className="title">급송요금 DB화 ({corpName})</h1>
          <div className="description">
            *급송요금은 부분삭제가 불가하며, 전체삭제시 "기존 급송요금"으로 자동
            적용됩니다.
          </div>
        </ExpressHeader>
        {expressData.length > 0 ? (
          <ExpressBody>
            <AddExtraChargeSelector
              maxCharge={updateMaxCharge}
              addCharge={addExpressCharge}
              onChange={onChange}
              onSubmit={onSubmit}
              onAllDelete={onAllDelete}
              isVehicleType={false}
            />
            <ExtraExpressChargeList expressData={expressData} />
          </ExpressBody>
        ) : (
          <NoExtraChargeList
            type={"급송"}
            onAllRegist={onAllRegist}
            handleMaxBaseCharge={handleMaxBaseCharge}
          />
        )}
      </ExpressWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default CorporationExpressChargeContainer;

const ExpressWrapper = styled.section`
  margin-top: 20px;
  text-align: center;
`;

const ExpressHeader = styled.header`
  padding-bottom: 1.5rem;

  .title {
    margin: 10px;
    font-size: 1.7rem;
  }
  .description {
    font-size: 18px;
    font-weight: 800;
    color: #9e9e9e;
  }
`;

const ExpressBody = styled.section`
  display: flex;
  flex-direction: column;
`;
