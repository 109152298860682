import styled from "styled-components";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { BulkChargeType } from "../../containers/corporation/extraTypes";

const MAX_BULK_SCORE = 13;
const BULK_WEIGHTS = [0, 20, 30, 40];

interface ExtraBulkChargeListProps {
  bulkData: BulkChargeType[];
  onChange: (score: number, weight: number, charge: number) => void;
}

function ExtraBulkChargeList({ bulkData, onChange }: ExtraBulkChargeListProps) {
  const classes = useStyles();

  type ChargeRecord = {
    [score: number]: {
      [weight: number]: number;
    };
  };
  const charge = bulkData.reduce<ChargeRecord>((acc, data) => {
    if (acc[data.bulk_score] === undefined) acc[data.bulk_score] = {};
    acc[data.bulk_score][data.weight] = data.bulk_charge;
    return acc;
  }, {});

  return (
    <TableBox>
      <TableHead className={classes.root}>
        <TableRow>
          <TableCell align="center" />
          {BULK_WEIGHTS.map((weight) => (
            <TableCell key={`header_${weight}`} align="center">
              {weight}KG
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.from({ length: MAX_BULK_SCORE + 1 }).map((_, score) => (
          <TableRow key={`score_${score}`}>
            <TableCell align="center" className={classes.root}>
              {score}점
            </TableCell>
            {BULK_WEIGHTS.map((weight) => (
              <TableCell key={`weight_${weight}`} align="center">
                <StyledTextField
                  type="number"
                  id={`bulk_charge_${score}_${weight}`}
                  name={`bulk_charge_${score}_${weight}`}
                  defaultValue={charge[score]?.[weight]}
                  variant="outlined"
                  margin="normal"
                  inputProps={{
                    min: 0,
                    step: 1000,
                    style: { textAlign: "right" },
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(score, weight, +e.target.value)
                  }
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TableBox>
  );
}

export default ExtraBulkChargeList;

const useStyles = makeStyles(() => ({
  root: { backgroundColor: "#E7E6E6" },
}));

const TableBox = styled(Table)`
  margin: 1rem auto;
  width: 80% !important;
  border: 1px solid #e7e6e6;
`;

const StyledTextField = styled(TextField)`
  margin: 0;
  width: 50% !important;
`;
