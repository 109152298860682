import React, { useEffect, memo } from "react";
import styled from "styled-components";

import { macroHPath, macroVPath, microHPath, microVPath } from "./squarePoint";

import senderMakerImage from "../../static/map/sender-marker.png";
import receiverMaker1 from "../../static/map/receiver-marker-1.png";
import receiverMaker2 from "../../static/map/receiver-marker-2.png";
import receiverMaker3 from "../../static/map/receiver-marker-3.png";
import receiverMaker4 from "../../static/map/receiver-marker-4.png";
import receiverMaker5 from "../../static/map/receiver-marker-5.png";
import receiverMaker6 from "../../static/map/receiver-marker-6.png";
import receiverMaker7 from "../../static/map/receiver-marker-7.png";

const receiverMarkers = [
  receiverMaker1,
  receiverMaker2,
  receiverMaker3,
  receiverMaker4,
  receiverMaker5,
  receiverMaker6,
  receiverMaker7,
];

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const hexcodes = [
  "#B71C1C",
  "#FF6F00",
  "#FDD835",
  "#388E3C",
  "#1976D2",
  "#283593",
  "#7B1FA2",
  "#5D4037",
  "#424242",
  "#C2185B",
];

const Map = ({ senderAddress, receiverAddressList, resultPoints }) => {
  const mapScript = document.createElement("script");

  mapScript.async = true;
  mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_MAP_APP_KEY}&libraries=services&autoload=false`;

  document.getElementsByTagName("head")[0].appendChild(mapScript);

  useEffect(() => {
    const onLoadKakaoMap = () => {
      window.kakao.maps.load(() => {
        const mapContainer = document.getElementById("map");

        const mapOption = {
          center: new window.kakao.maps.LatLng(
            37.495523588183374,
            127.04874227280371
          ), // 지도의 중심좌표
          level: 10, // 지도의 확대 레벨
        };

        const map = new window.kakao.maps.Map(mapContainer, mapOption);

        const zoomControl = new window.kakao.maps.ZoomControl();
        map.addControl(zoomControl, window.kakao.maps.ControlPosition.RIGHT);
        const bounds = new window.kakao.maps.LatLngBounds();

        if (senderAddress.name) {
          const senderPosition = new window.kakao.maps.LatLng(
            ...senderAddress.startPoint
          );
          bounds.extend(senderPosition);
          map.setBounds(bounds);

          new window.kakao.maps.Marker({
            map: map,
            position: senderPosition,
            image: new window.kakao.maps.MarkerImage(
              senderMakerImage,
              new window.kakao.maps.Size(100, 90)
            ),
          });
        }

        if (receiverAddressList.length > 0) {
          receiverAddressList.forEach(({ id, name, bundlePoint }, index) => {
            const receiverPosition = new window.kakao.maps.LatLng(
              ...bundlePoint
            );

            bounds.extend(receiverPosition);
            map.setBounds(bounds);

            new window.kakao.maps.Marker({
              map: map,
              position: receiverPosition,
              image: new window.kakao.maps.MarkerImage(
                receiverMarkers[index],
                new window.kakao.maps.Size(100, 90)
              ),
            });
          });
        }

        if (resultPoints.length > 0) {
          const senderLatLngPoint = new window.kakao.maps.LatLng(
            ...senderAddress.startPoint
          );

          resultPoints.forEach((point, index) => {
            const resultLatLngPoint = point.map(
              (point) => new window.kakao.maps.LatLng(...point.slice(0, 2))
            );

            const resultPath = [senderLatLngPoint, ...resultLatLngPoint];

            const polyline = new window.kakao.maps.Polyline({
              endArrow: true,
              path: resultPath, // 선을 구성하는 좌표배열 입니다
              strokeWeight: 4, // 선의 두께 입니다
              strokeColor: hexcodes[index], // 선의 색깔입니다
              strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
              strokeStyle: "solid", // 선의 스타일입니다
            });

            polyline.setMap(map);
          });
        }

        const square = {
          isMicroRendered: false,
          isMacroRendered: false,
        };

        function renderPath(pathList, type) {
          pathList.map((path) => {
            const linePath = path.map((point) => {
              return new window.kakao.maps.LatLng(...point);
            });

            const polyline = new window.kakao.maps.Polyline({
              path: linePath, // 선을 구성하는 좌표배열 입니다
              strokeWeight: 2, // 선의 두께 입니다
              strokeColor: "blue", // 선의 색깔입니다
              strokeOpacity: 0.7, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
              strokeStyle: "dashed", // 선의 스타일입니다
            });

            if (type === "micro") {
              linePath.forEach((latLng) => {
                bounds.extend(latLng);
              });
              map.setBounds(bounds);
            }
            polyline.setMap(map);
            return polyline;
          });
        }

        function renderSquare(hpath, vpath, type) {
          renderPath(hpath, type);
          renderPath(vpath, type);
          return true;
        }

        square.isMacroRendered = renderSquare(macroHPath, macroVPath, "macro");

        new window.kakao.maps.event.addListener(map, "zoom_changed", () => {
          // 지도의 현재 레벨을 얻어옵니다
          const level = map.getLevel();

          if (level <= 5) {
            if (square.isMicroRendered) {
              return;
            }
            square.isMicroRendered = renderSquare(
              microHPath,
              microVPath,
              "micro"
            );
          }
        });
      });
    };
    mapScript.addEventListener("load", onLoadKakaoMap);
  }, [mapScript, receiverAddressList, resultPoints, senderAddress]);

  return <MapWrapper id="map"></MapWrapper>;
};

export default memo(Map);
