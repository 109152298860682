import React, { useState } from "react";
import styled from "styled-components";
import { sortDateByCreateAt } from "../../../../lib/dashboard";
import {
  StyledLink,
  StyledOrderList,
  StyledListBorder,
  StyledContents,
  StyledList,
  StyledListTitle,
  StyledColumn,
  StyledNumber,
} from "../styles";
import { Snackbar } from "@material-ui/core";
import Notice from "../../../common/Notice";
import { importOrder } from "../../../../lib/api/orders";

const StyledIndexColumn = styled(StyledColumn)`
  width: 3.5rem;
  text-align: center;
`;

const StyledErrorColumn = styled(StyledColumn)`
  width: 8rem;
  text-align: center;
`;

// 미전달 주문 리스트 컴포넌트
const OrderMissedList = ({ type, orderMissedProps }) => {
  const isdOrdersMissed = orderMissedProps.isdOrdersMissed;
  const algoOrdersMissed = orderMissedProps.algoOrdersMissed;

  // 스낵바 상태
  const [error, setError] = useState(false);
  const [noticeOpen, setNoticeOpen] = useState(false);
  const [message, setMessage] = useState("");

  /**
   * @description 리스트를 구성하는 개별 아이템 컴포넌트
   * @param {{ index, type, orderNumber, columnName, error }} 리스트 구성에 필요한 props
   * @return { object } 개별 아이템 컴포넌트
   */
  const RowList = ({ index, type, orderNumber, columnName, error }) => {
    return (
      <StyledList index={index} columnName={columnName}>
        <StyledIndexColumn>{index}</StyledIndexColumn>
        <StyledColumn>{orderNumber}</StyledColumn>
        <StyledErrorColumn>{error}</StyledErrorColumn>
        {type === "isd" ? (
          <StyledLink
            as="button"
            to={"#"}
            style={{ visibility: columnName ? "hidden" : "visible" }}
            onClick={() => handleSyncDataUpdateOnClick(orderNumber)}
          >
            업데이트
          </StyledLink>
        ) : (
          <StyledLink
            style={{ visibility: columnName ? "hidden" : "visible" }}
            to={`/order/${orderNumber}`}
          >
            주문 상세 보기
          </StyledLink>
        )}
      </StyledList>
    );
  };

  /**
   * @description 리스트에 표시할 아이템 컴포넌트 배열을 생성하는 함수
   * @return { object } 개별 아이템 컴포넌트 배열
   */
  const getRowList = () => {
    let sortedOrdersMissed = sortDateByCreateAt(
      type === "isd" ? isdOrdersMissed : algoOrdersMissed
    );
    return sortedOrdersMissed.map((item, index) => (
      <RowList
        key={index}
        index={index + 1}
        type={type}
        error={item.error ? item.error : ""}
        orderNumber={type === "isd" ? item.isdOrderNumber : item.id}
      />
    ));
  };

  /**
   * @description 인성에만 있는 주문을 알고퀵으로 가져오는 함수
   * @param { number } orderNumber 인성 주문번호
   * @return { undefined }
   */
  const handleSyncDataUpdateOnClick = async (orderNumber) => {
    try {
      const res = await importOrder(orderNumber);
      controlSnackbar(true, false, setMessage, res.data.contents);
    } catch (err) {
      controlSnackbar(true, true, setMessage, "업데이트에 실패하였습니다.");
    }
  };

  /**
   * @description 상태값에 따라 스낵바 팝업을 제어하는 함수
   * @param { boolean } noticeOpen 스낵바 팝업 여부
   * @param { boolean } error 에러 메시지 여부
   * @param { object } setMessage 스낵바에 표시할 메시지를 업데이트하는 훅
   * @param { string } msg 스낵바에 표시할 메시지
   * @return { undefined }
   */
  const controlSnackbar = (noticeOpen, error, setMessage, msg) => {
    setNoticeOpen(noticeOpen);
    setError(error);
    setMessage(msg);
  };

  /**
   * @description 사용자 클릭에 의해 스낵바를 닫는 함수
   * @param { object } event event 객체
   * @param { string } reason 닫히는 이유(방식): 클릭, 타임아웃 등
   * @return { undefined }
   */
  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNoticeOpen(false);
  };

  return (
    <>
      <StyledOrderList listType="orderMissed">
        {type === "isd" ? (
          <StyledListTitle>
            인성에만 있는 주문 (
            <StyledNumber number={getRowList().length}>
              {getRowList().length}
            </StyledNumber>
            ) 건
          </StyledListTitle>
        ) : (
          <StyledListTitle>
            알고퀵에만 있는 주문 (
            <StyledNumber number={getRowList().length}>
              {getRowList().length}
            </StyledNumber>
            ) 건
          </StyledListTitle>
        )}
        <StyledListBorder listType="orderMissed">
          <StyledContents>
            {/* 리스트 항목 표시 */}
            <RowList
              index="순번"
              orderNumber={type === "isd" ? "인성주문번호" : "알고퀵 주문번호"}
              columnName={true}
              type={type}
              error="에러 사항"
            />
            {/* 리스트 아이템 표시 */}
            {orderMissedProps.isLoading ? null : getRowList()}
          </StyledContents>
        </StyledListBorder>
      </StyledOrderList>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={noticeOpen}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        key={message}
      >
        {!error ? (
          <Notice variant="success" message={message} onClose={closeSnackbar} />
        ) : (
          <Notice variant="error" message={message} onClose={closeSnackbar} />
        )}
      </Snackbar>
    </>
  );
};

export default OrderMissedList;
