import React, { useCallback, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { beforeConvertExcel } from "../../modules/groupOrder";

import SenderInfoForm from "../../components/groupOrder/SenderInfoForm";

function SenderInfoFormContainer() {
  const dispatch = useDispatch();

  const { clientInfo, isSenderFormOpen } = useSelector(
    ({ groupOrder }: RootStateOrAny) => groupOrder
  );

  const initialState = {
    clientName: clientInfo.clientName,
    clientPhoneNumber: clientInfo.clientPhoneNumber,
    payment: clientInfo.payment,
  };

  const [senderState, setSenderState] = useState(initialState);

  const handleSenderInfo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { name, value },
      } = e;

      setSenderState({ ...senderState, [name]: value });
    },
    [senderState]
  );

  function convertExcel() {
    const { clientName, clientPhoneNumber, payment } = senderState;
    if (
      !!clientName === false ||
      !!clientPhoneNumber === false ||
      !!payment === false
    ) {
      alert("의뢰인 정보를 입력해주세요!");
      return;
    }
    dispatch(beforeConvertExcel(senderState));
  }

  if (!isSenderFormOpen) return <></>;

  return (
    <SenderInfoForm
      senderState={senderState}
      onChange={handleSenderInfo}
      onClick={convertExcel}
    />
  );
}

export default SenderInfoFormContainer;
