import { useMemo } from "react";
import styled from "styled-components";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
} from "@material-ui/core";
import {
  TruckLoadingChargeType,
  RefinedTruckLoadingChargeType,
  TRUCK_OPTION,
  LOADING_UNIT,
} from "../../containers/corporation/extraTypes";
import { sortbyLoading } from "../../containers/corporation/utils";

interface ExtraTruckChargeListProps {
  truckData: TruckLoadingChargeType[];
}

function ExtraTruckChargeList({ truckData }: ExtraTruckChargeListProps) {
  const classes = useStyles();

  // 데이터 객체안의 charges 배열을 vehicle_option 순으로 정렬
  const sortbyCharges = (
    a: { vehicle_option: string },
    b: { vehicle_option: string }
  ) => {
    return (
      TRUCK_OPTION.indexOf(a.vehicle_option) -
      TRUCK_OPTION.indexOf(b.vehicle_option)
    );
  };

  /**
   * @returns { RefinedTruckLoadingChargeType[] }
   */
  const refinedData = (acc: any, cur: TruckLoadingChargeType) => {
    const key = `${cur.loading}-${cur.unloading}`; // 상차-하차 조합(16가지)
    if (!acc[key]) {
      acc[key] = {
        loading: cur.loading,
        unloading: cur.unloading,
        charges: [
          {
            vehicle_option: cur.vehicle_option,
            loading_charge: cur.loading_charge,
          },
        ],
      };
    } else {
      // 상차-하차 조합마다 트럭옵션(1T~) 데이터 추가
      acc[key].charges.push({
        vehicle_option: cur.vehicle_option,
        loading_charge: cur.loading_charge,
      });
    }

    return acc;
  };
  const truckList = useMemo(() => {
    const refinedList: RefinedTruckLoadingChargeType[] = Object.values(
      truckData.reduce(refinedData, {})
    );
    const sortedList = refinedList.map((item) => ({
      ...item,
      charges: [...item.charges].sort(sortbyCharges), // vehicle_option 정렬
    }));

    return sortedList.sort(sortbyLoading); // loading -> unloading 정렬
  }, [truckData]);

  return (
    <TableBox>
      <TableHead className={classes.root}>
        <TableRow>
          <TableCell align="center">상차</TableCell>
          <TableCell align="center">하차</TableCell>
          {TRUCK_OPTION.map((ton) => (
            <TableCell key={ton} align="center">
              {ton}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {truckList.map((el, index) => (
          <TableRow key={index}>
            <TableCell align="center">{LOADING_UNIT[el.loading]}</TableCell>
            <TableCell align="center">{LOADING_UNIT[el.unloading]}</TableCell>
            {el.charges.map(({ vehicle_option, loading_charge }) => (
              <TableCell key={vehicle_option} align="center">
                {loading_charge}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TableBox>
  );
}

export default ExtraTruckChargeList;

const useStyles = makeStyles(() => ({
  root: { backgroundColor: "#E7E6E6" },
}));

const TableBox = styled(Table)`
  margin: 0 auto;
  width: 80% !important;
  border: 1px solid #e7e6e6;
`;
