import React from "react";
import styled from "styled-components";
import {
  StyledLink,
  StyledOrderList,
  StyledListTitle,
  StyledListBorder,
  StyledContents,
  StyledList,
  StyledColumn,
  StyledNumber,
} from "../styles";

const StyledAlgoIdColumn = styled(StyledColumn)`
  color: #cc0088;
`;

const StyledIsdIdColumn = styled(StyledColumn)`
  color: #005580;
`;

const StyledDetailColumn = styled(StyledColumn)`
  width: auto;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
  justify-content: "center";
`;

const DetailContents = styled.ul`
  padding-left: 0;
  margin: 0 1.3rem;
  list-style: none;
`;

const OrdersComparedList = ({ ordersComparedProps }) => {
  /**
   * @description 리스트를 구성하는 개별 아이템 컴포넌트
   * @param {{ index, algoId, isdId, details, columnName }} 리스트 구성에 필요한 props
   * @return { object } 개별 아이템 컴포넌트
   */
  const RowList = ({ index, algoId, isdId, details, columnName }) => {
    return (
      <StyledList index={index} columnName={columnName}>
        <StyledColumn columnName={columnName}>{index}</StyledColumn>
        <StyledAlgoIdColumn columnName={columnName}>
          {algoId}
        </StyledAlgoIdColumn>
        <StyledIsdIdColumn columnName={columnName}>{isdId}</StyledIsdIdColumn>
        {/* 비교 사항 컴포넌트 */}
        <StyledDetailColumn columnName={columnName}>
          <ComparisonDetail details={details} columnName={columnName} />
        </StyledDetailColumn>
        <StyledLink
          style={{ visibility: columnName ? "hidden" : "visible" }}
          to={`/order/${algoId}`}
        >
          주문 상세 보기
        </StyledLink>
      </StyledList>
    );
  };

  /**
   * @description 리스트에 표시할 아이템 컴포넌트 배열
   * @return { object } 개별 아이템 컴포넌트 배열
   */
  const rowList = ordersComparedProps.ordersCompared.map((item, index) => (
    <RowList
      key={index}
      index={index + 1}
      algoId={item.algoId}
      isdId={item.isdId}
      details={item.details}
    />
  ));

  /**
   * @description 비교 결과 내역을 ul, li 태그로 구성된 배열 컴포넌트로 생성
   * @param {{ details, columnName }} { details, columnName }
   * @return { object }  비교 결과 내역이 담긴 개별 아이템 컴포넌트
   */
  const ComparisonDetail = ({ details, columnName }) => {
    let comparisonDetails = [];
    if (!columnName) {
      // details : { 항목1 : [algo, isd], 항목2 : [algo, isd], 항목3 : [algo, isd], ... }
      for (let detail in details) {
        details[detail].unshift(detail);
        // details[detail] : [항목1, algo, isd]
        comparisonDetails.push(details[detail]);
        // comparisonDetails : [[항목1, algo, isd], [항목2, algo, isd], [항목3, algo, isd], ...]
      }
    }
    const comparisonDetail = comparisonDetails.map((item, index) => (
      /**
       * comparisonDetail:
       * [
       *  <ul>
       *    <li>항목1</li>
       *    <li>algo</li>
       *    <li>isd</li>
       *  </ul>,
       *  <ul>
       *    <li>항목2</li>
       *    <li>algo</li>
       *    <li>isd</li>
       *  </ul>,
       *    ...
       * ]
       */
      <DetailContents key={index}>
        {item.map((item, index) => (
          <li
            key={index}
            style={{
              color: `${
                index === 0 ? "#404040" : index === 1 ? "#cc0088" : "#005580"
              }`,
            }}
          >
            {item}
          </li>
        ))}
      </DetailContents>
    ));
    return columnName ? <>{details}</> : <>{comparisonDetail}</>;
  };

  return (
    <>
      <StyledOrderList>
        <StyledListTitle>
          주문내역 비교(
          <StyledNumber number={rowList.length}>{rowList.length}</StyledNumber>
          )건
        </StyledListTitle>
        <StyledListBorder>
          <StyledContents>
            <RowList
              index="순번"
              algoId="알고퀵"
              isdId="인성"
              details="비교 사항"
              columnName={true}
            />
            {ordersComparedProps.isLoading ? null : rowList}
          </StyledContents>
        </StyledListBorder>
      </StyledOrderList>
    </>
  );
};

export default OrdersComparedList;
