import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";

type WidthTextFieldProps = Omit<TextFieldProps, "width"> & { width?: string };

const StyledTextField = styled(({ width, ...props }: WidthTextFieldProps) => (
  <TextField {...props} />
))`
  width: ${(props) => props.width};
`;

export function WidthTextField({ children, ...props }: WidthTextFieldProps) {
  return <StyledTextField {...props}>{children}</StyledTextField>;
}

export function HalfTextField({ children, ...props }: WidthTextFieldProps) {
  return (
    <WidthTextField {...props} width="50%">
      {children}
    </WidthTextField>
  );
}

export function QuarterTextField({ children, ...props }: WidthTextFieldProps) {
  return (
    <WidthTextField {...props} width="25%">
      {children}
    </WidthTextField>
  );
}

export function OneThirdTextField({ children, ...props }: WidthTextFieldProps) {
  return (
    <WidthTextField {...props} width="33%">
      {children}
    </WidthTextField>
  );
}

export function OneFifthTextField({ children, ...props }: WidthTextFieldProps) {
  return (
    <WidthTextField {...props} width="20%">
      {children}
    </WidthTextField>
  );
}
