import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Snackbar } from "@material-ui/core";

import InquiryList from "../../components/corporationInquiry/InquiryList";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import { getInquiriesList } from "../../lib/hasura/orders";
import { destructResponse } from "../../lib/hasura/common";
import { InquiryListRowType } from "./types";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";

function InquiryContainer() {
  const dispatch = useDispatch();

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  // inquiries
  const [inquiries, setInquiries] = useState<InquiryListRowType[]>([]);
  const [inquiriesCount, setInquiriesCount] = useState(0);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(startGlobalLoading());
    try {
      const response = await destructResponse<InquiryListRowType[]>(
        "corporations_corporationinquiry",
        () => getInquiriesList()
      );
      const inquiries = response.map((inquiry) => ({
        ...inquiry,
        checkerFullname: inquiry.users_user?.fullname || "",
      }));

      setInquiries(inquiries);
      setInquiriesCount(response.length);
    } catch (err) {
      console.log(err);
      openSnackbar(
        "법인신청 정보를 받아올 수 없습니다. 개발팀에 문의해주세요.",
        true
      );
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  /* pagination */
  const handleChangePage = (e: React.MouseEvent | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeFirst = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseFloat(e.target.value)); // 25 50 100 페이지 뷰
    setPage(0); // 페이지 뷰 변할때마다 첫페이지 렌더링
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnackbar();
  };

  return (
    <>
      <InquiryList
        rows={inquiries}
        count={inquiriesCount}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeFirst={handleChangeFirst}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}
export default InquiryContainer;
