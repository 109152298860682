import { combineReducers } from "redux";
import { all, fork } from "redux-saga/effects";
import auth, { authSaga } from "./auth";
import user, { userSaga } from "./user";
import userAuth, { adminAuthSaga } from "./userAuth";
import groupOrder, { groupOrderSaga } from "./groupOrder";
import loading from "./loading";
import sendBill, { sendBillSaga } from "./sendBill";
import squareBundleOrder, { squareBundleOrderSaga } from "./squareBundle";

const rootReducer = combineReducers({
  auth,
  user,
  userAuth,
  loading,
  groupOrder,
  sendBill,
  squareBundleOrder,
});

export function* rootSaga() {
  yield all([
    fork(groupOrderSaga),
    fork(authSaga),
    fork(userSaga),
    fork(adminAuthSaga),
    fork(sendBillSaga),
    fork(squareBundleOrderSaga),
  ]);
}

export default rootReducer;
