import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import * as XLSX from "xlsx";
import CustomExcelDownload from "../../../lib/excel/CustomExcelDownload";
import CustomExcelUpload from "../../../lib/excel/CustomExcelUpload";
import {
  createNetworkSingleCorpCharge,
  createSingleCorpCharge,
  deleteAllSingleCorpCharge,
} from "../../../lib/hasura/corporations";
import { convertExcelToData } from "../SingleCorpChargeFunction";
import { EXCEL_ROWS } from "../SingleCorpChargeFunction";
import {
  CorpChargeByLocVeh,
  CorpChargeDataType,
  UploadDataType,
} from "../../../containers/singleCorpCharge/types";
import { postLog } from "../../../lib/hasura/users";
import { LOG_TYPE } from "../../../lib/constants/constants";
import { sleep } from "../../../lib/core";
import {
  startGlobalLoading,
  finishGlobalLoading,
} from "../../../modules/loading";

interface ChargeHeadProps {
  corpId: string;
  corpName: string;
  chargeList: CorpChargeByLocVeh[];
  uploadData: UploadDataType[];
  setUploadData: React.Dispatch<React.SetStateAction<UploadDataType[]>>;
  fetchData: () => void;
  openSnackbar: (message: string, isError?: boolean) => void;
}

function CorpChargeHeader({
  corpId,
  corpName,
  chargeList,
  uploadData,
  setUploadData,
  fetchData,
  openSnackbar,
}: ChargeHeadProps) {
  const dispatch = useDispatch();
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  // 엑셀 양식 다운로드
  const sampleDownload = (e: React.MouseEvent) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.href = `https://algoquick-email-asset.s3.ap-northeast-2.amazonaws.com/algoquick_corpcharge_250122.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // 법인 요금 다운로드
  const excelDownload = () => {
    const fileName = `${corpName} 법인 거래처 요금.xlsx`;
    const excelData = chargeList.map((el) => {
      return EXCEL_ROWS.reduce((acc, { label, format }) => {
        if (!el) acc[label] = "";
        else if (format !== undefined) acc[label] = format(el);

        return acc;
      }, {} as CorpChargeDataType);
    });

    const workSheet = XLSX.utils.json_to_sheet(excelData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "거래처 요금");
    XLSX.writeFile(workBook, fileName);
  };
  const handleDownload = async () => {
    if (chargeList.length === 0)
      return openSnackbar("다운로드할 데이터가 없습니다.", true);

    dispatch(startGlobalLoading());
    try {
      excelDownload();
      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.EXCEL,
        `${corpName} 거래처요금 다운로드`,
        "거래처요금"
      );
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // 법인 요금 업로드
  const handleFile = (file: File) => {
    const reader = new FileReader();

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const data = e.target && e.target.result;

      const workbook = XLSX.read(data, { type: "array" });
      const worksheetName = workbook.SheetNames[0];
      const workSheet = workbook.Sheets[worksheetName];
      const result: UploadDataType[] = XLSX.utils.sheet_to_json(workSheet, {
        // header: 1,
        defval: "",
        blankrows: false,
      });

      if (result.length < 1) {
        const alertMsg = "데이터를 입력하지 않은 엑셀파일인지 확인해주세요";
        return openSnackbar(alertMsg, true);
      }

      try {
        dispatch(startGlobalLoading());
        const convertedResult = await convertExcelToData(result);
        setUploadData(convertedResult);
      } catch (error) {
        openSnackbar(
          (error as Error).message || "데이터 변경에 실패하였습니다.",
          true
        );
      } finally {
        dispatch(finishGlobalLoading());
      }
    };
    reader.readAsArrayBuffer(file);
  };
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      handleFile(files[0]);
      e.target.value = ""; // 같은 파일을 다시 올리면 인지하지 못하므로 여기서 초기화!!
    }
  };

  // 법인 요금 업로드 전송
  const onSubmit = async () => {
    dispatch(startGlobalLoading());
    try {
      await submitData(corpId);
      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.EXCEL,
        `${corpName} 거래처요금 업로드`,
        "거래처요금"
      );
      openSnackbar("요금 등록이 완료되었습니다.");
    } catch (error) {
      openSnackbar(`요금 등록에 실패하였습니다.\n${error}`, true);
    } finally {
      fetchData();
      setUploadData([]);
      dispatch(finishGlobalLoading());
    }
  };
  const submitData = async (corporation_id: string) => {
    await uploadData.reduce(
      async (
        accPromise: any,
        { departure_id, destination_id, vehicle_type, charge }
      ) => {
        const acc = await accPromise;
        if (vehicle_type === "MOTORCYCLE" || vehicle_type === "DAMAS") {
          await createNetworkSingleCorpCharge({
            corporation_id,
            departure_id,
            destination_id,
            vehicle_type,
            charge,
          });
        } else {
          await createSingleCorpCharge({
            corporation_id,
            departure_id,
            destination_id,
            vehicle_type,
            AlgoQuickCharge_id: departure_id + destination_id,
            charge,
          });
        }
        await sleep(1000);
        return acc;
      },
      []
    );
  };

  // 법인 요금 전체 삭제
  const handleAllDelete = async () => {
    const alertMsg = "삭제할 데이터가 없습니다.";
    if (chargeList.length === 0) return openSnackbar(alertMsg, true);
    const deleteMsg = "해당 법인의 모든 요금이 삭제됩니다. 진행하시겠습니까?";
    if (!confirm(deleteMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await deleteAllSingleCorpCharge(corpId);

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 거래처요금 전체삭제`,
        "거래처요금"
      );
      openSnackbar("요금이 전부 삭제되었습니다.");
    } catch (error) {
      openSnackbar(`전체 삭제에 실패하였습니다. ${error}`, true);
    } finally {
      fetchData();
      dispatch(finishGlobalLoading());
    }
  };

  return (
    <>
      <Headers>
        <ButtonWrap>
          <CustomExcelUpload
            isIcon={false}
            buttonName={"법인 요금 업로드"}
            onChange={handleUpload}
          />
          <CustomExcelDownload
            isIcon={false}
            buttonName={"법인 요금 다운로드"}
            color="primary"
            onClick={handleDownload}
          />
        </ButtonWrap>
        <CorporationTitle>
          <span>{corpName}</span>
          {chargeList.length > 0 && (
            <span className="counts">{`(${chargeList.length})`}</span>
          )}
        </CorporationTitle>
        <ButtonWrap>
          <CustomExcelDownload isIcon={true} onClick={sampleDownload} />
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={handleAllDelete}
          >
            법인 요금 전체 삭제
          </Button>
        </ButtonWrap>
      </Headers>
      {uploadData.length > 0 && (
        <Notice>
          <div>
            {corpName} <b>{uploadData.length}</b>개의 데이터를 변경하였습니다.
            요금을 등록하시겠습니까?
          </div>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={onSubmit}
          >
            요금 등록
          </Button>
        </Notice>
      )}
    </>
  );
}

export default CorpChargeHeader;

const Headers = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  width: 100%;
`;

const CorporationTitle = styled.h1`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 25px;
  .counts {
    font-size: 20px;
    font-weight: 500;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  gap: 10px;
`;

const Notice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 25px 0;
  gap: 5rem;
  width: 100%;
  border: 1px solid #d3d3d3;

  .button-text {
    margin: auto 0;
  }
`;
