import React from "react";

import { Button } from "@material-ui/core";
import { CloudDownload, CloudUpload } from "@material-ui/icons";
import { ButtonWrapper, ExcelUploadForm } from "../../pages/GroupOrder/style";
import { SheetJSFT } from "../../pages/GroupOrder/utils";

type ExcelFormProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileName: string;
  fetchJibunAddress: () => void;
  sampleDownload: (e: React.MouseEvent) => void;
};

function ExcelForm({
  onChange,
  fileName,
  fetchJibunAddress,
  sampleDownload,
}: ExcelFormProps) {
  return (
    <>
      <ExcelUploadForm className="form-inline">
        <div className="form-group">
          <div className="down-container">
            <Button
              className="excel-button"
              variant="outlined"
              color="default"
              size="large"
              startIcon={<CloudDownload />}
              onClick={sampleDownload}
            >
              엑셀양식 다운로드
            </Button>
          </div>

          <label htmlFor="excel-upload" className="excel-upload">
            <Button
              className="excel-button"
              variant="outlined"
              color="default"
              component="span"
              size="large"
              startIcon={<CloudUpload />}
            >
              엑셀파일 업로드
            </Button>
          </label>
          <input
            type="file"
            className="form-control"
            id="excel-upload"
            accept={SheetJSFT}
            style={{ display: "none" }}
            onChange={onChange}
          />
          <p>{fileName && `파일 이름 : ${fileName}`}</p>
        </div>
      </ExcelUploadForm>
      <ButtonWrapper
        variant="contained"
        color="primary"
        onClick={fetchJibunAddress}
      >
        지번주소변환
      </ButtonWrapper>
    </>
  );
}

export default ExcelForm;
