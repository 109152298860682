import React from "react";
import { useHistory } from "react-router";
import DepartmentDetailContainer from "../containers/department/DepartmentDetailContainer";
import DepartmentAddContainer from "../containers/department/DepartmentAddContainer";

export default function DepartmentDetailPage() {
  const history = useHistory();
  const route = history.location.pathname.replace("/department/", "");
  return (
    <>
      {route === "add" ? (
        <DepartmentAddContainer />
      ) : (
        <DepartmentDetailContainer />
      )}
    </>
  );
}
