import React from "react";
import styled from "styled-components";
import { Button, TextField } from "@material-ui/core";

import OrderSwapDetail from "./OrderSwapDetail";
import { OrderByPkType } from "../../containers/order/types";
import { SwapOrderProps } from "../../containers/order/OrderSwapContainer";
import { FlexBox, FlexColumnCenter, RowDivider } from "../common/Base";

type OrderSwapProps = {
  row: OrderByPkType;
  isdNumber: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  swapOrder: SwapOrderProps;
  onSubmit: () => void;
  swap: boolean;
  swapSubmit: () => void;
};

function OrderSwap({
  row,
  isdNumber,
  onChange,
  swapOrder,
  onSubmit,
  swap,
  swapSubmit,
}: OrderSwapProps) {
  return (
    <FlexColumnCenter>
      <FlexBox>
        <TextField
          type="number"
          label="인성 주문 번호"
          id="swap-isd-number"
          name="swap-isd-number"
          value={isdNumber}
          onChange={onChange}
          variant="outlined"
          margin="normal"
        />
        <StyledButton variant="contained" color="primary" onClick={onSubmit}>
          검색
        </StyledButton>
        {swap && (
          <StyledButton
            variant="contained"
            color="secondary"
            onClick={swapSubmit}
          >
            변경
          </StyledButton>
        )}
      </FlexBox>
      <RowDivider />
      <FlexBox>
        <OrderSwapDetail row={row} />
        <OrderSwapDetail row={swapOrder} />
      </FlexBox>
    </FlexColumnCenter>
  );
}

export default OrderSwap;

const StyledButton = styled(Button)`
  margin: 16px 0 8px 8px !important;
`;
