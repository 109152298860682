import React, { useState, useEffect } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Snackbar } from "@material-ui/core";

import CorporationDetail from "../../components/corporation/CorporationDetail";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { CorpProps, CorpPropsKey, DEFAULT_CORP_ADD } from "./types";
import { HTMLTargetEvent } from "../../lib/constants/orders/types";
import { LOG_TYPE } from "../../lib/constants/constants";
import { createCorp } from "../../lib/hasura/corporations";
import { validateCorp } from "../../lib/validate";
import { postLog } from "../../lib/hasura/users";

function CorporationAddContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const [corp, setCorp] = useState<CorpProps>(DEFAULT_CORP_ADD);
  const [chipData, setChipData] = useState<any>([]);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // 사용자의 채널정보에 따라 법인채널 설정이 가능
  useEffect(() => {
    const channel = user?.channel || "algoquick";
    setCorp({ ...corp, channel });
  }, [user]);

  const onChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: any;
      checked?: boolean;
    }>
  ) => {
    e.preventDefault();
    const { name, value, checked } = e.target;
    switch (name) {
      case "order_client_fix_enable":
      case "billing_department_enable":
      case "costcenter_change_memo_enable":
      case "corporate_account_enable":
      case "corporate_order_memo_enable":
      case "corporate_card_memo_enable":
      case "express_order_memo_enable":
      case "corporate_credit_enable":
      case "corporate_department_enable":
      case "corporate_department_select_enable":
        setCorp({
          ...corp,
          [name]: checked,
        });
        break;
      case "corporate_email_address_list":
        setCorp({
          ...corp,
          [name]: chipData,
        });
        break;
      default:
        // 법인명, 채널, 관리자
        setCorp({
          ...corp,
          [name as string]: value,
        });
        break;
    }
  };

  const onChangeRadioSync = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    switch (name) {
      case "address_book_enable":
        setCorp({
          ...corp,
          [name]: checked,
          address_book_type: checked ? "CORPORATION" : null,
        });
        break;
      default:
        break;
    }
  };

  const onChangeLowerSync = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const dependencies: CorpPropsKey[] = [];
    switch (name) {
      case "budget_enable":
        dependencies.push("restrict_by_budget");
        break;
      case "order_approval_enable":
        dependencies.push(
          "order_approval_user_select_enable",
          "order_approval_time_enable",
          "order_approval_time_holiday",
          "order_approval_time_weekend"
        );
        break;
      case "order_approval_time_enable":
        dependencies.push(
          "order_approval_time_holiday",
          "order_approval_time_weekend"
        );
        break;
      default:
        break;
    }

    const sync: Record<string, boolean> = {};
    if (!checked)
      dependencies.forEach((name) => {
        sync[name] = false;
      });
    setCorp((corp) => ({ ...corp, ...sync, [name]: checked }));
  };

  const onChangeUpperSync = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const dependencies: CorpPropsKey[] = [];
    switch (name) {
      case "restrict_by_budget":
        dependencies.push("budget_enable");
        break;
      case "order_approval_user_select_enable":
      case "order_approval_time_enable":
        dependencies.push("order_approval_enable");
        break;
      case "order_approval_time_holiday":
      case "order_approval_time_weekend":
        dependencies.push(
          "order_approval_enable",
          "order_approval_time_enable"
        );
        break;
      default:
        break;
    }

    const sync: Record<string, boolean> = {};
    if (checked)
      dependencies.forEach((name) => {
        sync[name] = true;
      });
    setCorp((corp) => ({ ...corp, ...sync, [name]: checked }));
  };

  const handleDateChange = (name: string, date: Date | null) => {
    switch (name) {
      case "contracted":
        setCorp({
          ...corp,
          contracted: date?.toISOString().slice(0, 10) as string,
        });
        break;
      case "order_approval_time_start": {
        const h = String(date?.getHours() || 0).padStart(2, "0");
        const m = String(date?.getMinutes() || 0).padStart(2, "0");
        setCorp({
          ...corp,
          order_approval_time_start: `${h}:${m}`,
        });
        break;
      }
      case "order_approval_time_end": {
        const h = String(date?.getHours() || 24).padStart(2, "0");
        const m = String(date?.getMinutes() || 0).padStart(2, "0");
        setCorp({
          ...corp,
          order_approval_time_end: `${h}:${m}`,
        });
        break;
      }
      default:
        break;
    }
  };

  /* --- email list chips --- */
  const handleKeyDown = (e: HTMLTargetEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const duplicatedValue = chipData.indexOf(e.target.value.trim());
      if (duplicatedValue !== -1 || e.target.value.trim() === "") {
        e.target.value = "";
        return;
      }

      setChipData(chipData.concat(e.target.value.trim()));
      setCorp({
        ...corp,
        corporate_email_address_list: chipData.concat(e.target.value.trim()),
      });
      e.target.value = "";
    }
  };
  const handleDelete = (chipToDelete: string) => () => {
    setChipData(chipData.filter((chip: string) => chip !== chipToDelete));
    setCorp({
      ...corp,
      corporate_email_address_list: chipData.filter(
        (chip: string) => chip !== chipToDelete
      ),
    });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    try {
      const invalidMessage = validateCorp(corp);
      if (invalidMessage !== "") throw new Error(invalidMessage);

      const {
        data: { errors },
      } = await createCorp(corp);
      if (errors !== undefined) throw new Error();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `법인 생성: ${corp.name}`,
        "법인 관리"
      );
      openSnackbar("신규 생성을 완료했습니다.");
    } catch (error) {
      const message =
        (error as Error).message ||
        `${corp.name} 생성을 실패했습니다.\n데이터를 확인해주세요.`;
      openSnackbar(message, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
    if (!error) history.push("/corporations");
  };

  return (
    <>
      <CorporationDetail
        corp={corp}
        onChange={onChange}
        onChangeRadioSync={onChangeRadioSync}
        onChangeLowerSync={onChangeLowerSync}
        onChangeUpperSync={onChangeUpperSync}
        onSubmit={onSubmit}
        handleDateChange={handleDateChange}
        action="add"
        chipData={chipData}
        setChipData={setChipData}
        handleKeyDown={handleKeyDown}
        handleDelete={handleDelete}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default CorporationAddContainer;
