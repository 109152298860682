export type AdminUserAuthsType = {
  [x: string]: any;
  custom1: string | null;
  etc1: boolean;
  excel: boolean;
  menu_id: string;
  privacy: boolean;
  search: boolean;
};

export type AdminUserType = {
  auth_level: string;
  id: number;
  ip_address: string[];
  is_external_access: boolean;
  otp_key: string;
  user_id: string;
  users_user: {
    corporations_corporation: null | {
      id: string;
      name: string;
    };
    corporations_department: null | {
      id: number;
      name: string;
    };
    fullname: string;
    username: string;
    users_adminuserauths: AdminUserAuthsType[];
  };
};

export type AuthUserDetailType = {
  auth_level: string;
  id: number;
  ip_address: string[];
  is_external_access: boolean;
  otp_key: string;
  user_id: string;
  corporation_id: string;
  corporation_name: string;
  department_id: string | number;
  department_name: string;
  fullname: string;
  username: string;
};

export type UsersListType = {
  id: string;
  isd_c_code: string;
  username: string;
  fullname: string;
  phone: string;
  platform: string[];
  corporations_corporation: {
    name: string;
  };
  corporations_department: {
    name: string;
  };
};

export type AddAuthDataType = typeof ADD_AUTH_DATA & {
  [key: string]: any;
};
export const ADD_AUTH_DATA = {
  menu_id: "",
  search: true,
  privacy: false,
  excel: true,
  etc1: false,
  custom1: "",
};

type AuthLevelsType = {
  [x: string]: number;
  master: number;
  manager: number;
  user: number;
  g1_master: number;
  customer: number;
};
export const AuthLevels: AuthLevelsType = {
  master: 1,
  manager: 2,
  user: 3,
  g1_master: 4,
  customer: 5,
};

type MenuType = {
  [index: string]: string;
  ORDERS: string;
  GROUPORDERS: string;
  BUNDLE: string;
  SQUAREBUNDLE: string;
  NETWORK: string;
  CORPORATIONS: string;
  CORPORATIONS_DEPARTMENTS: string;
  CORPORATIONS_CHARGE: string;
  USERS: string;
  USERS_AUTH: string;
  SCHEDULER: string;
  SENDBILL: string;
  ADDRESSDETAIL: string;
  LARGECHARGE: string;
  INQUIRY: string;
  RIDERREVIEW: string;
  LOGS: string;
  SETTINGS: string;
};
export const MENU: MenuType = {
  ORDERS: "주문정보",
  GROUPORDERS: "단체주문",
  BUNDLE: "법정동 단위묶음배송",
  SQUAREBUNDLE: "Square 단위묶음배송",
  NETWORK: "터미널 연계배송",
  CORPORATIONS: "법인관리",
  CORPORATIONS_DEPARTMENTS: "부서관리",
  CORPORATIONS_CHARGE: "거래처요금",
  USERS: "회원관리",
  USERS_AUTH: "회원권한관리",
  SCHEDULER: "스케쥴러 설정",
  SENDBILL: "청구서발송",
  ADDRESSDETAIL: "상세주소등록",
  LARGECHARGE: "대량주소표준화 + 대량요금조회",
  INQUIRY: "법인신청조회",
  RIDERREVIEW: "기사평점조회",
  LOGS: "로그기록조회",
  SETTINGS: "환경설정",
};

export const headRows = [
  { id: "username", numeric: false, disablePadding: false, label: "아이디" },
  {
    id: "isd_c_code",
    numeric: false,
    disablePadding: false,
    label: "고객코드",
  },
  { id: "fullname", numeric: false, disablePadding: false, label: "이름" },
  { id: "phone", numeric: false, disablePadding: false, label: "연락처" },
  {
    id: "corporation_name",
    numeric: false,
    disablePadding: false,
    label: "법인",
  },
  {
    id: "department_name",
    numeric: false,
    disablePadding: false,
    label: "부서",
  },
  {
    id: "platform",
    numeric: false,
    disablePadding: false,
    label: "가입경로",
  },
];

// UsersAuthList
export const authHeadRows = [
  { id: "username", numeric: false, disablePadding: false, label: "아이디" },
  { id: "ip_address", numeric: false, disablePadding: false, label: "ip주소" },
  { id: "fullname", numeric: false, disablePadding: false, label: "이름" },
  {
    id: "corporation_name",
    numeric: false,
    disablePadding: false,
    label: "법인",
  },
  {
    id: "department_name",
    numeric: false,
    disablePadding: false,
    label: "부서",
  },
  { id: "auth_level", numeric: false, disablePadding: false, label: "권한" },
];

export const authLevelColor: { [key: string]: string } = {
  master: "#ffd8d8",
  manager: "#ffff80",
  user: "#fff",
  g1_master: "#dcdcdc",
  customer: "#dcdcdc",
};

export const filteredMenu = [
  { menuId: "ORDERS", label: "주문정보", letter: 0 },
  { menuId: "GROUPORDERS", label: "단체주문", letter: 1 },
  { menuId: "BUNDLE", label: "법정동 단위묶음배송", letter: 2 },
  { menuId: "SQUAREBUNDLE", label: "Square 단위묶음배송", letter: 3 },
  { menuId: "NETWORK", label: "터미널 연계배송", letter: 4 },
  { menuId: "CORPORATIONS", label: "법인관리", letter: 5 },
  { menuId: "CORPORATIONS_DEPARTMENTS", label: "부서관리", letter: 6 },
  { menuId: "CORPORATIONS_CHARGE", label: "거래처요금", letter: 7 },
  { menuId: "USERS", label: "회원관리", letter: 8 },
  { menuId: "USERS_AUTH", label: "회원권한관리", letter: 9 },
  { menuId: "SCHEDULER", label: "스케쥴러 설정", letter: 10 },
  { menuId: "SENDBILL", label: "청구서발송", letter: 11 },
  { menuId: "ADDRESSDETAIL", label: "상세주소등록", letter: 12 },
  { menuId: "LARGECHARGE", label: "대량주소표준화 + 대량요금조회", letter: 13 },
  { menuId: "INQUIRY", label: "법인신청조회", letter: 14 },
  { menuId: "RIDERREVIEW", label: "기사평점조회", letter: 15 },
  { menuId: "LOGS", label: "로그기록조회", letter: 16 },
  { menuId: "SETTINGS", label: "환경설정", letter: 17 },
];

export const authCheckboxProps = [
  { key: "search", label: "" },
  { key: "privacy", label: "" },
  { key: "excel", label: "" },
  { key: "etc1", label: "" },
];

export const DEFAULT_AUTH_USER = {
  auth_level: "",
  corporation_id: "",
  corporation_name: "",
  department_id: 0,
  department_name: "",
  fullname: "",
  id: 0,
  ip_address: [],
  is_external_access: false,
  otp_key: "",
  user_id: "",
  username: "",
};

export const AdminAuthHeader = [
  "",
  "메뉴",
  "주소/연락처 공개",
  "주문내역 다운",
  "카드 재결제",
  "외부 채널",
  "",
];
