import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";

import {
  CorporationsType,
  headRows,
} from "../../containers/corporations/types";
import { CHANNEL } from "../../lib/constants/constants";
import { stableSort, getSorting } from "../dataTable/base";
import EnhancedTableHead from "../dataTable/EnhancedTableHead";
import Paper from "../common/Paper";

interface CorporationsListProps {
  rows: CorporationsType[];
}

function CorporationsList({ rows }: CorporationsListProps) {
  const history = useHistory();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (e: React.MouseEvent, property: string) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleRowClick = (id: string) => {
    history.push(`/corporation/${id}`);
  };

  return (
    <Paper>
      <Table>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headRows={headRows}
        />
        <TableBody>
          {stableSort(rows, getSorting(order, orderBy)).map(
            (row: CorporationsType) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  onClick={() => handleRowClick(row.id)}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{CHANNEL[row.channel]}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{row.users_user?.username || " "}</TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.registration_number}</TableCell>
                  <TableCell>{row.contracted}</TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={row.corporate_account_enable}
                          color="primary"
                          disabled
                        />
                      }
                      id="corporate_account_enable"
                      name="corporate_account_enable"
                      label=""
                    />
                  </TableCell>
                  <TableCell>{row.discount_rate} %</TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default CorporationsList;
