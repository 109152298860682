import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Stack,
  Toolbar,
} from "@mui/material";
import { Button, MenuItem, Snackbar } from "@material-ui/core";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";
import {
  getLocationL1,
  getLocationL2,
  LocationL1,
  LocationL2,
} from "../../lib/api/base";
import { destructResponse } from "../../lib/hasura/common";
import {
  deleteNetworkTerminal,
  getNetworkTerminals,
} from "../../lib/hasura/network";
import PaginatedTable from "../../components/common/PaginatedTable";
import { finishGlobalLoading, startGlobalLoading } from "../../modules/loading";

type NetworkSearchParams = {
  type: string;
  senderL1: string;
  senderL2: string;
  receiverL1: string;
  receiverL2: string;
};

export type NetworkTerminalRow = {
  id: number;
  network_type: "EXPRESSBUS" | "KTX" | "AIR";
  receiver_locationl1: { name: string };
  receiver_locationl2: { name: string } | null;
  receiver_network_name: string;
  sender_locationl1: { name: string };
  sender_locationl2: { name: string } | null;
  sender_network_name: string;
};

function NetworkContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [locationL1, setLocationL1] = useState<LocationL1[]>([]);
  const [senderL2, setSenderL2] = useState<LocationL2[]>([]);
  const [receiverL2, setReceiverL2] = useState<LocationL2[]>([]);
  const [searchParams, setSearchParams] = useState<NetworkSearchParams>({
    type: "EXPRESSBUS",
    senderL1: "",
    senderL2: "",
    receiverL1: "",
    receiverL2: "",
  });
  const [rows, setRows] = useState<NetworkTerminalRow[]>([]);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();
  const handleClose = (_: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnackbar();
  };

  useEffect(() => {
    const fetch = async () => {
      const results: LocationL1[] = await getLocationL1();
      setLocationL1(results);
    };
    fetch();
  }, []);

  const fetchLocationL2 = async (
    target: "sender" | "receiver",
    l1Id: string
  ) => {
    const results = await getLocationL2(l1Id);
    const sorted = results.sort((a, b) => a.name.localeCompare(b.name)) || [];
    if (target === "sender") setSenderL2(sorted);
    else if (target === "receiver") setReceiverL2(sorted);
  };

  const onSearch = async () => {
    if (!searchParams.senderL1)
      return openSnackbar("출발지 정보를 입력하세요.", true);
    if (!searchParams.receiverL1)
      return openSnackbar("도착지 정보를 입력하세요.", true);

    dispatch(startGlobalLoading());
    const response = await destructResponse<NetworkTerminalRow[]>(
      "algoquick_network",
      () => getNetworkTerminals(searchParams)
    );
    setRows(response);
    dispatch(finishGlobalLoading());
  };

  const onRowDelete = async (row: NetworkTerminalRow) => {
    if (!confirm("해당 연계배송 지역을 삭제하시겠습니까?")) return;

    dispatch(startGlobalLoading());
    await deleteNetworkTerminal(row.id);
    onSearch();
    dispatch(finishGlobalLoading());
  };

  return (
    <>
      <Toolbar sx={{ justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/network/add");
          }}
        >
          터미널 등록
        </Button>
      </Toolbar>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        mb={2}
        gap={2}
      >
        <Box>
          <FormControl variant="standard">
            <InputLabel id="type-select-label">연계 구분</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={searchParams.type}
              label="연계 구분"
              onChange={(e) => {
                setSearchParams({
                  ...searchParams,
                  type: e.target.value,
                });
              }}
            >
              <MenuItem value="EXPRESSBUS">고속버스</MenuItem>
              <MenuItem value="KTX">KTX</MenuItem>
              <MenuItem value="AIR">항공</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box width={400} display="grid" gridTemplateColumns="3fr 5fr">
          <FormControl variant="standard">
            <InputLabel id="sender-l1-select-label">출발지 시/도</InputLabel>
            <Select
              labelId="sender-l1-select-label"
              id="sender-l1-select"
              value={searchParams.senderL1}
              onChange={(e) => {
                setSearchParams({
                  ...searchParams,
                  senderL1: e.target.value,
                  senderL2: "",
                });
                fetchLocationL2("sender", e.target.value);
              }}
              label="출발지 시/도"
            >
              {locationL1.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard">
            <InputLabel id="sender-l2-select-label">출발지 시/군/구</InputLabel>
            <Select
              labelId="sender-l2-select-label"
              id="sender-l2-select"
              value={searchParams.senderL2}
              onChange={(e) => {
                setSearchParams({ ...searchParams, senderL2: e.target.value });
              }}
              label="출발지 시/군/구"
              disabled={!searchParams.senderL1}
            >
              {senderL2.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            {senderL2.length === 0 ? (
              <FormHelperText>시/도를 먼저 선택해주세요.</FormHelperText>
            ) : (
              <FormHelperText>선택하지 않으면, 전체 검색합니다.</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box width={400} display="grid" gridTemplateColumns="3fr 5fr">
          <FormControl variant="standard">
            <InputLabel id="receiver-l1-select-label">도착지 시/도</InputLabel>
            <Select
              labelId="receiver-l1-select-label"
              id="receiver-l1-select"
              value={searchParams.receiverL1}
              onChange={(e) => {
                setSearchParams({
                  ...searchParams,
                  receiverL1: e.target.value,
                  receiverL2: "",
                });
                fetchLocationL2("receiver", e.target.value);
              }}
              label="도착지 시/도"
            >
              {locationL1.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard">
            <InputLabel id="receiver-l2-select-label">
              도착지 시/군/구
            </InputLabel>
            <Select
              labelId="receiver-l2-select-label"
              id="receiver-l2-select"
              value={searchParams.receiverL2}
              onChange={(e) => {
                setSearchParams({
                  ...searchParams,
                  receiverL2: e.target.value,
                });
              }}
              label="도착지 시/군/구"
              disabled={!searchParams.receiverL1}
            >
              {receiverL2.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            {receiverL2.length === 0 ? (
              <FormHelperText>시/도를 먼저 선택해주세요.</FormHelperText>
            ) : (
              <FormHelperText>선택하지 않으면, 전체 검색합니다.</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box marginTop="12px" marginBottom="24px">
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={onSearch}
          >
            검색
          </Button>
        </Box>
      </Stack>
      <PaginatedTable
        headCells={[
          {
            id: "network_type",
            label: "연계 구분",
            formatter: (row) =>
              ({
                EXPRESSBUS: "고속버스",
                KTX: "KTX",
                AIR: "항공",
              })[row.network_type],
          },
          {
            id: "sender_locationl1",
            label: "출발지 시/도",
            formatter: (row) => row.sender_locationl1.name,
          },
          {
            id: "sender_locationl2",
            label: "출발지 시/군/구",
            formatter: (row) => row.sender_locationl2?.name || "",
          },
          { id: "sender_network_name", label: "출발지 터미널" },
          {
            id: "receiver_locationl1",
            label: "도착지 시/도",
            formatter: (row) => row.receiver_locationl1.name,
          },
          {
            id: "receiver_locationl2",
            label: "도착지 시/군/구",
            formatter: (row) => row.receiver_locationl2?.name || "",
          },
          { id: "receiver_network_name", label: "도착지 터미널" },
        ]}
        rows={rows}
        rowActions={[
          {
            id: "delete",
            label: "삭제",
            component: (row: NetworkTerminalRow) => (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => onRowDelete(row)}
              >
                삭제
              </Button>
            ),
          },
        ]}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default NetworkContainer;
