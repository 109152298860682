import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { useHistory, withRouter, RouteComponentProps } from "react-router-dom";
import { Snackbar } from "@material-ui/core";

import CorporationBulk from "../../components/corporation/CorporationBulk";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import { destructResponse } from "../../lib/hasura/common";
import { getCorpBulk, updateCorpBulk } from "../../lib/hasura/corporations";
import { DEFAULT_BULK_DATA, RESET_BULK, BulkType } from "./types";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { postLog } from "../../lib/hasura/users";
import { LOG_TYPE } from "../../lib/constants/constants";

const CorporationBulkContainer = ({
  match,
}: RouteComponentProps<{ id: string }>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const corpId = match.params.id;
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const [bulk, setBulk] = useState<BulkType>(DEFAULT_BULK_DATA);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchData();
  }, [corpId]);

  const fetchData = async () => {
    dispatch(startGlobalLoading());
    try {
      const response = await destructResponse<BulkType>(
        "corporations_motorcyclebulkscore_by_pk",
        () => getCorpBulk(corpId)
      );

      setBulk(response);
    } catch (err) {
      openSnackbar(`과적 포인트 조회에 실패했습니다.\n${err}`, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setBulk({
      ...bulk,
      [name]: parseFloat(value),
    });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    try {
      const {
        data: { errors },
      } = await updateCorpBulk(bulk);
      if (errors !== undefined) throw new Error(errors[0].message);

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${bulk?.corporations_corporation?.name} 과적 포인트 변경`,
        "법인 관리"
      );
      openSnackbar("변경되었습니다.");
    } catch (err) {
      openSnackbar(`변경에 실패하였습니다.\n${(err as Error).message}`, true);
    } finally {
      dispatch(finishGlobalLoading());
      fetchData();
    }
  };

  const resetSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!confirm("초기화 하시겠습니까?")) return;
    dispatch(startGlobalLoading());
    try {
      const {
        data: { errors },
      } = await updateCorpBulk({ ...bulk, ...RESET_BULK });
      if (errors !== undefined) throw new Error(errors[0].message);

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${bulk?.corporations_corporation?.name} 과적 포인트 초기화`,
        "법인 관리"
      );
      openSnackbar("초기화 되었습니다.");
    } catch (err) {
      openSnackbar(`초기화에 실패하였습니다.\n${(err as Error).message}`, true);
    } finally {
      dispatch(finishGlobalLoading());
      fetchData();
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
    if (!error) history.push(`/corporation/${corpId}`);
  };

  return (
    <>
      {bulk?.corporation_id && (
        <CorporationBulk
          row={bulk}
          onChange={onChange}
          onSubmit={onSubmit}
          resetSubmit={resetSubmit}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
};

export default withRouter(CorporationBulkContainer);
