import styled from "styled-components";
import { DialogTitle } from "@material-ui/core";

export const ModalContainer = styled.div`
  width: 90%;
  padding: 1rem 0 5rem 0;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  font-weight: 900 !important;
  text-align: center !important;
  background: indianred !important;
`;

/* react && vue 공통 모달 로직 */
export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;

  background-color: rgba(
    0,
    0,
    0,
    ${({ size }: { size?: number }) => size || 0.4}
  );
  z-index: 100;
`;
