import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Button,
} from "@material-ui/core";
import styled from "styled-components";
import {
  convertVehicleTypeToKorean,
  convertPaymentToKorean,
  removeComma,
} from "../../pages/GroupOrder/utils";
import { useDispatch } from "react-redux";
import { POST_EXCEL_ORDER } from "../../modules/groupOrder";
import ResultDialog from "./ResultDialog";
import SenderDialog from "./SenderDialog";
import { vehicleOptionFilter } from "../../lib/orders/charge";

interface ResultTableProps {
  convertedData: any;
  result: any;
}
interface dialogStateInterface {
  open: boolean;
  index: number;
}
const initialState: dialogStateInterface = { open: false, index: 0 };

function ResultTable({ convertedData, result }: ResultTableProps) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState(initialState);

  const { success, error } = result;
  const isSucceed = success.state;
  const isErrorOccured = error.state;

  // success.data === action.payload.fail
  const failedOrderCount = Object.keys(success.data).length;

  const handleDialog = (state: boolean) => () => {
    setDialogState({ ...dialogState, open: state });
  };

  const openDialog = (index: number) => () => {
    setDialogState({ open: true, index });
  };

  function postExcelOrder() {
    dispatch({ type: POST_EXCEL_ORDER, payload: convertedData });
  }

  /* makeStyles를 함수 내에서 사용 (isSucceed 내부 프로퍼티를 사용하기 위해) */
  const useStylesRow = makeStyles(() => ({
    head: {
      backgroundColor: "whitesmoke",
      whiteSpace: "pre-line",
    },
    root: {
      backgroundColor: `${isSucceed ? "#fdecea" : "none"}`,
      whiteSpace: "pre-line",
      "&:hover": {
        cursor: "pointer",
      },
    },
  }));
  const classesRow = useStylesRow();

  return (
    <>
      <TableWrapper>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className={classesRow.head}>
                <TableCell>{`의뢰인이름\n(연락처)`}</TableCell>
                <TableCell>{`출발지이름\n(연락처)`}</TableCell>
                <TableCell>{`출발지\n전체주소`}</TableCell>
                <TableCell>{`도착지이름\n(연락처)`}</TableCell>
                <TableCell>{`도착지\n전체주소`}</TableCell>
                <TableCell>배송품</TableCell>
                <TableCell>{`차량종류\n차량옵션\n요금\n지불방법`}</TableCell>
                <TableCell>메모</TableCell>
                <TableCell>픽업예약시간</TableCell>
                <TableCell>도착시간범위</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {convertedData.map((row: any, index: number) => (
                <TableRow
                  className={classesRow.root}
                  hover={true}
                  key={`${row.receiverName}${row.receiverPhoneNumber}`}
                  onClick={openDialog(index)}
                >
                  <TableCell>{`${row.clientName}\n(${row.clientPhoneNumber})`}</TableCell>
                  <TableCell>{`${row.senderName}\n(${row.senderPhoneNumber})`}</TableCell>
                  <TableCell>{`${row.senderJibunAddress}\n${row.senderAddressDetail}`}</TableCell>
                  <TableCell>{`${row.receiverName}\n(${row.receiverPhoneNumber})`}</TableCell>
                  <TableCell>{`${row.receiverJibunAddress}\n${row.receiverAddressDetail}`}</TableCell>
                  <TableCell>{row.deliveryItem}</TableCell>
                  <TableCell>
                    {`${convertVehicleTypeToKorean(
                      row.vehicleType
                    )}\n${vehicleOptionFilter(row.vehicleOption)}\n${
                      row.charge && removeComma(row.charge)
                    }\n${convertPaymentToKorean(row.payment)}`}
                  </TableCell>
                  <TableCell>{row.memo}</TableCell>
                  <TableCell>{`${
                    row.pickupRequestTime ? row.pickupRequestTime : ""
                  }`}</TableCell>
                  <TableCell>{`${
                    row.completeRequestTime.lower
                      ? row.completeRequestTime.lower
                      : ""
                  }~\n${
                    row.completeRequestTime.upper
                      ? row.completeRequestTime.upper
                      : ""
                  }`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
      {dialogState.open && (
        <SenderDialog
          dialogState={dialogState}
          handleDialog={handleDialog}
          convertedData={convertedData}
        />
      )}
      {!isSucceed && (
        <StyledButton
          variant="contained"
          color="primary"
          onClick={postExcelOrder}
        >
          주문전송
        </StyledButton>
      )}
      {isSucceed && (
        <ResultDialog
          result={isSucceed}
          title={"🎉 단체주문성공 🎉"}
          text={`단체주문이 완료되었습니다.실패한 주문갯수는 ${failedOrderCount}개 입니다. ${"\n"}빨간색으로 표시된 주문을 확인하시고 직접 주문해주세요`}
        />
      )}
      {isErrorOccured && (
        <ResultDialog
          result={isErrorOccured}
          title={"🚨 단체주문실패 🚨"}
          text={"단체주문전송에 실패하였습니다. 다시 시도해 주세요"}
          error={isErrorOccured}
        />
      )}
    </>
  );
}

export default ResultTable;

const TableWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
`;

const StyledButton = styled(Button)`
  width: 33%;
  display: block !important ;
  margin: 0 auto !important;
`;
