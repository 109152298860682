import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { Search, Check, Close } from "@material-ui/icons";
import Pagination from "../common/Pagination";
import { coord2regioncode } from "../../lib/api/base";
import {
  createNetworkSingleCorpCharge,
  createSingleCorpCharge,
  networkSingleCorpCharge,
  threeSingleCorpCharge,
  truckSingleCorpCharge,
  truckWeightFiveLower,
  truckWeightFiveUpper,
} from "../../lib/hasura/corporations";
import {
  CORPVEHICLE,
  MODALVEHICLE,
  TRUCKOPTION,
  MODALTRUCKOPTION,
} from "../../lib/constants/constants";
import {
  AddressType,
  ChargeDataType,
  CorpChargeByLocVeh,
} from "../../containers/singleCorpCharge/types";
import { registAddress, isValidMessage } from "./SingleCorpChargeFunction";
import { ModalWrapper } from "../common/modal/Modal";

const DEFAULT_CHARGE_DATA = {
  AlgoQuickCharge_id: "",
  charge: 0,
  corporation_id: "",
  departure_id: "",
  destination_id: "",
  vehicle_type: "",
};

interface AddChargeProps {
  corpId: string;
  chargeList: CorpChargeByLocVeh[];
  handleModal: () => void;
  closeRefresh: () => void;
}

function AddChargeModal({
  corpId,
  chargeList,
  handleModal,
  closeRefresh,
}: AddChargeProps) {
  const [departAddress, setDepartAddress] = useState("");
  const [destinateAddress, setDestinateAddress] = useState("");
  const [departure, setDeparture] = useState<AddressType[]>([]);
  const [destination, setDestination] = useState<AddressType[]>([]);

  // 출발지 도착지 차량종류 트럭종류
  const [departCode, setDepartCode] = useState("");
  const [destinateCode, setDestinateCode] = useState("");
  const [vehicleData, setVehicleData] = useState("");
  const [truckData, setTruckData] = useState("");

  const [defaultCharge, setDefaultCharge] = useState(0);
  const [changeCharge, setChangeCharge] = useState("");

  // 온클릭 데이터 띄우기
  const [onDepart, setOnDepart] = useState("");
  const [onDestinate, setOnDestinate] = useState("");
  const [onTruckOption, setOnTruckOption] = useState("");

  const isTruck = (vehicle: string) => vehicle.startsWith("truck");
  const isTruckSelected = isTruck(vehicleData);

  const [chargeData, setChargeData] =
    useState<ChargeDataType>(DEFAULT_CHARGE_DATA);

  /**
   * @description Pagination State[2]
   * <div>의 height를 명시하여 <1,2,3,4> 정렬

   * @return { setCurrentPage } currentPage 상태를 변경하는 setter 함수 를 넘긴다.
   * @return { postsPerPage } 페이지당 포스트 수

   * @return { search } 전체 배열(departure, destination)
   * @return { currentPosts } 전체 배열을 slice함수로 자른 포스트 수
   */
  const [postsPerPage] = useState(5);

  // departure
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLast = currentPage * postsPerPage; // 5 10 15
  const indexOfFirst = indexOfLast - postsPerPage; // 0 5 10
  const currentPosts = (depart: AddressType[]) => {
    return depart.slice(indexOfFirst, indexOfLast);
  };

  // destination
  const [current_Page, setCurrent_Page] = useState(1);
  const indexOf_Last = current_Page * postsPerPage; // 5 10 15
  const indexOf_First = indexOf_Last - postsPerPage; // 0 5 10
  const current_Posts = (destinate: AddressType[]) => {
    return destinate.slice(indexOf_First, indexOf_Last);
  };

  /*  출도착지 입력 */
  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    action: "depart" | "destinate"
  ) => {
    switch (action) {
      case "depart":
        return setDepartAddress(e.target.value);
      case "destinate":
        return setDestinateAddress(e.target.value);
    }
  };

  /* 출도착지 검색 API */
  const departureSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (departAddress === "") {
      alert("검색어를 입력해주세요.");
      return;
    }
    registAddress(departAddress, setDeparture, setCurrentPage);
  };
  const destinationSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (destinateAddress === "") {
      alert("검색어를 입력해주세요.");
      return;
    }
    registAddress(destinateAddress, setDestination, setCurrent_Page);
  };

  /* 출도착지 클릭 */
  const departClick = async ({
    address,
    b_code,
    longitude,
    latitude,
  }: AddressType) => {
    setOnDepart(address);
    if (!b_code) {
      const subCode = await coord2regioncode(longitude, latitude);
      setDepartCode(subCode.b_code.substring(0, 8));
    } else {
      setDepartCode(b_code.substring(0, 8));
    }
  };
  const destinateClick = async ({
    address,
    b_code,
    longitude,
    latitude,
  }: AddressType) => {
    setOnDestinate(address);
    if (!b_code) {
      const sub_Code = await coord2regioncode(longitude, latitude);
      setDestinateCode(sub_Code.b_code.substring(0, 8));
    } else {
      setDestinateCode(b_code.substring(0, 8));
    }
  };

  /* 차량종류 클릭 */
  const vehicleClick = useCallback(async (idx: number) => {
    setVehicleData(MODALVEHICLE[idx]);

    // 트럭 아닐때 트럭옵션 초기화
    if (!isTruck(MODALVEHICLE[idx])) {
      setOnTruckOption("");
      setTruckData("");
    }
  }, []);

  /* 트럭 종류 클릭 */
  const truckOptClick = (idx: number) => {
    if (isTruckSelected) {
      setOnTruckOption(TRUCKOPTION[MODALTRUCKOPTION[idx]]);
      setTruckData(MODALTRUCKOPTION[idx]);
    }
  };

  /* 현재 금액 */
  const handleExpected = useCallback(async () => {
    if (!departCode || !destinateCode) return;

    if (
      vehicleData === "motorcycle" ||
      vehicleData === "damas" ||
      vehicleData === "labo"
    ) {
      const vehicleCharge = await threeSingleCorpCharge({
        departure_id: departCode,
        destination_id: destinateCode,
      });
      for (let keys in vehicleCharge) {
        if (keys === vehicleData) {
          return setDefaultCharge(vehicleCharge[keys]);
        }
      }
    } else if (vehicleData.includes("truck") && truckData) {
      const truckOption = await truckSingleCorpCharge({
        departure_id: departCode,
        destination_id: destinateCode,
      });
      let truckWeight = "";
      let truckCharge = 0;

      for (let keys in truckOption) {
        if (keys === vehicleData) {
          truckWeight = keys;
          truckCharge = truckOption[keys];
        }
      }

      // 트럭 1~5톤
      if (
        truckWeight === "truck_1t" ||
        truckWeight === "truck_1_4t" ||
        truckWeight === "truck_2_5t" ||
        truckWeight === "truck_3_5t" ||
        truckWeight === "truck_5t"
      ) {
        const fiveLower = await truckWeightFiveLower(truckCharge);
        for (let keys in fiveLower) {
          if (keys.split("_charge")[0] === truckData.slice(1))
            return setDefaultCharge(fiveLower[keys]);
        }
      }
      // 트럭 5톤 초과
      else {
        const fiveUpper = await truckWeightFiveUpper(truckCharge);
        for (let keys in fiveUpper) {
          if (keys.split("_charge")[0] === truckData.slice(1))
            return setDefaultCharge(fiveUpper[keys]);
        }
      }
    } else if (vehicleData === "MOTORCYCLE" || vehicleData === "DAMAS") {
      // 연계배송 - 오토바이, 다마스
      const vehicleCharge = await networkSingleCorpCharge({
        departure_id: departCode,
        destination_id: destinateCode,
      });
      const key = vehicleData.toLowerCase();
      return setDefaultCharge(vehicleCharge?.[key] || 0);
    }
  }, [departCode, destinateCode, vehicleData, truckData]);

  useEffect(() => {
    handleExpected();
  }, [handleExpected]);

  /**
   * @descrition 백엔드로 전송하는 chargeData.vehicle_type
   * @returns {오토바이/다마스/라보} vehicleData
   * @returns {트럭} vehicleData + truckData
   */
  useEffect(() => {
    const algoquickId = departCode + destinateCode;

    let truckCheck = "";
    if (vehicleData.includes("truck")) {
      if (truckData === "_truck") truckCheck = vehicleData;
      // 트럭 기본요금
      else truckCheck = vehicleData + truckData; // 트럭 옵션요금
    }

    setChargeData({
      ...chargeData,
      corporation_id: corpId,
      departure_id: departCode,
      destination_id: destinateCode,
      vehicle_type: vehicleData.includes("truck") ? truckCheck : vehicleData,
      AlgoQuickCharge_id: algoquickId,
    });
  }, [corpId, departCode, destinateCode, vehicleData, truckData]);

  // changeCharge
  const createdCharge = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChangeCharge(e.target.value);
  };
  useEffect(() => {
    setChargeData({
      ...chargeData,
      charge:
        changeCharge === "" || changeCharge === "0"
          ? defaultCharge
          : Math.floor(Number(changeCharge) / 1000) * 1000,
    });
  }, [changeCharge]);

  /* 데이터 추가 */
  const onAddData = async (e: React.FormEvent) => {
    e.preventDefault();
    const message = isValidMessage(chargeList, chargeData, truckData);
    if (message) {
      alert(message);
      return;
    }

    try {
      if (
        chargeData.vehicle_type.includes("truck") &&
        chargeData.vehicle_type.length >= 8 &&
        chargeData.vehicle_type.length <= 10
      ) {
        // 트럭(전체/카고): truck_0t, truck_0t_cargo 둘다 등록
        let cargoData = {
          ...chargeData,
          vehicle_type: chargeData.vehicle_type.concat("_cargo"),
        };
        await createSingleCorpCharge(chargeData);
        await createSingleCorpCharge(cargoData);
      } else if (
        chargeData.vehicle_type === "MOTORCYCLE" ||
        chargeData.vehicle_type === "DAMAS"
      ) {
        // 연계배송 - 오토바이, 다마스
        await createNetworkSingleCorpCharge(chargeData);
      } else {
        // 오토바이, 다마스, 라보, 나머지트럭(호루 ~ 냉동윙바디)
        await createSingleCorpCharge(chargeData);
      }

      closeRefresh();
    } catch (err) {
      alert(`요금 등록에 실패하였습니다. ${err}`);
    }
  };

  return (
    <>
      <ModalWrapper>
        <ChargeModal>
          <MenuTable>
            <MenuBody>
              <SearchForm onSubmit={departureSearch}>
                <span>출발지</span>
                <input
                  type="search"
                  placeholder="출발지"
                  onChange={(e) => handleInput(e, "depart")}
                />
                <button>
                  <Search />
                </button>
              </SearchForm>
              <Test>
                <div>
                  {departure &&
                    currentPosts(departure).map(
                      (el: AddressType, idx: number) => (
                        <button key={idx} onClick={() => departClick(el)}>
                          {el.address_name ? (
                            <>{el.address}</>
                          ) : (
                            <>
                              {el.place_name || el.address_detail}
                              <div>{el.address}</div>
                            </>
                          )}
                        </button>
                      )
                    )}
                </div>
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={departure.length}
                  getPaginate={currentPage}
                  setPaginate={setCurrentPage}
                  viewData={onDepart}
                />
              </Test>
            </MenuBody>
            <MenuBody>
              <SearchForm onSubmit={destinationSearch}>
                <span>도착지</span>
                <input
                  type="text"
                  placeholder="도착지"
                  onChange={(e) => handleInput(e, "destinate")}
                />
                <button>
                  <Search />
                </button>
              </SearchForm>
              <Test>
                <div>
                  {destination &&
                    current_Posts(destination).map(
                      (el: AddressType, idx: number) => (
                        <button key={idx} onClick={() => destinateClick(el)}>
                          {el.address_name ? (
                            <>{el.address}</>
                          ) : (
                            <>
                              {el.place_name || el.address_detail}
                              <div>{el.address}</div>
                            </>
                          )}
                        </button>
                      )
                    )}
                </div>
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={destination.length}
                  getPaginate={current_Page}
                  setPaginate={setCurrent_Page}
                  viewData={onDestinate}
                />
              </Test>
            </MenuBody>
            <Vehicle>
              <span>차량종류</span>
              <section>
                {MODALVEHICLE.map((el, idx) => (
                  <div
                    className="enable"
                    key={idx}
                    onClick={() => vehicleClick(idx)}
                  >
                    {CORPVEHICLE[el]}
                  </div>
                ))}
              </section>
              <ViewVehicle>{CORPVEHICLE[vehicleData]}</ViewVehicle>
            </Vehicle>
            <Vehicle>
              <span>트럭종류</span>
              <section>
                {MODALTRUCKOPTION.map((el, idx) => (
                  <div
                    key={idx}
                    className={isTruckSelected ? "enable" : "disable"}
                    onClick={() => truckOptClick(idx)}
                  >
                    {TRUCKOPTION[el]}
                  </div>
                ))}
              </section>
              <Term />
              <ViewVehicle>{onTruckOption}</ViewVehicle>
            </Vehicle>
          </MenuTable>
          <CurrentCheck>
            <div>현재 금액: </div>
            <section>{defaultCharge}</section>
          </CurrentCheck>
          <ChangeCheck>
            <div>변경 금액: </div>
            <input
              type="number"
              onChange={(e) => createdCharge(e)}
              value={changeCharge}
            />
          </ChangeCheck>
          <Buttons>
            <Button onClick={onAddData}>
              <Check />
            </Button>
            <Button onClick={handleModal}>
              <Close />
            </Button>
          </Buttons>
        </ChargeModal>
      </ModalWrapper>
    </>
  );
}

export default AddChargeModal;

const ChargeModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: auto;
  height: auto;
  color: black;
  background-color: #fff;
  letter-spacing: 1px;
  border-radius: 20px;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);
`;

const MenuTable = styled.div`
  display: flex;
  justify-content: center;
  height: 34rem;
  line-height: 2rem;
`;

const MenuBody = styled.section``;

const SearchForm = styled.form`
  display: flex;
  width: 20rem;
  span {
    margin-right: 1rem;
    font-size: 1.1rem;
    font-weight: 700;
  }
  input {
    flex-grow: 4;
  }
  button {
    position: relative;
    top: 0.15rem;
    right: 2.3rem;
    border: none;
    background-color: inherit;
  }
`;

const Test = styled.article`
  display: flex;
  flex-direction: column;
  margin-top: 0.6rem;
  div {
    display: flex;
    flex-direction: column;
    height: 20rem;
    button {
      margin-top: 0.5rem;
      padding-top: 0.4rem;
      width: 17.7rem;
      height: 3.6rem;
      font-size: 1.1rem;
      font-weight: 800;
      line-height: 1.3rem;
      border: none;
      border-radius: 20px;
      color: #ffffff;
      background-color: #a5a5a5;
      &:hover {
        cursor: pointer;
        background-color: #4472c5;
      }
      div {
        font-size: 0.9rem;
        font-weight: 350;
      }
    }
  }
`;

const Vehicle = styled.div`
  display: flex;
  flex-direction: column;
  width: 10rem;
  span {
    font-size: 1.1rem;
    font-weight: 700;
    text-align: center;
  }
  section {
    display: flex;
    flex-direction: column;

    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #fff;

    div.enable {
      margin: 0.5rem auto 0 auto;
      width: 9rem;
      height: 1.5rem;
      background-color: #a5a5a5;
      border: none;
      border-radius: 20px;
      &:focus {
        background-color: #4472c5;
      }
      &:hover {
        cursor: pointer;
        background-color: #4472c5;
      }
    }
    div.disable {
      margin: 0.5rem auto 0 auto;
      width: 9rem;
      height: 1.5rem;
      background-color: #eee;
      border: none;
      border-radius: 20px;
    }
  }
`;

const Term = styled.div`
  margin-top: 12rem;
`;

const ViewVehicle = styled.div`
  margin-top: 0.5rem;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 700;
  color: #4472c5;
`;

const CurrentCheck = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  width: 50rem;
  div {
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1.7rem;
  }
  section {
    margin-left: 2rem;
    margin-right: 7rem;
    width: 10rem;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1.7rem;
  }
`;

const ChangeCheck = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 50rem;
  div {
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1.8rem;
  }
  input {
    margin-left: 2rem;
    margin-right: 7rem;
    width: 10rem;
    height: 1.8rem;
  }
`;

const Buttons = styled.div`
  margin: 2rem auto 0;
`;
