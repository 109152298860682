import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import {
  removeDash,
  convertVehicleTypeToEnglish,
  checkValidTimeFormat,
  checkValidCompleteRequestTime,
  convertTruckWeightToEnglish,
  convertTruckOptionToEnglish,
} from "../pages/GroupOrder/utils";
import * as orderAPI from "../lib/api/orders";
import { checkPhone } from "../lib/api/users";
import { all, call, put, takeLatest } from "redux-saga/effects";

export const READ_EXCEL = "groupOrder/READ_EXCEL";
export const BEFORE_CONVERT_EXCEL = "groupOrder/DO_CONVERT_EXCEL";
export const CONVERT_EXCEL_DATA = "groupOrder/CONVERT_EXCEL_DATA";

export const SET_CLIENT_INFO = "groupOrder/SET_CLIENT_INFO";
export const POST_EXCEL_ORDER = "groupOrder/POST_EXCEL_ORDER";
export const POST_EXCEL_ORDER_SUCCESS = "groupOrder/POST_EXCEL_ORDER_SUCCESS";
export const POST_EXCEL_ORDER_ERROR = "groupOrder/POST_EXCEL_ORDER_ERROR";
export const GROUP_ORDER_LOADING_START = "groupOrder/GROUP_ORDER_LOADING_START";
export const GROUP_ORDER_LOADING_QUIT = "groupOrder/GROUP_ORDER_LOADING_QUIT";
export const GET_JIBUN_ADDRESS = "groupOrder/GET_JIBUN_ADDRESS";
export const GET_JIBUN_ADDRESS_SUCCESS = "groupOrder/GET_JIBUN_ADDRESS_SUCCESS";
export const GET_JIBUN_ADDRESS_FAILURE = "groupOrder/GET_JIBUN_ADDRESS_FAILURE";
export const CHANGE_RECEIVER_ADDRESS = "groupOrder/CHANGE_RECEIVER_ADDRESS";
export const OPEN_SENDERFORM = "groupOrder/OPEN_SENDERFORM";
export const GET_ADDRESS_SEARCH_LIST = "groupOrder/GET_ADDRESS_SEARCH_LIST";
export const GET_ADDRESS_SEARCH_LIST_SUCCESS =
  "groupOrder/GET_ADDRESS_SEARCH_LIST_SUCCESS";
export const GET_ADDRESS_SEARCH_LIST_FAILURE =
  "groupOrder/GET_ADDRESS_SEARCH_LIST_FAILURE";
export const RESET_ADDRESS_SEARCH_LIST = "groupOrder/RESET_ADDRESS_SEARCH_LIST";
export const CHANGE_SENDER_INFO = "groupOrder/CHANGE_SENDER_INFO";

export const beforeConvertExcel = createAction(
  BEFORE_CONVERT_EXCEL,
  (clientInfo) => ({ clientInfo })
);
export const convertExcelData = createAction(CONVERT_EXCEL_DATA);
export const setClientInfo = createAction(SET_CLIENT_INFO, (clientInfo) => ({
  clientInfo,
}));

const initialState = {
  originData: [],
  convertedData: [],
  isAddressCheckOpen: true,
  loading: false,
  isSenderFormOpen: false,
  clientInfo: {
    clientName: "",
    clientPhoneNumber: "",
    payment: "CORPORATE_ACCOUNT",
  },
  senderInfo: {
    senderName: "",
    senderAddress: "",
    senderPhoneNumber: "",
    senderJibunAddress: "",
    senderAddressDetail: "",
    // payment: "CORPORATE_ACCOUNT",
  },
  receiverInfo: {
    receiverName: "",
    receiverAddress: "",
    receiverPhoneNumber: "",
    receiverJibunAddress: "",
    receiverAddressDetail: "",
    deliveryItem: "",
    charge: "",
    vehicleType: "MOTORCYCLE",
    vehicleOption: "",
    truckOption: "",
    truckWeight: "",
    isLift: false,
    memo: "",
    pickupRequestTime: "",
    completeRequestTime: "",
  },
  isResultTableOpen: false,
  result: {
    getJibunAddress: {
      success: { state: false, data: [] },
      error: {
        state: false,
        data: null,
      },
    },
    getAddressSearchList: {
      success: { state: false, data: [], noData: false },
      error: {
        state: false,
        data: null,
      },
    },
    postExcelOrder: {
      success: { state: false, data: {} },
      error: {
        state: false,
        data: null,
      },
    },
  },
};

const groupOrder = handleActions(
  {
    [READ_EXCEL]: (state, action) => {
      const data = action.payload;
      const {
        receiverName,
        receiverAddress,
        receiverPhoneNumber,
        charge,
        memo,
        deliveryItem,
        vehicleType,
        vehicleOption,
        truckWeight,
        truckOption,
        pickupRequestTime,
        completeRequestTime,
      } = state.receiverInfo;
      const { senderName, senderAddress, senderPhoneNumber } = state.senderInfo;
      const originData = data.filter((excelRow) => {
        return excelRow.length > 0;
      });
      originData.shift();

      const convertedData = originData.map((row) => {
        return {
          senderName: row[0] ? row[0] : senderName,
          senderAddress: row[1] ? row[1] : senderAddress,
          senderPhoneNumber: row[2] ? removeDash(row[2]) : senderPhoneNumber,
          receiverName: row[3] ? row[3] : receiverName,
          receiverAddress: row[4] ? row[4] : receiverAddress,
          receiverPhoneNumber: row[5]
            ? removeDash(row[5])
            : receiverPhoneNumber,
          deliveryItem: row[6] ? row[6] : deliveryItem,
          charge: row[7] ? row[7] : charge,
          vehicleType: row[8]
            ? convertVehicleTypeToEnglish(row[8])
            : vehicleType,
          truckWeight:
            row[9] && row[8] === "트럭"
              ? convertTruckWeightToEnglish(row[9])
              : truckWeight,
          truckOption:
            row[10] && row[8] === "트럭"
              ? convertTruckOptionToEnglish(row[10])
              : truckOption,
          memo: row[11] ? row[11] : memo,
          pickupRequestTime: row[12]
            ? checkValidTimeFormat(row[12])
            : pickupRequestTime,
          completeRequestTime:
            row[13] || row[14]
              ? checkValidCompleteRequestTime(row[13], row[14])
              : completeRequestTime,
          vehicleOption:
            row[8] === "트럭"
              ? `${convertTruckWeightToEnglish(
                  row[9]
                )}${convertTruckOptionToEnglish(row[10])}`
              : vehicleOption,
        };
      });

      return produce(state, (draft) => {
        draft.originData = convertedData;
      });
    },
    [GET_JIBUN_ADDRESS_SUCCESS]: (state, action) => {
      const addressList = action.payload;
      const orginData = state.originData;
      return produce(state, (draft) => {
        const mergedReceiverInfo = addressList.map(
          (convertedAddress, index) => {
            const {
              senderJibunAddress,
              senderAddressDetail,
              receiverJibunAddress,
              receiverAddressDetail,
            } = convertedAddress;
            const senderJibun = senderJibunAddress ? senderJibunAddress : "";
            const senderDetail = senderAddressDetail ? senderAddressDetail : "";
            const receiverJibun = receiverJibunAddress
              ? receiverJibunAddress
              : "";
            const receiverDetail = receiverAddressDetail
              ? receiverAddressDetail
              : "";
            return {
              ...orginData[index],
              senderJibunAddress: senderJibun,
              senderAddressDetail: senderDetail,
              receiverJibunAddress: receiverJibun,
              receiverAddressDetail: receiverDetail,
            };
          }
        );
        draft.result.getJibunAddress.success.data = mergedReceiverInfo;
        draft.result.getJibunAddress.success.state = true;
      });
    },
    [GET_JIBUN_ADDRESS_FAILURE]: (state, action) => {
      return produce(state, (draft) => {
        draft.result.getJibunAddress.error = {
          state: true,
          data: action.payload,
        };
      });
    },
    [GET_ADDRESS_SEARCH_LIST_SUCCESS]: (state, action) => {
      const response = action.payload.map((adressList) => adressList.data);
      const addressSearchList = [].concat(...response);

      if (addressSearchList.length < 1) {
        return produce(state, (draft) => {
          draft.result.getAddressSearchList.success = {
            state: true,
            data: addressSearchList,
            noData: true,
          };
        });
      }
      return produce(state, (draft) => {
        draft.result.getAddressSearchList.success = {
          ...state.result.getAddressSearchList.success,
          state: true,
          data: addressSearchList,
        };
      });
    },
    [GET_ADDRESS_SEARCH_LIST_FAILURE]: (state, action) => {
      return produce(state, (draft) => {
        draft.result.getAddressSearchList.error = {
          state: true,
          data: action.payload,
        };
      });
    },
    [RESET_ADDRESS_SEARCH_LIST]: (state) => {
      return produce(state, (draft) => {
        draft.result.getAddressSearchList.success = {
          state: false,
          data: [],
          noData: false,
        };
      });
    },
    [CHANGE_RECEIVER_ADDRESS]: (state, action) => {
      const {
        index,
        senderJibunAddress,
        senderAddressDetail,
        receiverJibunAddress,
        receiverAddressDetail,
        deliveryItem,
        vehicleType,
        vehicleOption,
        truckOption,
        truckWeight,
        isLift,
        charge,
        memo,
        pickupRequestTime,
        completeRequestTime,
      } = action.payload;

      return produce(state, (draft) => {
        const originData = state.result.getJibunAddress.success.data[index];
        draft.result.getJibunAddress.success.data[index] = {
          ...originData,
          senderJibunAddress,
          senderAddressDetail,
          receiverJibunAddress,
          receiverAddressDetail,
          deliveryItem,
          charge,
          vehicleType,
          vehicleOption,
          truckOption,
          truckWeight,
          isLift,
          memo,
          pickupRequestTime,
          completeRequestTime,
        };
      });
    },
    [OPEN_SENDERFORM]: (state) => {
      return produce(state, (draft) => {
        draft.isAddressCheckOpen = false;
        draft.isSenderFormOpen = true;
      });
    },
    [SET_CLIENT_INFO]: (state, { payload: { clientInfo } }) => {
      return produce(state, (draft) => {
        draft.clientInfo = { ...clientInfo };
      });
    },

    [CONVERT_EXCEL_DATA]: (state) => {
      const clientPhoneNumber = removeDash(state.clientInfo.clientPhoneNumber);
      const receiverInfo = state.result.getJibunAddress.success.data;
      const convertedData = receiverInfo.map((row) => {
        return {
          ...row,
          ...state.clientInfo,
          clientPhoneNumber,
        };
      });
      return produce(state, (draft) => {
        draft.convertedData = convertedData;
        draft.isResultTableOpen = true;
      });
    },

    [CHANGE_SENDER_INFO]: (state, action) => {
      const { index, clientName, clientPhoneNumber } = action.payload;

      return produce(state, (draft) => {
        const originData = state.convertedData[index];
        draft.convertedData[index] = {
          ...originData,
          clientName,
          clientPhoneNumber,
        };
      });
    },
    [POST_EXCEL_ORDER_SUCCESS]: (state, action) => {
      return produce(state, (draft) => {
        const { convertedData } = state;
        const failedOrders = Object.entries(action.payload.fail).map(
          (failInfo) => {
            return convertedData[failInfo[0]];
          }
        );

        draft.result.postExcelOrder.success = {
          state: true,
          data: action.payload.fail, // 실패 갯수 출력
        };
        draft.isSenderFormOpen = false;
        draft.convertedData = failedOrders; // 실패만 출력
      });
    },

    [POST_EXCEL_ORDER_ERROR]: (state, action) => {
      return produce(state, (draft) => {
        draft.result.postExcelOrder.error = {
          state: true,
          data: action.payload.error,
        };
      });
    },
    [GROUP_ORDER_LOADING_START]: (state) => {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    },
    [GROUP_ORDER_LOADING_QUIT]: (state) => {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    },
  },
  initialState
);

function* excelOrderSaga(action) {
  yield put({ type: GROUP_ORDER_LOADING_START });

  try {
    /* isList, senderAddress 제외 */
    const data = action.payload.map(
      ({
        charge,
        clientName,
        clientPhoneNumber,
        completeRequestTime,
        deliveryItem,
        memo,
        payment,
        pickupRequestTime,
        receiverAddress,
        receiverAddressDetail,
        receiverJibunAddress,
        receiverName,
        receiverPhoneNumber,
        senderAddressDetail,
        senderJibunAddress,
        senderName,
        senderPhoneNumber,
        vehicleOption,
        vehicleType,
      }) => ({
        charge,
        clientName,
        clientPhoneNumber,
        completeRequestTime,
        deliveryItem,
        memo,
        payment,
        pickupRequestTime,
        receiverAddress,
        receiverAddressDetail,
        receiverJibunAddress,
        receiverName,
        receiverPhoneNumber,
        senderAddressDetail,
        senderJibunAddress,
        senderName,
        senderPhoneNumber,
        vehicleOption,
        vehicleType,
      })
    );
    const response = yield call(orderAPI.postExcelOrders, data);
    yield put({ type: POST_EXCEL_ORDER_SUCCESS, payload: response }); // response.data 변경
  } catch (error) {
    console.log("error", error);
    yield put({ type: POST_EXCEL_ORDER_ERROR, payload: error });
  }
  yield put({ type: GROUP_ORDER_LOADING_QUIT });
}

/**
 * @description /algoquick/bulk-order/address
 * 프로퍼티를 무조건 receiverAddress만 받음
 * 출발지 주소도 도착지처럼 프로퍼티명을 "receiverAddress"로 post 해야함
 * @returns {receiverAddress, receiverJibunAddress, receiverAddressDetail}
 * @returns { combineAddress = 출발지 + 도착지 주소}
 */
function* getJibunAddressSaga(action) {
  let sender = [];
  let receiver = [];
  let combineAddress = [];

  // 출발지 주소만 뽑아내기
  for (let index in action.payload) {
    sender = [...sender, { receiverAddress: action.payload[index].senderData }];
  }
  // 도착지 주소만 뽑아내기
  for (let index in action.payload) {
    receiver = [
      ...receiver,
      { receiverAddress: action.payload[index].receiverData },
    ];
  }
  yield put({ type: GROUP_ORDER_LOADING_START });

  try {
    const SenderResponse = yield call(orderAPI.getJibunAddress, sender);
    const ReceiverResponse = yield call(orderAPI.getJibunAddress, receiver);

    // 출발지 주소 매핑
    for (let index in SenderResponse.data) {
      combineAddress = [
        ...combineAddress,
        {
          senderAddress: SenderResponse.data[index].receiverAddress,
          senderJibunAddress: SenderResponse.data[index].receiverJibunAddress,
          senderAddressDetail: SenderResponse.data[index].receiverAddressDetail,
        },
      ];
    }
    // 도착지 주소 매핑 (출발지에서 만든 "객체 index"안에 추가!)
    for (let index in ReceiverResponse.data) {
      combineAddress[index] = {
        ...combineAddress[index],
        receiverAddress: ReceiverResponse.data[index].receiverAddress,
        receiverJibunAddress: ReceiverResponse.data[index].receiverJibunAddress,
        receiverAddressDetail:
          ReceiverResponse.data[index].receiverAddressDetail,
      };
    }

    yield put({ type: GET_JIBUN_ADDRESS_SUCCESS, payload: combineAddress });
  } catch (error) {
    console.log("error", error);
    yield put({ type: GET_JIBUN_ADDRESS_FAILURE, payload: error });
  }
  yield put({ type: GROUP_ORDER_LOADING_QUIT });
}

function* getAddressSearchListSaga(action) {
  try {
    const response = yield all([
      call(orderAPI.getJibunAddressSearchList, action.payload),
      call(orderAPI.getKeywordAddressSearchList, action.payload),
    ]);

    yield put({
      type: GET_ADDRESS_SEARCH_LIST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.error("getAddressSearchList error", error);
    yield put({ type: GET_ADDRESS_SEARCH_LIST_FAILURE, payload: error });
  }
}
function* beforeConvertExcelSaga({ payload: { clientInfo } }) {
  // 법인신용일때만 의뢰인 연락처 체크
  if (clientInfo.payment === "CORPORATE_ACCOUNT") {
    try {
      yield call(checkPhone, clientInfo.clientPhoneNumber);
      alert("입력하신 휴대폰 번호로 의뢰인 정보를 찾을 수 없습니다.");
    } catch (error) {
      if (error.response?.status === 400) {
        yield put(setClientInfo(clientInfo));
        yield put(convertExcelData());
      } else console.log(error);
    }
  } else {
    yield put(setClientInfo(clientInfo));
    yield put(convertExcelData());
  }
}

export function* groupOrderSaga() {
  yield takeLatest(POST_EXCEL_ORDER, excelOrderSaga);
  yield takeLatest(GET_JIBUN_ADDRESS, getJibunAddressSaga);
  yield takeLatest(GET_ADDRESS_SEARCH_LIST, getAddressSearchListSaga);
  yield takeLatest(BEFORE_CONVERT_EXCEL, beforeConvertExcelSaga);
}

export default groupOrder;
