import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Button, Snackbar } from "@material-ui/core";
import useSnackbar from "../../hooks/useSnackbar";
import ExtraHolidayChargeList from "../../components/corporation/ExtraHolidayChargeList";
import NoExtraChargeList from "../../components/corporation/NoExtraChargeList";
import Notice from "../../components/common/Notice";
import { LOG_TYPE, VEHICLE } from "../../lib/constants/constants";
import { postLog } from "../../lib/hasura/users";
import { destructResponse } from "../../lib/hasura/common";
import {
  getCorpHolidayCharge,
  createCorpHolidayCharge,
  updateCorpHolidayCharge,
  deleteCorpHolidayCharge,
} from "../../lib/hasura/extraCharge";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { HolidayChargeType } from "./extraTypes";

interface HolidayChargeContainerProps {
  corpId: string;
  corpName: string;
}

function CorporationHolidayChargeContainer({
  corpId,
  corpName,
}: HolidayChargeContainerProps) {
  const dispatch = useDispatch();
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const [addHoliday, setAddHoliday] = useState<HolidayChargeType[]>([]); // 등록할 휴일/명절요금
  const [holidayData, setHolidayData] = useState<HolidayChargeType[]>([]); // 휴일요금 데이터

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchHolidayData();
  }, [corpId]);

  const fetchHolidayData = async () => {
    dispatch(startGlobalLoading());
    try {
      const response = await destructResponse<HolidayChargeType[]>(
        "corporations_corporationholidaycharge",
        () => getCorpHolidayCharge(corpId)
      );
      setHolidayData(response);
    } catch (err) {
      console.log(err);
      openSnackbar("휴일/명절요금을 불러오는데 실패하였습니다.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // 전체 휴일/명절요금 생성
  const onAllRegist = async () => {
    const confirmMsg = `${corpName}의 휴일/명절요금을 생성하시겠습니까?`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await createCorpHolidayCharge(corpId);
      await fetchHolidayData();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 휴일/명절요금 전체생성`,
        "법인 관리"
      );
    } catch (err) {
      const failMsg = (err as Error).message || "등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onChange = (vehicle: string, special: boolean, charge: number) => {
    setAddHoliday((prev) => {
      return prev
        .filter(
          (data) => data.vehicle_type !== vehicle || data.is_special !== special
        )
        .concat({
          vehicle_type: vehicle,
          is_special: special,
          holiday_charge: charge,
        });
    });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const confirmMsg = `휴일/명절요금을 등록하시겠습니까?`;
    if (!confirm(confirmMsg)) return;

    dispatch(startGlobalLoading());
    try {
      const promise = addHoliday.map(
        async (data) => await updateCorpHolidayCharge(corpId, data)
      );
      const result = await Promise.allSettled(promise);
      await fetchHolidayData();

      const isRejected = result.some((el) => el.status === "rejected");
      if (isRejected) throw new Error("등록에 실패한 요금이 있습니다.");

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 휴일/명절요금 요금등록(${arrayToString(addHoliday)})`,
        "법인 관리"
      );
      openSnackbar("요금이 등록되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "요금 등록에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };
  const arrayToString = (data: HolidayChargeType[]): string => {
    const holidayArr = data.filter(({ is_special }) => !is_special);
    const specialArr = data.filter(({ is_special }) => is_special);

    const holidayText = holidayArr
      .map((el) => `${VEHICLE[el.vehicle_type]} : ${el.holiday_charge}원`)
      .join(", ");
    const specialText = specialArr
      .map((el) => `${VEHICLE[el.vehicle_type]} : ${el.holiday_charge}원`)
      .join(", ");

    return `([휴일요금] ${holidayText || "변경없음"}) ([명절요금] ${
      specialText || "변경없음"
    })`;
  };

  const onAllDelete = async () => {
    const deleteMsg = `휴일/명절요금을 전체 삭제하시곘습니까?\n전체 삭제시 "기존 휴일/명절요금"으로 자동 적용됩니다.`;
    if (!confirm(deleteMsg)) return;

    dispatch(startGlobalLoading());
    try {
      await deleteCorpHolidayCharge(corpId);
      await fetchHolidayData();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corpName} 휴일/명절요금 전체삭제`,
        "법인 관리"
      );
      openSnackbar("삭제가 완료되었습니다.", false);
    } catch (err) {
      const failMsg = (err as Error).message || "삭제에 실패하였습니다.";
      openSnackbar(failMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <HolidayWrapper>
        <HolidayHeader>
          <h1 className="title">휴일/명절요금 DB화 ({corpName})</h1>
          <div className="description">
            *휴일/명절요금은 부분삭제가 불가하며, 전체삭제시 "기존
            휴일/명절요금"으로 자동 적용됩니다.
          </div>
        </HolidayHeader>
        {holidayData.length > 0 ? (
          <HolidayBody>
            <ExtraHolidayChargeList
              holidayData={holidayData}
              isSpecial={false}
              onChange={onChange}
            />
            <ExtraHolidayChargeList
              holidayData={holidayData}
              isSpecial={true}
              onChange={onChange}
            />
            <ButtonWrapper>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                요금 등록
              </StyledButton>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={onAllDelete}
              >
                전체 삭제
              </StyledButton>
            </ButtonWrapper>
          </HolidayBody>
        ) : (
          <NoExtraChargeList type={"휴일/명절"} onAllRegist={onAllRegist} />
        )}
      </HolidayWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default CorporationHolidayChargeContainer;

const HolidayWrapper = styled.section`
  margin-top: 20px;
  text-align: center;
`;

const HolidayHeader = styled.header`
  padding-bottom: 1.5rem;

  .title {
    margin: 10px;
    font-size: 1.7rem;
  }
  .description {
    font-size: 18px;
    font-weight: 800;
    color: #9e9e9e;
  }
`;

const HolidayBody = styled.section`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.section`
  display: flex;
  margin: 1rem auto;
  gap: 4rem;
`;

const StyledButton = styled(Button)`
  margin: 2rem auto !important;
  width: 10rem;
  height: 3rem;
  font-size: 1rem;
`;
