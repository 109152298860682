import React, { useState, useEffect } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Snackbar } from "@material-ui/core";

import Notice from "../../components/common/Notice";
import DepartmentDetail from "../../components/department/DepartmentDetail";
import useSnackbar from "../../hooks/useSnackbar";

import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import {
  DEFAULT_DEPART,
  DEPART_DATA_LABEL,
  DepartDataLabel,
  DepartmentData,
} from "./types";
import { CorporateUser } from "../corporation/types";
import { validateDepart } from "../../lib/validate";
import { listCorpUser, postLog } from "../../lib/hasura/users";
import {
  getDepart,
  updateDepart,
  deleteDepart,
  updateNullBackupUser,
  updateCorpCredit,
} from "../../lib/hasura/departments";
import {
  LOG_TYPE,
  STRING_ON_EMPTY,
  STRING_ON_NOT_FOUND,
} from "../../lib/constants/constants";

function DepartmentDetailContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const departId = history.location.pathname.replace("/department/", "");
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const [depart, setDepart] = useState<DepartmentData>(DEFAULT_DEPART);
  const [orgDepart, setOrgDepart] = useState(depart);

  const [users, setUsers] = useState<CorporateUser[]>([]);
  const [legacyCredit, setLegacyCredit] = useState(0);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  const fetchData = async () => {
    dispatch(startGlobalLoading());
    try {
      const { data } = await getDepart(departId);

      const { corporations_department_by_pk: result_depart } = data.data;
      const { id: corpId } = result_depart.corporations_corporation;

      const info = {
        id: result_depart["id"],
        name: result_depart["name"],
        limit_budget: result_depart["limit_budget"] || 0,
        administrator: result_depart["administrator_id"] || " ",
        address_book_enable: result_depart["address_book_enable"],
        department_credit: result_depart["department_credit"] || 0,
        department_credit_enable:
          result_depart["department_credit_enable"] || false,
        corporate_credit:
          result_depart["corporations_corporation"]["corporate_credit"] || 0,
        corporation_id: result_depart["corporations_corporation"]["id"],
        corporation_name: result_depart["corporations_corporation"]["name"],
      };
      setDepart(info);
      setOrgDepart(info);
      handleCorpUser(corpId);
      setLegacyCredit(result_depart["department_credit"]); // 렌더링시 기존 부서 크레딧 저장
    } catch (err) {
      console.log(err);
      const errMsg = "부서 정보를 받아올 수 없습니다. 개발팀에 문의해주세요.";
      openSnackbar(errMsg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // mounted
  useEffect(() => {
    fetchData();
  }, [departId]);

  const handleCorpUser = (id: string) => {
    listCorpUser(id).then((result) => {
      const { users_user } = result.data.data;
      setUsers(users_user);
    });
  };

  const handleDeleteDepart = async () => {
    try {
      await updateNullBackupUser(departId);
      const {
        data: { errors },
      } = await deleteDepart(departId);
      if (errors !== undefined) throw new Error();

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `부서 삭제: ${depart.corporation_name} - ${depart.name}`,
        "부서 관리"
      );
      history.goBack();
    } catch (error) {
      const msg =
        "삭제에 실패하였습니다.\n부서에 등록된 회원이 남아있는지 확인해주세요.";
      openSnackbar(msg, true);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value, checked } = e.target;

    switch (name) {
      case "address_book_enable":
      case "department_credit_enable":
        setDepart({
          ...depart,
          [name]: checked,
        });
        break;
      default:
        setDepart({
          ...depart,
          [name]: value,
        });
        break;
    }
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    try {
      // 저장시 잔여 법인 크레딧 재호출
      const { data } = await updateCorpCredit(depart.corporation_id);
      const { corporations_corporation_by_pk: corp } = data.data;

      const errorMessage = validateDepart(depart, legacyCredit, corp);
      if (errorMessage !== "") throw new Error(errorMessage);

      const {
        data: { errors },
      } = await updateDepart(depart, legacyCredit);
      if (errors !== undefined) throw new Error();

      const modifiedDetail =
        Object.entries(orgDepart)
          .reduce((acc: string[], [key, orgValue]) => {
            const nowValue = depart[key as keyof DepartmentData];
            if (orgValue !== nowValue) {
              // 초기값과 저장값이 다르면
              acc.push(
                `${
                  DEPART_DATA_LABEL[key as keyof DepartDataLabel]
                }: ${valueToString(orgValue, key)} -> ${valueToString(
                  nowValue,
                  key
                )}`
              );
            }
            return acc;
          }, [])
          .join(" / ") || "변경 사항 없음";
      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `부서 수정: ${depart.corporation_name} - ${depart.name} (${modifiedDetail})`,
        "부서 관리"
      );
      setOrgDepart(depart); // 초기값도 최신화
      openSnackbar(`${depart.name} 수정에 성공하였습니다.`);
    } catch (error) {
      const message =
        (error as Error).message ||
        `${depart.name} 수정에 실패했습니다.\n데이터를 확인해 주세요.`;
      openSnackbar(message, true);
    } finally {
      dispatch(finishGlobalLoading());
    }

    function valueToString(
      value: number | boolean | string | undefined,
      key: string
    ) {
      switch (typeof value) {
        case "number":
          return value;
        case "boolean":
          return value ? "O" : "X";
        case "string":
          if (value.trim() === "") return STRING_ON_EMPTY;
          if (key === "administrator")
            return (
              users.find((user) => user.id === value)?.username ||
              STRING_ON_NOT_FOUND
            );
          return value || STRING_ON_EMPTY;
        default:
          return value || STRING_ON_EMPTY;
      }
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
    if (!error) history.goBack();
  };

  return (
    <>
      <DepartmentDetail
        row={depart}
        users={users}
        handleDeleteDepart={handleDeleteDepart}
        onChange={onChange}
        onSubmit={onSubmit}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default DepartmentDetailContainer;
