import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@material-ui/core";
import {
  InquiryListRowType,
  headRows,
} from "../../containers/corporationInquiry/types";
import { formatDate } from "../../lib/datetime";
import { stableSort, getSorting } from "../dataTable/base";
import EnhancedTableHead from "../dataTable/EnhancedTableHead";

interface InquiryListProps {
  rows: InquiryListRowType[];
  count: number;
  page: number;
  rowsPerPage: number;
  handleChangePage: (
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeFirst: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function InquiryList({
  rows,
  count,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeFirst,
}: InquiryListProps) {
  const history = useHistory();

  /* EnhancedTableHead */
  // direction = {asc || desc}
  const [order, setOrder] = useState<string>("asc");
  // active = {true || false}
  const [orderBy, setOrderBy] = useState<string>("");

  const handleRequestSort = (e: React.MouseEvent, property: string) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleRowClick = (id: string) => {
    return history.push(`/inquiry/${id}`);
  };

  const emptyRows =
    count !== 0
      ? rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)
      : 0;

  return (
    <PaddingTopPaper>
      <Table>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headRows={headRows}
        />
        <TableBody>
          {stableSort(rows, getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(
              ({
                created,
                id,
                name,
                monthly_usage_amount,
                customer_phone,
                customer_email,
                api_enable,
                checkerFullname,
              }: InquiryListRowType) => (
                <OrderTableRow
                  hover
                  tabIndex={-1}
                  key={id}
                  color={checkerFullname && "#fff"}
                  onClick={() => handleRowClick(id)}
                >
                  <TableCell>{formatDate(created)}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{monthly_usage_amount}</TableCell>
                  <TableCell>{customer_phone}</TableCell>
                  <TableCell>{customer_email}</TableCell>
                  <TableCell>{api_enable ? "O" : "X"}</TableCell>
                  <TableCell>{checkerFullname}</TableCell>
                </OrderTableRow>
              )
            )}
          {emptyRows > 0 && (
            <TableRow style={{ height: 49 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={count}
        rowsPerPageOptions={[25, 50, 100]}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeFirst}
        page={page}
        onPageChange={handleChangePage}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
      />
    </PaddingTopPaper>
  );
}

export default InquiryList;

const PaddingTopPaper = styled.div`
  margin-top: 1rem;
  border-top: 2px rgba(0, 0, 0, 0.08) solid;
`;

const OrderTableRow = styled(TableRow)`
  background: ${(props) => props.color || "#c3ffff"};
`;
