import React, { useState, useEffect, useRef } from "react";
import TimePanel from "../../components/orders/OrdersDashboard/TimePanel";
import OrdersMissed from "../../components/orders/OrdersDashboard/OrdersMissed/OrdersMissed";
import OrdersCompared from "../../components/orders/OrdersDashboard/OrdersCompared/OrdersCompared";
import { requestOrdersToBeChecked } from "../../lib/api/dashboard";
import {
  createAlgoOrders,
  createIsdOrders,
  compareOrders,
} from "../../lib/dashboard";

const OrdersDashboardContainer = () => {
  // 미전달, 지연, 비교 내역 주문 상태
  const [isdOrdersMissed, setIsdOrdersMissed] = useState([]);
  const [algoOrdersMissed, setAlgoOrdersMissed] = useState([]);
  const [ordersDelayed, setOrdersDelayed] = useState({
    wait: [],
    allocation: [],
    pickup: [],
    run: [],
    etc: [],
  });
  const [ordersCompared, setOrdersCompared] = useState([]);

  // 데이터 로딩 여부
  const [isLoading, setIsLoading] = useState(false);
  // API 요청 성공 여부
  const [isSuccess, setIsSuccess] = useState(false);

  // 타이머 관련 변수
  const secRemain = 30;
  const intervalSec = 1000;
  const timerRef = useRef("");
  const secRef = useRef(null);

  /**
   * @description - 미전달, 지연, 비교 내역 주문 상태 요청, 상태 업데이트 함수
   * @return { true | false } - 상태 업데이트를 성공적으로 수행했을 때 true 반환, 실패시 false 반환
   */
  const getOrdersToBeChecked = async () => {
    setIsLoading(true);
    setIsSuccess(false);
    try {
      const ordersToBeChecked = await requestOrdersToBeChecked();
      let ordersDelayedByCase = ordersToBeChecked.ordersDelayed.data.reduce(
        // 각각의 order를 지연 사유별로 분류하여 accumulatedOrders에 누적시키고 반환
        (accumulatedOrders, order) => {
          if (order.state === "대기시간초과") {
            accumulatedOrders.wait.push(order);
          } else if (order.state === "예약배차시간초과") {
            accumulatedOrders.allocation.push(order);
          } else if (order.state === "픽업시간초과") {
            accumulatedOrders.pickup.push(order);
          } else if (order.state === "운행시간기준초과") {
            accumulatedOrders.run.push(order);
          } else {
            accumulatedOrders.etc.push(order);
          }
          return accumulatedOrders;
        },
        // accumulatedOrders 형태
        {
          wait: [],
          allocation: [],
          pickup: [],
          run: [],
          etc: [],
        }
      );

      const ordersCompared = [];
      // 주문 내역 비교 결과 데이터 생성
      ordersToBeChecked.ordersCompared.data.forEach((pairedOrder) => {
        if (pairedOrder.alJson && pairedOrder.isdJson) {
          let algoOrders = createAlgoOrders(pairedOrder.alJson);
          let isdOrders = createIsdOrders(pairedOrder.isdJson);
          let resultCompared = compareOrders(algoOrders, isdOrders);
          if (Object.keys(resultCompared.details).length === 0) return;
          ordersCompared.push(resultCompared);
        }
      });
      // 미전달, 지연 주문, 주문 비교 내역 일괄 업데이트
      setOrdersDelayed(ordersDelayedByCase);
      setIsdOrdersMissed([...ordersToBeChecked.isdOrders.data]);
      setAlgoOrdersMissed([...ordersToBeChecked.algoOrders.data]);
      setOrdersCompared(ordersCompared);

      setIsSuccess(true);
      setIsLoading(false);
      return true;
    } catch (err) {
      alert(`주문 내역을 불러오는데 실패하였습니다.\n${err}`);
      stopScheduler();
      setIsSuccess(false);
      setIsLoading(false);
      return false;
    }
  };

  /**
   * @description  사용자에 의해 스케줄러를 일시 중지하는 함수
   * @return { undefined }
   */
  const stopScheduler = () => {
    clearTimeout(timerRef.current);
  };

  /**
   * @description  사용자 일시 중지 후 스케줄러 재시작
   * @return { undefined }
   */
  const restartScheduler = () => {
    timerRef.current = setTimeout(async function tick() {
      secRef.current.innerText = Number(secRef.current.innerText) - 1;
      if (secRef.current.innerText < 1) {
        secRef.current.innerText = secRemain;
        await getOrdersToBeChecked();
      }
      timerRef.current = setTimeout(tick, intervalSec);
    }, intervalSec);
  };

  /**
   * @description  업데이트 실패 시 스케줄러를 재시작하는 함수
   * @return { undefined }
   */
  const startScheduler = async () => {
    const updateResult = await getOrdersToBeChecked();
    if (updateResult) {
      timerRef.current = setTimeout(async function tick() {
        secRef.current.innerText = Number(secRef.current.innerText) - 1;
        if (secRef.current.innerText < 1) {
          secRef.current.innerText = secRemain;
          const updateResult = await getOrdersToBeChecked();
          if (!updateResult) return;
        }
        timerRef.current = setTimeout(tick, intervalSec);
      }, intervalSec);
    }
  };

  /**
   * @description - 컴포넌트 마운트 후 주문 내역 API 요청 및 상태 업데이트를 수행하는 함수
   * @return { undefined}
   */
  useEffect(() => {
    /**
     * @description - 미전달, 지연, 비교 내역 주문 상태 요청, 상태 업데이트 함수
     * @return { true | false } - 상태 업데이트를 성공적으로 수행했을 때 true 반환, 실패시 false 반환
     */
    const getOrdersToBeChecked = async () => {
      setIsLoading(true);
      setIsSuccess(false);
      try {
        const ordersToBeChecked = await requestOrdersToBeChecked();
        let ordersDelayedByCase = ordersToBeChecked.ordersDelayed.data.reduce(
          // 각각의 order를 지연 사유별로 분류하여 accumulatedOrders에 누적시키고 반환
          (accumulatedOrders, order) => {
            if (order.state === "대기시간초과") {
              accumulatedOrders.wait.push(order);
            } else if (order.state === "예약배차시간초과") {
              accumulatedOrders.allocation.push(order);
            } else if (order.state === "픽업시간초과") {
              accumulatedOrders.pickup.push(order);
            } else if (order.state === "운행시간기준초과") {
              accumulatedOrders.run.push(order);
            } else {
              accumulatedOrders.etc.push(order);
            }
            return accumulatedOrders;
          },
          // accumulatedOrders 형태
          {
            wait: [],
            allocation: [],
            pickup: [],
            run: [],
            etc: [],
          }
        );

        const ordersCompared = [];
        // 주문 내역 비교 결과 데이터 생성
        ordersToBeChecked.ordersCompared.data.forEach((pairedOrder) => {
          if (pairedOrder.alJson && pairedOrder.isdJson) {
            let algoOrders = createAlgoOrders(pairedOrder.alJson);
            let isdOrders = createIsdOrders(pairedOrder.isdJson);
            let resultCompared = compareOrders(algoOrders, isdOrders);
            if (Object.keys(resultCompared.details).length === 0) return;
            ordersCompared.push(resultCompared);
          }
        });
        // 미전달, 지연 주문, 주문 비교 내역 일괄 업데이트
        setOrdersDelayed(ordersDelayedByCase);
        setIsdOrdersMissed([...ordersToBeChecked.isdOrders.data]);
        setAlgoOrdersMissed([...ordersToBeChecked.algoOrders.data]);
        setOrdersCompared(ordersCompared);

        setIsSuccess(true);
        setIsLoading(false);
        return true;
      } catch (err) {
        alert(`주문 내역을 불러오는데 실패하였습니다.\n${err}`);
        stopScheduler();
        setIsSuccess(false);
        setIsLoading(false);
        return false;
      }
    };

    let isSubscribed = true;
    /**
     * @description 컴포넌트 마운트 이후 스케줄러를 시작하는 함수
     * @return { undefined }
     */
    const startScheduler = async () => {
      const updateResult = await getOrdersToBeChecked();
      setIsLoading(false);
      setIsSuccess(true);
      // 추후 삭제
      if (updateResult) {
        timerRef.current = setTimeout(async function tick() {
          if (isSubscribed) {
            secRef.current.innerText = Number(secRef.current.innerText) - 1;
            if (secRef.current.innerText < 1) {
              secRef.current.innerText = secRemain;
              const updateResult = await getOrdersToBeChecked();
              if (!updateResult) return;
            }
            timerRef.current = setTimeout(tick, intervalSec);
          }
        }, intervalSec);
      } else return;
    };
    // 스케줄러 최초 시작
    startScheduler();
    return () => {
      // 페이지 이동으로 컴포넌트가 삭제될 때 API 요청으로 받은 응답값으로 업데이트되는 것을 막음
      stopScheduler();
      isSubscribed = false;
    };
  }, []);

  // 미전달 주문, 지연 주문 리스트 하위 컴포넌트로 전달할 props 묶음
  const orderMissedProps = {
    isdOrdersMissed,
    algoOrdersMissed,
    ordersDelayed,
    isLoading,
    setIsLoading,
    startScheduler,
    restartScheduler,
    stopScheduler,
    isSuccess,
  };

  // 주문 비교 내역 하위 컴포넌트로 전달할 props 묶음
  const ordersComparedProps = {
    ordersCompared,
    isLoading,
    setIsLoading,
  };

  return (
    <>
      <TimePanel ref={secRef} orderMissedProps={orderMissedProps} />
      <OrdersMissed orderMissedProps={orderMissedProps} />
      <OrdersCompared ordersComparedProps={ordersComparedProps} />
    </>
  );
};

export default OrdersDashboardContainer;
