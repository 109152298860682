import { useState, useMemo } from "react";
import { RootStateOrAny, useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { List, ListItem, ListItemIcon, Collapse } from "@material-ui/core";
import {
  Reorder,
  PostAdd,
  EditLocation,
  ViewModule,
  Domain,
  ExpandLess,
  ExpandMore,
  PlaylistAddCheck,
  People,
  Schedule,
  Mail,
  LibraryAdd,
  SportsMotorsports,
  AttachMoney,
  ContactMail,
  MenuBook,
  Settings,
  FindInPage,
  DirectionsBus,
} from "@material-ui/icons";
import { AdminUserAuthsType } from "../containers/users/types";
import { LOG_TYPE } from "../lib/constants/constants";
import { postLog } from "../lib/hasura/users";

export const DEFAULT_MENU = [
  "ORDERS",
  "GROUPORDERS",
  "BUNDLE",
  "SQUAREBUNDLE",
  "NETWORK",
  "CORPORATIONS",
  "CORPORATIONS_DEPARTMENTS",
  "CORPORATIONS_CHARGE",
  "USERS",
  "USERS_AUTH",
  "SCHEDULER",
  "SENDBILL",
  "ADDRESSDETAIL",
  "LARGECHARGE",
  "INQUIRY",
  "RIDERREVIEW",
  "LOGS",
  "SETTINGS",
];

function MenuPage() {
  const [user, userAuth, adminUserAuth] = useSelector(
    ({ user, userAuth }: RootStateOrAny) => [
      user.user,
      userAuth,
      userAuth.userAuth?.data?.users_adminuserauth,
    ],
    shallowEqual
  );
  const [openNetwork, setOpenNetework] = useState(false);
  const [openCorp, setOpenCorp] = useState(false);
  const [openUser, setOpenUser] = useState(false);

  // 메뉴명 권한
  const [
    ORDERS,
    GROUPORDERS,
    BUNDLE,
    SQUAREBUNDLE,
    NETWORK,
    CORPORATIONS,
    CORPORATIONS_DEPARTMENTS,
    CORPORATIONS_CHARGE,
    USERS,
    USERS_AUTH,
    SCHEDULER,
    SENDBILL,
    ADDRESSDETAIL,
    LARGECHARGE,
    INQUIRY,
    RIDERREVIEW,
    LOGS,
    SETTINGS,
  ] = useMemo(
    () =>
      DEFAULT_MENU.reduce((acc: AdminUserAuthsType[], cur: string) => {
        const isMatched = adminUserAuth?.find(
          ({ menu_id }: AdminUserAuthsType) => menu_id === cur
        );
        acc.push(isMatched);
        return acc;
      }, []),
    [adminUserAuth]
  );

  const onNetworkClick = () => {
    setOpenNetework(!openNetwork);
  };
  const onCorpClick = () => {
    setOpenCorp(!openCorp);
  };
  const onUserClick = () => {
    setOpenUser(!openUser);
  };

  const goToOrders = () => {
    if (user && userAuth) {
      /* 주문정보 조회시 로그 등록 */
      postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.SEARCH,
        "주문정보 조회",
        "주문정보"
      );
    }
  };

  return (
    <>
      <Wrapper>
        <InnerWrapper>
          <List component="nav">
            {ORDERS?.search && (
              <Link to="/orders" onClick={goToOrders}>
                <ListItem button divider>
                  <ListItemIcon>
                    <Reorder fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>주문 정보</MenuSpan>
                </ListItem>
              </Link>
            )}
            {GROUPORDERS?.search && (
              <a href="/group-orders">
                <ListItem button divider>
                  <ListItemIcon>
                    <PostAdd fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>단체 주문</MenuSpan>
                </ListItem>
              </a>
            )}
            {BUNDLE?.search && (
              <Link to="/bundle">
                <ListItem button divider>
                  <ListItemIcon>
                    <EditLocation fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>
                    <BundleSpan>법정동 </BundleSpan>단위묶음배송
                  </MenuSpan>
                </ListItem>
              </Link>
            )}
            {SQUAREBUNDLE?.search && (
              <a href="/square-bundle">
                <ListItem button divider key={"square-bundle"}>
                  <ListItemIcon>
                    <ViewModule fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>
                    <SquareSpan>Square </SquareSpan>단위묶음배송
                  </MenuSpan>
                </ListItem>
              </a>
            )}
            {NETWORK?.search && (
              <>
                <ListItem button divider onClick={onNetworkClick}>
                  <ListItemIcon>
                    <DirectionsBus fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>터미널 연계배송</MenuSpan>
                  {openNetwork ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openNetwork} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Link to="/network">
                      <InnerMenuList button divider>
                        <ListItemIcon>
                          <PlaylistAddCheck />
                        </ListItemIcon>
                        <InnerMenuSpan>지역 등록</InnerMenuSpan>
                      </InnerMenuList>
                    </Link>
                  </List>
                </Collapse>
              </>
            )}
            {CORPORATIONS?.search && (
              <>
                <ListItem button divider onClick={onCorpClick}>
                  <ListItemIcon>
                    <Domain fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>법인 관리</MenuSpan>
                  {openCorp ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openCorp} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Link to="/corporations">
                      <InnerMenuList button divider>
                        <ListItemIcon>
                          <PlaylistAddCheck />
                        </ListItemIcon>
                        <InnerMenuSpan>법인 관리</InnerMenuSpan>
                      </InnerMenuList>
                    </Link>
                    {CORPORATIONS_DEPARTMENTS?.search && (
                      <Link to="/departments">
                        <InnerMenuList button divider>
                          <ListItemIcon>
                            <PlaylistAddCheck />
                          </ListItemIcon>
                          <InnerMenuSpan>부서 관리</InnerMenuSpan>
                        </InnerMenuList>
                      </Link>
                    )}
                    {CORPORATIONS_CHARGE?.search && (
                      <Link to="/coporate-charge">
                        <InnerMenuList button divider>
                          <ListItemIcon>
                            <PlaylistAddCheck />
                          </ListItemIcon>
                          <InnerMenuSpan>거래처요금</InnerMenuSpan>
                        </InnerMenuList>
                      </Link>
                    )}
                  </List>
                </Collapse>
              </>
            )}
            {USERS?.search && (
              <>
                <ListItem button divider onClick={onUserClick}>
                  <ListItemIcon>
                    <People fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>회원 관리</MenuSpan>
                  {openUser ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openUser} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Link to="/users">
                      <InnerMenuList button divider>
                        <ListItemIcon>
                          <PlaylistAddCheck />
                        </ListItemIcon>
                        <InnerMenuSpan>회원 관리</InnerMenuSpan>
                      </InnerMenuList>
                    </Link>
                    {USERS_AUTH?.search && (
                      <Link to="/users/auth">
                        <InnerMenuList button divider>
                          <ListItemIcon>
                            <PlaylistAddCheck />
                          </ListItemIcon>
                          <InnerMenuSpan>회원 권한 관리</InnerMenuSpan>
                        </InnerMenuList>
                      </Link>
                    )}
                  </List>
                </Collapse>
              </>
            )}
            {SCHEDULER?.search && (
              <Link to="/scheduler">
                <ListItem button divider>
                  <ListItemIcon>
                    <Schedule fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>스케줄러 설정</MenuSpan>
                </ListItem>
              </Link>
            )}
            {SENDBILL?.search && (
              <Link to="/send-bill">
                <ListItem button divider>
                  <ListItemIcon>
                    <Mail fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>청구서 발송</MenuSpan>
                </ListItem>
              </Link>
            )}
            {ADDRESSDETAIL?.search && (
              <Link to="/addressdetail">
                <ListItem button divider>
                  <ListItemIcon>
                    <LibraryAdd fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>상세 주소 등록</MenuSpan>
                </ListItem>
              </Link>
            )}
            {LARGECHARGE?.search && (
              <>
                <Link to="/large-address">
                  <ListItem button divider>
                    <ListItemIcon>
                      <FindInPage fontSize={"large"} />
                    </ListItemIcon>
                    <MenuSpan>대량 주소 표준화</MenuSpan>
                  </ListItem>
                </Link>
                <Link to="/large-charge">
                  <ListItem button divider>
                    <ListItemIcon>
                      <AttachMoney fontSize={"large"} />
                    </ListItemIcon>
                    <MenuSpan>대량 요금 조회</MenuSpan>
                  </ListItem>
                </Link>
              </>
            )}
            {INQUIRY?.search && (
              <Link to="/inquiry">
                <ListItem button divider>
                  <ListItemIcon>
                    <ContactMail fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>법인 신청 조회</MenuSpan>
                </ListItem>
              </Link>
            )}
            {RIDERREVIEW?.search && (
              <Link to="/rider-review">
                <ListItem button divider>
                  <ListItemIcon>
                    <SportsMotorsports fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>기사 평점 조회</MenuSpan>
                </ListItem>
              </Link>
            )}
            {LOGS?.search && (
              <Link to="/logs">
                <ListItem button divider>
                  <ListItemIcon>
                    <MenuBook fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>로그 기록 조회</MenuSpan>
                </ListItem>
              </Link>
            )}
            {SETTINGS?.search && (
              <Link to="/settings">
                <ListItem button divider>
                  <ListItemIcon>
                    <Settings fontSize={"large"} />
                  </ListItemIcon>
                  <MenuSpan>환경 설정</MenuSpan>
                </ListItem>
              </Link>
            )}
          </List>
        </InnerWrapper>
      </Wrapper>
    </>
  );
}

export default MenuPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  width: 60%;
  padding: 5rem 0 5rem 0;
`;

const MenuSpan = styled.span`
  width: 100%;
  font-size: 1.2em;
  letter-spacing: 2rem;
  padding-left: 5rem;
`;

const InnerMenuList = styled(ListItem)`
  padding-left: 3rem !important;
`;

const InnerMenuSpan = styled(MenuSpan)`
  width: 100%;
  font-size: 1em;
  letter-spacing: 1.5rem;
`;

const SquareSpan = styled.span`
  font-size: 1.2rem;
  letter-spacing: 0.5rem;
  margin-right: 0.9rem;
`;

const BundleSpan = styled.span`
  font-size: 1.2rem;
  letter-spacing: 0.5rem;
  margin-right: 3rem;
`;
