import {
  AddChargeType,
  AddLoadingChargeType,
  RoundTripType,
} from "./extraTypes";

/**
 * @description N단위로 증가하는 배열 생성
 * @property { min } 최소값 2000
 * @property { max } 최대값 5000
 * @property { range } 증가단위 1000
 * @returns [2000, 3000, 4000, 5000]
 */
export const displayNumberRange = (min: number, max: number) => {
  let arr: number[] = [];
  for (let i = min; i <= max; i += 1000) {
    arr = [...arr, i];
  }
  return arr;
};

// 급송 + 시간요금 유효성검사
export const validateExtraCharge = (
  { max_base_Charge, min_base_charge, percent_of_charge }: AddChargeType,
  updateMaxCharge: number
) => {
  const [maxBaseCharge, minBaseCharge, percentOfCharge] = [
    max_base_Charge,
    min_base_charge,
    percent_of_charge,
  ].map(Number);

  let errorMessage = "";
  if (maxBaseCharge > updateMaxCharge) {
    errorMessage = `기본요금 최대범위는 ${updateMaxCharge}원 이하입니다.`;
  } else if (minBaseCharge > maxBaseCharge) {
    errorMessage = "최소범위는 최대범위보다 작아야합니다.";
  } else if (percentOfCharge > 100) {
    errorMessage = "기본요금x00%는 100% 이하입니다.";
  }

  return errorMessage;
};

// 상하차요금 유효성검사
export const validateExtraLoadingCharge = ({
  loading,
  unloading,
  loading_level,
}: AddLoadingChargeType) => {
  let errorMessage = "";
  if (loading === "") {
    errorMessage = "상차 방법을 선택해주세요.";
  } else if (unloading === "") {
    errorMessage = "하차 방법을 선택해주세요.";
  } else if (loading_level === 0) {
    errorMessage = "회전수를 선택해주세요.";
  }

  return errorMessage;
};

// 왕복요금 유효성검사
export const validateroundTripCharge = ({
  round_trip_charge_rate,
}: RoundTripType) => {
  let errorMessage = "";
  if (round_trip_charge_rate > 100) {
    errorMessage = "할증률은 100% 이하입니다.";
  }
  return errorMessage;
};

// 과적요금 유효성검사
export const validateBulkCharge = (charge: number) => {
  if (charge < 0) return "과적요금은 0원 이상입니다.";
  if (charge % 1000 > 0) return "과적요금은 1000원 단위입니다.";
  return "";
};

// 상하차요금 loading정렬 내에서 unloading정렬
export const sortbyLoading = (
  a: { loading: string; unloading: string },
  b: { loading: string; unloading: string }
) => {
  const sortArr = ["CUSTOMER", "FORK_LIFT", "TOGETHER", "RIDER"];

  if (a.loading === b.loading)
    return sortArr.indexOf(a.unloading) - sortArr.indexOf(b.unloading);
  else return sortArr.indexOf(a.loading) - sortArr.indexOf(b.loading);
};

/**
 * @description 추가요금 등록에 필요한 데이터를 연산하는 공통합수
 * @property { AddChargeType } : addExpressCharge, addTimeCharge
 * @property { Array } : expressData, timeData
 * @property { Number } : 요금 적용 단위
 * @returns { baseCharge, extraCharge, vehicleType }
 */
export const extraCalculation = (
  {
    min_base_charge,
    max_base_Charge,
    calc_selector,
    fixed_charge,
    percent_of_charge,
    vehicle_type: vehicleType,
  }: AddChargeType,
  chargeData: any[]
) => {
  const [minBaseCharge, maxBaseCharge, fixedCharge, percentOfCharge] = [
    min_base_charge,
    max_base_Charge,
    fixed_charge,
    percent_of_charge,
  ].map(Number);

  // min ~ max 범위의 range단위 배열
  const baseChargeRange = displayNumberRange(minBaseCharge, maxBaseCharge);

  const result = baseChargeRange.map((charge) => {
    // 등록할 추가요금 연산 (고정금액 또는 %)
    const extraCharge =
      calc_selector === "fixed"
        ? fixedCharge
        : charge * (percentOfCharge / 100);

    return {
      baseCharge: charge,
      extraCharge,
      vehicleType,
    };
  });

  return result;
};

/**
 * @description 급송요금
 * @return { number }
 */
export const calcExpressCharge = (baseCharge: number) => {
  if (baseCharge <= 6000) return 4000;
  else if (baseCharge <= 7000) return 5000;
  else if (baseCharge <= 8000) return 6000;
  else if (baseCharge <= 10000) return 7000;
  else if (baseCharge <= 12000) return 8000;
  else if (baseCharge <= 14000) return 9000;
  else if (baseCharge <= 16000) return 10000;
  else if (baseCharge <= 18000) return 11000;
  else if (baseCharge <= 20000) return 12000;
  else if (baseCharge <= 23000) return 13000;
  else if (baseCharge <= 26000) return 14000;
  else if (baseCharge <= 29000) return 15000;
  else if (baseCharge <= 32000) return 16000;
  else if (baseCharge <= 35000) return 17000;
  else if (baseCharge <= 39000) return 18000;
  else if (baseCharge <= 44000) return 19000;
  else if (baseCharge <= 50000) return 20000;
  else return 0;
};

/**
 * @description 시간요금
 * @return { number }
 */
export const calcTimeCharge = (time: number, baseCharge: number) => {
  switch (time) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      if (baseCharge <= 9000) return 7000;
      if (baseCharge >= 10000 && baseCharge <= 29000) return 10000;
      if (baseCharge >= 30000 && baseCharge <= 49000) return 15000;
      else return 20000;
    case 6:
    case 22:
    case 23:
      if (baseCharge <= 9000) return 5000;
      if (baseCharge >= 10000 && baseCharge <= 29000) return 7000;
      if (baseCharge >= 30000 && baseCharge <= 49000) return 10000;
      else return 15000;
    case 7:
    case 20:
    case 21:
      if (baseCharge <= 9000) return 3000;
      if (baseCharge >= 10000 && baseCharge <= 29000) return 5000;
      if (baseCharge >= 30000 && baseCharge <= 49000) return 7000;
      else return 10000;
    case 8:
    case 18:
    case 19:
      if (baseCharge <= 9000) return 2000;
      if (baseCharge >= 10000 && baseCharge <= 29000) return 3000;
      if (baseCharge >= 30000 && baseCharge <= 49000) return 5000;
      else return 7000;
    default:
      return 0;
  }
};

/**
 * @description 다마스/라보 상하차요금
 * @return { number }
 */
export const calcDamasLaboCharge = (
  loadingLevel: number,
  loading: string,
  unloading: string
) => {
  if (loadingLevel === 2) {
    // 손수레 회전수 2인 경우
    switch (loading) {
      case "CUSTOMER":
        if (unloading === "RIDER") return 5000;
        // 고객 - 기사 5000원
        else return 0;
      case "TOGETHER":
        if (unloading === "RIDER") return 5000;
        // 같이 - 기사 5000원
        else return 0;
      case "RIDER":
        if (unloading === "RIDER") return 10000;
        // 기사 - 기사 10000원
        else return 5000;
    }
  } else if (loadingLevel === 3) {
    // 손수레 회전수 3인 경우
    switch (loading) {
      case "CUSTOMER":
        if (unloading === "RIDER") return 10000;
        // 고객 - 기사 10000원
        else return 0;
      case "TOGETHER":
        if (unloading === "RIDER") return 10000;
        // 함께 - 기사 10000원
        else if (unloading === "TOGETHER") return 5000;
        // 함께 - 함께 5000원
        else return 0;
      case "RIDER":
        if (unloading === "RIDER") return 15000;
        // 기사 - 기사 15000원
        else return 10000;
    }
  } else if (loadingLevel === 4) {
    // 손수레 회전수 4인 경우
    switch (loading) {
      case "CUSTOMER":
        if (unloading === "RIDER") return 10000;
        // 고객 - 기사 10000원
        else if (unloading === "TOGETHER") return 5000;
        // 고객 - 함께 5000원
        else return 0;
      case "TOGETHER":
        if (unloading === "RIDER") return 15000;
        // 함께 - 기사 15000원
        else if (unloading === "TOGETHER") return 10000;
        // 함께 - 함께 10000원
        else return 5000;
      case "RIDER":
        if (unloading === "RIDER") return 20000;
        // 기사 - 기사 20000원
        else if (unloading === "TOGETHER") return 15000;
        // 기사 - 함께 15000원
        else return 10000;
    }
  } else return 0;
};

/**
 * @description 트럭 상하차 레벨
 * @return { String || Boolean }
 */
const truckloadingType = (loading: string, unloading: string) => {
  switch (loading) {
    case "CUSTOMER":
    case "FORK_LIFT":
      if (unloading === "TOGETHER") return "A";
      else if (unloading === "RIDER") return "C";
      else return false;
    case "TOGETHER":
      if (unloading === "TOGETHER") return "B";
      else if (unloading === "RIDER") return "D";
      else return "A";
    case "RIDER":
      if (unloading === "TOGETHER") return "D";
      else if (unloading === "RIDER") return "E";
      else return "C";
  }
};
/**
 * @description 트럭 상하차요금
 * @returns { number }
 */
export const calcTruckCharge = (
  truckOption: string,
  loading: string,
  unloading: string
) => {
  const loadingType = truckloadingType(loading, unloading);
  switch (truckOption) {
    case "1T":
      if (loadingType === "A") return 5000;
      else if (loadingType === "B") return 10000;
      else if (loadingType === "C") return 20000;
      else if (loadingType === "D") return 25000;
      else if (loadingType === "E") return 40000;
      else return 0;
    case "1_4T":
      if (loadingType === "A") return 5000;
      else if (loadingType === "B") return 10000;
      else if (loadingType === "C") return 25000;
      else if (loadingType === "D") return 30000;
      else if (loadingType === "E") return 50000;
      else return 0;
    case "2_5T":
      if (loadingType === "A") return 10000;
      else if (loadingType === "B") return 20000;
      else if (loadingType === "C") return 40000;
      else if (loadingType === "D") return 50000;
      else if (loadingType === "E") return 80000;
      else return 0;
    case "3_5T":
      if (loadingType === "A") return 15000;
      else if (loadingType === "B") return 30000;
      else if (loadingType === "C") return 50000;
      else if (loadingType === "D") return 65000;
      else if (loadingType === "E") return 100000;
      else return 0;
    case "5T":
      if (loadingType === "A") return 20000;
      else if (loadingType === "B") return 40000;
      else return 0;
    case "8T":
    case "11T":
    case "14T":
    case "15T":
    case "18T":
    case "25T":
      if (loadingType === "A") return 30000;
      else if (loadingType === "B") return 60000;
      else return 0;
    default:
      return 0;
  }
};

/**
 * @description 날씨 및 휴일요금의 차량별 기본요금
 * @return { number }
 */
export const calcVehicleDefaultCharge = (vehicleType: string) => {
  switch (vehicleType) {
    case "MOTORCYCLE":
      return 3000;
    case "DAMAS":
      return 5000;
    case "LABO":
      return 5000;
    case "TRUCK":
      return 5000;
    default:
      return 0;
  }
};

// 업데이트 이후 차량별 데이터 정렬 (왕복요금, 날씨요금)
export const VehicleTypes = ["MOTORCYCLE", "DAMAS", "LABO", "TRUCK"];
