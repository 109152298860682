import React, { forwardRef } from "react";
import styled from "styled-components";
import UtilButton, { StyledButton } from "../../common/UtilButton";
import { StyledPanel, StyledLink } from "./styles";

const StyledRemainSeconds = styled.span`
  display: inline-block;
  font-weight: bolder;
  font-size: 1.5rem;
  margin-left: 0.6rem;
  color: #b30000;
`;

const StyledRestartButton = styled(StyledButton)`
  background: #ff9e00;
`;

const StyledMoveToOrdersLink = styled(StyledLink)`
  width: 9rem;
  line-height: 2.2;
  font-size: 1.2rem;
`;

const TimePanel = forwardRef(({ orderMissedProps }, ref) => {
  const secRemain = 30;
  return (
    <StyledPanel>
      <div>
        {orderMissedProps.isLoading ? (
          <StyledRemainSeconds>업데이트 중</StyledRemainSeconds>
        ) : orderMissedProps.isSuccess ? (
          <>
            <span>
              새로고침 남은 시간
              <StyledRemainSeconds style={{ width: "1.5rem" }} ref={ref}>
                {secRemain}
              </StyledRemainSeconds>
              &nbsp;
              <span>초</span>
            </span>
            <UtilButton
              fn1={orderMissedProps.stopScheduler}
              fn2={orderMissedProps.restartScheduler}
              on="일시 중지"
              off="재개"
              isEnable={orderMissedProps.isEnable}
            >
              중지
            </UtilButton>
          </>
        ) : (
          <>
            <span>업데이트에 실패하였습니다.</span>
            <StyledRestartButton onClick={orderMissedProps.startScheduler}>
              재시도
            </StyledRestartButton>
          </>
        )}
      </div>
      <StyledMoveToOrdersLink to={`/orders`}>
        주문 내역 이동
      </StyledMoveToOrdersLink>
    </StyledPanel>
  );
});

export default TimePanel;
