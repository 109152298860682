import styled from "styled-components";
import {
  Button,
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import {
  AddChargeType,
  radioChargeTypes,
  radioVehicleTypes,
} from "../../containers/corporation/extraTypes";
import { ColumnDivider } from "../common/Base";

interface ExtraExpressSelectProps {
  maxCharge: number;
  addCharge: AddChargeType;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent) => void;
  onAllDelete: () => void;
  isVehicleType: boolean;
}

function AddExtraChargeSelector({
  maxCharge,
  addCharge,
  onChange,
  onSubmit,
  onAllDelete,
  isVehicleType,
}: ExtraExpressSelectProps) {
  const {
    min_base_charge,
    max_base_Charge,
    calc_selector,
    fixed_charge,
    percent_of_charge,
    vehicle_type,
  } = addCharge;

  return (
    <FormSelector>
      <FormWrapper>
        <BaseChargeForm>
          <StyledTextField
            label="기본요금 최소범위"
            placeholder="최소 0"
            type="number"
            id="min_base_charge"
            name="min_base_charge"
            value={min_base_charge}
            variant="outlined"
            margin="normal"
            inputProps={{ min: 0, max: maxCharge, step: 1000 }}
            onChange={onChange}
          />
          <Tilde> ~ </Tilde>
          <StyledTextField
            label="기본요금 최대범위"
            placeholder={`최대 ${maxCharge}`}
            type="number"
            id="max_base_Charge"
            name="max_base_Charge"
            value={max_base_Charge}
            variant="outlined"
            margin="normal"
            inputProps={{ min: 0, max: maxCharge, step: 1000 }}
            onChange={onChange}
          />
        </BaseChargeForm>
        <InputChargeForm>
          <StyledRadioCharge
            row
            name="calc_selector"
            onChange={onChange}
            value={calc_selector}
          >
            {radioChargeTypes.map(({ value, label }, idx: number) => {
              return (
                <FormControlLabel
                  control={<Radio />}
                  key={idx}
                  label={label}
                  value={value}
                />
              );
            })}
          </StyledRadioCharge>
          <StyledTextField
            label="고정금액"
            placeholder="ex) 7000"
            type="number"
            id="fixed_charge"
            name="fixed_charge"
            value={fixed_charge}
            variant="outlined"
            margin="normal"
            inputProps={{ min: 0, step: 1000 }}
            onChange={onChange}
            disabled={calc_selector !== "fixed"}
          />
          <ColumnDivider />
          <StyledTextField
            label="기본요금x00%"
            placeholder="ex) 10"
            type="number"
            id="percent_of_charge"
            name="percent_of_charge"
            value={percent_of_charge}
            variant="outlined"
            margin="normal"
            inputProps={{ min: 0, max: 100, step: 5 }}
            onChange={onChange}
            disabled={calc_selector !== "percent"}
          />
          <StyledButton variant="contained" color="primary" onClick={onSubmit}>
            요금 등록
          </StyledButton>
          <StyledButton
            variant="contained"
            color="secondary"
            onClick={onAllDelete}
          >
            전체 삭제
          </StyledButton>
        </InputChargeForm>
      </FormWrapper>
      {isVehicleType && (
        <StyledRadioVehicle
          row
          name="vehicle_type"
          onChange={onChange}
          value={vehicle_type}
        >
          {radioVehicleTypes.map(({ value, label }, idx: number) => {
            return (
              <FormControlLabel
                control={<Radio />}
                key={idx}
                label={label}
                value={value}
              />
            );
          })}
        </StyledRadioVehicle>
      )}
    </FormSelector>
  );
}

export default AddExtraChargeSelector;

const FormSelector = styled.nav`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 3rem;
`;

const BaseChargeForm = styled.section`
  display: flex;
`;

const InputChargeForm = styled.section`
  display: flex;
  position: relative;
`;

const StyledTextField = styled(TextField)`
  width: 200px;
`;

const Tilde = styled.span`
  margin: auto 5px;
`;

const StyledRadioCharge = styled(RadioGroup)`
  position: absolute;
  top: -1.7rem;
  gap: 7rem;
`;

const StyledRadioVehicle = styled(RadioGroup)`
  margin: 0 auto;
`;

const StyledButton = styled(Button)`
  margin: 1rem 0.5rem !important;
  height: 3.5rem !important;
`;
