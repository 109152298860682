import { Nullable } from "./../../lib/constants/orders/types";
import { SearchTarget, ChannelMenu } from "../../lib/constants/constants";

export type StatusOptionType = {
  key: string;
  label: string;
  value: boolean;
};

export type InitialSearchParamsProps = {
  start: Date;
  end: Date;
  search: string;
  target: SearchTarget;
  channel: ChannelMenu;
  statusOptions: StatusOptionType[];
};

export type OrdersOrder = {
  algoquick_orders: [
    {
      auth_code: string | null;
      corporate_credit_amt: number;
      discount_charge: number;
      payment_charge: number;
      vat: number;
      insurance_charge: number;
    },
  ];
  allocated: string | null;
  aq_pickup_request_time: string | null;
  call24_order_number: string | null;
  call24_order_receipt: string | null;
  channel: string;
  client_department: string;
  client_name: string;
  client_phone_number: string;
  completed: string | null;
  corporate_account_memo: string | null;
  costcenter: string | null;
  costcenter_type: string;
  created: string;
  delivery_charge: number;
  distance: number;
  external_isd_order_number: string | null;
  extra_charge: number;
  id: string;
  is_isd_order: boolean;
  isd_order_number: string | null;
  location_charge: number;
  memo: string;
  payment: string;
  picked_up: string | null;
  receiver_address_detail: string | null;
  receiver_jibun_address: string;
  receiver_name: string;
  receiver_phone_number: string;
  receiver_staff: string;
  rider_name: string | null;
  rider_phone_number: string | null;
  rider_vehicle_number: string | null;
  sender_address_detail: string | null;
  sender_jibun_address: string;
  sender_name: string;
  sender_phone_number: string;
  sender_staff: string;
  status: string;
  usersUserByApprovalUserId: {
    fullname: string;
    username: string;
  } | null;
  usersUserByRelatedUserId: Nullable<OrdersMembers>;
  users_user: Nullable<OrdersMembers>;
  vehicle_option: string;
  vehicle_type: string;
};
type OrdersMembers = {
  corporations_corporation: {
    billing_department_enable: boolean;
    costcenter_change_memo_enable: boolean;
    discount_rate: number;
    name: string;
  } | null;
  corporations_department: {
    name: string;
  } | null;
  fullname: string;
  phone: string;
  username: string;
};

// 주문내역 다운로드시 병합 데이터
export type RegistExcelOrder = {
  [x: string]: any;
  corporations_corporation: {
    billing_department_enable: boolean;
    costcenter_change_memo_enable: boolean;
    name: string;
  } | null;
  corporations_department: {
    name: string;
  } | null;
  discount_charge: number;
  corporate_credit_amt: number;
  payment_charge: number;
  vat: number;
};

// OrderList.tsx
type OrderColorType = {
  [index: string]: string;
  BEFORE_WAITING: string;
  REJECTION: string;
  RESERVATION: string;
  WAITING: string;
  RECEIPT: string;
  ALLOCATION: string;
  BEFORE_PICK_UP: string;
  BEFORE_COMPLETION: string;
  PICK_UP: string;
  COMPLETION: string;
  CANCELLATION: string;
};

export const OrderColor: OrderColorType = {
  BEFORE_WAITING: "#dcdcdc",
  REJECTION: "#dcdcdc",
  RESERVATION: "#ffd8d8",
  WAITING: "#c3ffff",
  RECEIPT: "#ffff80",
  ALLOCATION: "#efe1c3",
  BEFORE_PICK_UP: "#efe1c3",
  BEFORE_COMPLETION: "#dbeac8",
  PICK_UP: "#dbeac8",
  COMPLETION: "#ffffff",
  CANCELLATION: "#c9e6e9",
};

export const usersUserNull = {
  corporations_corporation: null,
  corporations_department: null,
  username: null,
  fullname: null,
  phone: null,
};
