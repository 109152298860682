import React from "react";
import styled from "styled-components";
import { RootStateOrAny, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Button } from "@material-ui/core";
import { format } from "date-fns";
import { LOG_TYPE } from "../../lib/constants/constants";
import { postLog } from "../../lib/hasura/users";

type fileType = {
  rootElementId: string;
  defaultName: string;
  clientName: string;
  orderName: string;
};

function PdfDownloader({
  rootElementId,
  defaultName,
  clientName,
  orderName,
}: fileType) {
  const { user, userAuth } = useSelector(
    ({ user, userAuth }: RootStateOrAny) => ({
      user: user.user,
      userAuth,
    })
  );

  const fileName = `${format(
    new Date(),
    "yyyy-MM-dd"
  )} ${clientName}님 ${defaultName}`;

  const downloadPdfDocument = async () => {
    const input = document.getElementById(rootElementId);
    try {
      const canvas = await html2canvas(input as HTMLElement, { scale: 1.5 }); // 해상도 scale
      const imgData = canvas.toDataURL("image/png");

      // 마지막 인자가 compressPDF
      const pdf = new jsPDF("p", "mm", "a4", true);
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
      pdf.save(`${fileName}.pdf`);

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.FUNC,
        `${orderName} ${defaultName} 다운로드 성공`,
        "주문정보"
      );
    } catch (err) {
      alert("PDF 다운로드에 실패했습니다.\n개발팀에 문의해주세요.");
    }
  };
  return (
    <DownWrapper>
      <DownloadBtn
        variant="contained"
        color="primary"
        size="large"
        onClick={downloadPdfDocument}
      >
        PDF 내려받기
      </DownloadBtn>
    </DownWrapper>
  );
}
export default PdfDownloader;

const DownWrapper = styled.span`
  display: block;
  text-align: center;
  width: 100%;
`;

const DownloadBtn = styled(Button)`
  display: block;
  margin: 1rem auto !important;
  width: 20rem;
  height: 3.5rem;
  font-size: 1.2rem;
`;
