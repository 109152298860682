import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Snackbar,
} from "@material-ui/core";

import UsersList from "../../components/users/UsersList";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { UsersListType } from "./types";
import { getUsersSearch } from "../../lib/hasura/users";
import { destructResponse } from "../../lib/hasura/common";
import { ChannelData, ChannelMenu } from "../../lib/constants/constants";

import {
  SearchWrapper,
  StyledButtonArea,
  FormControlWrapper,
  StyledSelect,
  StyledTextField,
  StyledFormButton,
} from "../../components/common/StyledContainer";

function UsersContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(({ user }: RootStateOrAny) => user.user);

  const [users, setUsers] = useState<UsersListType[]>([]);
  const [channel, setChannel] = useState<ChannelMenu>("algoquick");
  const [search, setSearch] = useState("");

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  useEffect(() => {
    if (user) setChannel(user.channel);
  }, [user]);

  const isDisableChannel = useMemo(() => user?.channel !== "algoquick", [user]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onInputSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (search.trim() === "") return openSnackbar("검색어는 필수입니다.", true);
    dispatch(startGlobalLoading());

    try {
      const response = await destructResponse<UsersListType[]>(
        "users_user",
        () => getUsersSearch(search, channel)
      );
      if (response.length === 0) {
        openSnackbar("검색 결과가 없습니다.", true);
        setUsers([]);
        return;
      }

      setUsers(response);
    } catch (err) {
      console.log(err);
      openSnackbar("검색에 실패하였습니다. 개발팀에 문의해주세요.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <SearchWrapper>
        <StyledButtonArea />
        <FormControlWrapper>
          <form onSubmit={onInputSubmit}>
            <FormControl>
              <InputLabel id="channel-select-label">채널</InputLabel>
              <StyledSelect
                labelId="channel-select-label"
                id="channel-select"
                value={channel}
                onChange={(e) => setChannel(e.target.value as ChannelMenu)}
                disabled={isDisableChannel}
              >
                {ChannelData.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
            <StyledTextField
              name="search"
              value={search}
              placeholder="회원검색 - 고객코드, 아이디, 이름, 연락처"
              margin="normal"
              inputProps={{ style: { fontSize: 18 } }}
              onChange={onInputChange}
            />
            <StyledFormButton type="submit" size="large">
              검색
            </StyledFormButton>
          </form>
        </FormControlWrapper>
        <StyledButtonArea>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => history.push("/user/add")}
          >
            신규 회원 등록
          </Button>
        </StyledButtonArea>
      </SearchWrapper>

      <UsersList rows={users} />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default UsersContainer;
