import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Snackbar } from "@material-ui/core";
import Scheduler from "../../components/scheduler/Scheduler";
import { SchedulerType, SchedulerStatus } from "./types";
import {
  deleteScheduler,
  insertScheduler,
  listScheduler,
} from "../../lib/hasura/scheduler";
import { getSchedulerStatus, schedulerRestart } from "../../lib/api/scheduler";
import { destructResponse } from "../../lib/hasura/common";
import { postLog } from "../../lib/hasura/users";
import { LOG_TYPE } from "../../lib/constants/constants";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import useSnackbar from "../../hooks/useSnackbar";
import Notice from "../../components/common/Notice";

const DEFAULT_FORM = {
  channel: "algoquick",
  company_name: "",
  user_id: "",
};

function SchedulerContainer() {
  const dispatch = useDispatch();
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  const [listSchedulers, setListSchedulers] = useState<SchedulerType[]>([]);
  const [listSchedulerStatus, setListSchedulerStatus] = useState<
    SchedulerStatus[]
  >([]);
  const [form, setForm] = useState(DEFAULT_FORM);
  const [status, setStatus] = useState(false);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchScheduler();
  }, []);

  const getSchedulers = async () => {
    const result: SchedulerType[] = await destructResponse(
      "orders_scheduleruser",
      () => listScheduler()
    );
    setListSchedulers(result);
  };
  const getListSchedulerStatus = async () => {
    const { data } = await getSchedulerStatus();
    setListSchedulerStatus(data);
  };

  const fetchScheduler = async () => {
    dispatch(startGlobalLoading());
    try {
      await getSchedulers();
      await getListSchedulerStatus();
    } catch (err) {
      openSnackbar(`스케쥴러 호출에 실패하였습니다.\n${err}`, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (form.company_name.trim() === "" || form.user_id.trim() === "")
      return openSnackbar(`입력 값은 필수 입니다`, true);

    try {
      const {
        data: { errors },
      } = await insertScheduler(form);
      if (errors !== undefined) throw new Error();

      await getSchedulers();
      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.FUNC,
        `${form.company_name}(${form.user_id}) 스케줄러 추가`.trim(),
        "스케줄러설정"
      );

      const msg = `${form.company_name}(${form.user_id}) 추가 완료 되었습니다.\n다음 스케줄링부터 관리가 시작됩니다.`;
      openSnackbar(msg, false);
      setForm(DEFAULT_FORM);
    } catch {
      const msg = `${form.company_name}(${form.user_id}) 추가에 실패하였습니다.\n 데이터를 확인하여주세요`;
      openSnackbar(msg, true);
    }
  };

  const onChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    e.preventDefault();
    const { name, value } = e.target;
    setForm({
      ...form,
      [name as string]: value,
    });
  };

  const onDelete = async (schedule: SchedulerType) => {
    const { company_name, user_id } = schedule;
    if (!confirm(`${company_name}(${user_id})을 정말 삭제하시겠습니까?`))
      return;

    try {
      await deleteScheduler(user_id);
      await getSchedulers();
      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.FUNC,
        `${company_name}(${user_id}) 스케줄러 삭제`.trim(),
        "스케줄러설정"
      );

      const msg = `${company_name} 삭제 완료 되었습니다.\n다음 스케줄링부터 관리가 시작됩니다.`;
      openSnackbar(msg, false);
    } catch (err) {
      openSnackbar(`${company_name} 삭제에 실패했습니다\n${err}`, true);
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnackbar();
  };

  const handleStatus = async () => {
    if (!status) await getListSchedulerStatus();
    setStatus(!status);
  };

  const handleSchedulerRestart = async () => {
    await schedulerRestart();
    await getListSchedulerStatus();
  };

  return (
    <>
      <Scheduler
        form={form}
        listSchedulers={listSchedulers}
        listSchedulerStatus={listSchedulerStatus}
        status={status}
        handleStatus={handleStatus}
        handleSchedulerRestart={handleSchedulerRestart}
        onChange={onChange}
        onSubmit={onSubmit}
        onDelete={onDelete}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default SchedulerContainer;
