import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { AxiosError } from "axios";
import { Snackbar } from "@material-ui/core";

import OrderDetail from "../../components/order/OrderDetail";
import Notice from "../../components/common/Notice";
import ReOrderDetailContainer from "./ReOrderDetailContainer";
import Call24OrderDetailContainer from "./Call24OrderDetailContainer";
import CancelCardContainer from "./CancelCardContainer";
import OrderSwapContainer from "./OrderSwapContainer";
import UseStatementModal from "../../components/UseStatement/UseStatementModal";

import {
  ChangeableType,
  ReOrderType,
  Call24Type,
  KakaoType,
  ReceiptImgType,
  ReceiptUploadType,
  // ReceiptFileType,
  UserIdType,
  OrderByPkType,
  DEFAULT_ORDER_BY_PK,
  DEFAULT_CHANGEABLE,
  DEFAULT_REORDER,
  DEFAULT_CALL24,
  DEFAULT_RECEIPT_IMG,
} from "./types";
import { allocationMessage, setTruckOption } from "./utils";
import { validateOrder, validateCall24 } from "../../lib/validate";
import { isEmptyObject } from "../../lib/core";
import {
  call24Api,
  cancelCardApi,
  repaymentCardApi,
  reOrder as apiReOrder,
  sendKakao,
  sendTextMessage as sendTextMessageAPI,
  syncOrder,
  getExternalOrder,
} from "../../lib/api/orders";
import { getUserByUsername, postLog } from "../../lib/hasura/users";
import { destructResponse } from "../../lib/hasura/common";
import {
  getOrder,
  updateOrder,
  updateUsersUsername,
  updateRelatedUsername,
  searchRider,
} from "../../lib/hasura/orders";
import {
  delteDeliveryReceipt,
  uploadDeliveryReceipts,
} from "../../lib/api/aws";
import {
  LOG_TYPE,
  ORDER_DATA_LABEL,
  STRING_ON_EMPTY,
} from "../../lib/constants/constants";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import useSnackbar from "../../hooks/useSnackbar";

const MAX_FILE_HEIGHT = 869; // 갤럭시 노트10 해상도 높이
const MAX_FILE_COUNTS = 10;

function OrderDetailContainer({ history }: RouteComponentProps) {
  const dispatch = useDispatch();
  const { user, userAuth } = useSelector(
    ({ user, userAuth }: RootStateOrAny) => ({
      user: user.user,
      userAuth,
    })
  );
  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  const [order, setOrder] = useState<OrderByPkType>(DEFAULT_ORDER_BY_PK);
  const [changeable, setChangeable] =
    useState<ChangeableType>(DEFAULT_CHANGEABLE);
  const [orgChangeable, setOrgChangeable] =
    useState<ChangeableType>(changeable);
  const [reOrder, setReOrder] = useState<ReOrderType>(DEFAULT_REORDER);
  const [reOrderPopup, setReOrderPopup] = useState(false);
  const [callOrderPopup, setCallOrderPopup] = useState(false);
  const [call24, setCall24] = useState<Call24Type>(DEFAULT_CALL24);
  const [riderList, setRiderList] = useState([]);
  const [inputOpen, setInputOpen] = useState(false);

  // 카카오톡 수동 발송
  const [kakaoLog, setKakaoLog] = useState(false);
  const [kakao, setKakao] = useState<KakaoType>({
    id: "",
    kakao_phone_number: "",
    kakao_status: "",
  });

  // 접수회원
  const [orderUser, setOrderUser] = useState("");
  const [legacyOrderUser, setLegacyOrderUser] = useState("");

  const [cancelCardPopup, setCancelCardPopup] = useState(false);
  const [card, setCard] = useState({
    id: "",
    tid: "",
    orderCharge: "",
    charge: "",
    cancelMessage: "",
    password: "",
    code: "0",
  });
  const [swapPopup, setSwapPopup] = useState(false);
  const [isTextMessageDialogOpen, setTextMessageDialogOpen] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [originTextMessage, setOriginTextMessage] = useState("");

  const [receiptImageUrl, setReceiptImageUrl] = useState("");
  const [receiptImages, setReceiptImages] = useState<ReceiptImgType[]>([
    DEFAULT_RECEIPT_IMG,
  ]);
  const [isReceiptDialogOpen, setIsReceiptDialogOpen] = useState(false);
  const [isReceiptUploading, setIsReceiptUploading] = useState(false);
  const [deletingReceiptFilename, setDeletingReceiptFilename] = useState("");
  const [statementOpen, setStatementOpen] = useState(false);

  const orderId = history.location.pathname.replace("/order/", "");
  const resizedRef = useRef<HTMLImageElement>(null);

  const fetchData = async () => {
    dispatch(startGlobalLoading());
    try {
      const orders_order_by_pk = await destructResponse<OrderByPkType>(
        "orders_order_by_pk",
        () => getOrder(orderId)
      );
      setOrder(orders_order_by_pk);

      const originSMS = allocationMessage(orders_order_by_pk);
      setOriginTextMessage(originSMS);
      setTextMessage(originSMS);

      // 수정 가능한 컬럼
      if (isEmptyObject(orders_order_by_pk)) {
        const changeableData = {
          isd_order_number: orders_order_by_pk.isd_order_number || "",
          external_isd_order_number:
            orders_order_by_pk.external_isd_order_number || "",
          call24_order_number: orders_order_by_pk.call24_order_number || "",
          location_charge: orders_order_by_pk.location_charge || 0,
          extra_charge: orders_order_by_pk.extra_charge || 0,
          delivery_charge: orders_order_by_pk.delivery_charge || 0,
          express: orders_order_by_pk.express || false,
          status: orders_order_by_pk.status || " ",
          note: orders_order_by_pk.note || " ",
          rider_name: orders_order_by_pk.rider_name || "",
          rider_phone_number: orders_order_by_pk.rider_phone_number || "",
        };
        setChangeable(changeableData);
        setOrgChangeable(changeableData);

        // KAKAO
        setKakao({
          id: orders_order_by_pk.id,
          kakao_phone_number: "",
          kakao_status: orders_order_by_pk.status,
        });

        // 재주문 관련
        setReOrder({
          id: orders_order_by_pk.id || " ",
          channel: orders_order_by_pk.channel || " ",
          created: orders_order_by_pk.created || " ",
          modified: orders_order_by_pk.modified || " ",
          client_name: orders_order_by_pk.client_name || " ",
          sender_name: orders_order_by_pk.sender_name || " ",
          receiver_name: orders_order_by_pk.receiver_name || " ",
          receiver_address: orders_order_by_pk.receiver_address || " ",
          receiver_jibun_address: orders_order_by_pk.receiver_address || " ",
          memo: orders_order_by_pk.memo || " ",
          username: orders_order_by_pk.users_user?.username || " ",
        });

        const {
          sender_jibun_address,
          sender_address_detail,
          receiver_jibun_address,
          receiver_address_detail,
          sender_bcode,
          receiver_bcode,
          vehicle_type,
          vehicle_option,
          express,
          trip,
          note,
          users_user,
        } = orders_order_by_pk;

        // 접수회원
        setOrderUser(users_user?.username ?? "비회원");
        setLegacyOrderUser(users_user?.username ?? "비회원");

        // set call24 data
        setCall24({
          id: orderId,
          sender: `${sender_jibun_address} ${sender_address_detail || ""}`,
          receiver: `${receiver_jibun_address} ${
            receiver_address_detail || ""
          }`,
          charge: 20000,
          senderBcode: sender_bcode,
          receiverBcode: receiver_bcode,
          fee: 0,
          vehicle_type: vehicle_type,
          weight:
            vehicle_type === "TRUCK" &&
            vehicle_option &&
            vehicle_option.trim() !== ""
              ? vehicle_option.split("/")[0]
              : "",
          option:
            vehicle_type === "TRUCK" ? setTruckOption(vehicle_option) : "",
          multiCargoGub: false,
          express: express,
          trip: trip === "ROUND_TRIP",
          startPlanDt: false,
          note: note || " ",
          firstType: false,
        });

        // card
        const { algoquick_orders } = orders_order_by_pk;
        if (algoquick_orders) {
          const { tid, card_total_amt } = algoquick_orders[0];
          const totalCharge = card_total_amt || 0; // 전체크레딧시 null
          setCard({
            id: orderId,
            tid: tid || "",
            orderCharge: totalCharge.toString(),
            charge: totalCharge.toString(),
            cancelMessage: "",
            code: "0",
            password: "",
          });
        }

        // 인수증
        const { call24_order_receipt } = orders_order_by_pk;
        if (call24_order_receipt) {
          const receipts = call24_order_receipt
            .split(",")
            .map((url: string) => ({
              filename: url.split("/").pop() || "",
              url,
            }));
          setReceiptImages(receipts);
        }
      }
    } catch (err) {
      openSnackbar(`주문 정보를 받아올수 없습니다.\n${err}`, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // mounted
  useEffect(() => {
    fetchData();
  }, [orderId]);

  const sendTextMessage = () => {
    sendTextMessageAPI({
      phone: order?.rider_phone_number || null,
      allocateMessage: textMessage || null,
    });
    closeTextMessageDialog();
  };

  // 주문 정보 디테일 정보 수정
  const onChange = (
    e: React.ChangeEvent<{
      name: string;
      value: string | number | boolean;
      checked?: boolean;
    }>
  ) => {
    const { name, value, checked } = e.target;

    switch (name) {
      case "express":
        setChangeable({
          ...changeable,
          [name]: checked || false, // undefined일 경우 false 타입처리
        });
        break;
      default:
        setChangeable({
          ...changeable,
          [name]: value,
        });
        break;
    }
  };

  // 주문 정보 재접수 정보 수정
  const ReOrderOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setReOrder({
      ...reOrder,
      [name]: value,
    });
  };

  const handleUserInput = (userText: string, isOpen: boolean) => {
    setOrderUser(userText);
    setInputOpen(isOpen);
  };

  // 접수회원 닫기
  const closeOrderUser = () => handleUserInput(legacyOrderUser, false);

  // 접수회원 변경
  const submitOrderUser = async () => {
    if (orderUser === "비회원" || orderUser.trim() === "")
      return openSnackbar("변경하실 아이디를 입력하세요", true);

    try {
      const userData: UserIdType[] = await destructResponse("users_user", () =>
        getUserByUsername(orderUser)
      );
      if (userData.length === 0) throw new Error("회원을 찾을 수 없습니다.");

      const { id } = userData[0];
      await updateUsersUsername(orderId, id);

      handleUserInput(orderUser, false);
      openSnackbar("접수회원 변경이 완료되었습니다.", false);
    } catch (err) {
      const msg =
        (err as Error).message || `접수회원 변경에 실패하였습니다.\n${err}`;
      openSnackbar(msg, true);
    }
  };

  // 접수회원 삭제
  const deleteOrderUser = async () => {
    try {
      await updateUsersUsername(orderId, null);

      handleUserInput("", false);
      openSnackbar("접수회원이 삭제되었습니다.", false);
    } catch (err) {
      openSnackbar(`접수회원 삭제에 실패하였습니다.\n${err}`, true);
    }
  };

  // 관련회원 삭제
  const deleteRelatedUser = async () => {
    try {
      await updateRelatedUsername(orderId, null);

      handleUserInput(orderUser, false);
      openSnackbar("관련회원이 삭제되었습니다.", false);
    } catch (err) {
      openSnackbar(`관련회원 삭제에 실패하였습니다.\n${err}`, true);
    }
  };

  // 주문정보 디테일 정보 제출
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const errorMessage = validateOrder(changeable);
      if (errorMessage !== "") throw new Error(errorMessage);

      const refinedData: ChangeableType = { ...changeable };
      for (const key in refinedData) {
        if (
          key === "isd_order_number" ||
          key === "external_isd_order_number" ||
          key === "call24_order_number" ||
          key === "rider_phone_number"
        ) {
          // .replace(/\s/g,'') 문자열 내의 모든 공백 제거
          refinedData[key] = refinedData[key].toString().replace(/\s/g, "");
        }
        if (key === "call24_order_number") {
          refinedData[key] = refinedData[key].replace("-", "").replace("-", "");
        }
        if (key === "rider_name" || key === "note") {
          refinedData[key] = refinedData[key].toString().trim();
        }
      }

      const {
        data: { errors },
      } = await updateOrder({ id: orderId, ...refinedData });
      if (errors !== undefined) throw new Error(errors[0].message);

      const modifiedDetail = Object.entries(ORDER_DATA_LABEL)
        .reduce((modified: string[], [key, label]) => {
          const og = orgChangeable[key] || STRING_ON_EMPTY;
          const nw = refinedData[key] || STRING_ON_EMPTY;
          if (og !== nw) modified.push(`${label}: ${og} -> ${nw}`);
          return modified;
        }, [])
        .join(" / ");

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.FUNC,
        `${order.id} 주문정보 수정${modifiedDetail && ` (${modifiedDetail})`}`,
        "주문정보"
      );

      setOrgChangeable(refinedData);
      openSnackbar("변경완료되었습니다", false);
    } catch (err) {
      openSnackbar((err as Error).message, true);
    }
  };

  // 주문 재접수
  const ReOrderOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await apiReOrder(reOrder);
      await fetchData();

      openSnackbar("변경완료되었습니다", false);
      setReOrderPopup(false);
    } catch (err) {
      openSnackbar(`주문수정에 실패하였습니다. ${err}`, true);
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnackbar();
    if (!error) fetchData();
  };

  const handleReOrder = () => setReOrderPopup(!reOrderPopup);

  /**
   * @description 주문 업데이트
   * @param { string | null } isdOrderNumber 인성 주문 번호
   * @returns { undefined }
   */
  const handleOrderSync = async (isdOrderNumber: string | null) => {
    try {
      if (!isdOrderNumber)
        throw new Error("인성 주문 번호가 존재하지 않습니다.");

      await syncOrder(isdOrderNumber);
      await fetchData();
      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.FUNC,
        `${order.id} 주문 업데이트 클릭`,
        "주문정보"
      );
      openSnackbar("주문을 업데이트 하였습니다.", false);
    } catch (e) {
      openSnackbar(`주문 업데이트에 실패하였습니다.\n${e}`, true);
    }
  };

  /**
   * @description 지방 인성번호 업데이트
   * @param { string } orderId - 주문 번호 (order.id)
   * @param { null|string } externalIsdNumber - 지방 인성 번호 (order.external_isd_number)
   * @returns { call24_order_number }에 지방 인성번호를 넣어준다.
   * 지방 인성번호를 업데이트한 주문은 24시 콜화물을 사용하지 않는다는 전제!
   */
  const handleExternalNumber = async (
    orderId: string,
    externalIsdNumber: string | null
  ) => {
    const externalMsg =
      "지방 주문접수(대구 및 부산)인지 다시 한번 확인해주세요.\n지방 인성번호를 접수하시겠습니까?";
    const againMsg =
      "이미 지방 인성번호를 접수한 주문입니다.\n다시 접수하시겠습니까?";

    if (!window.confirm(externalMsg)) return;
    if (externalIsdNumber && !window.confirm(againMsg)) return;

    try {
      await getExternalOrder(orderId);
      openSnackbar("접수가 완료되었습니다.", false);

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.FUNC,
        `${order.id} 지방 인성번호 접수`,
        "주문정보"
      );
    } catch (err) {
      if (err instanceof AxiosError) {
        const msg = `접수에 실패했습니다. ${err.response?.data.detail || err}`;
        openSnackbar(msg, true);
      } else openSnackbar((err as Error).message, true);
    }
  };

  // KAKAO TALK SEND
  /**
   * @description 해당 주문 카카오톡 발송 내역 표출
   * @returns {void}
   */
  const handleKakaoLog = () => setKakaoLog(!kakaoLog);

  const kakaoOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setKakao({
      ...kakao,
      [name]: value,
    });
  };

  const kakaoSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const phone_number_startswith_list = [
      "010",
      "011",
      "016",
      "017",
      "018",
      "019",
    ];
    const kakaoOrderStatus = [
      "WAITING",
      "ALLOCATION",
      "BEFORE_PICK_UP",
      "PICK_UP",
      "BEFORE_COMPLETION",
      "COMPLETION",
    ];
    const phone = kakao.kakao_phone_number;
    const confirmMsg = `${kakao.kakao_phone_number}으로 카카오톡 수동 발송 하시겠습니까?`;

    if (phone_number_startswith_list.indexOf(phone.substring(0, 3)) !== 0)
      return openSnackbar(`휴대폰 번호를 확인해주세요!`, true);
    if (phone.length < 10 || phone.length > 11)
      return openSnackbar(`휴대폰 번호를 자리수를 확인해주세요!`, true);
    if (kakaoOrderStatus.indexOf(kakao.kakao_status) === -1)
      return openSnackbar(`상태값을 확인해주세요!`, true);
    if (!window.confirm(confirmMsg)) return;

    try {
      const {
        data: { result },
      } = await sendKakao(kakao);

      const msg = `카카오톡 전송을 요청하였습니다.\n약 1분 후에 결과를 확인해주세요`;
      if (result) {
        openSnackbar(msg, false);
        setKakao({
          id: order.id,
          kakao_phone_number: "",
          kakao_status: order["status"],
        });
      }
    } catch (err) {
      openSnackbar(`카카오톡 전송에 실패하였습니다.\n${err}`, true);
    }
  };

  const sendReceiptKakao = useCallback(async () => {
    const { id } = order;
    const { rider_phone_number: phone } = changeable;
    if (!window.confirm(`${phone}로 인수증 첨부 링크를 전송하시겠습니까?`))
      return;

    try {
      const {
        data: { result, errors },
      } = await sendKakao({
        id,
        kakao_phone_number: phone,
        kakao_status: "CALL24_RIDER",
      });

      if (result) openSnackbar("카카오톡 전송을 요청하였습니다.");
      else throw new Error(errors);
    } catch (err) {
      openSnackbar(
        `카카오톡 전송에 실패하였습니다.\n${(err as Error).message}`,
        true
      );
    }
  }, [order, changeable]);

  /**
   * @description 전국24시콜화물 접수
   * @returns { object } 접수 성공 실패 여부
   * - 성공시 {"result": true, "message": call24_order_number}
   * - 실패시 {"result": false, "message": result_dict["message"]}
   */
  const handleCall24Api = async () => {
    const errorMessage = validateCall24(call24);

    if (errorMessage) {
      openSnackbar(errorMessage, true);
      return;
    }

    if (!window.confirm("전국24시콜화물 접수하시겠습니까?")) return;

    try {
      const response = await call24Api({
        ...call24,
        firstType: call24.firstType ? "02" : "01",
      });

      if (response.data.result) {
        const msg = `전국24시콜화물 접수하였습니다 화물번호: ${response.data.message}`;
        openSnackbar(msg, false);
        setCallOrderPopup(false);

        // 접수 성공 로그
        postLog(
          user.id,
          userAuth.ip,
          LOG_TYPE.FUNC,
          `${order.id} 전국24시콜화물 접수 성공 ${response.data.message}`,
          "주문정보"
        );
        return;
      } else {
        const msg = `전국24시콜화물 접수에 실패하였습니다 \n${response.data.message}`;
        openSnackbar(msg, true);

        // 접수 실패 로그
        postLog(
          user.id,
          userAuth.ip,
          LOG_TYPE.FUNC,
          `${order.id} 전국24시콜화물 접수 실패`,
          "주문정보"
        );
      }
    } catch (err) {
      const msg = `전국24시콜화물 접수에 실패하였습니다 \n${err}`;
      openSnackbar(msg, true);

      // 접수 실패 로그
      postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.FUNC,
        `${order.id} 전국24시콜화물 접수 실패`,
        "주문정보"
      );
    }
  };

  const handleCallOrderPopup = async () => {
    const { data } = await searchRider(
      call24.senderBcode,
      call24.receiverBcode,
      order.vehicle_type
    );
    const { orders_order } = data.data;

    setRiderList(orders_order);
    setCallOrderPopup(true);
  };

  const handleCallOrderOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "vehicle_type":
        setCall24({
          ...call24,
          [name]: value,
          weight: "",
        });
        break;

      case "express":
      case "multiCargoGub":
      case "trip":
      case "startPlanDt":
      case "firstType":
        setCall24({
          ...call24,
          [name]: e.target.checked,
        });
        break;

      case "charge":
      case "fee":
        setCall24({
          ...call24,
          [name]: parseInt(value),
        });
        break;

      default:
        setCall24({
          ...call24,
          [name]: value,
        });
    }
  };

  const handleCancelCard = () => setCancelCardPopup(!cancelCardPopup);

  const handleCancelCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCard({
      ...card,
      [name]: value,
    });
  };

  /**
   * @description 웹 카드 주문 취소
   * @returns { result: true } 취소 성공
   * @returns { message: string, result: false } 취소 실패
   * 해당 성공/실패 모두 200 status code를 반환
   */
  const submitCancelCard = async () => {
    if (card.charge.trim() === "")
      return openSnackbar("취소 금액은 필수입니다.", true);
    if (card.cancelMessage.trim() === "")
      return openSnackbar("취소 사유는 필수입니다.", true);

    try {
      const { data } = await cancelCardApi(card);
      if (!data.result) throw new Error(data.message);

      openSnackbar(`카드 취소 / 주문 취소에 성공하였습니다`, false);
      setCancelCardPopup(false);
    } catch (err) {
      if (err instanceof AxiosError) {
        const msg = `카드 취소 / 주문 취소에 실패하였습니다.\n에러코드: ${err.response?.status}`;
        openSnackbar(msg, true);
      } else openSnackbar((err as Error).message, true);
    } finally {
      postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.FUNC,
        `${order.id} 카드 취소`,
        "주문정보"
      );
    }
  };

  /**
   * @description 웹 카드 재결제
   * @returns { result: true } 재결제 성공
   * @returns { message: string, result: false } 재결제 실패
   * 해당 성공/실패 모두 200 status code를 반환
   */
  const repaymentCard = async () => {
    if (!window.confirm("카드 재결제를 진행하시겠습니까?")) return;
    try {
      const { data } = await repaymentCardApi(orderId);
      if (!data.result) throw new Error(data.message);

      openSnackbar(`카드 재결제에 성공하였습니다`, false);
    } catch (err) {
      if (err instanceof AxiosError) {
        const msg = `카드 재결제에 실패하였습니다.\n에러코드: ${err.response?.status}`;
        openSnackbar(msg, true);
      } else openSnackbar((err as Error).message, true);
    }
  };

  const handleOrderSwap = () => setSwapPopup(!swapPopup);

  function closeTextMessageDialog() {
    setTextMessageDialogOpen(false);
    setTextMessage(originTextMessage);
  }
  function handleChangeDialog(text: string) {
    setTextMessage(text);
  }

  /* 거래 내역서 */
  const modalRef = useRef(null);
  const openUseStateModal = () => {
    setStatementOpen(true);
    document.body.style.overflow = "hidden";
  };
  const closeUseStateModal = () => {
    setStatementOpen(false);
    document.body.style.overflow = "";
  };
  const outSideUseStateModal = (e: React.MouseEvent) => {
    if (modalRef.current === e.target) {
      setStatementOpen(false);
      document.body.style.overflow = "";
    }
  };
  const useStatementDisabled = useMemo(
    () =>
      order?.status === "BEFORE_WAITING" ||
      order?.status === "REJECTION" ||
      order?.status === "CANCELLATION",
    [order]
  );

  const toggleIsReceiptDialogOpen = useCallback(() => {
    setIsReceiptDialogOpen((opened) => {
      // Dialog를 Open할 때 첫 번째 사진으로 설정
      if (!opened && receiptImages.length > 0)
        setReceiptImageUrl(receiptImages[0].url);
      return !opened;
    });
  }, [receiptImages]);

  const uploadReceiptFile = useCallback(
    async (event) => {
      const files: File[] = Array.from(event.target.files);
      if (hasInvalidFile(files)) return;

      try {
        setIsReceiptUploading(true);

        const resizedFiles: File[] = [];
        for (const file of files) {
          const resized = await getResizedFile(file);
          resizedFiles.push(resized);
        }

        const results = await uploadDeliveryReceipts(order.id, resizedFiles);
        const newImages =
          Array.isArray(results) &&
          results.reduce(
            (
              arr: ReceiptImgType[],
              { success, key: filename, url }: ReceiptUploadType
            ) => (success ? [...arr, { filename, url }] : arr),
            []
          );

        setReceiptImages((images) => images.concat(newImages));
        openSnackbar("인수증 첨부를 완료했습니다.");
      } catch (err) {
        const msg = `인수증 첨부를 실패했습니다.\n${(err as Error).message}`;
        openSnackbar(msg, true);
      } finally {
        setIsReceiptUploading(false);
      }

      function hasInvalidFile(files: File[]) {
        if (!files.length) return true;
        if (receiptImages.length + files.length > MAX_FILE_COUNTS) {
          openSnackbar(
            `저장 가능한 인수증의 최대 개수는 ${MAX_FILE_COUNTS}장입니다.`,
            true
          );
          return true;
        }

        return [
          [
            "지원하지 않는 파일 형식입니다.",
            (file: File) => !file.type.startsWith("image/"),
          ],
          [
            "20MB를 초과하는 파일은 지원하지 않습니다.",
            (file: File) => file.size / 1024 / 1024 > 20,
          ],
        ].some(([msg, isFileInvalid]: any) => {
          const invalidFile = files.find(isFileInvalid);

          if (invalidFile !== undefined)
            openSnackbar(`${msg}\n(파일 이름: ${invalidFile.name})`, true);
          return invalidFile !== undefined;
        });
      }

      async function getResizedFile(file: File): Promise<File> {
        const reader = new FileReader();
        await new Promise<void>((resolve, reject) => {
          reader.onload = () => resolve();
          reader.onerror = () => reject(new Error("파일 로드에 실패했습니다."));
          reader.readAsDataURL(file as File);
        });

        await new Promise<void>((resolve, reject) => {
          if (resizedRef.current) {
            resizedRef.current.onload = () => resolve();
            resizedRef.current.onerror = () =>
              reject(new Error("이미지 로드에 실패했습니다."));
            resizedRef.current.src = reader.result as string;
          }
        });

        const { w, h } = getImageSize();
        const canvas = document.createElement("canvas");
        canvas.width = w;
        canvas.height = h;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(resizedRef.current as HTMLImageElement, 0, 0, w, h);

        return new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (!blob)
              return reject(new Error("파일 리사이징에 실패했습니다."));

            const newFile = new File([blob], file.name, { type: blob.type });
            resolve(newFile);
          }, file.type);
        });
      }

      function getImageSize() {
        if (!resizedRef.current) return { w: 0, h: 0 };

        const { naturalWidth: w, naturalHeight: h } = resizedRef.current;
        if (Math.max(h, w) > MAX_FILE_HEIGHT)
          return h > w
            ? { w: (w / h) * MAX_FILE_HEIGHT, h: MAX_FILE_HEIGHT }
            : { w: MAX_FILE_HEIGHT, h: (h / w) * MAX_FILE_HEIGHT };
        return { w, h };
      }
    },
    [order, receiptImages]
  );

  const deleteReceiptFile = (file: ReceiptImgType) => async () => {
    try {
      setDeletingReceiptFilename(file.filename);
      await delteDeliveryReceipt(file.filename);

      const images = receiptImages.filter(
        ({ filename }) => filename !== file.filename
      );
      setReceiptImages(images);
      setReceiptImageUrl((url) => (url === file.url ? images[0].url : url));
      openSnackbar("인수증 삭제를 완료했습니다.");
    } catch (err) {
      const msg = `인수증 삭제를 실패했습니다.\n${(err as Error).message}`;
      openSnackbar(msg, true);
    } finally {
      setDeletingReceiptFilename("");
    }
  };

  return (
    <>
      {order?.id && (
        <OrderDetail
          row={order}
          changeable={changeable}
          onChange={onChange}
          onSubmit={onSubmit}
          handleReOrder={handleReOrder}
          orderUser={orderUser}
          setOrderUser={setOrderUser}
          submitOrderUser={submitOrderUser}
          closeOrderUser={closeOrderUser}
          deleteOrderUser={deleteOrderUser}
          deleteRelatedUser={deleteRelatedUser}
          inputOpen={inputOpen}
          setInputOpen={setInputOpen}
          handleOrderSync={handleOrderSync}
          handleExternalNumber={handleExternalNumber}
          kakaoLog={kakaoLog}
          handleKakaoLog={handleKakaoLog}
          kakao={kakao}
          kakaoOnChange={kakaoOnChange}
          kakaoSubmit={kakaoSubmit}
          handleCall24Api={handleCallOrderPopup}
          handleCancelCard={handleCancelCard}
          repaymentCard={repaymentCard}
          handleOrderSwap={handleOrderSwap}
          isTextMessageDialogOpen={isTextMessageDialogOpen}
          setTextMessageDialogOpen={setTextMessageDialogOpen}
          handleCloseDialog={closeTextMessageDialog}
          handleChangeDialog={handleChangeDialog}
          textMessage={textMessage}
          sendTextMessage={sendTextMessage}
          receiptImageUrl={receiptImageUrl}
          receiptImages={receiptImages}
          handleSetReceiptImageUrl={setReceiptImageUrl}
          isReceiptDialogOpen={isReceiptDialogOpen}
          handleToggleIsReceiptDialogOpen={toggleIsReceiptDialogOpen}
          handleSendReceiptKakao={sendReceiptKakao}
          receiptFileMaxCnt={MAX_FILE_COUNTS}
          isReceiptUploading={isReceiptUploading}
          handleUploadReceiptFile={uploadReceiptFile}
          deletingReceiptFilename={deletingReceiptFilename}
          handleDeleteReceiptFile={deleteReceiptFile}
          openUseStateModal={openUseStateModal}
          useStatementDisabled={useStatementDisabled}
        />
      )}
      {/* {!order && <NotFound />} */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
      {reOrder && (
        <ReOrderDetailContainer
          reOrder={reOrder}
          reOrderPopup={reOrderPopup}
          setReOrderPopup={setReOrderPopup}
          ReOrderOnChange={ReOrderOnChange}
          ReOrderOnSubmit={ReOrderOnSubmit}
        />
      )}
      {call24 && (
        <Call24OrderDetailContainer
          callOrderPopup={callOrderPopup}
          setCallOrderPopup={setCallOrderPopup}
          order={order}
          riderList={riderList}
          call24={call24}
          handleCallOrderOnChange={handleCallOrderOnChange}
          handleCall24Api={handleCall24Api}
        />
      )}
      {cancelCardPopup && (
        <CancelCardContainer
          cancelCardPopup={cancelCardPopup}
          setCancelCardPopup={setCancelCardPopup}
          card={card}
          handleCancelCardChange={handleCancelCardChange}
          submitCancelCard={submitCancelCard}
        />
      )}
      {swapPopup && (
        <OrderSwapContainer
          swapPopup={swapPopup}
          setSwapPopup={setSwapPopup}
          order={order}
          fetchData={fetchData}
        />
      )}
      {statementOpen && (
        <UseStatementModal
          row={order}
          modalRef={modalRef}
          outSideUseStateModal={outSideUseStateModal}
          closeUseStateModal={closeUseStateModal}
        />
      )}
      <img ref={resizedRef} alt="미리보기" style={{ display: "none" }} />
    </>
  );
}

export default withRouter(OrderDetailContainer);
