import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import { Button } from "@material-ui/core";
import { listReviews } from "../../lib/hasura/orders";
import { ListReviewType } from "../../components/riderReview/ReviewListType";
// import { formatDate } from "../../lib/datetime";
import CircularLoading from "../../components/common/CircularLoading";
// import * as XLSX from "xlsx";
import ReviewList from "../../components/riderReview/ReviewList";

function ReviewContainer() {
  // loading
  const [loading, setLoading] = useState<boolean>(false);

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [page, setPage] = useState<number>(0);

  // reviews
  const [reviews, setReviews] = useState<ListReviewType[]>([]);
  const [reviewsCount, setReviewsCount] = useState<number>(0);

  const fetchData = async () => {
    setLoading(true);

    await listReviews()
      .then((res) => {
        const { select_orderreview_list } = res.data.data;
        const reviews = select_orderreview_list.map(
          (review: ListReviewType) => ({
            ...review,
            checkerFullname: review.fullname || "",
          })
        );
        setReviews(reviews);
        setReviewsCount(select_orderreview_list.length);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData()
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  /* pagination */
  const handleChangePage = (
    event: React.MouseEvent | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseFloat(event.target.value)); // 25 50 100 페이지 뷰
    setPage(0); // 페이지 뷰 변할때마다 첫페이지 렌더링
  };

  /* 불친절 파손 지연 => 엑셀 다운로드시 O/X로 변경 */
  // const formatBoolean = (data: boolean) => {
  //   if(data) return "O";
  //   else return "X";
  // };

  // const excelDownload = () => {
  //   let fileName = `기사 평점 조회(다운일자 ${new Date().toISOString().split("T")[0]}).xlsx`;

  //   const excelOrders = reviews.map((el: ListReviewType) => {
  //     const {
  //       created,
  //       id,
  //       sender_address,
  //       sender_name,
  //       receiver_address,
  //       receiver_name,
  //       rider_name,
  //       rider_phone_number,
  //       is_broken,
  //       is_delayed,
  //       is_unkind,
  //       review,
  //       rider_score
  //     } = el;

  //     return {
  //       등록시간: formatDate(created),
  //       주문번호: id,
  //       출발지주소: sender_address,
  //       출발지이름: sender_name,
  //       도착지주소: receiver_address,
  //       도착지이름: receiver_name,
  //       기사이름: rider_name,
  //       기사번호: rider_phone_number,
  //       불친절: formatBoolean(is_unkind),
  //       파손: formatBoolean(is_broken),
  //       지연: formatBoolean(is_delayed),
  //       후기: review,
  //       평점: rider_score
  //     };
  //   });

  //   const workSheet = XLSX.utils.json_to_sheet(excelOrders);
  //   const workBook = XLSX.utils.book_new();
  //   /* Append a worksheet "to a workbook" */
  //   XLSX.utils.book_append_sheet(workBook, workSheet, "평점내역");

  //   /* Attempts to write or download workbook data "to a file" */
  //   const output = XLSX.writeFile(workBook, fileName);
  //   return new Blob([output], { type: "application/xlsx" });
  // };

  return (
    <>
      {/* <ExcelWrapper>
        <Button
          onClick={excelDownload}
          variant="contained"
          color="primary"
          size="medium"
        >
          엑셀 다운로드
        </Button>
      </ExcelWrapper> */}
      {loading ? (
        <CircularLoading />
      ) : (
        <ReviewList
          rows={reviews}
          count={reviewsCount}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeFirst={handleChangeFirst}
        />
      )}
    </>
  );
}

export default ReviewContainer;

export const ExcelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 0 auto;
  width: 10rem;
  height: 3rem;
`;
