import React from "react";

import { Card } from "@material-ui/core";

import styled from "styled-components";

type receiverNumber = number[];
type ResultProp = {
  open: boolean;
  receiverNumbers: receiverNumber[];
};

const hexcodes = [
  "#B71C1C",
  "#FF6F00",
  "#FDD835",
  "#388E3C",
  "#1976D2",
  "#283593",
  "#7B1FA2",
  "#5D4037",
  "#424242",
  "#C2185B",
];

const ResultCard = styled(Card)`
  margin: 10px 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;

  &:hover {
    transition: 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
`;

type PathColorIconProps = {
  readonly arrowColor: string;
};

const PathColorIcon = styled.div<PathColorIconProps>`
  background-color: ${(props) => props.arrowColor};

  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
`;

const AddressWrapper = styled.div`
  margin: 5px 0;

  width: 100%;

  text-align: start;
`;

const ResultList = styled.ul`
  max-height: 37%;
  overflow-y: scroll;
`;

export default function Result({ open, receiverNumbers }: ResultProp) {
  if (!open) return <></>;

  return (
    <ResultList>
      {receiverNumbers.map((receiverNumber, index) => (
        <li key={`${index + 1}_${index}`}>
          <ResultCard>
            <PathColorIcon arrowColor={hexcodes[index]} />
            <AddressWrapper>
              <span>{`묶음 ${index + 1}. 출발지`}</span>
              {receiverNumber.map((number) => (
                <span key={number}>{` ➡ 도착지 ${number}`}</span>
              ))}
            </AddressWrapper>
          </ResultCard>
        </li>
      ))}
    </ResultList>
  );
}
