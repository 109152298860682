export type Corporation = {
  id: string;
  name: string;
};

export type DepartState = {
  name: string;
  limit_budget: number;
};

export type DepartDataLabel = typeof DEPART_DATA_LABEL;
export const DEPART_DATA_LABEL = {
  name: "부서명",
  limit_budget: "예산한도",
  administrator: "관리자",
  address_book_enable: "부서 주소록 사용여부",
  department_credit_enable: "부서 크레딧 사용여부",
  department_credit: "부서 크레딧",
  corporate_credit: "현재 잔여 법인 크레딧",
};

export type DepartmentData = typeof DEFAULT_ADD_DEPART & {
  id?: string;
  corporation_name?: string;
  address_book_enable: boolean;
};

export const DEFAULT_ADD_DEPART = {
  corporation_id: "",
  name: "",
  limit_budget: 0,
  administrator: "",
  address_book_enable: false,
  department_credit_enable: false,
  department_credit: 0,
  corporate_credit: 0,
};

export const DEFAULT_DEPART = {
  id: "",
  name: "",
  limit_budget: 0,
  administrator: "",
  address_book_enable: false,
  department_credit: 0,
  department_credit_enable: false,
  corporate_credit: 0,
  corporation_id: "",
  corporation_name: "",
};
