import styled from "styled-components";
import { InputLabel, TextField } from "@material-ui/core";

export const CenterWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FlexBox = styled.div`
  display: flex;
`;

export const FlexBoxWith100 = styled(FlexBox)`
  width: 100%;
`;

export const FlexCenterSpaceBetween = styled(FlexBox)`
  align-content: center;
  justify-content: space-between;
`;

export const FlexColumnCenter = styled(FlexBox)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FlexColumnCenterHalf = styled(FlexColumnCenter)`
  width: 50%;
`;

export const FlexRowCenter = styled(FlexBox)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const FlexRow = styled(FlexBox)`
  flex-direction: row;
`;

export const MarginAutoDiv = styled.div`
  margin: auto;
`;

export const MarginTopSelectDiv = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const ContainerWrapper = styled.div`
  height: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: -5rem 0 0 0;
`;

export const RowDivider = styled.div`
  height: ${({ size }: { size?: string }) => size || 1}rem;
`;

export const ColumnDivider = styled.div`
  width: ${({ size }: { size?: string }) => size || 1}rem;
`;

export const FlexBoxContentEnd = styled(FlexBox)`
  justify-content: flex-end;
`;

export const CustomInputLabel = styled(InputLabel)`
  padding: 0 5px !important;
  background-color: #fff;
`;

// 적용하는 상단 컴포넌트에 position: relative 추가
interface CustomLegendProps {
  top?: string;
  font?: string;
}
export const CustomLegend = styled.legend<CustomLegendProps>`
  position: absolute;
  top: ${({ top }: { top?: string }) => top || -12}px; // Cast to string
  left: 9px;
  text-align: center;
  padding: 0 10px;

  font-size: ${({ font }: { font?: string }) => font || 18}px;
  color: rgba(0, 0, 0, 0.6);
  background-color: #fff;
`;

// TextField width 커스터마이징
interface CustomTextFieldProps {
  width?: string;
}
export const CustomTextField = styled(TextField)<CustomTextFieldProps>`
  width: ${(props) => props.width || "50%"};
`;
