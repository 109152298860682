import styled from "styled-components";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { WeatherChargeType } from "../../containers/corporation/extraTypes";
import { VehicleTypes } from "../../containers/corporation/utils";

interface WeatherChargeListProps {
  weatherData: WeatherChargeType[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function ExtraWeatherChargeList({
  weatherData,
  onChange,
}: WeatherChargeListProps) {
  const classes = useStyles();

  const sortedWeatherList = weatherData.sort(
    (a, b) =>
      VehicleTypes.indexOf(a.vehicle_type) -
      VehicleTypes.indexOf(b.vehicle_type)
  );

  return (
    <TableBox>
      <TableHead className={classes.root}>
        <TableRow>
          <TableCell align="center"></TableCell>
          <TableCell align="center">오토바이</TableCell>
          <TableCell align="center">다마스</TableCell>
          <TableCell align="center">라보</TableCell>
          <TableCell align="center">트럭</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="center">요금(원)</TableCell>
          {sortedWeatherList.map(({ vehicle_type, weather_charge }, idx) => (
            <TableCell key={idx} align="center">
              <StyledTextField
                type="number"
                id={vehicle_type}
                name={vehicle_type}
                defaultValue={weather_charge}
                variant="outlined"
                margin="normal"
                inputProps={{
                  min: 0,
                  step: 1000,
                  style: { textAlign: "right" },
                }}
                onChange={onChange}
              />
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </TableBox>
  );
}

export default ExtraWeatherChargeList;

const useStyles = makeStyles(() => ({
  root: { backgroundColor: "#E7E6E6" },
}));

const TableBox = styled(Table)`
  margin: 1rem auto;
  width: 80% !important;
  border: 1px solid #e7e6e6;
`;

const StyledTextField = styled(TextField)`
  margin: 0;
  width: 50% !important;
`;
