import { useMemo } from "react";
import styled from "styled-components";
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ko from "date-fns/locale/ko";
import { ExceptHolidayType } from "../../containers/corporation/extraTypes";

interface ExceptHolidayListProps {
  addHoliday: ExceptHolidayType[];
  holidayData: ExceptHolidayType[];
  onChange: (date: Date | null) => void;
  addDelete: (date: string) => void;
  onDelete: (date: string) => void;
}

function ExtraExceptHolidayList({
  addHoliday,
  holidayData,
  onChange,
  addDelete,
  onDelete,
}: ExceptHolidayListProps) {
  const classes = useStyles();

  const sortHolidays = (holidays: ExceptHolidayType[]) => {
    return holidays.sort(
      (a: ExceptHolidayType, b: ExceptHolidayType) =>
        new Date(a.except_date).getTime() - new Date(b.except_date).getTime()
    );
  };
  const sortedAddHoliday = useMemo(
    () => sortHolidays(addHoliday),
    [addHoliday]
  );
  const sortedHolidayData = useMemo(
    () => sortHolidays(holidayData),
    [holidayData]
  );

  return (
    <ExceptHolidayWrapper>
      {holidayData.length > 0 && (
        <TableBox>
          <TableHead className={classes.root}>
            <TableRow>
              <TableCell align="center">제외 휴일 날짜</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedHolidayData.map(({ except_date, id }) => (
              <TableRow key={id}>
                <TableCell align="center">{except_date}</TableCell>
                <TableCell align="center">
                  <Button onClick={() => onDelete(except_date)}>
                    <Close />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableBox>
      )}
      <section>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
          <MobileDatePicker
            className={classes.datePicker}
            label="제외휴일 등록"
            format="yyyy-MM-dd"
            value={new Date()}
            slotProps={{
              actionBar: { actions: [] },
              day: { disableMargin: true },
              textField: { variant: "standard", margin: "normal" },
              toolbar: { toolbarFormat: "MM월 dd일", hidden: false },
            }}
            closeOnSelect
            onChange={onChange}
          />
        </LocalizationProvider>
        <AddDateBox>
          {sortedAddHoliday.map(({ except_date }, i) => (
            <div className="date-wapper" key={i}>
              <span>{except_date}</span>
              <StyledClose onClick={() => addDelete(except_date)} />
            </div>
          ))}
        </AddDateBox>
      </section>
    </ExceptHolidayWrapper>
  );
}

export default ExtraExceptHolidayList;

const useStyles = makeStyles(() => ({
  root: { backgroundColor: "#E7E6E6" },
  datePicker: {
    width: "400px",
  },
}));

const ExceptHolidayWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 50%;
`;

const TableBox = styled(Table)`
  margin: 1rem auto;
  height: fit-content;
  border: 1px solid #e7e6e6;
`;

const AddDateBox = styled.section`
  margin: 1rem auto 0;
  padding: 1.5rem 5rem;
  width: 400px;
  min-height: 150px;
  border: 1px solid #d3d3d3;

  .date-wapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
  }
`;

const StyledClose = styled(Close)`
  height: 30px;
  line-height: 30px;
  :hover {
    cursor: pointer;
  }
`;
