import React from "react";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import ko from "date-fns/locale/ko";
import DateFnsUtils from "@date-io/date-fns";

type sender = {
  emailAddress: string;
  emailTitle: string;
  emailText: string;
};
type SenderMailInput = {
  sender: sender;
  billDate: string;
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onDateChange: (date: MaterialUiPickersDate) => void;
};

export default function SenderMailInput({
  sender,
  billDate,
  onChange,
  onDateChange,
}: SenderMailInput) {
  const { emailAddress, emailTitle, emailText } = sender;
  const date = billDate ? new Date(billDate) : null;

  return (
    <InputWrapper>
      <TextField
        margin="normal"
        label="발신 이메일 주소"
        placeholder="xxx@algolab.kr"
        variant="outlined"
        fullWidth
        name="emailAddress"
        value={emailAddress}
        onChange={onChange}
      />
      <TextField
        margin="normal"
        label="이메일 제목"
        placeholder="[정산단위명] XXXX 년 X월 사용내역서 입니다."
        variant="outlined"
        fullWidth
        name="emailTitle"
        value={emailTitle}
        onChange={onChange}
      />
      <TextField
        margin="normal"
        label="이메일 본문"
        placeholder="알고퀵 입니다. [정산단위명]의 XXXX 년 X월 사용내역서를 다음과 같이 첨부하였습니다~~ "
        variant="outlined"
        fullWidth
        name="emailText"
        multiline
        minRows={8}
        value={emailText}
        onChange={onChange}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ko}>
        <DatePicker
          variant="dialog"
          openTo="year"
          name="date"
          fullWidth
          views={["year", "month"]}
          label="정산일자"
          format="yyyy년 MM월"
          helperText="정산년도와 월을 선택하세요"
          value={date}
          onChange={onDateChange}
        />
      </MuiPickersUtilsProvider>
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
`;
