import { CORPVEHICLE } from "../../lib/constants/constants";
import {
  lowerFiveTonTruck,
  upperFiveTonTruck,
} from "../../lib/hasura/corporations";
import { AlgoquickChargeType, hasuraTruckOptionsToKorean } from "./types";
import {
  extractTruckTons,
  extractTruckTypes,
  extractTruckLift,
  extractTruckOpts,
  registTruckCharge,
} from "./utils";

// 차량종류(트럭) 조합
export const truckNaming = (truckData: string) => {
  const truckWeight = extractTruckTons(truckData);
  const type = extractTruckTypes(truckData);
  const lift = extractTruckLift(truckData);

  const transType = type ? hasuraTruckOptionsToKorean[type] : "";
  const transLift = lift ? hasuraTruckOptionsToKorean[lift] : "";
  return `${CORPVEHICLE[truckWeight]}${transType}${transLift}`;
};

// 표준요금 조합
export const defaultBaseCharge = (
  charge_algoquickcharge: AlgoquickChargeType,
  vehicle_type: string
) => {
  let truckWeight = "";
  let truckData = "";
  let truckCharge = 0;

  switch (vehicle_type) {
    case "motorcycle":
    case "damas":
    case "labo":
      return charge_algoquickcharge[vehicle_type];
    default: {
      truckWeight = extractTruckTons(vehicle_type);
      truckCharge = charge_algoquickcharge[truckWeight];
      truckData = extractTruckOpts(vehicle_type);

      return isTruckTons(truckWeight, truckCharge, truckData);
    }
  }
};

const isTruckTons = async (
  truckWeight: string,
  truckCharge: number,
  truckData: string
) => {
  switch (truckWeight) {
    case "truck_1t":
    case "truck_1_4t":
    case "truck_2_5t":
    case "truck_3_5t":
    case "truck_5t": {
      const truckOptStr = registTruckCharge(truckData).slice(1);
      const result = await lowerFiveTonTruck(truckCharge, truckOptStr);
      return result[truckOptStr];
    }
    // 5톤 초과
    default: {
      const truckOptStr = registTruckCharge(truckData).slice(1);
      const result = await upperFiveTonTruck(truckCharge, truckOptStr);
      return result[truckOptStr];
    }
  }
};
