import React from "react";
import styled from "styled-components";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import DepartmentsList from "../departments/DepartsmentsList";
import UsersList from "../users/UsersList";
import ProjectsList from "../projects/ProjectsList";
import {
  ChangeColumnOptionHandler,
  ExcelColumnOption,
} from "../../containers/corporation/types";
import { CorporateDept } from "../../containers/departments/types";
import { UsersListType } from "../../containers/users/types";

interface ManageCorporateStuffProps {
  deptOpened: boolean;
  departments: CorporateDept[];
  userOpened: boolean;
  users: UsersListType[];
  columnOpened: boolean;
  columnOptions: ExcelColumnOption[];
  projectOpened: boolean;
  handleChangeColumnOption: ChangeColumnOptionHandler;
}

function ManageCorporateStuff({
  deptOpened = false,
  departments = [],
  userOpened = false,
  users = [],
  columnOpened = false,
  columnOptions = [],
  projectOpened = false,
  handleChangeColumnOption,
}: ManageCorporateStuffProps) {
  const notVisible = [
    deptOpened,
    userOpened,
    columnOpened,
    projectOpened,
  ].every((opened) => !opened);
  if (notVisible) return <></>;

  return (
    <Box>
      {deptOpened && <DepartmentsList rows={departments} />}
      {userOpened && <UsersList rows={users} />}
      {projectOpened && <ProjectsList />}
      {columnOpened && (
        <FormGroup row>
          {columnOptions.map((opt) => (
            <StyledFormControlLabel
              key={opt.key}
              control={
                <Checkbox
                  checked={opt.value}
                  onChange={handleChangeColumnOption}
                  name={opt.key}
                />
              }
              label={opt.label}
              labelPlacement="start"
            />
          ))}
        </FormGroup>
      )}
    </Box>
  );
}

export default React.memo(ManageCorporateStuff);

const Box = styled.div`
  padding: 1rem 0 3rem;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-bottom: 10px;
    margin-left: 25px;

    > .MuiTypography-body1 {
      font-size: 0.9rem;
    }
  }
`;
