import graphqlQuery from "./base";

export const getCorporateCreditHistory = (gte: Date, lt: Date, cid: string) =>
  graphqlQuery(`{
  orders_credit(
    where: {
      _and: {
        created: {
          _gte: "${gte.toISOString()}", 
          _lt: "${lt.toISOString()}"
        }, 
        corporation_id: {_eq: "${cid}"}
      }
    }
  ) {
    id
    created
    order_id
    credit_amt
    current_credit
    credit_type
    query_type
    memo
  }
}`);
